<div class="card tlb--max-screen-height">
  <div class="mb-4" *ngIf="isEditMode; else viewToolbar">
    <button
      class="p-button-success"
      pButton
      pRipple
      label="Enregistrer"
      icon="pi pi-check"
      (click)="save()"
    ></button>

    <button
      class="p-button-outlined p-button-danger ml-2"
      pButton
      pRipple
      label="Annuler"
      icon="pi pi-times"
      (click)="cancelEditMode()"
    ></button>
  </div>

  <ng-template #viewToolbar>
    <div class="mb-4">
      <button
        class="p-button-warning"
        pButton
        pRipple
        label="Modifier"
        icon="pi pi-pencil"
        (click)="enterEditMode()"
      ></button>
    </div>
  </ng-template>

  <div class="grid w-full">
    <div class="col-12 md:col-6">
      <p-orderList
        [stripedRows]="true"
        [dragdrop]="true"
        (onSelectionChange)="onSelectionChangeInFrench($event)"
        [value]="entries?.fr || []"
        [filterBy]="isEditMode ? 'question' : ''"
        filterPlaceholder="Filtrer par question"
        styleClass="w-full"
        [disabled]="!isEditMode"
        [styleClass]="
          isEditMode
            ? 'tlb--orderlist-with-filters-and-controls'
            : 'tlb--orderlist-without-filters-or-controls'
        "
      >
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center">
              <img class="mr-2" src="assets/flags/fr.svg" height="20" />

              <div class="p-orderlist-title">F.A.Q. en français</div>
            </div>

            <div>
              <p-button
                icon="pi pi-pencil"
                styleClass="p-button-warning mr-2"
                (click)="editEntryInFrench()"
                *ngIf="isEditMode && isSelectionEditPossible['fr']"
              ></p-button>

              <p-button
                icon="pi pi-trash"
                styleClass="p-button-danger mr-2"
                (click)="deleteEntryInFrench()"
                *ngIf="isEditMode && isSelectionEditPossible['fr']"
              ></p-button>

              <p-button
                icon="pi pi-plus"
                (click)="addEntryInFrench()"
                *ngIf="isEditMode"
              ></p-button>
            </div>
          </div>
        </ng-template>

        <ng-template let-entry pTemplate="item">
          <ng-container *ngLet="asEntry(entry) as entry">
            <div
              class="flex align-items-center p-2 w-full flex-wrap"
              *ngIf="entry"
            >
              <div class="flex-1">
                <h5 class="mb-2">{{ entry.question }}</h5>

                <p class="vertical-align-middle line-height-1">
                  {{ entry.answer }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="empty">
          <ng-container *ngTemplateOutlet="notSet"></ng-container>
        </ng-template>

        <ng-template pTemplate="emptyfilter">
          <ng-container *ngTemplateOutlet="notFound"></ng-container>
        </ng-template>
      </p-orderList>
    </div>

    <div class="col-12 md:col-6">
      <p-orderList
        [stripedRows]="true"
        [dragdrop]="true"
        (onSelectionChange)="onSelectionChangeInArabic($event)"
        [value]="entries?.ar || []"
        [filterBy]="isEditMode ? 'question' : ''"
        filterPlaceholder="Filtrer par question"
        styleClass="w-full"
        [disabled]="!isEditMode"
        [styleClass]="
          isEditMode
            ? 'tlb--orderlist-with-filters-and-controls'
            : 'tlb--orderlist-without-filters-or-controls'
        "
      >
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center">
              <img class="mr-2" src="assets/flags/ma.svg" height="20" />

              <div class="p-orderlist-title">F.A.Q. en arabe</div>
            </div>

            <div>
              <p-button
                icon="pi pi-pencil"
                styleClass="p-button-warning mr-2"
                (click)="editEntryInArabic()"
                *ngIf="isEditMode && isSelectionEditPossible['ar']"
              ></p-button>

              <p-button
                icon="pi pi-trash"
                styleClass="p-button-danger mr-2"
                (click)="deleteEntryInArabic()"
                *ngIf="isEditMode && isSelectionEditPossible['ar']"
              ></p-button>

              <p-button
                icon="pi pi-plus"
                (click)="addEntryInArabic()"
                *ngIf="isEditMode"
              ></p-button>
            </div>
          </div>
        </ng-template>

        <ng-template let-entry pTemplate="item">
          <ng-container *ngLet="asEntry(entry) as entry">
            <div
              class="flex align-items-center p-2 w-full flex-wrap"
              *ngIf="entry"
            >
              <div class="flex-1 text-right" dir="rtl">
                <h5 class="mb-2">{{ entry.question }}</h5>

                <p class="vertical-align-middle line-height-1">
                  {{ entry.answer }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="empty">
          <ng-container *ngTemplateOutlet="notSet"></ng-container>
        </ng-template>

        <ng-template pTemplate="emptyfilter">
          <ng-container *ngTemplateOutlet="notFound"></ng-container>
        </ng-template>
      </p-orderList>
    </div>
  </div>
</div>

<ng-template #notFound>
  <div class="text-pink-600 font-italic">
    <div class="p-editor-content ql-snow" style="padding: 12px 15px">
      Question non trouvée
    </div>
  </div>
</ng-template>

<ng-template #notSet>
  <div class="text-pink-600 font-italic">
    <div class="p-editor-content ql-snow" style="padding: 12px 15px">
      Non défini
    </div>
  </div>
</ng-template>

<p-dialog
  header="{{ editHeader + editHeaderLanguage }}"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '640px' }"
  [(visible)]="isEditDialogVisible"
  *ngIf="editHeader && editHeaderLanguage"
>
  <div>
    <div class="p-error block" *ngIf="messageError">
      La question et la réponse sont requises
    </div>

    <div class="w-full">
      <input
        class="w-full my-2"
        type="text"
        [dir]="entryLanguage === 'ar' ? 'rtl' : 'ltr'"
        [(ngModel)]="entryQuestion"
        pInputText
        required
        maxlength="60"
        (keyup)="messageError = entryQuestion === ''"
      />

      <p>
        {{ 60 - entryQuestion.length }}
        caractères restants.
      </p>
    </div>

    <div class="w-full">
      <textarea
        class="w-full my-2"
        [dir]="entryLanguage === 'ar' ? 'rtl' : 'ltr'"
        [(ngModel)]="entryAnswer"
        pInputTextarea
        required
        maxlength="1024"
        [autoResize]="true"
        [cols]="30"
        [rows]="5"
        style="min-height: 109px"
        (keyup)="messageError = entryAnswer === ''"
      ></textarea>

      <p>
        {{ 1024 - entryAnswer.length }}
        caractères restants.
      </p>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-times"
      label="Annuler"
      (click)="isEditDialogVisible = false"
    ></button>

    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-check"
      label="Enregistrer"
      (click)="saveEntry()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isDeleteDialogVisible"
>
  <div class="flex align-items-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="editedEntry"
      >Voulez-vous supprimer l’entrée <b>{{ editedEntry.question }}</b> ?</span
    >
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isDeleteDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmDeleteEntry()"
    ></button>
  </ng-template>
</p-dialog>
