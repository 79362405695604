import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialApi } from '@be-green/api-services';
import { MaterialDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { MenuItem } from 'primeng/api';
import { first } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--materials-view',
  templateUrl: './materials-view.component.html',
  styleUrls: ['./materials-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsViewComponent implements OnInit {
  action?: 'delete';
  actionDefaultStyleClass = 'p-button-warning';
  actionMenuItems: MenuItem[] = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      styleClass: 'p-menuitem-warning',
      command: () => {
        this.edit();
      },
    },

    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      styleClass: 'p-menuitem-danger',
      command: () => {
        this.delete();
      },
    },
  ];

  confirmationDialogMessage = '';
  isConfirmDialogVisible = false;
  isLoading = false;
  material?: MaterialDto;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly materialApi: MaterialApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Matériaux' }]);
    this.seoService.setTitle('Matériaux - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.materialApi.getOne(code).subscribe({
      next: (material) => {
        this.material = material;

        this.layoutService.registerBreadcrumbs([
          { label: 'Matériaux', routerLink: '/materials' },
          { label: `${this.material.nameFr}` },
        ]);

        this.seoService.setTitle(
          `${this.material.nameFr} - Matériaux - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  confirm() {
    switch (this.action) {
      case 'delete':
        this._delete();
    }
  }

  private _delete() {
    if (!this.material) {
      return;
    }

    if (this.action === 'delete') {
      this.isLoading = true;

      this.materialApi
        .delete(this.material.code)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.router.navigate(['materials']);
          },

          error: async (error: HttpErrorResponse) => {
            await this.errorService.handleError(error);

            this.isLoading = false;
            this.isConfirmDialogVisible = false;
            this.action = undefined;
          },
        });
    }
  }

  private delete() {
    if (!this.material) {
      return;
    }

    this.action = 'delete';

    this.confirmationDialogMessage = `<p>Êtes-vous sûr de vouloir <b>supprimer</b> le matériau <b>${this.material.nameFr}</b>?</p>`;

    this.isConfirmDialogVisible = true;
  }

  edit() {
    if (!this.material) {
      return;
    }

    this.router.navigate(['materials', this.material.code, 'update']);
  }
}
