export const enum BasketStatusCode {
  /**
   *                                   ┌═══════════════┐
   *                                   │     DRAFT     │
   *                                   └═══════┬═══════┘
   *                                           │
   *                               ╔───────────▼───────────╗
   *                               ║ USER OR LEVEL-1 HNDLR ║
   *                               ║ BASKET OF PRO PRGRMS  ║
   *                               ║ || L1 HANDLER BASKET  ║
   *              ┌────── NO ──────║ OF GOOD DEALS WITH    ║───── YES ──────┐
   *              │                ║ RECYCL BINS || L1 HDLR║                │
   *              │                ║ DELAYED APPROVAL PRG. ║                │
   *              │                ╚───────────────────────╝                │
   *              │                                                 ╔───────▼───────╗
   *              │                                                 ║ LVL-1 HANDLER ║─────┐
   *              │                                                 ╚───────┬───────╝     │
   *              │                                                         │             │
   *              │                                                         NO           YES
   *              │                                                         │             │
   *      ┌───────▼───────┐                                         ┌───────▼───────┐     │
   *      │   VALIDATED   │                                         │   DEPOSITED   │     │
   *      └───────┬───────┘                                         └───────┬───────┘     │
   *              │                                                         │             │
   *              ├───────────────────────────┐ ┌───────────────────────────┤             │
   *              │                           │ │                           │             │
   *      ┌───────▼───────┐                   │ │                   ┌───────▼───────┐     │
   *      │    SCANNED    ├───────────────────⌒─⌒├─────────────────▶│   COLLECTED   │◀────┘
   *      └───────┬───────┘                   │ │                   └───────┬───────┘
   *              │                           │ │                           │
   *              ├─────────────────────────┐ │ │ ┌─────────────────────────┤
   *              │                         │ │ │ │                         │
   *      ┌═══════▼═══════┐            ┌════▼═▼═▼═▼════┐                    │
   *      │   APPROVED    │            │    EXPIRED    │                    │
   *      └═══════▲═══════┘            └═══════════════┘                    │
   *              │                                                         │
   *              └─────────────────────────────────────────────────────────┘
   */

  /**
   * Approved by a collector.
   * ------------------------
   * Previous:
   * 1) SCANNED for non-Pro Programs
   * 2) COLLECTED for:
   *    - Users of Pro Programs
   *    - Level-1 Handlers of Pro Programs
   *    - Level-1 Handlers of Good Deals with Recycling Bins
   *    - Level-1 Handlers of Delayed Approval Programs
   * END OF LIFECYCLE.
   */
  Approved = 'APPROVED',

  /**
   * Collected in a bin.
   * -----------------------------
   * ONLY FOR:
   * - USERS OF PRO PROGRAMS
   * - LEVEL-1 HANDLERS OF PRO PROGRAMS
   * - LEVEL-1 HANDLERS OF GOOD DEALS WITH RECYCLING BINS
   * - LEVEL-1 HANDLERS OF DELAYED APPROVAL PROGRAMS
   * -----------------------------
   * Previous:
   * - DRAFT (only for level-1 Handlers = bins)
   * - DEPOSITED (only for users)
   * Next: APPROVED or EXPIRED
   */
  Collected = 'COLLECTED',

  /**
   * Deposited by a user in a bin.
   * -----------------------------
   * ONLY FOR USERS OF PRO PROGRAMS
   * -----------------------------
   * Previous: DRAFT
   * Next: COLLECTED
   */
  Deposited = 'DEPOSITED',

  /**
   * Created.
   * --------
   * START OF LIFECYCLE.
   * Next:
   * 1) VALIDATED for:
   *    - Non-Pro Programs
   *    - Any Handler with Level > 1
   * 2) DEPOSITED for Users in pro Programs
   * 3) COLLECTED for:
   *    - Level-1 Handlers of Pro Programs
   *    - Level-1 Handlers of Good Deals with Recycling Bins
   *    - Level-1 Handlers of Delayed Approval Programs
   */
  Draft = 'DRAFT',

  /**
   * Expired.
   * ----------
   * Previous: :
   * 1) SCANNED or VALIDATED for:
   *    - Non-Pro Programs
   *    - Any Handler with Level > 1
   * 2) DEPOSITED for Users in pro Programs
   * 3) COLLECTED for:
   *    - Users of Pro Programs
   *    - Level-1 Handlers of Pro Programs
   *    - Level-1 Handlers of Good Deals with Recycling Bins
   *    - Level-1 Handlers of Delayed Approval Programs
   * END OF LIFECYCLE.
   */
  Expired = 'EXPIRED',

  /**
   * Scanned by a collector.
   * -----------------------
   * ONLY FOR:
   * - USERS OF NON-PRO PROGRAMS
   * - LEVEL > 1 HANDLERS
   * -----------------------
   * Previous: VALIDATED
   * Next: APPROVED or EXPIRED
   */
  Scanned = 'SCANNED',

  /**
   * Validated.
   * ----------
   * ONLY FOR:
   * - USERS OF NON-PRO PROGRAMS
   * - LEVEL > 1 HANDLERS
   * ----------
   * Previous: DRAFT
   * Next: SCANNED or EXPIRED
   */
  Validated = 'VALIDATED',
}

export class BasketStatusDto {
  readonly code!: string;

  readonly name!: string;

  readonly description?: string;
}
