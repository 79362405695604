import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@be-green/ui-guards';
import { ConfirmOnLeaveGuard, UiServicesModule } from '@be-green/ui-services';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AccountsIndexComponent } from './accounts/accounts-index/accounts-index.component';
import { ActivateAccessComponent } from './activate-access/activate-access.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ArticlesEditComponent } from './articles/articles-edit/articles-edit.component';
import { ArticlesEditStepsContentComponent } from './articles/articles-edit/steps/articles-edit-steps-content/articles-edit-steps-content.component';
import { ArticlesEditStepsGeneralComponent } from './articles/articles-edit/steps/articles-edit-steps-general/articles-edit-steps-general.component';
import { ArticlesEditStepsIllustrationComponent } from './articles/articles-edit/steps/articles-edit-steps-illustration/articles-edit-steps-illustration.component';
import { ArticlesEditStepsSettingsComponent } from './articles/articles-edit/steps/articles-edit-steps-settings/articles-edit-steps-settings.component';
import { ArticlesIndexComponent } from './articles/articles-index/articles-index.component';
import { ArticlesViewComponent } from './articles/articles-view/articles-view.component';
import { BasketsIndexComponent } from './baskets/baskets-index/baskets-index.component';
import { BasketsViewComponent } from './baskets/baskets-view/baskets-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { ErrorComponent } from './error/error.component';
import { FaqComponent } from './faq/faq.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HandlersIndexComponent } from './handlers/handlers-index/handlers-index.component';
import { HandlersViewComponent } from './handlers/handlers-view/handlers-view.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { MaterialsEditComponent } from './materials/materials-edit/materials-edit.component';
import { MaterialsEditStepsCo2ImpactComponent } from './materials/materials-edit/steps/materials-edit-steps-co2-impact/materials-edit-steps-co2-impact.component';
import { MaterialsEditStepsEnergyImpactComponent } from './materials/materials-edit/steps/materials-edit-steps-energy-impact/materials-edit-steps-energy-impact.component';
import { MaterialsEditStepsGeneralComponent } from './materials/materials-edit/steps/materials-edit-steps-general/materials-edit-steps-general.component';
import { MaterialsEditStepsIllustrationComponent } from './materials/materials-edit/steps/materials-edit-steps-illustration/materials-edit-steps-illustration.component';
import { MaterialsIndexComponent } from './materials/materials-index/materials-index.component';
import { MaterialsViewComponent } from './materials/materials-view/materials-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrganizationTypesEditComponent } from './organization-types/organization-types-edit/organization-types-edit.component';
import { OrganizationTypesIndexComponent } from './organization-types/organization-types-index/organization-types-index.component';
import { OrganizationTypesViewComponent } from './organization-types/organization-types-view/organization-types-view.component';
import { ProgramsEditComponent } from './programs/programs-edit/programs-edit.component';
import { ProgramsEditStepsDescriptionComponent } from './programs/programs-edit/steps/programs-edit-steps-description/programs-edit-steps-description.component';
import { ProgramsEditStepsGeneralComponent } from './programs/programs-edit/steps/programs-edit-steps-general/programs-edit-steps-general.component';
import { ProgramsEditStepsIllustrationComponent } from './programs/programs-edit/steps/programs-edit-steps-illustration/programs-edit-steps-illustration.component';
import { ProgramsEditStepsSettingsComponent } from './programs/programs-edit/steps/programs-edit-steps-settings/programs-edit-steps-settings.component';
import { ProgramsIndexComponent } from './programs/programs-index/programs-index.component';
import { ProgramsViewComponent } from './programs/programs-view/programs-view.component';
import { QualitiesIndexComponent } from './qualities/qualities-index/qualities-index.component';
import { RedemptionsIndexComponent } from './redemptions/redemptions-index/redemptions-index.component';
import { RedemptionsViewComponent } from './redemptions/redemptions-view/redemptions-view.component';
import { ReferralsIndexComponent } from './referrals/referrals-index/referrals-index.component';
import { ResetAccessComponent } from './reset-access/reset-access.component';
import { TermsComponent } from './terms/terms.component';
import { TipsEditComponent } from './tips/tips-edit/tips-edit.component';
import { TipsIndexComponent } from './tips/tips-index/tips-index.component';
import { TipsViewComponent } from './tips/tips-view/tips-view.component';
import { UsersIndexComponent } from './users/users-index/users-index.component';
import { UsersViewComponent } from './users/users-view/users-view.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    component: LayoutComponent,

    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

      { path: 'alerts', component: AlertsComponent },

      {
        path: 'accounts',

        children: [
          {
            path: '',
            component: AccountsIndexComponent,
          },
        ],
      },

      {
        path: 'articles',

        children: [
          {
            path: 'create',
            component: ArticlesEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              { path: 'general', component: ArticlesEditStepsGeneralComponent },
              {
                path: 'illustration',
                component: ArticlesEditStepsIllustrationComponent,
              },
              {
                path: 'content',
                component: ArticlesEditStepsContentComponent,
              },
              {
                path: 'settings',
                component: ArticlesEditStepsSettingsComponent,
              },
            ],
          },
          {
            path: ':code',
            component: ArticlesViewComponent,
          },
          {
            path: ':code/update',
            component: ArticlesEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              { path: 'general', component: ArticlesEditStepsGeneralComponent },
              {
                path: 'illustration',
                component: ArticlesEditStepsIllustrationComponent,
              },
              {
                path: 'content',
                component: ArticlesEditStepsContentComponent,
              },
              {
                path: 'settings',
                component: ArticlesEditStepsSettingsComponent,
              },
            ],
          },
          {
            path: '',
            component: ArticlesIndexComponent,
          },
        ],
      },

      {
        path: 'baskets',

        children: [
          {
            path: ':code',
            component: BasketsViewComponent,
          },
          {
            path: '',
            component: BasketsIndexComponent,
          },
        ],
      },

      {
        path: 'dashboard',
        component: DashboardComponent,
      },

      {
        path: 'demo-videos',
        component: DemoVideosComponent,
        canDeactivate: [ConfirmOnLeaveGuard],
      },

      {
        path: 'faq',
        component: FaqComponent,
        canDeactivate: [ConfirmOnLeaveGuard],
      },

      {
        path: 'handlers',

        children: [
          {
            path: ':code',
            component: HandlersViewComponent,
          },
          {
            path: '',
            component: HandlersIndexComponent,
          },
        ],
      },

      {
        path: 'materials',

        children: [
          {
            path: 'create',
            component: MaterialsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              {
                path: 'general',
                component: MaterialsEditStepsGeneralComponent,
              },
              {
                path: 'illustration',
                component: MaterialsEditStepsIllustrationComponent,
              },
              {
                path: 'energy-impact',
                component: MaterialsEditStepsEnergyImpactComponent,
              },
              {
                path: 'co2-impact',
                component: MaterialsEditStepsCo2ImpactComponent,
              },
            ],
          },
          {
            path: ':code',
            component: MaterialsViewComponent,
          },
          {
            path: ':code/update',
            component: MaterialsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              {
                path: 'general',
                component: MaterialsEditStepsGeneralComponent,
              },
              {
                path: 'illustration',
                component: MaterialsEditStepsIllustrationComponent,
              },
              {
                path: 'energy-impact',
                component: MaterialsEditStepsEnergyImpactComponent,
              },
              {
                path: 'co2-impact',
                component: MaterialsEditStepsCo2ImpactComponent,
              },
            ],
          },
          {
            path: '',
            component: MaterialsIndexComponent,
          },
        ],
      },

      {
        path: 'organization-types',

        children: [
          {
            path: 'create',
            component: OrganizationTypesEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],
          },
          {
            path: ':code',
            component: OrganizationTypesViewComponent,
          },
          {
            path: ':code/update',
            component: OrganizationTypesEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],
          },
          {
            path: '',
            component: OrganizationTypesIndexComponent,
          },
        ],
      },

      {
        path: 'programs',

        children: [
          {
            path: 'create',
            component: ProgramsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              { path: 'general', component: ProgramsEditStepsGeneralComponent },
              {
                path: 'illustration',
                component: ProgramsEditStepsIllustrationComponent,
              },
              {
                path: 'description',
                component: ProgramsEditStepsDescriptionComponent,
              },
              {
                path: 'settings',
                component: ProgramsEditStepsSettingsComponent,
              },
            ],
          },
          {
            path: ':code',
            component: ProgramsViewComponent,
          },
          {
            path: ':code/update',
            component: ProgramsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],

            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              { path: 'general', component: ProgramsEditStepsGeneralComponent },
              {
                path: 'illustration',
                component: ProgramsEditStepsIllustrationComponent,
              },
              {
                path: 'description',
                component: ProgramsEditStepsDescriptionComponent,
              },
              {
                path: 'settings',
                component: ProgramsEditStepsSettingsComponent,
              },
            ],
          },
          {
            path: '',
            component: ProgramsIndexComponent,
          },
        ],
      },

      {
        path: 'qualities',

        children: [
          {
            path: '',
            component: QualitiesIndexComponent,
          },
        ],
      },

      {
        path: 'redemptions',

        children: [
          {
            path: ':code',
            component: RedemptionsViewComponent,
          },
          {
            path: '',
            component: RedemptionsIndexComponent,
          },
        ],
      },

      {
        path: 'referrals',

        children: [
          {
            path: '',
            component: ReferralsIndexComponent,
          },
        ],
      },

      {
        path: 'terms',
        component: TermsComponent,
        canDeactivate: [ConfirmOnLeaveGuard],
      },

      {
        path: 'tips',

        children: [
          {
            path: 'create',
            component: TipsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],
          },
          {
            path: ':code',
            component: TipsViewComponent,
          },
          {
            path: ':code/update',
            component: TipsEditComponent,
            canDeactivate: [ConfirmOnLeaveGuard],
          },
          {
            path: '',
            component: TipsIndexComponent,
          },
        ],
      },

      {
        path: 'users',

        children: [
          {
            path: ':code',
            component: UsersViewComponent,
          },
          {
            path: '',
            component: UsersIndexComponent,
          },
        ],
      },
    ],
  },

  { path: '403', component: AccessDeniedComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'activate-access/:token', component: ActivateAccessComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-access/:token', component: ResetAccessComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),

    UiServicesModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
