<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4 class="font-bold mb-6">
        <ng-container
          *ngIf="materialsEditService.editAction === 'create'; else update"
          >Création matériau</ng-container
        >
        <ng-template #update>Mise à jour matériau</ng-template>

        <ng-container *ngIf="materialsEditService.material">
          <div
            class="inline-block mx-2 text-blue-600"
            [ngClass]="{
              'line-through':
                materialsEditService.material &&
                materialsEditService.dirtyMaterial &&
                materialsEditService.dirtyMaterial.nameFr !==
                  materialsEditService.material.nameFr
            }"
          >
            {{ materialsEditService.material.nameFr }}
          </div>
        </ng-container>

        <div
          class="inline-block mx-2 text-green-600"
          [ngClass]="{
            'font-bold':
              materialsEditService.material &&
              materialsEditService.dirtyMaterial &&
              materialsEditService.dirtyMaterial.nameFr !==
                materialsEditService.material.nameFr
          }"
          *ngIf="
            materialsEditService.dirtyMaterial &&
            (!materialsEditService.material ||
              materialsEditService.dirtyMaterial.nameFr !==
                materialsEditService.material.nameFr)
          "
        >
          {{ materialsEditService.dirtyMaterial.nameFr }}
        </div>
      </h4>

      <p-toast></p-toast>

      <p-steps [model]="steps" [readonly]="true"></p-steps>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
