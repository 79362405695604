<div class="card">
  <p-table
    #categoryIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de {totalRecords}'
    "
    [dataKey]="'code'"
    [globalFilterFields]="[
      'descriptionAr',
      'descriptionFr',
      'nameAr',
      'nameFr'
    ]"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [value]="categories"
    (onRowReorder)="onRowReorder($event)"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex justify-content-between align-items-start flex-column sm:flex-row"
      >
        <div class="flex-column sm:flex-row mb-2">
          <button
            class="p-button-outlined sm:mb-2 mr-2"
            (click)="clearCategoriesTable()"
            pButton
            label="Effacer tous les filtres"
            icon="pi pi-filter-slash"
          ></button>

          <button
            class="mb-2 p-button-success"
            routerLink="/tips/create"
            pButton
            label="Créer une catégorie"
            icon="pi pi-plus"
          ></button>
        </div>

        <span class="p-input-icon-left mb-2">
          <i class="pi pi-search"></i>
          <input
            class="w-full"
            type="text"
            #filterCategoryIndexDataTable
            (input)="
              categoryIndexDataTable.filterGlobal(
                eventTargetValue($event),
                'contains'
              )
            "
            pInputText
            placeholder="Filtrer aussi par description"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pFrozenColumn></th>

        <th style="min-width: 20rem" pSortableColumn="nameFr" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameAr"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameAr"></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-category let-index="rowIndex">
      <ng-container *ngLet="asCategoryDto(category) as category">
        <tr [pReorderableRow]="index" *ngIf="category">
          <td pFrozenColumn>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>

          <td pFrozenColumn>
            {{ category.nameFr }}
          </td>

          <td class="text-right" dir="rtl">
            {{ category.nameAr }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/tips/' + category.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">Aucune catégorie trouvée.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="4">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
