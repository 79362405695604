<div class="card" [ngClass]="{ 'tlb--max-screen-height': isEditMode }">
  <div class="mb-4" *ngIf="isEditMode; else viewToolbar">
    <button
      class="p-button-success"
      pButton
      pRipple
      label="Enregistrer"
      icon="pi pi-check"
      (click)="save()"
    ></button>

    <button
      class="p-button-outlined p-button-danger ml-2"
      pButton
      pRipple
      label="Annuler"
      icon="pi pi-times"
      (click)="cancelEditMode()"
    ></button>
  </div>

  <ng-template #viewToolbar>
    <div class="mb-4">
      <button
        class="p-button-warning"
        pButton
        pRipple
        label="Modifier"
        icon="pi pi-pencil"
        (click)="enterEditMode()"
      ></button>
    </div>
  </ng-template>

  <form
    class="w-full md:w-10 mx-auto"
    [formGroup]="form"
    *ngIf="form && isEditMode; else display"
  >
    <div class="flex align-items-start mb-4 gap-8">
      <div class="w-6">
        <label
          class="block text-900 text-xl font-medium mb-2 flex align-items-center"
          for="mobileDemoVideoFr"
        >
          <img class="mr-2" src="assets/flags/fr.svg" height="20" />
          Démo User en français
        </label>

        <input
          class="w-full mb-3"
          id="mobileDemoVideoFr"
          (keyup.enter)="save()"
          formControlName="mobileDemoVideoFr"
          pInputText
          style="padding: 1rem"
          maxlength="140"
        />

        <div>
          <be-green--ui--input-error
            [control]="formControls.mobileDemoVideoFr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>

      <div class="w-6">
        <label
          class="block text-900 text-xl font-medium mb-2 flex align-items-center"
          for="mobileDemoVideoAr"
        >
          <img class="mr-2" src="assets/flags/ma.svg" height="20" />
          Démo User en arabe
        </label>

        <input
          class="w-full mb-3"
          id="mobileDemoVideoAr"
          (keyup.enter)="save()"
          formControlName="mobileDemoVideoAr"
          pInputText
          style="padding: 1rem"
          maxlength="140"
        />

        <div>
          <be-green--ui--input-error
            [control]="formControls.mobileDemoVideoAr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>
    </div>

    <div class="flex align-items-start mb-4 gap-8">
      <div class="w-6">
        <label
          class="block text-900 text-xl font-medium mb-2 flex align-items-center"
          for="handlerDemoVideoFr"
        >
          <img class="mr-2" src="assets/flags/fr.svg" height="20" />
          Démo Handler en français
        </label>

        <input
          class="w-full mb-3"
          id="handlerDemoVideoFr"
          (keyup.enter)="save()"
          formControlName="handlerDemoVideoFr"
          pInputText
          style="padding: 1rem"
          maxlength="140"
        />

        <div>
          <be-green--ui--input-error
            [control]="formControls.handlerDemoVideoFr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>

      <div class="w-6">
        <label
          class="block text-900 text-xl font-medium mb-2 flex align-items-center"
          for="handlerDemoVideoAr"
        >
          <img class="mr-2" src="assets/flags/ma.svg" height="20" />
          Démo Handler en arabe
        </label>

        <input
          class="w-full mb-3"
          id="handlerDemoVideoAr"
          (keyup.enter)="save()"
          formControlName="handlerDemoVideoAr"
          pInputText
          style="padding: 1rem"
          maxlength="140"
        />

        <div>
          <be-green--ui--input-error
            [control]="formControls.handlerDemoVideoAr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>
    </div>
  </form>

  <ng-template #display>
    <div class="grid">
      <div class="col-12 sm:col-6 md:col-3">
        <div class="flex align-items-center tlb--editor-header">
          <img class="mr-2" src="assets/flags/fr.svg" height="20" />

          <div style="font-weight: 700">Démo User en français</div>
        </div>

        <div *ngIf="safeVideoUrls; else notSet">
          <div class="relative" style="padding-top: 120%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.mobile.fr"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12 sm:col-6 md:col-3">
        <div class="flex align-items-center tlb--editor-header">
          <img class="mr-2" src="assets/flags/ma.svg" height="20" />

          <div style="font-weight: 700">Démo User en arabe</div>
        </div>

        <div *ngIf="safeVideoUrls && safeVideoUrls.mobile.ar; else notSet">
          <div class="relative" style="padding-top: 120%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.mobile.ar"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12 sm:col-6 md:col-3">
        <div class="flex align-items-center tlb--editor-header">
          <img class="mr-2" src="assets/flags/fr.svg" height="20" />

          <div style="font-weight: 700">Démo Handler en français</div>
        </div>

        <div *ngIf="safeVideoUrls; else notSet">
          <div class="relative" style="padding-top: 120%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.handler.fr"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12 sm:col-6 md:col-3">
        <div class="flex align-items-center tlb--editor-header">
          <img class="mr-2" src="assets/flags/ma.svg" height="20" />

          <div style="font-weight: 700">Démo Handler en arabe</div>
        </div>

        <div *ngIf="safeVideoUrls && safeVideoUrls.handler.ar; else notSet">
          <div class="relative" style="padding-top: 120%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.handler.ar"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #notSet>
  <div class="p-editor-container text-pink-600 font-italic">
    <div class="p-editor-content ql-snow" style="padding: 12px 15px">
      Non défini
    </div>
  </div>
</ng-template>
