<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="user; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <p-tag
            [value]="user.status.name"
            [ngClass]="'mb-2'"
            [severity]="getStatusTagSeverity(user.status.code)"
            *ngIf="user.status"
          ></p-tag>

          <div class="flex flex-wrap align-items-center">
            <div class="font-medium text-3xl text-900">
              {{ user.person?.firstName }}
              {{ user.person?.lastName }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ user.person?.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="user.person?.updatedAt; else notSet">
                  {{ user.person?.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0">
          <!-- buttons -->
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 xl:col-4">
    <div class="card">
      <ul
        class="list-none p-0 m-0 flex-grow-1"
        *ngIf="user; else loadingSkeleton"
      >
        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            E-mail
          </div>

          <div class="text-900 w-full xl:w-8">
            {{ user.email }}
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            N° mobile
          </div>

          <div class="text-900 w-full xl:w-8">
            <span *ngIf="user.person?.mobilePhone; else notSet">
              {{ user.person?.mobilePhone }}

              <p-tag
                [value]="
                  'GENERIC.TELECOM_OPERATOR.' + user.person?.telecomOperator
                    | translate
                "
              ></p-tag>
            </span>
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Genre
          </div>

          <div class="text-900 w-full xl:w-8">
            <span *ngIf="user.person?.genre; else notSet">
              <p-tag
                [value]="'GENERIC.GENRE.' + user.person?.genre | translate"
              ></p-tag>
            </span>
          </div>
        </li>

        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Date de naissance
          </div>

          <div class="text-900 w-full xl:w-8">
            <span *ngIf="user.person?.genre; else notSet">
              {{ user.person?.birthdate | date : 'shortDate' : '' : 'fr' }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <div class="card mb-0">
      <div
        class="mt-3 lg:mt-0 flex align-items-center justify-content-around"
        *ngIf="user; else loadingSkeleton"
      >
        <div class="flex flex-column align-items-center">
          <p-avatar
            size="xlarge"
            [image]="
              'assets/images/badges/levels/' +
              user.person?.currentBadge +
              '.svg'
            "
          ></p-avatar>

          {{ badgeLabel }}

          <p>
            QR codes M-1 :
            <span
              *ngIf="user.person?.lastPeriodApprovedBasketCount; else notSet"
            >
              {{ user.person?.lastPeriodApprovedBasketCount }}</span
            >
          </p>
        </div>

        <div class="flex flex-column align-items-center">
          <div
            class="flex align-items-center justify-content-center"
            style="width: 4.25rem; height: 4.25rem"
          >
            <i class="pi pi-hashtag text-xl"></i>
          </div>

          <ng-container *ngIf="user.person?.currentRank; else notSet">
            {{ user.person?.currentRank }}
          </ng-container>
        </div>

        <div class="flex flex-column align-items-center">
          <div
            class="flex align-items-center justify-content-center"
            style="width: 4.25rem; height: 4.25rem"
          >
            <i class="pi pi-qrcode text-xl"></i>
          </div>

          <ng-container
            *ngIf="user.person?.currentPeriodApprovedBasketCount; else notSet"
          >
            {{ user.person?.currentPeriodApprovedBasketCount }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 xl:col-4">
    <div class="card mb-0">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Contributions</h3>
      </div>

      <canvas
        class="m-auto"
        id="impactChart"
        *ngIf="impact && impact.contributions.length; else notSet"
      ></canvas>
    </div>
  </div>

  <div class="col-12 xl:col-4">
    <div class="card">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Impact énergie</h3>
      </div>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="impact; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilo-joules économisés
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ impact.kiloJoulesSaved | number : '1.2-2' : 'fr' }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Heures d'éclairage
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ impact.hoursOfLightingAllowed | number : '1.2-2' : 'fr' }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card mb-0">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Impact CO<sub>2</sub></h3>
      </div>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="impact; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Grammes de CO<sub>2</sub> économisés
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ impact.gramsOfCo2Saved | number : '1.2-2' : 'fr' }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilomètres parcourus
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ impact.kilometersAllowed | number : '1.2-2' : 'fr' }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isParticipationCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">
          Résumé
          <ng-container *ngIf="participations; else miniSkeleton"
            >: {{ participations.length }} participations</ng-container
          >
        </h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isParticipationCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isParticipationCardOpen = !isParticipationCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isParticipationCardOpen">
        <p-table
          #participationIndexDataTable
          styleClass="p-datatable-gridlines"
          [currentPageReportTemplate]="
            'Enregistrements {first} à {last} sur un total de {totalRecords}'
          "
          [dataKey]="'code'"
          [loading]="isLoading"
          [paginator]="true"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="true"
          [showCurrentPageReport]="true"
          [value]="participations"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="program.nameFr" pFrozenColumn>
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Programme</div>

                  <p-sortIcon field="program.nameFr"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="program.type">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Type</div>

                  <p-sortIcon field="program.type"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="createdAt">
                <div class="flex justify-content-between align-items-center">
                  Premier engagement

                  <p-sortIcon field="createdAt"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="createdAt">
                <div class="flex justify-content-between align-items-center">
                  Détachement

                  <p-sortIcon field="deletedAt"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="referralCount">
                <div class="flex justify-content-between align-items-center">
                  Parrain

                  <p-sortIcon field="referralCount"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="referralCount">
                <div class="flex justify-content-between align-items-center">
                  Parrainages envoyés

                  <p-sortIcon field="referralCount"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="convertedReferrals">
                <div class="flex justify-content-between align-items-center">
                  Parrainages convertis

                  <p-sortIcon field="convertedReferrals"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="points">
                <div class="flex justify-content-between align-items-center">
                  Points

                  <p-sortIcon field="points"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="quantity">
                <div class="flex justify-content-between align-items-center">
                  Emballages recyclés

                  <p-sortIcon field="quantity"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="weight">
                <div class="flex justify-content-between align-items-center">
                  Poids recyclé

                  <p-sortIcon field="weight"></p-sortIcon>
                </div>
              </th>

              <th alignFrozen="right" pFrozenColumn></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-participation>
            <ng-container
              *ngLet="asParticipationDto(participation) as participation"
            >
              <tr *ngIf="participation">
                <td pFrozenColumn>
                  <a [routerLink]="'/programs/' + participation.program.code">
                    {{ participation.program.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>

                <td>
                  <p-tag
                    [value]="
                      participation.program.type === 'goodDeal'
                        ? 'Bon plan'
                        : participation.program.type === 'pro'
                        ? 'Pro'
                        : 'Recyclage'
                    "
                    [severity]="
                      getProgramTypeTagSeverity(participation.program.type)
                    "
                  ></p-tag>

                  <p
                    *ngIf="
                      participation.program.type === 'pro' &&
                      participation.program.organizationType
                    "
                  >
                    <p-tag
                      [value]="participation.program.organizationType.nameFr"
                      severity="info"
                    ></p-tag>
                  </p>
                </td>

                <td>
                  {{
                    participation.createdAt | date : 'medium' : undefined : 'fr'
                  }}
                </td>

                <td>
                  <ng-container *ngIf="participation.deletedAt; else notSet">
                    {{
                      participation.deletedAt
                        | date : 'medium' : undefined : 'fr'
                    }}
                  </ng-container>
                </td>

                <td>
                  <ng-container
                    *ngIf="participation.referrerParticipation; else notSet"
                  >
                    <a
                      [routerLink]="
                        '/users/' +
                        participation.referrerParticipation.createdBy.code
                      "
                      target="_blank"
                      *ngIf="participation.referrerParticipation.createdBy"
                    >
                      {{ participation.referrerParticipation.person.firstName }}
                      {{ participation.referrerParticipation.person.lastName }}
                      <i class="pi pi-external-link"></i>
                    </a>
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container
                    *ngIf="participation.referralCount; else notSet"
                  >
                    {{ participation.referralCount | number : '1.0-0' : 'fr' }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container
                    *ngIf="participation.convertedReferrals; else notSet"
                  >
                    {{
                      participation.convertedReferrals | number : '1.0-0' : 'fr'
                    }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.points; else notSet">
                    {{ participation.points | number : '1.0-0' : 'fr' }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.quantity; else notSet">
                    {{ participation.quantity | number : '1.0-0' : 'fr' }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.weight; else notSet">
                    {{ participation.weight | number : '1.0-0' : 'fr' }} g
                  </ng-container>
                </td>

                <td alignFrozen="right" pFrozenColumn>
                  <button
                    class="p-button-rounded p-button-danger"
                    pButton
                    pRipple
                    icon="pi pi-ban"
                    (click)="detachFromParticipation(participation)"
                    *ngIf="
                      participation.program.type === 'pro' &&
                      !participation.deletedAt
                    "
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">Aucune participation trouvée.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="9">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isHistoryCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">
          Historique
          <ng-container *ngIf="history; else miniSkeleton"
            >: {{ history.length }} événements</ng-container
          >
        </h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isHistoryCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isHistoryCardOpen = !isHistoryCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isHistoryCardOpen">
        <p-table
          #historyIndexDataTable
          styleClass="p-datatable-gridlines"
          [currentPageReportTemplate]="
            'Enregistrements {first} à {last} sur un total de {totalRecords}'
          "
          [dataKey]="'code'"
          [loading]="isLoading"
          [paginator]="true"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="true"
          [showCurrentPageReport]="true"
          [value]="history"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="program.nameFr" pFrozenColumn>
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Programme</div>

                  <p-sortIcon field="program.nameFr"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="program.type">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Type</div>

                  <p-sortIcon field="program.type"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="createdAt">
                <div class="flex justify-content-between align-items-center">
                  Date

                  <p-sortIcon field="createdAt"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="context">
                <div class="flex justify-content-between align-items-center">
                  Événement

                  <p-sortIcon field="context"></p-sortIcon>
                </div>
              </th>

              <th>Points (+ / -)</th>

              <th alignFrozen="right" pFrozenColumn></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-history>
            <ng-container *ngLet="asHistoryDto(history) as history">
              <tr *ngIf="history">
                <td pFrozenColumn>
                  <a [routerLink]="'/programs/' + history.program?.code">
                    {{ history.program?.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>

                <td>
                  <p-tag
                    [value]="
                      history.program.type === 'goodDeal'
                        ? 'Bon plan'
                        : history.program.type === 'pro'
                        ? 'Pro'
                        : 'Recyclage'
                    "
                    [severity]="getProgramTypeTagSeverity(history.program.type)"
                    *ngIf="history.program"
                  ></p-tag>

                  <p
                    *ngIf="
                      history.program &&
                      history.program.type === 'pro' &&
                      history.program.organizationType
                    "
                  >
                    <p-tag
                      [value]="history.program.organizationType.nameFr"
                      severity="info"
                    ></p-tag>
                  </p>
                </td>

                <td>
                  {{ history.createdAt | date : 'medium' : undefined : 'fr' }}
                </td>

                <ng-container
                  *ngIf="history.context === 'GIFTS'; else basketDisplay"
                >
                  <ng-container *ngIf="history.data">
                    <ng-container
                      *ngLet="asRedemptionNotificationDto(history.data) as data"
                    >
                      <ng-container *ngIf="data">
                        <td>
                          <div class="flex align-items-center gap-2">
                            <div
                              class="flex align-items-center justify-content-center bg-red-100 border-round"
                              style="width: 2rem; height: 2rem"
                            >
                              <i class="pi pi-gift text-red-500"></i>
                            </div>

                            <div
                              class="flex align-items-center justify-content-center bg-purple-100 border-round"
                              style="width: 2rem; height: 2rem"
                              *ngIf="
                                asRedemptionUsedPoints(data.usedPoints) < 0
                              "
                            >
                              <i class="pi pi-undo"></i>
                            </div>

                            {{ data.giftName.fr }}
                          </div>
                        </td>

                        <td class="text-right">
                          <ng-container
                            *ngIf="asRedemptionUsedPoints(data.usedPoints) < 0"
                            >+</ng-container
                          >{{ -asRedemptionUsedPoints(data.usedPoints) }}
                        </td>

                        <td alignFrozen="right" pFrozenColumn>
                          <button
                            class="p-button-rounded"
                            type="button"
                            pButton
                            icon="pi pi-search"
                            [routerLink]="
                              data.code
                                ? '/redemptions/' + data.code
                                : '/programs/' + data.programCode
                            "
                          ></button>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #basketDisplay>
                  <ng-container *ngIf="history.data">
                    <ng-container
                      *ngLet="asBasketNotificationDto(history.data) as data"
                    >
                      <ng-container *ngIf="data">
                        <td>
                          <div
                            class="flex align-items-center font-medium gap-3"
                          >
                            <ng-container [ngSwitch]="history.context">
                              <ng-container *ngSwitchCase="'BASKETS'">
                                <ng-container
                                  *ngIf="
                                    data.statusCode === 'VALIDATED';
                                    else notValidatedBasket
                                  "
                                >
                                  <div class="flex align-items-center gap-2">
                                    <div
                                      class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                      style="width: 2rem; height: 2rem"
                                    >
                                      <i class="pi pi-qrcode text-cyan-500"></i>
                                    </div>

                                    QR Code créé
                                  </div>
                                </ng-container>

                                <ng-template #notValidatedBasket>
                                  <ng-container
                                    *ngIf="
                                      data.statusCode === 'DEPOSITED';
                                      else approvedBasket
                                    "
                                  >
                                    <div class="flex align-items-center gap-2">
                                      <div
                                        class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                        style="width: 2rem; height: 2rem"
                                      >
                                        <i
                                          class="pi pi-qrcode text-cyan-500"
                                        ></i>
                                      </div>

                                      QR Code déposé
                                    </div>
                                  </ng-container>

                                  <ng-template #approvedBasket>
                                    <div class="flex align-items-center gap-2">
                                      <div
                                        class="flex align-items-center justify-content-center bg-green-100 border-round"
                                        style="width: 2rem; height: 2rem"
                                      >
                                        <i
                                          class="pi pi-inbox text-green-500"
                                        ></i>
                                      </div>

                                      <p-tag
                                        [value]="
                                          (data.validatedQuantity
                                            | number : '1.0-0' : 'fr') || ''
                                        "
                                        severity="success"
                                      ></p-tag>
                                    </div>

                                    <div class="flex align-items-center gap-2">
                                      <div
                                        class="flex align-items-center justify-content-center bg-orange-100 border-round"
                                        style="width: 2rem; height: 2rem"
                                      >
                                        <i
                                          class="pi pi-map-marker text-orange-500"
                                        ></i>
                                      </div>

                                      <p-tag
                                        [value]="
                                          (data.validatedWeight || 0
                                            | number : '1.0-0' : 'fr') + ' g'
                                        "
                                        severity="success"
                                      ></p-tag>

                                      QR Code approuvé
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </ng-container>

                              <ng-container *ngSwitchCase="'POINTS'">
                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i class="pi pi-star text-yellow-500"></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedPoints
                                        | number : '1.0-0' : 'fr') || '0'
                                    "
                                    severity="success"
                                  ></p-tag>
                                </div>

                                Panier approuvé
                              </ng-container>

                              <ng-container *ngSwitchCase="'REFERRED_POINTS'">
                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i class="pi pi-star text-yellow-500"></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedPoints
                                        | number : '1.0-0' : 'fr') || '0'
                                    "
                                    severity="success"
                                  ></p-tag>
                                </div>

                                Points gagnés en tant que parrainé, suite à un
                                premier panier approuvé
                              </ng-container>

                              <ng-container *ngSwitchCase="'REFERRER_POINTS'">
                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i class="pi pi-star text-yellow-500"></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedPoints
                                        | number : '1.0-0' : 'fr') || '0'
                                    "
                                    severity="success"
                                  ></p-tag>
                                </div>

                                Points gagnés en tant que parrain, suite à un
                                premier panier approuvé du parrainé
                              </ng-container>
                            </ng-container>
                          </div>
                        </td>

                        <td class="text-right">
                          <ng-container
                            *ngIf="data.validatedPoints; else notSet"
                          >
                            +{{ data.validatedPoints }}
                          </ng-container>
                        </td>

                        <td alignFrozen="right" pFrozenColumn>
                          <button
                            class="p-button-rounded"
                            type="button"
                            pButton
                            icon="pi pi-search"
                            [routerLink]="'/baskets/' + data.code"
                          ></button>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">Aucun historique trouvé.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="5">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isCategoryCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">Astuces écolos</h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isCategoryCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isCategoryCardOpen = !isCategoryCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isCategoryCardOpen">
        <p-table
          #categoryIndexDataTable
          styleClass="p-datatable-gridlines"
          [dataKey]="'code'"
          [expandedRowKeys]="expandedCategories"
          [loading]="isLoading"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="categories.length"
          [value]="categories"
          *ngIf="categories"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>

              <th pSortableColumn="position">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Position</div>

                  <p-sortIcon field="position"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="nameFr">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Catégorie</div>

                  <p-sortIcon field="nameFr"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="progress">
                <div class="flex justify-content-between align-items-center">
                  Progrès

                  <p-sortIcon field="progress"></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-category let-expanded="expanded">
            <ng-container *ngLet="asCategoryDto(category) as category">
              <tr *ngIf="category">
                <td>
                  <button
                    class="p-button-text p-button-rounded p-button-plain"
                    type="button"
                    pButton
                    pRipple
                    [pRowToggler]="category"
                    (click)="category.isExpanded = !category.isExpanded"
                    [icon]="
                      expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "
                  ></button>
                </td>

                <td>
                  <a [routerLink]="'/tips/' + category.code">
                    {{ category.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>

                <td># {{ category.position }}</td>

                <td>
                  <p-progressBar
                    [value]="100 * (category.progress || 0) | number : '1.0-0'"
                  ></p-progressBar>
                </td>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-category>
            <ng-container *ngLet="asCategoryDto(category) as category">
              <tr *ngIf="category">
                <td colspan="4">
                  <div class="p-3">
                    <p-table
                      styleClass="p-datatable-gridlines"
                      [dataKey]="'code'"
                      [loading]="isLoading"
                      [responsiveLayout]="'scroll'"
                      [rowHover]="true"
                      [rows]="(category.tips || []).length"
                      [value]="category.tips || []"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th pSortableColumn="nameFr">
                            <div
                              class="flex justify-content-between align-items-center"
                            >
                              Habitude

                              <p-sortIcon field="nameFr"></p-sortIcon>
                            </div>
                          </th>

                          <th pSortableColumn="checked">
                            <div
                              class="flex justify-content-between align-items-center"
                            >
                              Validée ?

                              <p-sortIcon field="checked"></p-sortIcon>
                            </div>
                          </th>

                          <th pSortableColumn="checkedAt">
                            <div
                              class="flex justify-content-between align-items-center"
                            >
                              Quand ?

                              <p-sortIcon field="checkedAt"></p-sortIcon>
                            </div>
                          </th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-tip>
                        <ng-container *ngLet="asTipDto(tip) as tip">
                          <ng-container *ngIf="tip">
                            <tr>
                              <td>
                                {{ tip.nameFr }}
                              </td>

                              <td>
                                <span
                                  class="ozd--status-badge ozd--status-active"
                                  *ngIf="tip.checked; else notIndexedTemplate"
                                >
                                  <i
                                    class="pi true-icon pi-check-circle text-green-500 mr-2"
                                  ></i
                                  >Oui</span
                                >

                                <ng-template #notIndexedTemplate>
                                  <span
                                    class="ozd--status-badge ozd--status-terminated"
                                  >
                                    <i
                                      class="pi false-icon pi-times-circle text-pink-500 mr-2"
                                    ></i
                                    >Non</span
                                  >
                                </ng-template>
                              </td>

                              <td>
                                <ng-container
                                  *ngIf="tip.checkedAt; else notSet"
                                >
                                  {{
                                    tip.checkedAt | date : 'long' : '' : 'fr'
                                  }}
                                </ng-container>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-template>

                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="3">Aucune habitude trouvée.</td>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="loadingbody">
                        <tr>
                          <td colspan="3">
                            Chargement en cours. Veuillez patienter...
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">Aucune categorie trouvée.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="4">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isParticipationActionDialogVisible"
>
  <div class="flex align-items-center">
    <i
      class="pi pi-exclamation-triangle mr-3 text-blue-500"
      style="font-size: 2rem"
      [ngClass]="{ 'text-red-500': participationAction === '_detach' }"
    ></i>

    <div
      class="flex flex-column"
      *ngIf="participation && participationAction && user"
    >
      <div>
        Êtes-vous sûr de vouloir
        <b>{{ participationActions[participationAction].verb }}</b>
        l’utilisateur <b>{{ user.email }}</b> du programme
        <b>
          {{ participation.program.nameFr }}
        </b>
        &nbsp;?
      </div>

      <div
        class="mt-4 text-red-500 font-bold"
        *ngIf="participationAction === '_detach'"
      >
        Attention, cette action est irréversible&nbsp;!
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isParticipationActionDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmParticipationAction()"
    ></button>
  </ng-template>
</p-dialog>
