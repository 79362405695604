import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleApi } from '@be-green/api-services';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../layout/layout.service';
import { ArticlesEditService } from './articles-edit.service';

@Component({
  selector: 'be-green--admin--articles-edit',
  templateUrl: './articles-edit.component.html',
  styleUrls: ['./articles-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesEditComponent implements OnInit, ComponentCanDeactivate {
  isLoading = false;
  steps: MenuItem[] = [
    { label: 'Général', routerLink: 'general' },
    { label: 'Illustration', routerLink: 'illustration' },
    { label: 'Contenu', routerLink: 'content' },
    { label: 'Paramètres', routerLink: 'settings' },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly articleApi: ArticleApi,
    readonly articlesEditService: ArticlesEditService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Articles' }]);
    this.seoService.setTitle('Articles - Admin - Be Green');
  }

  ngOnInit(): void {
    this.articlesEditService.init();

    const code = this.activatedRoute.snapshot.paramMap.get('code');

    if (code) {
      this.isLoading = true;

      this.articleApi.getOne(code).subscribe({
        next: (article) => {
          this.articlesEditService.setArticle('update', article);

          if (this.articlesEditService.article) {
            this.layoutService.registerBreadcrumbs([
              { label: 'Articles', routerLink: '/articles' },
              {
                label: this.articlesEditService.article.titleFr,
                routerLink: `/articles/${code}`,
              },
            ]);

            this.seoService.setTitle(
              `${this.articlesEditService.article.titleFr}  - Articles - Admin - Be Green`,
            );
          }
          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
    } else {
      this.articlesEditService.setArticle('create');
    }
  }

  isDirty() {
    return this.articlesEditService.isDirty;
  }
}
