import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImpactDto, ImpactLocalizedDto, LanguageCode } from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContributionApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  getMyImpact(languageCode: LanguageCode): Observable<ImpactLocalizedDto> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.get<ImpactLocalizedDto>(
      `/contributions/impact/${languageCode}`,
    );
  }

  getUserImpact(code: string): Observable<ImpactDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ImpactDto>(
      `/contributions/impact/admin/${code}`,
    );
  }
}
