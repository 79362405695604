<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start"></div>

  <div class="p-toolbar-group-end">
    <button
      class="p-button-help ml-2"
      icon="pi pi-upload"
      label="Export"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="export()"
    ></button>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="card">
  <p-table
    #referralIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="apiPageSize"
    [rowsPerPageOptions]="[10, 25, 50]"
    [showCurrentPageReport]="true"
    [totalRecords]="recordCount"
    [value]="referrals"
    (onLazyLoad)="fetchNextPage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="program.nameFr">
          <div class="flex justify-content-between align-items-center">
            Programme

            <p-sortIcon field="program.nameFr"></p-sortIcon>
          </div>
        </th>

        <th
          pSortableColumn="referrerParticipation.person.firstName, referrerParticipation.person.lastName"
        >
          <div class="flex justify-content-between align-items-center">
            Parrain

            <p-sortIcon
              field="referrerParticipation.person.firstName, referrerParticipation.person.lastName"
            ></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="person.firstName, person.lastName">
          <div class="flex justify-content-between align-items-center">
            Parrainé

            <p-sortIcon field="person.firstName, person.lastName"></p-sortIcon>
          </div>
        </th>
      </tr>

      <!-- <tr>
        <th>
          <p-columnFilter type="text" field="program.nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="referrerParticipation.person.firstName, referrerParticipation.person.lastName"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="person.firstName, person.lastName"
          ></p-columnFilter>
        </th>
      </tr> -->
    </ng-template>

    <ng-template pTemplate="body" let-referral>
      <ng-container *ngLet="asParticipationDto(referral) as referral">
        <tr *ngIf="referral">
          <td>
            <a [routerLink]="'/programs/' + referral.program.code">
              {{ referral.program.nameFr }}

              <i class="pi pi-external-link"></i>
            </a>
          </td>

          <td>
            <a
              [routerLink]="
                '/users/' + referral.referrerParticipation?.createdBy?.code
              "
            >
              {{ referral.referrerParticipation?.person?.firstName }}
              {{ referral.referrerParticipation?.person?.lastName }}

              <i class="pi pi-external-link"></i>
            </a>
          </td>

          <td>
            <a [routerLink]="'/users/' + referral.createdBy?.code">
              {{ referral.person.firstName }}
              {{ referral.person.lastName }}

              <i class="pi pi-external-link"></i>
            </a>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">Aucun parrainage trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="3">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>
