import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ParameterApi } from '@be-green/api-services';
import { DemoVideoConfigDto } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  InputValidationService,
  SeoService,
  UtilsService,
} from '@be-green/ui-services';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'be-green--admin--demo-videos',
  templateUrl: './demo-videos.component.html',
  styleUrls: ['./demo-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DemoVideosComponent implements OnInit, ComponentCanDeactivate {
  contentCache?: DemoVideoConfigDto;
  form?: FormGroup;
  isEditMode = false;
  isLoading = false;
  isNewRecord = false;
  safeVideoUrls?: {
    handler: { ar?: SafeUrl; fr?: SafeUrl };
    mobile: { ar?: SafeUrl; fr?: SafeUrl };
  };

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly parameterApi: ParameterApi,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Vidéos de démo' }]);
    this.seoService.setTitle('Vidéos de démo. - Admin - Be Green');
  }

  isDirty() {
    return this.isEditMode && this.form !== undefined && this.form.dirty;
  }

  ngOnInit(): void {
    this.initForm();

    this.isLoading = true;

    this.parameterApi.getDemoVideos().subscribe({
      next: (demoVideoUrls) => {
        this.isNewRecord = demoVideoUrls === null;

        if (!this.isNewRecord) {
          this.contentCache = demoVideoUrls;

          this.resetContent();
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  get formControls() {
    return this.form?.controls as {
      handlerDemoVideoAr: AbstractControl;
      handlerDemoVideoFr: AbstractControl;
      mobileDemoVideoAr: AbstractControl;
      mobileDemoVideoFr: AbstractControl;
    };
  }

  cancelEditMode() {
    this.isEditMode = false;
    this.resetContent();
  }

  enterEditMode() {
    this.isEditMode = true;
  }

  private generateEmbedUrls() {
    this.safeVideoUrls = {
      handler: {
        fr: <SafeUrl>(
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            UtilsService.generateEmbedUrlFromVideoUrl(
              this.formControls.handlerDemoVideoFr.value,
            ) as string,
          )
        ),
      },
      mobile: {
        fr: <SafeUrl>(
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            UtilsService.generateEmbedUrlFromVideoUrl(
              this.formControls.mobileDemoVideoFr.value,
            ) as string,
          )
        ),
      },
    };

    if (this.formControls.handlerDemoVideoAr.value) {
      this.safeVideoUrls.handler.ar = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.formControls.handlerDemoVideoAr.value,
          ) as string,
        )
      );
    }

    if (this.formControls.mobileDemoVideoAr.value) {
      this.safeVideoUrls.mobile.ar = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.formControls.mobileDemoVideoAr.value,
          ) as string,
        )
      );
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      handlerDemoVideoAr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      handlerDemoVideoFr: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.videoUrlEmbed,
        ]),
      ],
      mobileDemoVideoAr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      mobileDemoVideoFr: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.videoUrlEmbed,
        ]),
      ],
    });
  }

  private resetContent() {
    if (this.form) {
      this.form.reset();

      if (this.contentCache) {
        this.formControls.handlerDemoVideoAr.patchValue(
          this.contentCache.handler.ar,
        );
        this.formControls.handlerDemoVideoFr.patchValue(
          this.contentCache.handler.fr,
        );
        this.formControls.mobileDemoVideoAr.patchValue(
          this.contentCache.mobile.ar,
        );
        this.formControls.mobileDemoVideoFr.patchValue(
          this.contentCache.mobile.fr,
        );
      }

      this.generateEmbedUrls();
    }
  }

  save() {
    if (!this.form) {
      return;
    }

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    if (this.isNewRecord) {
      this.parameterApi
        .createDemoVideos({
          handler: {
            ar: this.formControls.handlerDemoVideoAr.value,
            fr: this.formControls.handlerDemoVideoFr.value,
          },
          mobile: {
            ar: this.formControls.mobileDemoVideoAr.value,
            fr: this.formControls.mobileDemoVideoFr.value,
          },
        })
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.isEditMode = false;
            this.isNewRecord = false;

            this.generateEmbedUrls();
          },

          error: (error: HttpErrorResponse) => {
            this.errorService.handleError(error);

            this.isLoading = false;
          },
        });
    } else {
      this.parameterApi
        .updateDemoVideos({
          handler: {
            ar: this.formControls.handlerDemoVideoAr.value,
            fr: this.formControls.handlerDemoVideoFr.value,
          },
          mobile: {
            ar: this.formControls.mobileDemoVideoAr.value,
            fr: this.formControls.mobileDemoVideoFr.value,
          },
        })
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.isEditMode = false;

            this.generateEmbedUrls();
          },

          error: (error: HttpErrorResponse) => {
            this.errorService.handleError(error);

            this.isLoading = false;
          },
        });
    }
  }
}
