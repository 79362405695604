<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="category; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center">
            <div class="font-bold text-3xl text-900">
              {{ category.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ category.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="category.updatedAt; else notSet">
                  {{ category.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <p-splitButton
            [disabled]="isLoading"
            [icon]="actionMenuItems[0].icon!!"
            [label]="actionMenuItems[0].label!!"
            [model]="actionMenuItems"
            [styleClass]="actionDefaultStyleClass"
            (onClick)="actionMenuItems[0].command!!()"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </div>

  <p-messages></p-messages>

  <div class="col-12 md:col-5">
    <div class="card">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isGeneralCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">Général</h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isGeneralCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isGeneralCardOpen = !isGeneralCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isGeneralCardOpen">
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="category; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nom
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="category.nameFr; else notSet">{{
                  category.nameFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="category.nameAr; else notSet">{{
                  category.nameAr
                }}</ng-container>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-7">
    <div id="tips">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isTipsCardOpen }"
        >
          <h3 class="text-2xl font-bold mb-0">
            <ng-container *ngIf="tips; else miniSkeleton">{{
              tips.length
            }}</ng-container>
            habitudes
          </h3>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="'pi ' + (isTipsCardOpen ? 'pi-minus' : 'pi-plus')"
              (click)="isTipsCardOpen = !isTipsCardOpen"
              *ngIf="!isTipsCardMaximized"
            ></button>

            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' +
                (isTipsCardMaximized
                  ? 'pi-window-minimize'
                  : 'pi-window-maximize')
              "
              (click)="toggleTipsCardSize()"
              *ngIf="isTipsCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isTipsCardOpen">
          <p-table
            #tipIndexDataTable
            styleClass="p-datatable-gridlines"
            [currentPageReportTemplate]="
              'Enregistrements {first} à {last} sur un total de {totalRecords}'
            "
            [dataKey]="'code'"
            [globalFilterFields]="['nameFr', 'nameAr']"
            [loading]="isLoading"
            [paginator]="true"
            [responsiveLayout]="'scroll'"
            [rowHover]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 25, 50]"
            [showCurrentPageReport]="true"
            [value]="tips"
            (onRowReorder)="onTipRowReorder($event)"
            *ngIf="category; else loadingSkeleton"
          >
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between flex-column sm:flex-row">
                <button
                  class="p-button-success sm:mb-2 mr-2"
                  pButton
                  pRipple
                  label="Créer une habitude"
                  icon="pi pi-plus"
                  (click)="addNewTip()"
                ></button>
              </div>

              <p-messages [key]="'tips'"></p-messages>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th *ngIf="isTipsCardMaximized"></th>

                <th></th>

                <th pSortableColumn="nameFr">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameFr"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="nameAr">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameAr"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="contentFr" *ngIf="isTipsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Contenu en français
                    </div>

                    <p-sortIcon field="contentFr"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="contentAr" *ngIf="isTipsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Contenu en arabe
                    </div>

                    <p-sortIcon field="contentAr"></p-sortIcon>
                  </div>
                </th>

                <th></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-tip let-index="rowIndex">
              <ng-container *ngLet="asTipDto(tip) as tip">
                <tr [pReorderableRow]="index" *ngIf="tip">
                  <td *ngIf="isTipsCardMaximized">
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>

                  <td style="text-align: center">
                    <div
                      class="flex justify-content-center"
                      style="line-height: 0"
                    >
                      <p-image
                        class="shadow-4"
                        [preview]="true"
                        [src]="imageBaseUrl + tip.imageUrl"
                        width="100"
                        *ngIf="tip.imageUrl; else noImage"
                      ></p-image>
                    </div>
                  </td>

                  <td>
                    {{ tip.nameFr }}
                  </td>

                  <td class="text-right" dir="rtl">
                    {{ tip.nameAr }}
                  </td>

                  <td *ngIf="isTipsCardMaximized">
                    {{ tip.contentFr }}
                  </td>

                  <td *ngIf="isTipsCardMaximized">
                    {{ tip.contentFr }}
                  </td>

                  <td>
                    <div class="flex">
                      <button
                        class="p-button-rounded p-button-warning mr-2"
                        pButton
                        pRipple
                        icon="pi pi-pencil"
                        (click)="editTip(tip)"
                      ></button>

                      <button
                        class="p-button-rounded p-button-danger"
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        (click)="deleteTip(tip)"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="isTipsCardMaximized ? 8 : 4">
                  Aucune habitude trouvée.
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr>
                <td [attr.colspan]="isTipsCardMaximized ? 8 : 4">
                  Chargement en cours. Veuillez patienter...
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="grid" *ngIf="category; else loadingSkeleton">
    <div class="col-12 md:col-6">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isDescriptionFrCardOpen }"
        >
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/fr.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Description en français</h3>
          </div>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' + (isDescriptionFrCardOpen ? 'pi-minus' : 'pi-plus')
              "
              (click)="isDescriptionFrCardOpen = !isDescriptionFrCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isDescriptionFrCardOpen">
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="category.descriptionFr"
              *ngIf="category.descriptionFr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isDescriptionArCardOpen }"
        >
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/ma.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Description en arabe</h3>
          </div>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' + (isDescriptionArCardOpen ? 'pi-minus' : 'pi-plus')
              "
              (click)="isDescriptionArCardOpen = !isDescriptionArCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isDescriptionArCardOpen">
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="category.descriptionAr"
              dir="rtl"
              *ngIf="category.descriptionAr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<p-dialog
  class="p-fluid"
  [header]="tipDialogHeader"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 920px)' }"
  [(visible)]="isTipDialogVisible"
>
  <ng-template pTemplate="content">
    <form class="w-full md:w-10 mx-auto" [formGroup]="tipForm">
      <div class="mb-4">
        <div class="text-center">
          <div class="inline-block my-4 shadow-4" style="line-height: 0">
            <p-image
              [preview]="true"
              [src]="croppedImageSrc"
              width="320"
              *ngIf="croppedImageSrc || (croppedFiles && imageFilename)"
            ></p-image>
          </div>

          <p-fileUpload
            name="file"
            #fileUploader
            accept="image/*"
            chooseIcon="pi pi-image"
            chooseLabel="Choisir une image"
            mode="basic"
            [files]="croppedFiles || []"
            [maxFileSize]="500 * 1024"
            [url]="tipIllustrationUploadUrl"
            (onError)="onUploadError($event)"
            (onProgress)="isLoading = true"
            (onSelect)="onSelect($event)"
            (onUpload)="onTipUploadSuccess($event)"
          ></p-fileUpload>

          <div>
            <be-green--ui--input-error
              [class]="'justify-content-center font-bold p-error mt-2'"
              [control]="tipFormControls.imageUrl"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="nameFr"
            (keyup.enter)="saveTip()"
            formControlName="nameFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - tipFormControls.nameFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="tipFormControls.nameFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="nameAr"
            dir="rtl"
            (keyup.enter)="saveTip()"
            formControlName="nameAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - tipFormControls.nameAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="tipFormControls.nameAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="contentFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Contenu en français
          </label>

          <textarea
            class="w-full mb-3"
            formControlName="contentFr"
            maxlength="2048"
            pInputTextarea
            style="min-height: 109px"
            [autoResize]="true"
            [rows]="5"
            (keyup.enter)="saveTip()"
          ></textarea>

          <p>
            {{ 2048 - tipFormControls.contentFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="tipFormControls.contentFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="contentAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Contenu en arabe
          </label>

          <textarea
            class="w-full mb-3"
            dir="rtl"
            formControlName="contentAr"
            maxlength="2048"
            pInputTextarea
            style="min-height: 109px"
            [autoResize]="true"
            [rows]="5"
            (keyup.enter)="saveTip()"
          ></textarea>

          <p>
            {{ 2048 - tipFormControls.contentAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="tipFormControls.contentAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isTipDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveTip()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isDeleteTipDialogVisible"
>
  <div class="flex align-items-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="tip"
      >Voulez-vous supprimer l’habitude <b>{{ tip.nameFr }}</b> ?</span
    >
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isDeleteTipDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmDeleteTip()"
    ></button>
  </ng-template>
</p-dialog>

<ng-template #noImage>
  <img
    class="shadow-4"
    src="assets/layout/images/empty-300x240.jpg"
    width="100"
  />
</ng-template>

<p-dialog
  header="Confirmation"
  [breakpoints]="{ '960px': '50vw', '640px': '85vw' }"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isConfirmDialogVisible"
>
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

    <div>
      <span [innerHTML]="confirmationDialogMessage"></span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isConfirmDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirm()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  header="Recadrer l’image (vers un ratio de 3/2)"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isCropperDialogVisible"
>
  <ng-template pTemplate="content">
    <image-cropper
      #imageCropper
      [aspectRatio]="3 / 2"
      [autoCrop]="false"
      [canvasRotation]="imageRotation"
      [imageBase64]="selectedImageData"
      [maintainAspectRatio]="true"
      [onlyScaleDown]="true"
      [resizeToWidth]="700"
      [transform]="imageTransforms"
      (cropperReady)="onCropperReady($event)"
      (imageCropped)="onImageCropped($event)"
      (loadImageFailed)="onLoadImageFailed()"
    ></image-cropper>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="grid" *ngIf="isCropperReady">
      <div class="w-3 text-left">
        <button
          class="p-button-text p-button-danger"
          icon="pi pi-times"
          label="Annuler"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cancelImageCropModal()"
        ></button>
      </div>

      <div class="w-6 text-center">
        <p-button
          icon="pi pi-replay"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateLeft()"
        ></p-button>

        <p-button
          icon="pi pi-refresh"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateRight()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-h"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipHorizontal()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-v"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipVertical()"
        ></p-button>

        <p-button
          icon="pi pi-search-minus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale <= imageScaleMin"
          [loading]="isLoading"
          (click)="zoomOut()"
        ></p-button>

        <p-button
          icon="pi pi-search-plus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale >= imageScaleMax"
          [loading]="isLoading"
          (click)="zoomIn()"
        ></p-button>
      </div>

      <div class="w-3 text-right">
        <button
          class="p-button-text p-button-success"
          icon="pi pi-check"
          label="Enregistrer"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cropAndUploadImage()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>
