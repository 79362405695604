import { Type } from 'class-transformer';
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
} from 'class-validator';

export const enum QualityCode {
  Happy = 'happy',
  Neutral = 'neutral',
  Sad = 'sad',
}

export class QualityDto {
  @IsNotEmpty()
  readonly code!: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsNotEmpty()
  readonly points!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @Min(0)
  @Max(100)
  @IsNotEmpty()
  readonly ratio!: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;
}
