import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditMaterialDto } from '@be-green/dto';
import { ErrorService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MaterialsEditService } from '../../materials-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--materials-edit-steps-co2-impact',
  templateUrl: './materials-edit-steps-co2-impact.component.html',
  styleUrls: ['./materials-edit-steps-co2-impact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsEditStepsCo2ImpactComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly materialsEditService: MaterialsEditService,
  ) {}

  ngOnInit(): void {
    if (!this.materialsEditService.dirtyMaterial) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.materialsEditService.currentStep === 4) {
      this.materialsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      gramsOfCo2SavedWhenRecyclingOneGram: AbstractControl;
      kilometersAllowedByOneKiloOfCo2: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      gramsOfCo2SavedWhenRecyclingOneGram: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(9.99),
        ]),
      ],
      kilometersAllowedByOneKiloOfCo2: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(9.99),
        ]),
      ],
    });

    if (this.materialsEditService.dirtyMaterial) {
      this.currentStepForm.patchValue(this.materialsEditService.dirtyMaterial);
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.materialsEditService.setDirtyMaterial(<EditMaterialDto>{
      gramsOfCo2SavedWhenRecyclingOneGram:
        this.currentStepFormControls.gramsOfCo2SavedWhenRecyclingOneGram.value,
      kilometersAllowedByOneKiloOfCo2:
        this.currentStepFormControls.kilometersAllowedByOneKiloOfCo2.value,
    });

    this.materialsEditService.saveDirtyMaterial().subscribe({
      next: (value: { code: string } | void) => {
        if (value) {
          this.router.navigate(['/materials', value.code]);
        } else if (
          this.materialsEditService.material &&
          this.materialsEditService.editAction === 'update'
        ) {
          this.router.navigate([
            '/materials',
            this.materialsEditService.material.code,
          ]);
        } else {
          this.router.navigate(['/materials']);
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.isLoading = false;
      },
    });
  }

  previousStep() {
    this.materialsEditService.previousStep();

    this.router.navigate(['energy-impact'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
