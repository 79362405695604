<div
  class="layout-wrapper"
  [ngClass]="{
    'layout-overlay': false,
    'layout-static': true,
    'layout-theme-light': true,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': false,
    'p-input-filled': false
  }"
>
  <be-green--admin--top-bar
    logoName=""
    [topMenuActive]="topMenuActive"
    (notifyToggleMenu)="toggleMenu($event)"
    (notifyToggleTopMenu)="toggleTopMenu($event)"
  ></be-green--admin--top-bar>

  <div class="layout-sidebar">
    <be-green--admin--menu
      [menuConfig]="layoutService.menuConfig"
      [isDesktop]="isDesktop()"
      [isOverlay]="isOverlay()"
    ></be-green--admin--menu>
  </div>

  <div class="layout-main-container">
    <div class="layout-main">
      <p-breadcrumb
        *ngIf="layoutService.breadcrumbs"
        [model]="layoutService.breadcrumbs"
        [home]="layoutService.breadcrumbsHome"
      ></p-breadcrumb>

      <router-outlet></router-outlet>
    </div>

    <be-green--admin--footer
      [footerName]="layoutService.footerName"
      [logoName]="layoutService.logoName"
    ></be-green--admin--footer>
  </div>

  <div class="layout-mask p-component-overlay" *ngIf="menuActiveMobile"></div>
</div>
