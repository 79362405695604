import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EditQualityDto, QualityDto } from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QualityApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  getAll(): Observable<QualityDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<QualityDto[]>(`/qualities/admin`);
  }

  getOne(code: string): Observable<QualityDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<QualityDto>(`/qualities/admin/${code}`);
  }

  update(code: string, payload: EditQualityDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/qualities/admin/${code}`, payload);
  }
}
