import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramLocalizedDto } from '../programs';

export class OrganizationTypeLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly name!: string;

  @ValidateNested()
  @Type(() => ProgramLocalizedDto)
  @IsOptional()
  readonly programs?: ProgramLocalizedDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  updatedAt?: Date;
}
