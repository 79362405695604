import { IsNotEmpty, IsOptional } from 'class-validator';
import { NotificationContext } from '../common';
import { ProgramType } from '../programs';
import { BasketStatusCode } from './basket-status.dto';

export class BasketNotificationDto {
  @IsNotEmpty()
  readonly context!: NotificationContext;

  @IsNotEmpty()
  readonly code!: string;

  @IsNotEmpty()
  readonly programCode!: string;

  @IsOptional()
  readonly programName?: {
    ar: string;
    fr: string;
  };

  @IsNotEmpty()
  readonly statusCode!: BasketStatusCode;

  @IsOptional()
  readonly expectedPoints?: string;

  @IsOptional()
  readonly validatedPoints?: string;

  @IsOptional()
  readonly validatedQuantity?: string;

  @IsOptional()
  readonly validatedWeight?: string;

  /**
   * DEPRECATED
   * ----------
   * Use "programType?: ProgramType"
   *
   * Kept for backwards compatibility with:
   * - Handler mobile apps ≤ 1.2.1
   * - User mobile apps ≤ 1.2.0
   */
  // 'true' or 'false'
  @IsOptional()
  readonly programIsGoodDeal?: string;

  @IsOptional()
  readonly programType?: ProgramType;
}
