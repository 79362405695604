import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ArticlesEditService } from '../../articles-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--articles-edit-steps-general',
  templateUrl: './articles-edit-steps-general.component.html',
  styleUrls: ['./articles-edit-steps-general.component.scss'],
})
export class ArticlesEditStepsGeneralComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly articlesEditService: ArticlesEditService,
  ) {}

  ngOnInit(): void {
    if (this.articlesEditService.currentStep === 1) {
      this.articlesEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      titleAr: AbstractControl;
      titleFr: AbstractControl;
      sourceAr: AbstractControl;
      sourceFr: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      titleAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      titleFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      sourceAr: [null, Validators.compose([Validators.maxLength(140)])],
      sourceFr: [null, Validators.compose([Validators.maxLength(140)])],
    });

    if (this.articlesEditService.dirtyArticle) {
      this.currentStepForm.patchValue(this.articlesEditService.dirtyArticle);
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.articlesEditService.setDirtyArticle(this.currentStepForm.value);

    this.articlesEditService.nextStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
