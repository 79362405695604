import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditProgramDto,
  LanguageCode,
  ParticipationLocalizedDto,
  ProgramDto,
  ProgramLocalizedDto,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  checkAffiliationCode(
    code: string,
    languageCode: LanguageCode,
  ): Observable<ProgramLocalizedDto> {
    return this.httpClient.get<ProgramLocalizedDto>(
      `/programs/affiliation/${code}/${languageCode}`,
    );
  }

  checkReferralCode(
    code: string,
    languageCode: LanguageCode,
  ): Observable<ParticipationLocalizedDto> {
    return this.httpClient.get<ParticipationLocalizedDto>(
      `/programs/referral/${code}/${languageCode}`,
    );
  }

  create(payload: EditProgramDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(`/programs/admin`, payload);
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/programs/admin/${code}`);
  }

  exportBaskets(code: string): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/programs/${code}/export/baskets`, {
      responseType: 'blob',
    });
  }

  exportHandlers(code: string): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/programs/${code}/export/handlers`, {
      responseType: 'blob',
    });
  }

  exportRedemptions(code: string): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/programs/${code}/export/redemptions`, {
      responseType: 'blob',
    });
  }

  exportUsers(code: string): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/programs/${code}/export/users`, {
      responseType: 'blob',
    });
  }

  getAffiliatedOne(
    languageCode: LanguageCode,
  ): Observable<ProgramLocalizedDto> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.get<ProgramLocalizedDto>(
      `/programs/affiliated/${languageCode}`,
    );
  }

  getAll(): Observable<ProgramDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ProgramDto[]>(`/programs/admin`);
  }

  getAllLocalized(
    languageCode: LanguageCode,
  ): Observable<ProgramLocalizedDto[]> {
    return this.httpClient.get<ProgramLocalizedDto[]>(
      `/programs/${languageCode}`,
    );
  }

  getAllLocalizedRecycling(
    languageCode: LanguageCode,
  ): Observable<ProgramLocalizedDto[]> {
    return this.httpClient.get<ProgramLocalizedDto[]>(
      `/programs/${languageCode}/recycling`,
    );
  }

  getOne(code: string): Observable<ProgramDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ProgramDto>(`/programs/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<ProgramLocalizedDto> {
    return this.httpClient.get<ProgramLocalizedDto>(
      `/programs/${languageCode}/${code}`,
    );
  }

  getOneLocalizedGoodDealBin(
    languageCode: LanguageCode,
    code: string,
  ): Observable<ProgramLocalizedDto> {
    return this.httpClient.get<ProgramLocalizedDto>(
      `/programs/${languageCode}/${code}/goodDealBin`,
    );
  }

  removeAffiliatedOne(referralCode: string): Observable<void> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.delete<void>(`/programs/affiliated/${referralCode}`);
  }

  reorder(codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/reorder`, codes);
  }

  saveAffiliation(code: string): Observable<void> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.post<void>(`/programs/affiliation/${code}`, {});
  }

  shareReferralLink(programCode: string): Observable<{ referralCode: string }> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.put<{ referralCode: string }>(
      `/programs/${programCode}/refer`,
      null,
    );
  }

  update(code: string, payload: EditProgramDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/admin/${code}`, payload);
  }
}
