import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasketApi } from '@be-green/api-services';
import { BasketDto, BasketProductDto, ProgramType } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--baskets-view',
  templateUrl: './baskets-view.component.html',
  styleUrls: ['./baskets-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasketsViewComponent implements OnInit {
  basket?: BasketDto;
  includedBaskets?: BasketDto[];
  isLoading = false;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly basketApi: BasketApi,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'QR codes' }]);
    this.seoService.setTitle('QR codes - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.basketApi.getOneAsAdmin(code).subscribe({
      next: (basket) => {
        this.basket = basket;

        if (basket.quality) {
          this.basketApi.getAllIncluded(code).subscribe({
            next: (includedBaskets) => {
              this.includedBaskets = includedBaskets;
            },

            error: (error: HttpErrorResponse) => {
              this.errorService.handleError(error);

              this.isLoading = false;
            },
          });
        }

        this.layoutService.registerBreadcrumbs([
          { label: 'QR codes', routerLink: '/baskets' },
          { label: this.basket.code },
        ]);

        this.seoService.setTitle(
          `${this.basket.code} - QR codes - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asBasketDto(basket: any): BasketDto {
    return basket as BasketDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asBasketProductDto(basketProduct: any): BasketProductDto {
    return basketProduct as BasketProductDto;
  }

  getProgramTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'danger' | 'success' | 'warning' | '' {
    return UtilsService.getBasketStatusTagSeverity(statusCode);
  }
}
