<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    articlesEditService.currentStep === 1 &&
      (articlesEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="articlesEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="titleFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="titleFr"
            formControlName="titleFr"
            maxlength="140"
            pInputText
            style="padding: 1rem"
            (keyup.enter)="nextStep()"
          />

          <p>
            {{ 140 - currentStepFormControls.titleFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.titleFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="titleAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="titleAr"
            dir="rtl"
            formControlName="titleAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
            (keyup.enter)="nextStep()"
          />

          <p>
            {{ 140 - currentStepFormControls.titleAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.titleAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="sourceFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Source en français
          </label>

          <input
            class="w-full mb-3"
            id="sourceFr"
            formControlName="sourceFr"
            maxlength="140"
            pInputText
            style="padding: 1rem"
            maxlength="140"
            (keyup.enter)="nextStep()"
          />

          <p>
            {{ 140 - currentStepFormControls.sourceFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.sourceFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="sourceAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Source en arabe
          </label>

          <input
            class="w-full mb-3"
            id="sourceAr"
            dir="rtl"
            formControlName="sourceAr"
            maxlength="140"
            pInputText
            style="padding: 1rem"
            maxlength="140"
            (keyup.enter)="nextStep()"
          />

          <p>
            {{ 140 - currentStepFormControls.sourceAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.sourceAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-end">
        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
