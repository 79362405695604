import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import 'reflect-metadata';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://fbee1541540b070d6710ddfbd6bee7d2@o1041861.ingest.sentry.io/4505953490894848',

    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/begreen_admin_dev/,
          /^https:\/\/bo\.bg\.tellibus\.com/,
          /^https:\/\/admin\.begreen\.africa/,
        ],

        routingInstrumentation: Sentry.routingInstrumentation,
      }),

      new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState !== 'loading') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
