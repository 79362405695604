import { SafeUrl } from '@angular/platform-browser';
import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { OrganizationTypeLocalizedDto } from '../organization-types';
import { GiftLocalizedDto } from './gift-localized.dto';
import { IconDto } from './icon.dto';
import { ProductLocalizedDto } from './product-localized.dto';
import { CollectionType, ProgramType } from './program.dto';

export class ProgramLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  /**
   * DEPRECATED
   * ----------
   * Use "type!: ProgramType"
   *
   * Kept for backwards compatibility with:
   * - Handler mobile apps ≤ 1.2.1
   * - User mobile apps ≤ 1.2.0
   */
  // 'true' or 'false'
  @IsNotEmpty()
  readonly isGoodDeal!: boolean;

  @IsNotEmpty()
  readonly type!: ProgramType;

  @IsNotEmpty()
  readonly collectionType!: CollectionType;

  @IsOptional()
  readonly organizationType?: OrganizationTypeLocalizedDto;

  @IsOptional()
  readonly affiliationCode?: string;

  @IsNotEmpty()
  readonly name!: string;

  @IsNotEmpty()
  readonly startDate!: Date;

  @IsNotEmpty()
  readonly endDate!: Date;

  @IsNotEmpty()
  readonly minimumProductQuantity!: number;

  readonly maximumProductQuantity?: number;

  readonly maximumUserBasketsPerDay?: number;

  @IsNotEmpty()
  readonly summary!: string;

  @IsNotEmpty()
  readonly description!: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  readonly referrerPoints!: number;

  @IsNotEmpty()
  readonly referredPoints!: number;

  @IsOptional()
  readonly mobileVideo?: string;

  @IsOptional()
  readonly handlerVideo?: string;

  @IsNotEmpty()
  readonly userCount!: number;

  @IsNotEmpty()
  readonly collectedQuantity!: number;

  @IsNotEmpty()
  readonly collectedWeight!: number;

  @IsNotEmpty()
  readonly gramsOfCo2Saved!: number;

  @IsNotEmpty()
  readonly metersAllowed!: number;

  @IsNotEmpty()
  readonly kiloJoulesSaved!: number;

  @IsNotEmpty()
  readonly hoursOfLightingAllowed!: number;

  @ValidateNested()
  @Type(() => GiftLocalizedDto)
  @IsOptional()
  readonly gifts?: GiftLocalizedDto[];

  @ValidateNested()
  @Type(() => IconDto)
  @IsOptional()
  readonly icons?: IconDto[];

  @ValidateNested()
  @Type(() => ProductLocalizedDto)
  @IsOptional()
  readonly products?: ProductLocalizedDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  gift?: boolean;
  pendingBasketCode?: string;
  progress?: number;
  ownPoints?: number;
  ownQuantity?: number;
  ownWeight?: number;
  referralCode?: string;
  safeVideoUrl?: SafeUrl;
}
