import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasketDto,
  BasketLocalizedDto,
  BasketStatusDto,
  EditBasketDto,
  LanguageCode,
  QueryUiDto,
} from '@be-green/dto';
import { AuthenticationService, UtilsService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasketApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  approve(code: string, basketDto: EditBasketDto): Observable<BasketStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(
      `/baskets/${code}/approve`,
      basketDto,
    );
  }

  approveBinCollection(
    code: string,
    basketDto: EditBasketDto,
  ): Observable<BasketStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(
      `/baskets/${code}/collection/approve`,
      basketDto,
    );
  }

  approveGoodDealBin(
    code: string,
    basketDto: EditBasketDto,
  ): Observable<BasketStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(
      `/baskets/${code}/good-deal/bin/approve`,
      basketDto,
    );
  }

  collect(code: string): Observable<BasketStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(
      `/baskets/${code}/collect`,
      null,
    );
  }

  createOrGetPendingBasket(programCode: string): Observable<{ code: string }> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/programs/${programCode}/basket`,
      null,
    );
  }

  deposit(
    code: string,
    basketDto: EditBasketDto,
    handlerCode: string,
  ): Observable<BasketStatusDto> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(
      `/baskets/${code}/handler/${handlerCode}`,
      basketDto,
    );
  }

  export(): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/baskets/export`, {
      responseType: 'blob',
    });
  }

  getAll(
    queryUiDto: QueryUiDto,
  ): Observable<{ rows: BasketDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: BasketDto[]; count: number }>(
      `/baskets`,
      {
        params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
      },
    );
  }

  getAllIncluded(code: string): Observable<BasketDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<BasketDto[]>(`/baskets/admin/${code}/included`);
  }

  getOneAsAdmin(code: string): Observable<BasketDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<BasketDto>(`/baskets/admin/${code}`);
  }

  getOneBinBasket(
    languageCode: LanguageCode,
    code: string,
  ): Observable<BasketLocalizedDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.get<BasketLocalizedDto>(
      `/baskets/bin/basket/${code}/${languageCode}`,
    );
  }

  getOneOfMine(
    languageCode: LanguageCode,
    code: string,
  ): Observable<BasketLocalizedDto> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.get<BasketLocalizedDto>(
      `/baskets/${code}/${languageCode}`,
    );
  }

  scan(
    languageCode: LanguageCode,
    code: string,
  ): Observable<BasketLocalizedDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketLocalizedDto>(
      `/baskets/${code}/scan/${languageCode}`,
      null,
    );
  }

  scanBinQrCode(
    languageCode: LanguageCode,
    code: string,
  ): Observable<BasketLocalizedDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<BasketLocalizedDto>(
      `/baskets/bin/${code}/${languageCode}`,
      null,
    );
  }

  validateOneOfMine(
    code: string,
    basketDto: EditBasketDto,
  ): Observable<BasketStatusDto> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.put<BasketStatusDto>(`/baskets/${code}`, basketDto);
  }
}
