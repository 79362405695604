import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditOrganizationTypeDto,
  LanguageCode,
  OrganizationTypeDto,
  OrganizationTypeLocalizedDto,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationTypeApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(payload: EditOrganizationTypeDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(`/organization-types/admin`, payload);
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/organization-types/admin/${code}`);
  }

  getAll(): Observable<OrganizationTypeDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<OrganizationTypeDto[]>(`/organization-types/admin`);
  }

  getAllLocalized(
    languageCode: LanguageCode,
  ): Observable<OrganizationTypeLocalizedDto[]> {
    return this.httpClient.get<OrganizationTypeLocalizedDto[]>(
      `/organization-types/${languageCode}`,
    );
  }

  getOne(code: string): Observable<OrganizationTypeDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<OrganizationTypeDto>(
      `/organization-types/admin/${code}`,
    );
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<OrganizationTypeLocalizedDto> {
    return this.httpClient.get<OrganizationTypeLocalizedDto>(
      `/organization-types/${languageCode}/${code}`,
    );
  }

  reorder(codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/organization-types/reorder`, codes);
  }

  update(code: string, payload: EditOrganizationTypeDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/organization-types/admin/${code}`, payload);
  }
}
