import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonApi } from '@be-green/api-services';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { QuillModules } from 'ngx-quill';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'be-green--admin--terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsComponent implements OnInit, ComponentCanDeactivate {
  private contentCache?: { ar?: string; fr?: string };
  isEditMode = false;
  isLoading = false;
  isNewRecord = false;
  quillModules: QuillModules = {
    clipboard: { matchVisual: false },
  };
  termsAndConditionsAr?: string;
  termsAndConditionsFr?: string;

  constructor(
    private readonly commonApi: CommonApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'C.G.U' }]);
    this.seoService.setTitle('C.G.U. - Admin - Be Green');
  }

  isDirty() {
    return (
      this.contentCache !== undefined &&
      (this.termsAndConditionsAr !== this.contentCache.ar ||
        this.termsAndConditionsFr !== this.contentCache.fr)
    );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.commonApi.getTermsAndConditions().subscribe({
      next: (terms) => {
        this.isNewRecord = terms === null;

        if (!this.isNewRecord) {
          this.termsAndConditionsAr = terms.contentAr;
          this.termsAndConditionsFr = terms.contentFr;
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  cancelEditMode() {
    this.termsAndConditionsAr = this.contentCache?.ar;
    this.termsAndConditionsFr = this.contentCache?.fr;
    this.isEditMode = false;
  }

  enterEditMode() {
    this.contentCache = {
      ar: this.termsAndConditionsAr,
      fr: this.termsAndConditionsFr,
    };

    this.isEditMode = true;
  }

  save() {
    if (this.termsAndConditionsFr) {
      this.isLoading = true;

      if (this.isNewRecord) {
        this.commonApi
          .createTermsAndConditions({
            ar: this.termsAndConditionsAr,
            fr: this.termsAndConditionsFr,
          })
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.isEditMode = false;
              this.isNewRecord = false;

              this.contentCache = {
                ar: this.termsAndConditionsAr,
                fr: this.termsAndConditionsFr,
              };
            },

            error: (error: HttpErrorResponse) => {
              this.errorService.handleError(error);

              this.isLoading = false;
            },
          });
      } else {
        this.commonApi
          .updateTermsAndConditions({
            ar: this.termsAndConditionsAr,
            fr: this.termsAndConditionsFr,
          })
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.isEditMode = false;

              this.contentCache = {
                ar: this.termsAndConditionsAr,
                fr: this.termsAndConditionsFr,
              };
            },

            error: (error: HttpErrorResponse) => {
              this.errorService.handleError(error);

              this.isLoading = false;
            },
          });
      }
    }
  }
}
