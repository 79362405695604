import { IsDateString, IsNotEmpty, IsOptional } from 'class-validator';

export const enum PersonGenre {
  DidNotRespond = 'DNR',
  Female = 'F',
  Male = 'M',
}

export const enum TelecomOperator {
  Inwi = 'INWI',
  MarocTelecom = 'MT',
  Orange = 'ORANGE',
  Other = 'OTHER',
}

export const enum TelecomOperatorMccMnc {
  Inwi = '60402',
  MarocTelecom = '60401',
  Orange = '60400',
}

export class PersonDto {
  @IsNotEmpty()
  code!: string;

  @IsOptional()
  readonly title?: string;

  @IsNotEmpty()
  readonly firstName!: string;

  @IsNotEmpty()
  readonly lastName!: string;

  @IsOptional()
  readonly genre?: PersonGenre;

  @IsOptional()
  readonly mobilePhone?: string;

  @IsOptional()
  readonly telecomOperator?: TelecomOperator;

  @IsDateString()
  @IsOptional()
  readonly birthdate?: string;

  lastPeriodApprovedBasketCount?: number;
  currentPeriodApprovedBasketCount?: number;
  currentBadge?: number;
  currentRank?: number;

  @IsOptional()
  readonly initial?: string;

  @IsOptional()
  readonly initials?: string;

  @IsOptional()
  readonly nameToHslColors?: { background: string; contrast: string };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contributions?: any[];
  createdAt?: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentAuthProvider?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler?: any;
  updatedAt?: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
}
