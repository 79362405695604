import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';

export class EditOrganizationTypeDto {
  @IsOptional()
  readonly code?: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameFr!: string;

  @MaxLength(140)
  @IsNotEmpty()
  readonly nameAr!: string;
}
