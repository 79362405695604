<div class="card" [ngClass]="{ 'tlb--max-screen-height': isEditMode }">
  <div class="mb-4" *ngIf="isEditMode; else viewToolbar">
    <button
      class="p-button-success"
      pButton
      pRipple
      label="Enregistrer"
      icon="pi pi-check"
      (click)="save()"
    ></button>

    <button
      class="p-button-outlined p-button-danger ml-2"
      pButton
      pRipple
      label="Annuler"
      icon="pi pi-times"
      (click)="cancelEditMode()"
    ></button>
  </div>

  <ng-template #viewToolbar>
    <div class="mb-4">
      <button
        class="p-button-warning"
        pButton
        pRipple
        label="Modifier"
        icon="pi pi-pencil"
        (click)="enterEditMode()"
      ></button>
    </div>
  </ng-template>

  <div class="grid" *ngIf="isEditMode; else display">
    <div class="col-12 md:col-6">
      <ng-content *ngTemplateOutlet="headerFr"></ng-content>

      <p-editor
        [(ngModel)]="termsAndConditionsFr"
        [sanitize]="true"
        [modules]="quillModules"
      >
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-header" title="Style">
              <option value="1">Titre</option>
              <option value="2">Sous-titre</option>
              <option selected></option>
            </select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold" type="button" title="Gras"></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-list"
              type="button"
              value="ordered"
              title="Liste numérotée"
            ></button>

            <button
              class="ql-list"
              type="button"
              value="bullet"
              title="Liste à puces"
            ></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-indent"
              type="button"
              value="-1"
              title="Désindenter"
            ></button>
            <button
              class="ql-indent"
              type="button"
              value="+1"
              title="Indenter"
            ></button>
          </span>

          <span class="ql-formats">
            <select class="ql-align" title="Alignement">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>

          <span class="ql-formats">
            <button
              class="ql-clean"
              type="button"
              title="Supprimer le formatage"
            ></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-link"
              type="button"
              title="Insérer un lien"
            ></button>
          </span>
        </ng-template>
      </p-editor>
    </div>

    <div class="col-12 md:col-6">
      <ng-content *ngTemplateOutlet="headerAr"></ng-content>

      <p-editor
        [(ngModel)]="termsAndConditionsAr"
        [sanitize]="true"
        [modules]="quillModules"
      >
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-header" title="Style">
              <option value="1">Titre</option>
              <option value="2">Sous-titre</option>
              <option selected></option>
            </select>
          </span>

          <span class="ql-formats">
            <button class="ql-bold" type="button" title="Gras"></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-list"
              type="button"
              value="ordered"
              title="Liste numérotée"
            ></button>

            <button
              class="ql-list"
              type="button"
              value="bullet"
              title="Liste à puces"
            ></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-indent"
              type="button"
              value="-1"
              title="Désindenter"
            ></button>
            <button
              class="ql-indent"
              type="button"
              value="+1"
              title="Indenter"
            ></button>
          </span>

          <span class="ql-formats">
            <select class="ql-align" title="Alignement">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>

          <span class="ql-formats">
            <button
              class="ql-clean"
              type="button"
              title="Supprimer le formatage"
            ></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-link"
              type="button"
              title="Insérer un lien"
            ></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-direction"
              type="button"
              value="rtl"
              title="Changer la direction"
            ></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
  </div>

  <ng-template #display>
    <div class="grid">
      <div class="col-12 md:col-6">
        <ng-content *ngTemplateOutlet="headerFr"></ng-content>

        <div class="p-editor-container">
          <div
            class="p-editor-content ql-snow"
            style="padding: 12px 15px"
            [innerHTML]="termsAndConditionsFr"
            *ngIf="termsAndConditionsFr; else notSet"
          ></div>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <ng-content *ngTemplateOutlet="headerAr"></ng-content>

        <div class="p-editor-container">
          <div
            class="p-editor-content ql-snow"
            style="padding: 12px 15px"
            [innerHTML]="termsAndConditionsAr"
            dir="rtl"
            *ngIf="termsAndConditionsAr; else notSet"
          ></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #notSet>
  <div class="text-pink-600 font-italic">
    <div class="p-editor-content ql-snow" style="padding: 12px 15px">
      Non défini
    </div>
  </div>
</ng-template>

<ng-template #headerAr>
  <div class="flex align-items-center tlb--editor-header">
    <img class="mr-2" src="assets/flags/ma.svg" height="20" />

    <div style="font-weight: 700">
      Conditions générales d’utilisation en arabe
    </div>
  </div>
</ng-template>

<ng-template #headerFr>
  <div class="flex align-items-center tlb--editor-header">
    <img class="mr-2" src="assets/flags/fr.svg" height="20" />

    <div style="font-weight: 700">
      Conditions générales d’utilisation en français
    </div>
  </div>
</ng-template>
