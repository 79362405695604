import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CategoryDto,
  CategoryLocalizedDto,
  EditCategoryDto,
  LanguageCode,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(payload: EditCategoryDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(`/categories/admin`, payload);
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/categories/admin/${code}`);
  }

  getAll(): Observable<CategoryDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<CategoryDto[]>(`/categories/admin`);
  }

  getAllLocalized(
    languageCode: LanguageCode,
  ): Observable<CategoryLocalizedDto[]> {
    return this.httpClient.get<CategoryLocalizedDto[]>(
      `/categories/${languageCode}`,
    );
  }

  getAllOfUser(code: string): Observable<CategoryDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<CategoryDto[]>(`/categories/admin/user/${code}`);
  }

  getOne(code: string): Observable<CategoryDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<CategoryDto>(`/categories/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<CategoryLocalizedDto> {
    return this.httpClient.get<CategoryLocalizedDto>(
      `/categories/${languageCode}/${code}`,
    );
  }

  reorder(codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/categories/reorder`, codes);
  }

  update(code: string, payload: EditCategoryDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/categories/admin/${code}`, payload);
  }
}
