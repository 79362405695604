<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="handler; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center">
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                class="shadow-4"
                src="{{ handler.clonedPhotoUrl ?? handler.photoUrl }}"
                [preview]="true"
                height="56"
                width="56"
                *ngIf="
                  handler.clonedPhotoUrl || handler.photoUrl;
                  else noPicture
                "
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="handler.nameFr.charAt(0)"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-medium text-3xl text-900">
              {{ handler.nameFr }}

              <br />
              <p-tag
                severity="info"
                [value]="'Niveau ' + handler.level"
              ></p-tag>
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ handler.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="handler.updatedAt; else notSet">
                  {{ handler.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <div class="flex flex-column align-items-center">
            <div
              class="flex align-items-center justify-content-center"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-hashtag text-xl"></i>
            </div>

            <ng-container *ngIf="handler.currentRank; else notSet">
              {{ handler.currentRank }}
            </ng-container>
          </div>

          <div class="flex flex-column align-items-center">
            <div
              class="flex align-items-center justify-content-center"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-qrcode text-xl"></i>
            </div>

            <ng-container
              *ngIf="handler.currentPeriodApprovedBasketCount; else notSet"
            >
              {{ handler.currentPeriodApprovedBasketCount }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <p-messages></p-messages>

    <div class="card mb-0">
      <ul
        class="list-none p-0 m-0 flex-grow-1"
        *ngIf="handler; else loadingSkeleton"
      >
        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Code
          </div>

          <div class="text-900 w-full xl:w-8">
            <p-tag [value]="handler.code"></p-tag>
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            N° de téléphone
          </div>

          <div class="text-900 w-full xl:w-8">
            <ng-container *ngIf="handler.phone; else notSet">
              <div class="flex align-items-center gap-2">
                {{ handler.phone }}

                <p-button
                  icon="pi pi-lock"
                  label="Réinitialiser MDP"
                  styleClass="p-button-warning"
                  (click)="resetPassword()"
                ></p-button>
              </div>
            </ng-container>
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Nom
          </div>

          <div class="text-900 w-full xl:w-8">
            <div class="flex align-items-center mb-4">
              <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

              <ng-container *ngIf="handler.nameFr; else notSet">{{
                handler.nameFr
              }}</ng-container>
            </div>

            <div class="flex align-items-center">
              <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

              <ng-container *ngIf="handler.nameAr; else notSet">{{
                handler.nameAr
              }}</ng-container>
            </div>
          </div>
        </li>

        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Adresse
          </div>

          <div class="text-900 w-full xl:w-8">
            <div class="flex align-items-center mb-4">
              <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

              <ng-container *ngIf="handler.addressFr; else notSet">{{
                handler.addressFr
              }}</ng-container>
            </div>

            <div class="flex align-items-center">
              <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

              <ng-container *ngIf="handler.addressAr; else notSet">{{
                handler.addressAr
              }}</ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <div class="card mb-0">
      <ul
        class="list-none p-0 m-0 flex-grow-1"
        *ngIf="handler; else loadingSkeleton"
      >
        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Latitude
          </div>

          <div class="text-900 w-full xl:w-8">
            <ng-container *ngIf="handler.latitude; else notSet">
              {{ handler.latitude }}
            </ng-container>
          </div>
        </li>

        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Longitude
          </div>

          <div class="text-900 w-full xl:w-8">
            <ng-container *ngIf="handler.longitude; else notSet">
              {{ handler.longitude }}
            </ng-container>
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          *ngIf="handler.latitude && handler.longitude"
        >
          <iframe
            class="w-full"
            [frameBorder]="0"
            [src]="
              'https://www.google.com/maps?q=' +
                handler.latitude +
                ',' +
                handler.longitude +
                '&output=embed' | bypassSecurityTrustResourceUrl
            "
            >Localiser</iframe
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <div class="card mb-0">
      <ul
        class="list-none p-0 m-0 flex-grow-1"
        *ngIf="handler; else loadingSkeleton"
      >
        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Jours de passage programmés
          </div>

          <div class="text-900 w-full xl:w-8">
            <ng-container *ngIf="handler.usualVisitDays; else notSet">
              <p-tag
                class="mx-2"
                [value]="day | date : 'EEEE' : '' : 'fr' | string"
                *ngFor="let day of handler.usualVisitDays"
              ></p-tag>
            </ng-container>
          </div>
        </li>

        <li
          class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
        >
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Jour de passage temporaire
          </div>

          <div class="text-900 w-full xl:w-8">
            <p-tag
              class="mx-2"
              severity="danger"
              [value]="
                handler.nextVisitDay | date : 'EEEE' : '' : 'fr' | string
              "
              *ngIf="handler.nextVisitDay; else notSet"
            >
            </p-tag>
          </div>
        </li>

        <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
          <div
            class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
          >
            Itinéraire
          </div>

          <div class="text-900 w-full xl:w-8">
            <ng-container *ngIf="handler.itinerary; else notSet">
              <ul>
                <li
                  class="py-2"
                  *ngFor="let itineraryHandler of handler.itinerary"
                >
                  <a
                    [routerLink]="'/handlers/' + itineraryHandler.code"
                    target="_blank"
                    >{{ itineraryHandler.code }}</a
                  >

                  <p-tag
                    value="Visité"
                    ngClass="ml-2"
                    severity="success"
                    *ngIf="itineraryHandler.hasBeenVisited"
                  ></p-tag>

                  <p-tag
                    value="Non visité"
                    ngClass="ml-2"
                    severity="danger"
                    *ngIf="!itineraryHandler.hasBeenVisited"
                  ></p-tag>
                </li>
              </ul>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isParticipationCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">
          Résumé
          <ng-container *ngIf="participations; else miniSkeleton"
            >: {{ participations.length }} participations</ng-container
          >
        </h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isParticipationCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isParticipationCardOpen = !isParticipationCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isParticipationCardOpen">
        <p-table
          #participationIndexDataTable
          styleClass="p-datatable-gridlines"
          [currentPageReportTemplate]="
            'Enregistrements {first} à {last} sur un total de {totalRecords}'
          "
          [dataKey]="'code'"
          [loading]="isLoading"
          [paginator]="true"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="true"
          [showCurrentPageReport]="true"
          [value]="participations"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="program.nameFr" pFrozenColumn>
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Programme</div>

                  <p-sortIcon field="program.nameFr"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="program.type">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Type</div>

                  <p-sortIcon field="program.type"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="createdAt">
                <div class="flex justify-content-between align-items-center">
                  Premier engagement

                  <p-sortIcon field="createdAt"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="points">
                <div class="flex justify-content-between align-items-center">
                  Points

                  <p-sortIcon field="points"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="quantity">
                <div class="flex justify-content-between align-items-center">
                  Emballages recyclés

                  <p-sortIcon field="quantity"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="weight">
                <div class="flex justify-content-between align-items-center">
                  Poids recyclé

                  <p-sortIcon field="weight"></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-participation>
            <ng-container
              *ngLet="asParticipationDto(participation) as participation"
            >
              <tr *ngIf="participation">
                <td pFrozenColumn>
                  <a [routerLink]="'/programs/' + participation.program.code">
                    {{ participation.program.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>

                <td>
                  <p-tag
                    [value]="
                      participation.program.type === 'goodDeal'
                        ? 'Bon plan'
                        : participation.program.type === 'pro'
                        ? 'Pro'
                        : 'Recyclage'
                    "
                    [severity]="
                      getProgramTypeTagSeverity(participation.program.type)
                    "
                  ></p-tag>

                  <p *ngIf="participation.program.type === 'recycling'">
                    <p-tag
                      [value]="
                        'Collecte ' +
                        (participation.program.collectionType === 'realTime'
                          ? 'Temps réel'
                          : 'Différée')
                      "
                      [severity]="
                        getCollectionTypeTagSeverity(
                          participation.program.collectionType
                        )
                      "
                    ></p-tag>
                  </p>

                  <p
                    *ngIf="
                      participation.program.type === 'pro' &&
                      participation.program.organizationType
                    "
                  >
                    <p-tag
                      [value]="participation.program.organizationType.nameFr"
                      severity="info"
                    ></p-tag>
                  </p>
                </td>

                <td>
                  {{
                    participation.createdAt | date : 'medium' : undefined : 'fr'
                  }}
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.points; else notSet">
                    {{ participation.points | number : '1.0-0' : 'fr' }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.quantity; else notSet">
                    {{ participation.quantity | number : '1.0-0' : 'fr' }}
                  </ng-container>
                </td>

                <td class="text-right">
                  <ng-container *ngIf="participation.weight; else notSet">
                    {{ participation.weight | number : '1.0-0' : 'fr' }} g
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">Aucune participation trouvée.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="5">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isHistoryCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">
          Historique
          <ng-container *ngIf="history; else miniSkeleton"
            >: {{ history.length }} événements</ng-container
          >
        </h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isHistoryCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isHistoryCardOpen = !isHistoryCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isHistoryCardOpen">
        <p-table
          #historyIndexDataTable
          styleClass="p-datatable-gridlines"
          [currentPageReportTemplate]="
            'Enregistrements {first} à {last} sur un total de {totalRecords}'
          "
          [dataKey]="'code'"
          [loading]="isLoading"
          [paginator]="true"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="true"
          [showCurrentPageReport]="true"
          [value]="history"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="program.nameFr" pFrozenColumn>
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Programme</div>

                  <p-sortIcon field="program.nameFr"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="program.type">
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Type</div>

                  <p-sortIcon field="program.type"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="createdAt">
                <div class="flex justify-content-between align-items-center">
                  Date

                  <p-sortIcon field="createdAt"></p-sortIcon>
                </div>
              </th>

              <th pSortableColumn="context">
                <div class="flex justify-content-between align-items-center">
                  Événement

                  <p-sortIcon field="context"></p-sortIcon>
                </div>
              </th>

              <th>Points (+ / -)</th>

              <th alignFrozen="right" pFrozenColumn></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-history>
            <ng-container *ngLet="asHistoryDto(history) as history">
              <tr *ngIf="history">
                <td pFrozenColumn>
                  <a [routerLink]="'/programs/' + history.program?.code">
                    {{ history.program?.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>

                <td>
                  <p-tag
                    [value]="
                      history.program.type === 'goodDeal'
                        ? 'Bon plan'
                        : history.program.type === 'pro'
                        ? 'Pro'
                        : 'Recyclage'
                    "
                    [severity]="getProgramTypeTagSeverity(history.program.type)"
                    *ngIf="history.program"
                  ></p-tag>

                  <p
                    *ngIf="
                      history.program && history.program.type === 'recycling'
                    "
                  >
                    <p-tag
                      [value]="
                        'Collecte ' +
                        (history.program.collectionType === 'realTime'
                          ? 'Temps réel'
                          : 'Différée')
                      "
                      [severity]="
                        getCollectionTypeTagSeverity(
                          history.program.collectionType
                        )
                      "
                    ></p-tag>
                  </p>

                  <p
                    *ngIf="
                      history.program &&
                      history.program.type === 'pro' &&
                      history.program.organizationType
                    "
                  >
                    <p-tag
                      [value]="history.program.organizationType.nameFr"
                      severity="info"
                    ></p-tag>
                  </p>
                </td>

                <td>
                  {{ history.createdAt | date : 'medium' : undefined : 'fr' }}
                </td>

                <ng-container
                  *ngIf="history.context === 'GIFTS'; else basketDisplay"
                >
                  <ng-container *ngIf="history.data">
                    <ng-container
                      *ngLet="asRedemptionNotificationDto(history.data) as data"
                    >
                      <ng-container *ngIf="data">
                        <td>
                          <div class="flex align-items-center gap-2">
                            <div
                              class="flex align-items-center justify-content-center bg-red-100 border-round"
                              style="width: 2rem; height: 2rem"
                            >
                              <i class="pi pi-gift text-red-500"></i>
                            </div>

                            <div
                              class="flex align-items-center justify-content-center bg-purple-100 border-round"
                              style="width: 2rem; height: 2rem"
                              *ngIf="
                                asRedemptionUsedPoints(data.usedPoints) < 0
                              "
                            >
                              <i class="pi pi-undo"></i>
                            </div>

                            {{ data.giftName.fr }}
                          </div>
                        </td>

                        <td class="text-right">
                          <ng-container
                            *ngIf="asRedemptionUsedPoints(data.usedPoints) < 0"
                            >+</ng-container
                          >{{ -asRedemptionUsedPoints(data.usedPoints) }}
                        </td>

                        <td alignFrozen="right" pFrozenColumn>
                          <button
                            class="p-button-rounded"
                            type="button"
                            pButton
                            icon="pi pi-search"
                            [routerLink]="
                              data.code
                                ? '/redemptions/' + data.code
                                : '/programs/' + data.programCode
                            "
                            *ngIf="data"
                          ></button>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #basketDisplay>
                  <ng-container *ngIf="history.data">
                    <ng-container
                      *ngLet="asBasketNotificationDto(history.data) as data"
                    >
                      <ng-container *ngIf="data">
                        <td>
                          <div
                            class="flex align-items-center font-medium gap-3"
                          >
                            <ng-container [ngSwitch]="history.context">
                              <ng-container *ngSwitchCase="'BASKETS'">
                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-green-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i class="pi pi-inbox text-green-500"></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedQuantity
                                        | number : '1.0-0' : 'fr') || '0'
                                    "
                                    severity="success"
                                  ></p-tag>
                                </div>

                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-orange-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i
                                      class="pi pi-map-marker text-orange-500"
                                    ></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedWeight || 0
                                        | number : '1.0-0' : 'fr') + ' g'
                                    "
                                    severity="success"
                                  ></p-tag>

                                  QR Code approuvé
                                </div>
                              </ng-container>

                              <ng-container *ngSwitchCase="'POINTS'">
                                <div class="flex align-items-center gap-2">
                                  <div
                                    class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                                    style="width: 2rem; height: 2rem"
                                  >
                                    <i class="pi pi-star text-yellow-500"></i>
                                  </div>

                                  <p-tag
                                    [value]="
                                      (data.validatedPoints
                                        | number : '1.0-0' : 'fr') || '0'
                                    "
                                    severity="success"
                                  ></p-tag>
                                </div>

                                Panier approuvé
                              </ng-container>

                              <ng-container *ngSwitchCase="'COLLECTIONS'">
                                <ng-container
                                  *ngIf="
                                    data.statusCode === 'VALIDATED';
                                    else approvedCollection
                                  "
                                >
                                  <div class="flex align-items-center gap-2">
                                    <div
                                      class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                      style="width: 2rem; height: 2rem"
                                    >
                                      <i class="pi pi-qrcode text-cyan-500"></i>
                                    </div>

                                    QR Code créé
                                  </div>
                                </ng-container>

                                <ng-template #approvedCollection>
                                  <div class="flex align-items-center gap-2">
                                    <div
                                      class="flex align-items-center justify-content-center bg-green-100 border-round"
                                      style="width: 2rem; height: 2rem"
                                    >
                                      <i class="pi pi-inbox text-green-500"></i>
                                    </div>

                                    <p-tag
                                      [value]="
                                        (data.validatedQuantity
                                          | number : '1.0-0' : 'fr') || '0'
                                      "
                                      severity="success"
                                    ></p-tag>
                                  </div>

                                  <div class="flex align-items-center gap-2">
                                    <div
                                      class="flex align-items-center justify-content-center bg-orange-100 border-round"
                                      style="width: 2rem; height: 2rem"
                                    >
                                      <i
                                        class="pi pi-map-marker text-orange-500"
                                      ></i>
                                    </div>

                                    <p-tag
                                      [value]="
                                        (data.validatedWeight || 0
                                          | number : '1.0-0' : 'fr') + ' g'
                                      "
                                      severity="success"
                                    ></p-tag>

                                    QR Code approuvé
                                  </div>
                                </ng-template>
                              </ng-container>
                            </ng-container>
                          </div>
                        </td>

                        <td class="text-right">
                          <ng-container
                            *ngIf="data.validatedPoints; else notSet"
                          >
                            +{{ data.validatedPoints }}
                          </ng-container>
                        </td>

                        <td alignFrozen="right" pFrozenColumn>
                          <button
                            class="p-button-rounded"
                            type="button"
                            pButton
                            icon="pi pi-search"
                            [routerLink]="'/baskets/' + data.code"
                            *ngIf="data"
                          ></button>
                        </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">Aucun historique trouvé.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="5">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isResetPasswordDialogVisible"
>
  <div class="flex align-items-center">
    <i
      class="pi pi-exclamation-triangle mr-3 text-orange-500"
      style="font-size: 2rem"
    ></i>

    <div class="flex flex-column" *ngIf="handler; else loadingSkeleton">
      <div>
        Êtes-vous sûr de vouloir
        <b>réinitialiser le mot de passe</b> du détaillant
        <b>{{ handler.code }}</b> ayant le login <b>{{ handler.phone }}</b
        >&nbsp;?
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isResetPasswordDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-warning"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmResetPassword()"
    ></button>
  </ng-template>
</p-dialog>
