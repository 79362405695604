<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    articlesEditService.currentStep === 2 &&
      (articlesEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <div class="mb-4">
      <p-messages></p-messages>
    </div>

    <div class="text-center">
      <div class="inline-block mb-4 shadow-4" style="line-height: 0">
        <p-image
          [preview]="true"
          [src]="croppedImageSrc"
          width="320"
          *ngIf="croppedImageSrc || (croppedFiles && imageFilename)"
        ></p-image>
      </div>

      <p-fileUpload
        name="file"
        #fileUploader
        accept="image/*"
        chooseIcon="pi pi-image"
        chooseLabel="Choisir l’image d’illustration"
        mode="basic"
        [files]="croppedFiles || []"
        [maxFileSize]="500 * 1024"
        [url]="illustrationFileUploadUrl"
        (onError)="onUploadError($event)"
        (onProgress)="isLoading = true"
        (onSelect)="onSelect($event)"
        (onUpload)="onUploadSuccess($event)"
      ></p-fileUpload>

      <div>
        <be-green--ui--input-error
          [class]="'justify-content-center font-bold p-error mt-2'"
          [control]="currentStepFormControls.imageUrl"
          platform="web"
        ></be-green--ui--input-error>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>

<p-dialog
  class="p-fluid"
  header="Recadrer l’image (vers un ratio de 34 / 15)"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isCropperDialogVisible"
>
  <ng-template pTemplate="content">
    <image-cropper
      #imageCropper
      [aspectRatio]="340 / 150"
      [autoCrop]="false"
      [canvasRotation]="imageRotation"
      [imageBase64]="selectedImageData"
      [maintainAspectRatio]="true"
      [onlyScaleDown]="true"
      [resizeToWidth]="700"
      [transform]="imageTransforms"
      (cropperReady)="onCropperReady($event)"
      (imageCropped)="onImageCropped($event)"
      (loadImageFailed)="onLoadImageFailed()"
    ></image-cropper>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="grid" *ngIf="isCropperReady">
      <div class="w-3 text-left">
        <button
          class="p-button-text p-button-danger"
          icon="pi pi-times"
          label="Annuler"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cancelImageCropModal()"
        ></button>
      </div>

      <div class="w-6 text-center">
        <p-button
          icon="pi pi-replay"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateLeft()"
        ></p-button>

        <p-button
          icon="pi pi-refresh"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateRight()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-h"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipHorizontal()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-v"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipVertical()"
        ></p-button>

        <p-button
          icon="pi pi-search-minus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale <= imageScaleMin"
          [loading]="isLoading"
          (click)="zoomOut()"
        ></p-button>

        <p-button
          icon="pi pi-search-plus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale >= imageScaleMax"
          [loading]="isLoading"
          (click)="zoomIn()"
        ></p-button>
      </div>

      <div class="w-3 text-right">
        <button
          class="p-button-text p-button-success"
          icon="pi pi-check"
          label="Enregistrer"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cropAndUploadImage()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
