<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    articlesEditService.currentStep === 4 &&
      (articlesEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="articlesEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="date">
            Date à afficher
          </label>

          <p-calendar
            id="date"
            formControlName="date"
            styleClass="w-full mb-3"
            [maxDate]="today"
            [showButtonBar]="true"
            [showIcon]="true"
          ></p-calendar>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.date"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="readingTimeInMinutes"
          >
            Temps de lecture en minutes
          </label>

          <input
            class="w-full mb-3"
            id="readingTimeInMinutes"
            (keyup.enter)="nextStep()"
            formControlName="readingTimeInMinutes"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.readingTimeInMinutes"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
