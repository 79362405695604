<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    programsEditService.currentStep === 1 &&
      (programsEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="programsEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="type"
            >Type</label
          >

          <div class="inline-block mb-3">
            <p-selectButton
              id="type"
              [options]="types"
              formControlName="type"
              (onChange)="programsEditService.setDirty()"
            ></p-selectButton>
          </div>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.type"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6" *ngIf="currentStepFormControls.type.value === 'pro'">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="organizationType"
            >Type d’organisme Pro</label
          >

          <p-dropdown
            formControlName="organizationType"
            optionLabel="nameFr"
            optionValue="code"
            placeholder="Choisir un type d’organisme"
            styleClass="w-full mb-3 tlb--p-dropdown-padding-1rem"
            [options]="organizationTypes"
            [showClear]="true"
            (onChange)="persistOrganizationTypeName($event.value)"
            *ngIf="organizationTypes"
          ></p-dropdown>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.organizationType"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div
          class="w-6"
          *ngIf="currentStepFormControls.type.value === 'recycling'"
        >
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="collectionType"
            >Type de collecte</label
          >

          <div class="inline-block mb-3">
            <p-selectButton
              id="collectionType"
              [options]="collectionTypes"
              formControlName="collectionType"
              (onChange)="programsEditService.setDirty()"
            ></p-selectButton>
          </div>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.collectionType"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="nameFr"
            (keyup.enter)="nextStep()"
            formControlName="nameFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - currentStepFormControls.nameFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.nameFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="nameAr"
            dir="rtl"
            (keyup.enter)="nextStep()"
            formControlName="nameAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - currentStepFormControls.nameAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.nameAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="summaryFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Résumé en français
          </label>

          <textarea
            class="w-full mb-3"
            formControlName="summaryFr"
            maxlength="255"
            pInputTextarea
            style="min-height: 109px"
            [autoResize]="true"
            [rows]="5"
            (keyup.enter)="nextStep()"
          ></textarea>

          <p>
            {{ 255 - currentStepFormControls.summaryFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.summaryFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="summaryAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Résumé en arabe
          </label>

          <textarea
            class="w-full mb-3"
            dir="rtl"
            formControlName="summaryAr"
            maxlength="255"
            pInputTextarea
            style="min-height: 109px"
            [autoResize]="true"
            [rows]="5"
            (keyup.enter)="nextStep()"
          ></textarea>

          <p>
            {{ 255 - currentStepFormControls.summaryAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.summaryAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-end">
        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
