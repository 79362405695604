import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CategoryApi } from '@be-green/api-services';
import { CategoryDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--tips-index',
  templateUrl: './tips-index.component.html',
  styleUrls: ['./tips-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TipsIndexComponent implements OnInit {
  @ViewChild('filterCategoryIndexDataTable')
  filterCategoryIndexDataTable!: ElementRef;
  @ViewChild('categoryIndexDataTable') categoryIndexDataTable!: Table;

  isLoading = false;
  categories!: CategoryDto[];

  constructor(
    private readonly categoryApi: CategoryApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Catégories' }]);
    this.seoService.setTitle('Catégories - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllCategories();
  }

  private getAllCategories() {
    this.categoryApi.getAll().subscribe({
      next: (categories) => {
        this.categories = categories;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asCategoryDto(category: any): CategoryDto {
    return category as CategoryDto;
  }

  clearCategoriesTable() {
    this.categoryIndexDataTable.clear();
    this.filterCategoryIndexDataTable.nativeElement.value = '';
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    this.isLoading = true;

    this.categoryApi
      .reorder(this.categories.map((category) => category.code))
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }
}
