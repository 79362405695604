import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditProductDto,
  LanguageCode,
  ProductDto,
  ProductLocalizedDto,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(code: string, payload: EditProductDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/programs/${code}/product`,
      payload,
    );
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/products/admin/${code}`);
  }

  getAll(): Observable<ProductDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ProductDto[]>(`/products/admin`);
  }

  getAllLocalized(
    languageCode: LanguageCode,
  ): Observable<ProductLocalizedDto[]> {
    return this.httpClient.get<ProductLocalizedDto[]>(
      `/products/${languageCode}`,
    );
  }

  getOne(code: string): Observable<ProductDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ProductDto>(`/products/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<ProductLocalizedDto> {
    return this.httpClient.get<ProductLocalizedDto>(
      `/products/${languageCode}/${code}`,
    );
  }

  reorder(code: string, codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(
      `/programs/${code}/reorder/products`,
      codes,
    );
  }

  update(code: string, payload: EditProductDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/${code}/product`, payload);
  }
}
