import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ArticleDto,
  ArticleLocalizedDto,
  EditArticleDto,
  LanguageCode,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(payload: EditArticleDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(`/articles/admin`, payload);
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/articles/admin/${code}`);
  }

  getAll(): Observable<ArticleDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ArticleDto[]>(`/articles/admin`);
  }

  getAllLocalized(
    languageCode: LanguageCode,
  ): Observable<ArticleLocalizedDto[]> {
    return this.httpClient.get<ArticleLocalizedDto[]>(
      `/articles/${languageCode}`,
    );
  }

  getOne(code: string): Observable<ArticleDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ArticleDto>(`/articles/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<ArticleLocalizedDto> {
    return this.httpClient.get<ArticleLocalizedDto>(
      `/articles/${languageCode}/${code}`,
    );
  }

  update(code: string, payload: EditArticleDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/articles/admin/${code}`, payload);
  }
}
