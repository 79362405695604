import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'be-green--admin--menu',
  template: `
    <div class="layout-menu-container">
      <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
        <li
          class="layout-menuitem-category"
          role="none"
          *ngFor="let item of menuConfig; let i = index"
          [item]="item"
          [index]="i"
          [root]="true"
          be-green-menu
        >
          <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">
            {{ item.label }}
          </div>

          <ul role="menu">
            <li
              role="none"
              *ngFor="let child of item.items"
              [item]="child"
              [index]="i"
              [isDesktop]="isDesktop"
              [isOverlay]="isOverlay"
              be-green--admin--menu-item
            ></li>
          </ul>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {
  @Input() menuConfig!: MenuItem[];
  @Input() isDesktop!: boolean;
  @Input() isOverlay!: boolean;

  onKeydown(event: KeyboardEvent) {
    const nodeElement = <HTMLDivElement>event.target;
    if (event.code === 'Enter' || event.code === 'Space') {
      nodeElement.click();
      event.preventDefault();
    }
  }
}
