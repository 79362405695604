import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  base64ToFile,
} from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ProgramsEditService } from '../../programs-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--programs-edit-steps-illustration',
  templateUrl: './programs-edit-steps-illustration.component.html',
  styleUrls: ['./programs-edit-steps-illustration.component.scss'],
})
export class ProgramsEditStepsIllustrationComponent implements OnInit {
  @ViewChild('fileUploader') fileUploader?: FileUpload;
  @ViewChild('imageCropper') imageCropper?: ImageCropperComponent;

  croppedFiles?: File[];
  croppedImageSrc = '';
  currentStepForm!: FormGroup;
  illustrationFileUploadUrl = `/programs/upload/illustration`;
  imageFilename?: string;
  imageRotation = 0;
  imageScale = 1;
  imageScaleMax = 2;
  imageScaleMin = 0.5;
  imageTransforms: ImageTransform = {};
  isCropperDialogVisible = false;
  isCropperReady = false;
  isLoading = false;
  selectedImageData = '';

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    readonly programsEditService: ProgramsEditService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.programsEditService.dirtyProgram) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.programsEditService.currentStep === 2) {
      this.programsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      imageUrl: AbstractControl;
    };
  }

  private initForm() {
    this.croppedFiles = undefined;
    this.croppedImageSrc = '';
    this.imageFilename = undefined;
    this.isCropperReady = false;
    this.resetImageCropper();

    this.currentStepForm = this.formBuilder.group({
      imageUrl: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
    });

    if (this.programsEditService.dirtyProgram) {
      this.currentStepForm.patchValue(this.programsEditService.dirtyProgram);

      if (this.programsEditService.dirtyProgram.imageUrl) {
        if (
          this.programsEditService.dirtyProgram.imageUrl.startsWith(
            'shared/img/programs/tmp',
          )
        ) {
          this.croppedImageSrc =
            this.imageBaseUrl +
            this.programsEditService.dirtyProgram.imageUrl.replace(
              'shared/',
              '',
            );
        } else {
          this.croppedImageSrc =
            this.imageBaseUrl + this.programsEditService.dirtyProgram.imageUrl;
        }
      }
    }
  }

  cancelImageCropModal() {
    this.croppedFiles = undefined;
    this.isCropperDialogVisible = false;
    this.resetImageCropper();
  }

  changeImageScale(): void {
    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  cropAndUploadImage() {
    this.imageCropper?.crop();
  }

  private flipAfterRotate() {
    const flippedH = this.imageTransforms.flipH;
    const flippedV = this.imageTransforms.flipV;

    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: !this.imageTransforms.flipH,
    };
  }

  flipVertical() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipV: !this.imageTransforms.flipV,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCropperReady(event: Dimensions) {
    this.isCropperReady = true;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.isCropperDialogVisible = false;

    if (this.imageFilename) {
      this.croppedImageSrc = event.base64 as string;

      const imageFilenameWithoutExtension =
        UtilsService.getFilenameWithoutExtension(this.imageFilename);

      this.croppedFiles = [
        new File(
          [base64ToFile(this.croppedImageSrc)],
          `${imageFilenameWithoutExtension}.png`,
          { type: 'image/png' },
        ),
      ];

      this.changeDetectorRef.detectChanges();

      this.fileUploader?.upload();
    }
  }

  onLoadImageFailed() {
    this.messageService.add({
      severity: 'error',
      summary: 'Erreur inattendue',
      detail: 'Impossible de charger l’image sélectionnée',
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(event: { files: File[]; error: any; originalEvent: Event }) {
    if (event.files && event.files.length) {
      const file = event.files[0];
      this.imageFilename = file.name;

      // Create a new FileReader object
      const reader = new FileReader();

      // Set the onload event handler of the FileReader object
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Get the base64 data of the selected image file
        const base64data = (e.target as FileReader).result;

        // Do something with the base64 data, such as display it in an image element
        this.selectedImageData = base64data as string;
        this.isCropperDialogVisible = true;
        this.fileUploader?.clear();
      };

      // Read the selected image file as a data URL
      reader.readAsDataURL(file);
    }
  }

  onUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      severity: 'error',
      summary: event.error.error?.reason,
      detail: event.error.error?.message,
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  onUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ url: string }>;
  }) {
    this.messageService.add({
      severity: 'success',
      summary: 'Téléversement réussi',
      detail: `L’image « ${this.imageFilename} » recadrée est enregistrée sur le serveur.`,
    });

    this.currentStepFormControls.imageUrl.setValue(
      event.originalEvent.body?.url,
    );

    this.currentStepForm.markAllAsTouched();

    this.isLoading = false;
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.programsEditService.setDirtyProgram(this.currentStepForm.value);

    this.programsEditService.nextStep();

    this.router.navigate(['description'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  previousStep() {
    this.programsEditService.previousStep();

    this.router.navigate(['general'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  resetImageCropper() {
    this.imageRotation = 0;
    this.imageTransforms = {};
    this.imageScale = 1;
  }

  rotateLeft() {
    this.imageRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.imageRotation++;
    this.flipAfterRotate();
  }

  zoomIn() {
    if (this.imageScale >= this.imageScaleMax) {
      return;
    }

    this.imageScale += 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  zoomOut() {
    if (this.imageScale <= this.imageScaleMin) {
      return;
    }

    this.imageScale -= 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }
}
