<div class="card">
  <p-table
    #materialIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de {totalRecords}'
    "
    [dataKey]="'code'"
    [globalFilterFields]="[
      'descriptionAr',
      'descriptionFr',
      'nameAr',
      'nameFr'
    ]"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [value]="materials"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex justify-content-between align-items-start flex-column sm:flex-row"
      >
        <div class="flex-column sm:flex-row mb-2">
          <button
            class="p-button-outlined sm:mb-2 mr-2"
            (click)="clearMaterialsTable()"
            pButton
            label="Effacer tous les filtres"
            icon="pi pi-filter-slash"
          ></button>

          <button
            class="mb-2 p-button-success"
            routerLink="/materials/create"
            pButton
            label="Créer un matériau"
            icon="pi pi-plus"
          ></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pFrozenColumn></th>

        <th style="min-width: 20rem" pSortableColumn="nameFr" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameAr"></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="kiloJoulesSavedWhenRecyclingOneGram"
        >
          <div class="flex justify-content-between align-items-center">
            KJ économisés par G recyclé

            <p-sortIcon
              field="kiloJoulesSavedWhenRecyclingOneGram"
            ></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="hoursOfLightingAllowedByOneKiloJoule"
        >
          <div class="flex justify-content-between align-items-center">
            H d'éclairage par KJ

            <p-sortIcon
              field="hoursOfLightingAllowedByOneKiloJoule"
            ></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="gramsOfCo2SavedWhenRecyclingOneGram"
        >
          <div class="flex justify-content-between align-items-center">
            <span>G de CO<sub>2</sub> économisés par G recyclé</span>

            <p-sortIcon
              field="gramsOfCo2SavedWhenRecyclingOneGram"
            ></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="kilometersAllowedByOneKiloOfCo2"
        >
          <div class="flex justify-content-between align-items-center">
            <span>Km parcourus par Kg de CO<sub>2</sub></span>

            <p-sortIcon field="kilometersAllowedByOneKiloOfCo2"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="kiloJoulesSavedWhenRecyclingOneGram"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="hoursOfLightingAllowedByOneKiloJoule"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="gramsOfCo2SavedWhenRecyclingOneGram"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="kilometersAllowedByOneKiloOfCo2"
          ></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-material>
      <ng-container *ngLet="asMaterialDto(material) as material">
        <tr *ngIf="material">
          <td style="text-align: center" pFrozenColumn>
            <div
              class="w-6rem h-6rem flex align-items-center justify-content-center"
              [style.background-color]="'#' + material.color"
              *ngIf="material"
            >
              <p-avatar
                size="xlarge"
                [image]="imageBaseUrl + material.imageUrl"
                *ngIf="material.imageUrl"
              ></p-avatar>
            </div>
          </td>

          <td pFrozenColumn>
            {{ material.nameFr }}
          </td>

          <td class="text-right" dir="rtl">
            {{ material.nameAr }}
          </td>

          <td class="text-right">
            {{
              material.kiloJoulesSavedWhenRecyclingOneGram
                | number : '1.2-2' : 'fr'
            }}
          </td>

          <td class="text-right">
            {{
              material.hoursOfLightingAllowedByOneKiloJoule
                | number : '1.2-2' : 'fr'
            }}
          </td>

          <td class="text-right">
            {{
              material.gramsOfCo2SavedWhenRecyclingOneGram
                | number : '1.2-2' : 'fr'
            }}
          </td>

          <td class="text-right">
            {{
              material.kilometersAllowedByOneKiloOfCo2 | number : '1.2-2' : 'fr'
            }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/materials/' + material.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">Aucun matériau trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="8">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
