import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditProgramDto, ProgramType } from '@be-green/dto';
import { ErrorService, InputValidationService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { format, isDate } from 'date-fns';
import { ProgramsEditService } from '../../programs-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--programs-edit-steps-settings',
  templateUrl: './programs-edit-steps-settings.component.html',
  styleUrls: ['./programs-edit-steps-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramsEditStepsSettingsComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly programsEditService: ProgramsEditService,
  ) {}

  ngOnInit(): void {
    if (!this.programsEditService.dirtyProgram) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.programsEditService.currentStep === 4) {
      this.programsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      endDate: AbstractControl;
      handlerEndDate: AbstractControl;
      handlerVideoAr: AbstractControl;
      handlerVideoFr: AbstractControl;
      maximumProductQuantity: AbstractControl;
      maximumUserBasketsPerDay: AbstractControl;
      minimumProductQuantity: AbstractControl;
      mobileVideoAr: AbstractControl;
      mobileVideoFr: AbstractControl;
      referredPoints: AbstractControl;
      referrerPoints: AbstractControl;
      startDate: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      endDate: [null, Validators.compose([Validators.required])],
      handlerEndDate: [null],
      handlerVideoAr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      handlerVideoFr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      maximumProductQuantity: [null],
      maximumUserBasketsPerDay: [null],
      minimumProductQuantity: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      mobileVideoAr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      mobileVideoFr: [
        null,
        Validators.compose([InputValidationService.videoUrlEmbed]),
      ],
      referredPoints: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      referrerPoints: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      startDate: [null, Validators.compose([Validators.required])],
    });

    if (this.programsEditService.dirtyProgram) {
      this.currentStepForm.patchValue(this.programsEditService.dirtyProgram);

      if (
        this.programsEditService.dirtyProgram.handlerEndDate &&
        this.programsEditService.dirtyProgram.type !== ProgramType.GoodDeal
      ) {
        this.currentStepFormControls.handlerEndDate.patchValue(
          new Date(this.programsEditService.dirtyProgram.handlerEndDate),
        );
      } else {
        this.currentStepFormControls.handlerEndDate.patchValue(null);
      }

      if (this.programsEditService.dirtyProgram.endDate) {
        this.currentStepFormControls.endDate.patchValue(
          new Date(this.programsEditService.dirtyProgram.endDate),
        );
      }

      if (this.programsEditService.dirtyProgram.startDate) {
        this.currentStepFormControls.startDate.patchValue(
          new Date(this.programsEditService.dirtyProgram.startDate),
        );
      }

      if (
        this.programsEditService.dirtyProgram.type !== ProgramType.Recycling
      ) {
        this.currentStepFormControls.referredPoints.patchValue(1);
        this.currentStepFormControls.referrerPoints.patchValue(1);
      }
    }

    const positiveIntegerValidator = Validators.compose([
      InputValidationService.integer,
      Validators.min(1),
    ]) as ValidatorFn;

    InputValidationService.setOptionalValidator(
      this.currentStepFormControls.maximumProductQuantity,
      positiveIntegerValidator,
    );

    InputValidationService.setOptionalValidator(
      this.currentStepFormControls.maximumUserBasketsPerDay,
      positiveIntegerValidator,
    );
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.programsEditService.setDirtyProgram(<EditProgramDto>{
      handlerEndDate:
        (this.programsEditService.dirtyProgram as EditProgramDto).type !==
          ProgramType.GoodDeal &&
        isDate(this.currentStepFormControls.handlerEndDate.value)
          ? format(
              this.currentStepFormControls.handlerEndDate.value,
              'yyyy-MM-dd',
            )
          : null,
      endDate: format(this.currentStepFormControls.endDate.value, 'yyyy-MM-dd'),
      handlerVideoAr: this.currentStepFormControls.handlerVideoAr.value,
      handlerVideoFr: this.currentStepFormControls.handlerVideoFr.value,
      maximumProductQuantity:
        this.currentStepFormControls.maximumProductQuantity.value || null,
      maximumUserBasketsPerDay:
        this.currentStepFormControls.maximumUserBasketsPerDay.value || null,
      minimumProductQuantity:
        this.currentStepFormControls.minimumProductQuantity.value,
      mobileVideoAr: this.currentStepFormControls.mobileVideoAr.value,
      mobileVideoFr: this.currentStepFormControls.mobileVideoFr.value,
      referredPoints: this.currentStepFormControls.referredPoints.value,
      referrerPoints: this.currentStepFormControls.referrerPoints.value,
      startDate: format(
        this.currentStepFormControls.startDate.value,
        'yyyy-MM-dd',
      ),
    });

    this.programsEditService.saveDirtyProgram().subscribe({
      next: (value: { code: string } | void) => {
        if (value) {
          this.router.navigate(['/programs', value.code]);
        } else if (
          this.programsEditService.program &&
          this.programsEditService.editAction === 'update'
        ) {
          this.router.navigate([
            '/programs',
            this.programsEditService.program.code,
          ]);
        } else {
          this.router.navigate(['/programs']);
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.isLoading = false;
      },
    });
  }

  previousStep() {
    this.programsEditService.previousStep();

    this.router.navigate(['description'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
