import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditGiftDto,
  GiftDto,
  GiftFormField,
  GiftLocalizedDto,
  LanguageCode,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GiftApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(code: string, payload: EditGiftDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/programs/${code}/gift`,
      payload,
    );
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/gifts/admin/${code}`);
  }

  getAll(): Observable<GiftDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<GiftDto[]>(`/gifts/admin`);
  }

  getAllLocalized(languageCode: LanguageCode): Observable<GiftLocalizedDto[]> {
    return this.httpClient.get<GiftLocalizedDto[]>(`/gifts/${languageCode}`);
  }

  getOne(code: string): Observable<GiftDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<GiftDto>(`/gifts/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<GiftLocalizedDto> {
    return this.httpClient.get<GiftLocalizedDto>(
      `/gifts/${languageCode}/${code}`,
    );
  }

  reorder(code: string, codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/${code}/reorder/gifts`, codes);
  }

  update(code: string, payload: EditGiftDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/${code}/gift`, payload);
  }

  updateFormDefinition(
    code: string,
    giftCode: string,
    payload: GiftFormField[],
  ): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(
      `/programs/${code}/gift/${giftCode}/form`,
      payload,
    );
  }
}
