<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4 class="font-bold mb-6">
        <ng-container
          *ngIf="articlesEditService.editAction === 'create'; else update"
          >Création article</ng-container
        >
        <ng-template #update>Mise à jour article</ng-template>

        <ng-container *ngIf="articlesEditService.article">
          <div
            class="inline-block mx-2 text-blue-600"
            [ngClass]="{
              'line-through':
                articlesEditService.article &&
                articlesEditService.dirtyArticle &&
                articlesEditService.dirtyArticle.titleFr !==
                  articlesEditService.article.titleFr
            }"
          >
            {{ articlesEditService.article.titleFr }}
          </div>
        </ng-container>

        <div
          class="inline-block mx-2 text-green-600"
          [ngClass]="{
            'font-bold':
              articlesEditService.article &&
              articlesEditService.dirtyArticle &&
              articlesEditService.dirtyArticle.titleFr !==
                articlesEditService.article.titleFr
          }"
          *ngIf="
            articlesEditService.dirtyArticle &&
            (!articlesEditService.article ||
              articlesEditService.dirtyArticle.titleFr !==
                articlesEditService.article.titleFr)
          "
        >
          {{ articlesEditService.dirtyArticle.titleFr }}
        </div>
      </h4>

      <p-toast></p-toast>

      <p-steps [model]="steps" [readonly]="true"></p-steps>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
