import { Injectable } from '@angular/core';
import { ProgramApi } from '@be-green/api-services';
import { EditProgramDto, ProgramDto } from '@be-green/dto';
import { format } from 'date-fns';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class ProgramsEditService {
  private _currentStep = 1;
  private _editAction?: 'create' | 'update';
  private _isDirty = false;
  private _program?: ProgramDto;
  private _dirtyProgram?: EditProgramDto;
  private uiReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly programApi: ProgramApi) {}

  get currentStep() {
    return this._currentStep;
  }

  get dirtyProgram() {
    return this._dirtyProgram;
  }

  get editAction() {
    return this._editAction;
  }

  get isDirty() {
    return this._isDirty;
  }

  get program() {
    return this._program;
  }

  get uiReady$() {
    return this.uiReadySubject.asObservable();
  }

  init() {
    this._isDirty = false;
    this.uiReadySubject.next(false);
  }

  nextStep() {
    if (this._currentStep < 4) {
      this._currentStep++;
    }
  }

  previousStep() {
    if (this._currentStep > 1) {
      this._currentStep--;
    }
  }

  saveDirtyProgram(): Observable<{ code: string } | void> {
    if (!this._dirtyProgram || !this.editAction) {
      return throwError(() => new Error(''));
    }

    const programCodeToUpdate = this._program ? this._program.code : undefined;

    switch (this._editAction) {
      case 'create':
        return this.programApi.create(this._dirtyProgram).pipe(
          tap(() => {
            this._dirtyProgram = undefined;
            this._isDirty = false;
            this._currentStep = 1;
            this.uiReadySubject.next(false);
          }),
        );

      case 'update':
        return this.programApi
          .update(programCodeToUpdate as string, this._dirtyProgram)
          .pipe(
            tap(() => {
              this._dirtyProgram = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      default:
        return throwError(() => new Error(''));
    }
  }

  setDirty(isDirty = true) {
    this._isDirty = isDirty;
  }

  setDirtyProgram(program: EditProgramDto) {
    this._dirtyProgram = { ...this._dirtyProgram, ...program };
  }

  setProgram(editAction: 'create' | 'update', program?: ProgramDto) {
    this._editAction = editAction;

    if (editAction === 'create' || !program) {
      this._dirtyProgram = undefined;
      this._program = undefined;
    } else {
      this._dirtyProgram = {
        ...program,

        handlerEndDate: program.handlerEndDate
          ? format(new Date(program.handlerEndDate), 'yyyy-MM-dd')
          : undefined,

        endDate: format(new Date(program.endDate), 'yyyy-MM-dd'),
        startDate: format(new Date(program.startDate), 'yyyy-MM-dd'),
      };
      this._program = program;
    }

    this.uiReadySubject.next(true);
  }
}
