import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription, filter } from 'rxjs';

import { LayoutService } from '../layout/layout.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[be-green--admin--menu-item]',
  template: `
    <ng-container>
      <a
        role="menuitem"
        *ngIf="(!item.routerLink || item.items) && item.visible !== false"
        [attr.href]="item.url"
        [ngClass]="item.styleClass"
        [attr.target]="item.target"
        [attr.tabindex]="0"
        [attr.aria-label]="item.label"
        (click)="itemClick($event)"
        pRipple
      >
        <i class="layout-menuitem-icon" [ngClass]="item.icon"></i>
        <span>{{ item.label }}</span>
        <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
        <i
          class="pi pi-fw {{
            active ? 'pi-angle-up' : 'pi-angle-down'
          }} ml-auto"
          *ngIf="item.items"
        ></i>
      </a>

      <a
        role="menuitem"
        *ngIf="item.routerLink && !item.items && item.visible !== false"
        [ngClass]="item.styleClass"
        [routerLink]="item.routerLink"
        [routerLinkActiveOptions]="{
          exact: item.routerLinkActiveOptions
            ? item.routerLinkActiveOptions.exact
            : false
        }"
        [attr.target]="item.target"
        [attr.tabindex]="0"
        [attr.aria-label]="item.label"
        (click)="itemClick($event)"
        routerLinkActive="active-menuitem-routerlink router-link-exact-active"
        pRipple
      >
        <i class="layout-menuitem-icon" [ngClass]="item.icon"></i>
        <span>{{ item.label }}</span>
        <span class="p-tag p-badge ml-auto" *ngIf="item.badge">{{
          item.badge
        }}</span>
        <i
          class="pi pi-fw {{
            active ? 'pi-angle-up' : 'pi-angle-down'
          }} ml-auto"
          *ngIf="item.items"
        ></i>
      </a>

      <ul
        role="menu"
        *ngIf="item.items && active && item.visible !== false"
        [@children]="active ? 'visibleAnimated' : 'hiddenAnimated'"
      >
        <ng-template [ngForOf]="item.items" ngFor let-child let-i="index">
          <li
            role="none"
            [item]="child"
            [index]="i"
            [parentKey]="key"
            [class]="child.badgeStyleClass"
            [isDesktop]="isDesktop"
            [isOverlay]="isOverlay"
            be-green--admin--menu-item
          ></li>
        </ng-template>
      </ul>
    </ng-container>
  `,
  styleUrls: ['./menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,

  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.active-menuitem]': 'active',
  },

  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        }),
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        }),
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        }),
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
      transition(
        'void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input() item!: MenuItem;
  @Input() index!: number;
  @Input() root!: boolean;
  @Input() parentKey!: string;
  @Input() isDesktop!: boolean;
  @Input() isOverlay!: boolean;

  @Output() notifyToggleMenuActiveMobile = new EventEmitter<boolean>();
  @Output() notifyToggleMenuInactiveDesktop = new EventEmitter<boolean>();

  active = false;
  menuSourceSubscription: Subscription;
  menuResetSubscription: Subscription;
  key!: string;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly router: Router,
  ) {
    this.menuSourceSubscription = this.layoutService.menuSource$.subscribe(
      (key) => {
        // deactivate current active menu
        if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
          this.active = false;
        }
      },
    );

    this.menuResetSubscription = this.layoutService.resetSource$.subscribe(
      () => {
        this.active = false;
      },
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.item.routerLink) {
          this.updateActiveStateFromRoute();
        } else {
          this.active = false;
        }
      });
  }

  ngOnInit() {
    if (this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);
  }

  updateActiveStateFromRoute() {
    this.active = this.router.isActive(
      this.item.routerLink[0],
      this.item.items ? false : true,
    );
  }

  itemClick(event: Event) {
    event.stopPropagation();
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // notify other items
    this.layoutService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      // hide overlay menus
      this.notifyToggleMenuActiveMobile.emit(false);

      if (this.isDesktop && this.isOverlay) {
        this.notifyToggleMenuInactiveDesktop.emit(true);
      }
    }
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
