import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  AccessTokenDto,
  ActivateUserDto,
  AppleIdentityDto,
  DeviceApp,
  DeviceDto,
  DeviceOperatingSystem,
  DevicePlatform,
  EditProfileDto,
  LanguageCode,
  NotificationPermissionDto,
  RegisterDto,
  ResetAccessDto,
  Scope,
  UserRole,
} from '@be-green/dto';
import { DeviceId, DeviceInfo } from '@capacitor/device';
import { BehaviorSubject, Observable, first, map, of, share, tap } from 'rxjs';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { StorageService } from '../storage/storage.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  accessToken$: Observable<string | undefined>;
  private accessTokenSubject: BehaviorSubject<string | undefined>;
  decodedToken$: Observable<AccessTokenDto | undefined>;
  private decodedTokenSubject: BehaviorSubject<AccessTokenDto | undefined>;
  deviceDto?: DeviceDto;
  isAuthenticated$: Observable<boolean | undefined>;
  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  private isAdminSubject: BehaviorSubject<boolean | undefined>;
  private isHandlerSubject: BehaviorSubject<boolean | undefined>;
  private isSupervisorSubject: BehaviorSubject<boolean | undefined>;
  private isUserSubject: BehaviorSubject<boolean | undefined>;

  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    @Inject('JWT_REFRESH_TOKEN_NONCE_NAME')
    private jwtRefreshTokenNonceName: string,
    private readonly httpClient: HttpClient,
    private readonly localNotificationsService: LocalNotificationsService,
    private readonly storageService: StorageService,
    private readonly usersService: UsersService,
  ) {
    const accessToken = this.storageService.get('AccessToken');

    this.accessTokenSubject = new BehaviorSubject(
      <string | undefined>accessToken,
    );
    this.accessToken$ = this.accessTokenSubject.asObservable();

    if (accessToken) {
      this.decodedTokenSubject = new BehaviorSubject(
        this.decodeToken(accessToken),
      );
    } else {
      this.decodedTokenSubject = new BehaviorSubject(
        <AccessTokenDto | undefined>undefined,
      );
    }
    this.decodedToken$ = this.decodedTokenSubject.asObservable();

    this.isAuthenticatedSubject = new BehaviorSubject(this.isAuthenticated);
    this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

    this.isAdminSubject = new BehaviorSubject(this.hasRole(UserRole.Admin));
    this.isHandlerSubject = new BehaviorSubject(this.hasRole(UserRole.Handler));
    this.isSupervisorSubject = new BehaviorSubject(
      this.hasRole(UserRole.Supervisor),
    );
    this.isUserSubject = new BehaviorSubject(this.hasRole(UserRole.User));
  }

  get accessToken(): string | undefined {
    return this.accessTokenSubject.value;
  }

  get decodedToken(): AccessTokenDto | undefined {
    return this.decodedTokenSubject.value;
  }

  get hasRefreshTokenNonce(): boolean {
    return this.checkRefreshTokenNonce();
  }

  get isAdmin(): boolean | undefined {
    return this.isAdminSubject.value;
  }

  get isAuthenticated(): boolean {
    if (this.isAuthenticatedSubject) {
      return this.isAuthenticatedSubject.value;
    } else {
      // Lightest flow:
      // - just check the cookie that contains the relevant token
      // - next API call will check token expiry server side
      const accessToken = this.storageService.get('AccessToken');
      const refreshToken = this.storageService.get('RefreshToken');

      return (
        accessToken !== undefined &&
        accessToken !== null &&
        accessToken.trim() !== '' &&
        refreshToken !== undefined &&
        refreshToken !== null &&
        refreshToken.trim() !== '' &&
        (this.decodeToken(refreshToken) as AccessTokenDto).sub !== undefined
      );
    }
  }

  get isHandler(): boolean | undefined {
    return this.isHandlerSubject.value;
  }

  get isSupervisor(): boolean | undefined {
    return this.isSupervisorSubject.value;
  }

  get isUser(): boolean | undefined {
    return this.isUserSubject.value;
  }

  activateAccess(payload: ActivateUserDto) {
    return this.httpClient
      .post(
        `/auth/${this.appScope === Scope.Mobile ? '' : 'bo/'}activate-access/${
          payload.token
        }`,
        { password: payload.password },
        {
          observe: 'response',
        },
      )
      .pipe(tap((response) => this.setTokens(response)));
  }

  changePassword(
    currentPassword: string,
    password: string,
    role: UserRole,
    iss?: DeviceApp,
  ) {
    return this.httpClient.post(`/auth/change-password`, {
      currentPassword,
      password,
      iss,
      role,
    });
  }

  private checkRefreshTokenNonce(): boolean {
    // Lightest flow:
    // - just check the key or cookie that contains the relevant token
    // - next API call will check token expiry server side
    const refreshToken = this.storageService.get(this.jwtRefreshTokenNonceName);

    return (
      refreshToken !== undefined &&
      refreshToken !== null &&
      refreshToken.trim() !== ''
    );
  }

  clearStorage(): void {
    // Double-check removing (non Http-only) cookies and / or localStorage keys
    this.storageService.remove('AccessToken');
    this.storageService.remove('RefreshToken'); // not stored in cookies
    this.storageService.remove(this.jwtRefreshTokenNonceName);

    // Reset subject behaviors
    this.accessTokenSubject.next(undefined);
    this.decodedTokenSubject.next(undefined);
    this.isAuthenticatedSubject.next(false);

    this.isAdminSubject.next(undefined);
    this.isHandlerSubject.next(undefined);
    this.isSupervisorSubject.next(undefined);
    this.isUserSubject.next(undefined);

    // Other services
    this.usersService.applyLogout();
  }

  private decodeToken(token: string): AccessTokenDto | undefined {
    const jwtHelperService = new JwtHelperService();

    return <AccessTokenDto>jwtHelperService.decodeToken(token);
  }

  deleteAccount(
    password: string,
    auth?: {
      appleUserId?: string;
      facebookUserId?: string;
      googleUserId?: string;
    },
  ) {
    if (!this.isUser || this.appScope !== Scope.Mobile) {
      return of();
    }

    return this.httpClient
      .post(
        '/auth/delete-account',
        { password, auth },
        {
          observe: 'response',
        },
      )
      .pipe(
        tap((response) => {
          this.clearStorage();
          this.setTokens(response);

          this.localNotificationsService.removeAll();
        }),
      );
  }

  forgotPassword(email: string, role: UserRole) {
    return this.httpClient.post(
      `/auth/${this.appScope === Scope.Mobile ? '' : 'bo/'}forgot-password`,
      { email, role },
    );
  }

  private hasRole(roleToCheck: UserRole): boolean | undefined {
    return (
      this.decodedTokenSubject.value &&
      Object.prototype.hasOwnProperty.call(
        this.decodedTokenSubject.value,
        'roles',
      ) &&
      this.decodedTokenSubject.value.roles &&
      this.decodedTokenSubject.value.roles.includes(roleToCheck)
    );
  }

  isTokenExpired(token: string): boolean {
    const jwtHelperService = new JwtHelperService();

    return jwtHelperService.isTokenExpired(token);
  }

  login(
    role: UserRole,
    username: string,
    password: string,
    iss?: DeviceApp,
    authProviders?: {
      apple?: { userId: string };
      facebook?: { userId: string };
      google?: { userId: string };
    },
  ) {
    return this.httpClient
      .post(
        `/auth/login`,
        {
          username,
          password,
          aud: this.deviceDto?.uuid,
          iss,
          role,
          authProviders,
        },
        { observe: 'response' },
      )
      .pipe(tap((response) => this.setTokens(response)));
  }

  logout() {
    const nonce = this.storageService.get(this.jwtRefreshTokenNonceName);

    if (!nonce) {
      this.clearStorage();
      location.reload();

      return of();
    }

    // call API route in order to clear storage
    return this.httpClient
      .post(`/auth/logout`, null, {
        headers: { [this.jwtRefreshTokenNonceName]: nonce },
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          this.clearStorage();
          this.setTokens(response);

          if (this.appScope === Scope.Mobile) {
            this.localNotificationsService.removeAll();
          }
        }),
      );
  }

  persistAppleIdentity(payload: AppleIdentityDto): Observable<void> {
    return this.httpClient.put<void>(`/auth/apple/identity`, payload);
  }

  requestNewActivationCode() {
    return this.httpClient.post('/auth/request-new-activation-code', null);
  }

  renewToken(): Observable<string> {
    const refreshToken = this.storageService.get('RefreshToken');

    if (!refreshToken || this.isTokenExpired(refreshToken)) {
      this.clearStorage();
      location.reload();

      return of();
    } else {
      const nonce = this.storageService.get(this.jwtRefreshTokenNonceName);

      if (!nonce) {
        this.clearStorage();
        location.reload();

        return of();
      }

      return this.httpClient
        .post(`/auth/token`, null, {
          observe: 'response',
          headers: {
            [this.jwtRefreshTokenNonceName]: nonce,
            'X-Refresh-Token': refreshToken,
          },
        })
        .pipe(
          first(),
          share(),
          map((response) => {
            const accessToken = response.headers.get(
              'X-Access-Token',
            ) as string;

            if (accessToken) {
              this.setUser({ accessToken, nonce, refreshToken });
            } else {
              this.setUser();
            }

            return accessToken;
          }),
        );
    }
  }

  resetAccess(payload: ResetAccessDto) {
    return this.httpClient.post(
      `/auth/${this.appScope === Scope.Mobile ? '' : 'bo/'}reset-access`,
      payload,
    );
  }

  retrieveAppleIdentity(userId: string): Observable<AppleIdentityDto> {
    return this.httpClient.get<AppleIdentityDto>(
      `/auth/apple/identity/${userId}`,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setTokens(response: HttpResponse<any>) {
    const accessToken = response.headers.get('X-Access-Token');
    const nonce = response.headers.get(this.jwtRefreshTokenNonceName);
    const refreshToken = response.headers.get('X-Refresh-Token');

    if (accessToken !== null) {
      this.setUser({ accessToken, nonce, refreshToken });
    } else {
      this.setUser();
    }
  }

  private setUser(tokens?: {
    accessToken: string;
    nonce?: string | null;
    refreshToken?: string | null;
  }) {
    let accessTokenFinal: string;
    let refreshTokenFinal: string | undefined;

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if (tokens) {
      const { accessToken, nonce, refreshToken } = tokens;

      this.storageService.set('AccessToken', accessToken);

      if (refreshToken) {
        this.storageService.set('RefreshToken', refreshToken);
        this.storageService.set(this.jwtRefreshTokenNonceName, nonce);
        refreshTokenFinal = refreshToken;
      }

      accessTokenFinal = accessToken;
    } else {
      accessTokenFinal = <string>this.storageService.get('AccessToken');
      refreshTokenFinal = <string>this.storageService.get('RefreshToken');
    }

    this.accessTokenSubject.next(accessTokenFinal);
    this.decodedTokenSubject.next(this.decodeToken(accessTokenFinal));

    this.isAuthenticatedSubject.next(
      refreshTokenFinal !== undefined &&
        refreshTokenFinal !== null &&
        refreshTokenFinal.trim() !== '' &&
        (this.decodeToken(refreshTokenFinal) as AccessTokenDto).sub !==
          undefined,
    );

    this.isAdminSubject.next(this.hasRole(UserRole.Admin));
    this.isHandlerSubject.next(this.hasRole(UserRole.Handler));
    this.isSupervisorSubject.next(this.hasRole(UserRole.Supervisor));
    this.isUserSubject.next(this.hasRole(UserRole.User));
  }

  signUp(payload: RegisterDto) {
    return this.httpClient
      .post('/auth/register', payload, {
        observe: 'response',
      })
      .pipe(tap((response) => this.setTokens(response)));
  }

  updateProfile(payload: EditProfileDto) {
    return this.httpClient
      .put('/auth/profile', payload, {
        observe: 'response',
      })
      .pipe(tap((response) => this.setTokens(response)));
  }

  upsertDevice(
    id: DeviceId,
    info: DeviceInfo,
    app: DeviceApp,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (!this.deviceDto) {
      this.deviceDto = {
        uuid: id.identifier,
        app,
        model: info.model,
        platform: (info.platform === 'web'
          ? info.operatingSystem
          : info.platform) as DevicePlatform,
        operatingSystem: info.operatingSystem as DeviceOperatingSystem,
        osVersion: info.osVersion,
        manufacturer: info.manufacturer,
        memoryUsedInMb: info.memUsed,
        realDiskFreeInMb: info.realDiskFree,
        realDiskTotalInMb: info.realDiskTotal,
        webViewVersion: info.webViewVersion,
      };
    }

    if (this.isAuthenticated) {
      return of(true);
    } else {
      return this.httpClient
        .put(`/auth/device`, this.deviceDto, { observe: 'response' })
        .pipe(tap((response) => this.setTokens(response)));
    }
  }

  upsertLanguageCode(languageCode: LanguageCode) {
    return this.httpClient.put(`/auth/device/${languageCode}`, null);
  }

  upsertNotificationPermission(
    notificationPermissionDto: NotificationPermissionDto,
  ) {
    return this.httpClient.put(`/auth/permissions`, notificationPermissionDto);
  }
}
