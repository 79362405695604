<div class="card">
  <p-toolbar styleClass="mb-4 bg-white">
    <div class="p-toolbar-group-start">
      <p-selectButton
        [options]="stateOptions"
        [(ngModel)]="period"
        optionLabel="label"
        optionValue="value"
        (onChange)="loadDashboard()"
      ></p-selectButton>
    </div>

    <div class="p-toolbar-group-end">
      <div>
        <div class="text-lg">
          Du
          {{
            dashboard?.period?.startOfThisPeriod
              | date : 'mediumDate' : '' : 'fr'
          }}
          au
          {{
            dashboard?.period?.endOfThisPeriod | date : 'mediumDate' : '' : 'fr'
          }}
        </div>

        <div class="mt-2">
          <p class="font-bold">Période de comparaison :</p>

          Du
          {{
            dashboard?.period?.startOfLastPeriod
              | date : 'mediumDate' : '' : 'fr'
          }}
          au
          {{
            dashboard?.period?.endOfLastPeriod | date : 'mediumDate' : '' : 'fr'
          }}
        </div>
      </div>
    </div>
  </p-toolbar>

  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <be-green--admin--dashboard-card
        title="QR Codes"
        icon="pi-qrcode"
        iconColor="cyan"
        [lastPeriodLabel]="lastPeriodLabels[period]"
        [thisPeriodLabel]="thisPeriodLabels[period]"
        [progression]="dashboard?.baskets?.progression"
        [total]="dashboard?.baskets?.total"
      ></be-green--admin--dashboard-card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <be-green--admin--dashboard-card
        title="Emballages recyclés"
        icon="pi-inbox"
        iconColor="green"
        [lastPeriodLabel]="lastPeriodLabels[period]"
        [thisPeriodLabel]="thisPeriodLabels[period]"
        [progression]="dashboard?.recycledQuantity?.progression"
        [total]="dashboard?.recycledQuantity?.total"
      ></be-green--admin--dashboard-card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <be-green--admin--dashboard-card
        title="Poids recyclés"
        icon="pi-map-marker"
        iconColor="orange"
        [lastPeriodLabel]="lastPeriodLabels[period]"
        [thisPeriodLabel]="thisPeriodLabels[period]"
        [progression]="dashboard?.recycledWeight?.progression"
        [total]="dashboard?.recycledWeight?.total"
        unit="g"
      ></be-green--admin--dashboard-card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <be-green--admin--dashboard-card
        title="Utilisateurs"
        icon="pi-user"
        iconColor="purple"
        [lastPeriodLabel]="lastPeriodLabels[period]"
        [thisPeriodLabel]="thisPeriodLabels[period]"
        [progression]="dashboard?.users?.progression"
        [total]="dashboard?.users?.total"
      ></be-green--admin--dashboard-card>
    </div>
  </div>
</div>

<div class="grid">
  <div class="col-12 md:col-4">
    <p-card header="Classement users" *ngIf="userRanking">
      <ng-template pTemplate="subtitle">
        Du {{ userRanking.start | date : 'mediumDate' : '' : 'fr' }} au
        {{ userRanking.end | date : 'mediumDate' : '' : 'fr' }}
      </ng-template>

      <ul class="list-none p-0 m-0">
        <li class="mb-4" *ngIf="!userRanking.persons.length">
          <span class="block text-500 font-medium mb-3 text-xl"
            >Aucun classement</span
          >
        </li>

        <li
          class="flex align-items-center justify-content-between mb-4"
          *ngFor="let rankedPerson of userRanking.persons"
        >
          <div class="flex align-items-center">
            <div
              class="text-xl text-900 font-bold mr-2 text-right"
              style="width: 30px"
            >
              {{ rankedPerson.currentRank }}
            </div>

            <p-avatar
              [label]="rankedPerson.initials"
              [style]="{
                'background-color': rankedPerson.nameToHslColors?.background,
                color: rankedPerson.nameToHslColors?.contrast
              }"
              styleClass="mr-2"
              *ngIf="rankedPerson.initials"
            ></p-avatar>

            <div class="text-xl text-900 font-medium">
              {{ rankedPerson.firstName }} {{ rankedPerson.initial }}
            </div>
          </div>

          <div class="flex align-items-center">
            <div class="text-xl text-900 font-medium mr-2">
              {{ rankedPerson.currentPeriodApprovedBasketCount }}
            </div>

            <i class="pi text-xl pi-qrcode"></i>
          </div>
        </li>
      </ul>
    </p-card>
  </div>

  <div class="col-12 md:col-4">
    <p-card header="Classement détaillants" *ngIf="handlerRanking">
      <ng-template pTemplate="subtitle">
        Du {{ handlerRanking.start | date : 'mediumDate' : '' : 'fr' }} au
        {{ handlerRanking.end | date : 'mediumDate' : '' : 'fr' }}
      </ng-template>

      <ul class="list-none p-0 m-0">
        <li class="mb-4" *ngIf="!handlerRanking.handlers.length">
          <span class="block text-500 font-medium mb-3 text-xl"
            >Aucun classement</span
          >
        </li>

        <li
          class="flex align-items-center justify-content-between mb-4"
          *ngFor="let rankedHandler of handlerRanking.handlers"
        >
          <div class="flex align-items-center">
            <div
              class="text-xl text-900 font-bold mr-2 text-right"
              style="width: 30px"
            >
              {{ rankedHandler.currentRank }}
            </div>

            <p-avatar
              [label]="rankedHandler.initials"
              [style]="{
                'background-color': rankedHandler.nameToHslColors?.background,
                color: rankedHandler.nameToHslColors?.contrast
              }"
              styleClass="mr-2"
              *ngIf="rankedHandler.initials"
            ></p-avatar>

            <div class="text-xl text-900 font-medium">
              {{ rankedHandler.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center">
            <div class="text-xl text-900 font-medium mr-2">
              {{ rankedHandler.currentPeriodApprovedBasketCount }}
            </div>

            <i class="pi text-xl pi-qrcode"></i>
          </div>
        </li>
      </ul>
    </p-card>
  </div>

  <div class="col-12 md:col-4">
    <p-card
      header="Classement programmes Pro"
      *ngIf="proProgramRanking && filteredProProgramRanking"
    >
      <ng-template pTemplate="subtitle">
        Du {{ proProgramRanking.start | date : 'mediumDate' : '' : 'fr' }} au
        {{ proProgramRanking.end | date : 'mediumDate' : '' : 'fr' }}
      </ng-template>

      <div class="mb-4">
        <p-dropdown
          optionLabel="nameFr"
          optionValue="code"
          placeholder="Tous types d’organismes"
          [options]="organizationTypes"
          [showClear]="true"
          [styleClass]="'w-full'"
          (onChange)="filterProProgramRankingByOrganizationType($event.value)"
          *ngIf="organizationTypes"
        ></p-dropdown>
      </div>

      <ul class="list-none p-0 m-0">
        <li class="mb-4" *ngIf="!filteredProProgramRanking.length">
          <span class="block text-500 font-medium mb-3 text-xl"
            >Aucun classement</span
          >
        </li>

        <li
          class="flex align-items-center justify-content-between mb-4"
          *ngFor="let rankedProgram of filteredProProgramRanking"
        >
          <div class="flex align-items-center">
            <div
              class="text-xl text-900 font-bold mr-2 text-right"
              style="width: 30px"
            >
              {{ rankedProgram.currentRank }}
            </div>

            <p-image
              class="mr-2"
              width="100"
              [preview]="true"
              [src]="imageBaseUrl + rankedProgram.imageUrl"
              *ngIf="rankedProgram.imageUrl"
            ></p-image>

            <div class="text-xl text-900 font-medium">
              {{ rankedProgram.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center">
            <div class="text-xl text-900 font-medium mr-2">
              {{ rankedProgram.currentPeriodApprovedBasketCount }}
            </div>

            <i class="pi text-xl pi-qrcode"></i>
          </div>
        </li>
      </ul>
    </p-card>
  </div>
</div>
