import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleApi } from '@be-green/api-services';
import { ArticleDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  base64ToFile,
} from 'ngx-image-cropper';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { first } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--articles-view',
  templateUrl: './articles-view.component.html',
  styleUrls: ['./articles-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesViewComponent implements OnInit {
  action?: 'delete';
  actionDefaultStyleClass = 'p-button-warning';
  actionMenuItems: MenuItem[] = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      styleClass: 'p-menuitem-warning',
      command: () => {
        this.edit();
      },
    },

    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      styleClass: 'p-menuitem-danger',
      command: () => {
        this.delete();
      },
    },
  ];
  article?: ArticleDto;
  confirmationDialogMessage = '';
  isConfirmDialogVisible = false;
  isLoading = false;

  /**
   * ImageCropper
   */
  @ViewChild('fileUploader') fileUploader?: FileUpload;
  @ViewChild('imageCropper') imageCropper?: ImageCropperComponent;
  croppedFiles?: File[];
  croppedImageSrc = '';
  imageFilename?: string;
  imageRotation = 0;
  imageScale = 1;
  imageScaleMax = 2;
  imageScaleMin = 0.5;
  imageTransforms: ImageTransform = {};
  isCropperDialogVisible = false;
  isCropperReady = false;
  selectedImageData = '';
  /* end of ImageCropper */

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly domSanitizer: DomSanitizer,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly articleApi: ArticleApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Articles' }]);
    this.seoService.setTitle('Articles - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.articleApi.getOne(code).subscribe({
      next: (article) => {
        this.article = article;

        this.layoutService.registerBreadcrumbs([
          { label: 'Articles', routerLink: '/articles' },
          { label: `${this.article.titleFr}` },
        ]);

        this.seoService.setTitle(
          `${this.article.titleFr} - Articles - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  confirm() {
    switch (this.action) {
      case 'delete':
        this._delete();
    }
  }

  private _delete() {
    if (!this.article) {
      return;
    }

    if (this.action === 'delete') {
      this.isLoading = true;

      this.articleApi
        .delete(this.article.code)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.router.navigate(['articles']);
          },

          error: async (error: HttpErrorResponse) => {
            await this.errorService.handleError(error);

            this.isLoading = false;
            this.isConfirmDialogVisible = false;
            this.action = undefined;
          },
        });
    }
  }

  private delete() {
    if (!this.article) {
      return;
    }

    this.action = 'delete';

    this.confirmationDialogMessage = `<p>Êtes-vous sûr de vouloir <b>supprimer</b> l’article <b>${this.article.titleFr}</b>?</p>`;

    this.isConfirmDialogVisible = true;
  }

  edit() {
    if (!this.article) {
      return;
    }

    this.router.navigate(['articles', this.article.code, 'update']);
  }

  /**
   * ImageCropper
   */

  cancelImageCropModal() {
    this.croppedFiles = undefined;
    this.isCropperDialogVisible = false;
    this.resetImageCropper();
  }

  changeImageScale(): void {
    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  cropAndUploadImage() {
    this.imageCropper?.crop();
  }

  private flipAfterRotate() {
    const flippedH = this.imageTransforms.flipH;
    const flippedV = this.imageTransforms.flipV;

    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: !this.imageTransforms.flipH,
    };
  }

  flipVertical() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipV: !this.imageTransforms.flipV,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCropperReady(event: Dimensions) {
    this.isCropperReady = true;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.isCropperDialogVisible = false;

    if (this.imageFilename) {
      this.croppedImageSrc = event.base64 as string;

      const imageFilenameWithoutExtension =
        UtilsService.getFilenameWithoutExtension(this.imageFilename);

      this.croppedFiles = [
        new File(
          [base64ToFile(this.croppedImageSrc)],
          `${imageFilenameWithoutExtension}.png`,
          { type: 'image/png' },
        ),
      ];

      this.changeDetectorRef.detectChanges();

      this.fileUploader?.upload();
    }
  }

  onLoadImageFailed() {
    this.messageService.add({
      detail: 'Impossible de charger l’image sélectionnée',
      severity: 'error',
      summary: 'Erreur inattendue',
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(event: { files: File[]; error: any; originalEvent: Event }) {
    if (event.files && event.files.length) {
      const file = event.files[0];
      this.imageFilename = file.name;

      // Create a new FileReader object
      const reader = new FileReader();

      // Set the onload event handler of the FileReader object
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Get the base64 data of the selected image file
        const base64data = (e.target as FileReader).result;

        // Do something with the base64 data, such as display it in an image element
        this.selectedImageData = base64data as string;
        this.isCropperDialogVisible = true;
        this.fileUploader?.clear();
      };

      // Read the selected image file as a data URL
      reader.readAsDataURL(file);
    }
  }

  onUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      detail: event.error.error?.message,
      severity: 'error',
      summary: event.error.error?.reason,
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  resetImageCropper() {
    this.imageRotation = 0;
    this.imageTransforms = {};
    this.imageScale = 1;
  }

  rotateLeft() {
    this.imageRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.imageRotation++;
    this.flipAfterRotate();
  }

  zoomIn() {
    if (this.imageScale >= this.imageScaleMax) {
      return;
    }

    this.imageScale += 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  zoomOut() {
    if (this.imageScale <= this.imageScaleMin) {
      return;
    }

    this.imageScale -= 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }
}
