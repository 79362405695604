<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    programsEditService.currentStep === 4 &&
      (programsEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="programsEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="startDate"
          >
            Date de début
          </label>

          <p-calendar
            id="startDate"
            formControlName="startDate"
            styleClass="w-full mb-3"
            [maxDate]="currentStepFormControls.endDate.value"
            [showButtonBar]="true"
            [showIcon]="true"
          ></p-calendar>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.startDate"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="endDate">
            Date de fin
          </label>

          <p-calendar
            id="endDate"
            formControlName="endDate"
            styleClass="w-full mb-3"
            [minDate]="currentStepFormControls.startDate.value"
            [showButtonBar]="true"
            [showIcon]="true"
          ></p-calendar>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.endDate"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div
          class="w-6"
          *ngIf="programsEditService.dirtyProgram?.type !== 'goodDeal'"
        >
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="handlerEndDate"
          >
            Date de fin détaillant
          </label>

          <p-calendar
            id="handlerEndDate"
            formControlName="handlerEndDate"
            styleClass="w-full mb-3"
            [minDate]="currentStepFormControls.endDate.value"
            [showButtonBar]="true"
            [showIcon]="true"
          ></p-calendar>

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.handlerEndDate"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="minimumProductQuantity"
          >
            Nombre minimal d’emballages
          </label>

          <input
            class="w-full mb-3"
            id="minimumProductQuantity"
            (keyup.enter)="nextStep()"
            formControlName="minimumProductQuantity"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.minimumProductQuantity"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="maximumProductQuantity"
          >
            Nombre maximal d’emballages
          </label>

          <input
            class="w-full mb-3"
            id="maximumProductQuantity"
            (keyup.enter)="nextStep()"
            formControlName="maximumProductQuantity"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.maximumProductQuantity"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="maximumUserBasketsPerDay"
          >
            Nombre max. de QR Codes User / j
          </label>

          <input
            class="w-full mb-3"
            id="maximumUserBasketsPerDay"
            (keyup.enter)="nextStep()"
            formControlName="maximumUserBasketsPerDay"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <p>
            Champ facultatif. Indique le nombre maximal de QR Codes pouvant être
            approuvés par User et par jour
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.maximumUserBasketsPerDay"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div
        class="flex align-items-start mb-4 gap-8"
        *ngIf="programsEditService.dirtyProgram?.type === 'recycling'"
      >
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="referrerPoints"
          >
            Points gagnés par le parrain
          </label>

          <input
            class="w-full mb-3"
            id="referrerPoints"
            (keyup.enter)="nextStep()"
            formControlName="referrerPoints"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.referrerPoints"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="referredPoints"
          >
            Points gagnés par le parrainé
          </label>

          <input
            class="w-full mb-3"
            id="referredPoints"
            (keyup.enter)="nextStep()"
            formControlName="referredPoints"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.referredPoints"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="mobileVideoFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Vidéo User en français
          </label>

          <input
            class="w-full mb-3"
            id="mobileVideoFr"
            (keyup.enter)="nextStep()"
            formControlName="mobileVideoFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.mobileVideoFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="mobileVideoAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Vidéo User en arabe
          </label>

          <input
            class="w-full mb-3"
            id="mobileVideoAr"
            (keyup.enter)="nextStep()"
            formControlName="mobileVideoAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.mobileVideoAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="handlerVideoFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Vidéo Handler en français
          </label>

          <input
            class="w-full mb-3"
            id="handlerVideoFr"
            (keyup.enter)="nextStep()"
            formControlName="handlerVideoFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.handlerVideoFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="handlerVideoAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Vidéo Handler en arabe
          </label>

          <input
            class="w-full mb-3"
            id="handlerVideoAr"
            (keyup.enter)="nextStep()"
            formControlName="handlerVideoAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>Champ facultatif.</p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.handlerVideoAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
