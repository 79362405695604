import { IsEmail, IsNotEmpty } from 'class-validator';

export class AppleIdentityDto {
  @IsEmail()
  @IsNotEmpty()
  readonly email!: string;

  @IsNotEmpty()
  readonly firstName!: string;

  @IsNotEmpty()
  readonly lastName!: string;

  @IsNotEmpty()
  readonly userId!: string;
}
