import {
  IsDateString,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  Matches,
  MaxLength,
  MinLength,
} from 'class-validator';
import { PersonGenre } from '../users';

export class EditProfileDto {
  @IsNotEmpty()
  password!: string;

  @MaxLength(10)
  @IsDateString()
  @IsOptional()
  readonly birthdate?: string;

  @IsOptional()
  readonly firstName?: string;

  @IsOptional()
  readonly lastName?: string;

  @IsOptional()
  readonly genre?: PersonGenre;

  @MinLength(10)
  @MaxLength(10)
  @IsNumberString()
  @Matches(/^0[6-7][0-9]{8}$/)
  @IsOptional()
  readonly mobilePhone?: string;

  @IsOptional()
  mccMnc?: string;

  @IsOptional()
  token?: string;

  @IsOptional()
  readonly appleUserId?: string;

  @IsOptional()
  readonly facebookUserId?: string;

  @IsOptional()
  readonly googleUserId?: string;
}
