import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  DashboardApi,
  OrganizationTypeApi,
  ParameterApi,
} from '@be-green/api-services';
import {
  CachedHandlerRankingDto,
  CachedProgramRankingDto,
  CachedRankingDto,
  DashboardDto,
  OrganizationTypeDto,
  ProgramDto,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { combineLatest } from 'rxjs';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'be-green--admin--dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  dashboard?: DashboardDto;
  filteredProProgramRanking?: ProgramDto[];
  handlerRanking?: CachedHandlerRankingDto;
  isLoading = false;
  lastPeriodLabels = {
    month: 'le mois précédent',
    week: 'la semaine précédente',
    year: 'l’année précédente',
  };
  organizationTypes?: OrganizationTypeDto[];
  period: 'month' | 'week' | 'year' = 'week';
  proProgramRanking?: CachedProgramRankingDto;
  stateOptions: ValueLabelDto[] = [
    { label: 'Année', value: 'year' },
    { label: 'Mois', value: 'month' },
    { label: 'Semaine', value: 'week' },
  ];
  thisPeriodLabels = {
    month: 'ce mois',
    week: 'cette semaine',
    year: 'cette année',
  };
  userRanking?: CachedRankingDto;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly dashboardApi: DashboardApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly organizationTypeApi: OrganizationTypeApi,
    private readonly parameterApi: ParameterApi,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Accueil' }]);
    this.seoService.setTitle('Accueil - Admin - Be Green');
  }

  ngOnInit(): void {
    this.loadDashboard();

    this.isLoading = true;

    combineLatest([
      this.organizationTypeApi.getAll(),
      this.parameterApi.getCachedHandlerRanking(),
      this.parameterApi.getCachedProProgramRanking(),
      this.parameterApi.getCachedUserRanking(),
    ]).subscribe({
      next: ([
        organizationTypes,
        handlerRanking,
        proProgramRanking,
        userRanking,
      ]) => {
        this.handlerRanking = handlerRanking;
        this.organizationTypes = organizationTypes;
        this.userRanking = userRanking;

        this.proProgramRanking = proProgramRanking;
        this.filterProProgramRankingByOrganizationType();

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  loadDashboard() {
    this.isLoading = true;

    this.dashboardApi.getDefault(this.period).subscribe({
      next: (dashboard) => {
        this.dashboard = dashboard;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  filterProProgramRankingByOrganizationType(
    selectedOrganizationTypeCode?: string,
  ) {
    if (!this.proProgramRanking) {
      return;
    }

    if (selectedOrganizationTypeCode) {
      this.filteredProProgramRanking = this.proProgramRanking.programs.filter(
        (program) =>
          (program.organizationType as OrganizationTypeDto).code ===
          selectedOrganizationTypeCode,
      );
    } else {
      this.filteredProProgramRanking = this.proProgramRanking.programs;
    }
  }
}
