import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LanguageCode,
  QueryUiDto,
  RedemptionDto,
  RedemptionFormValue,
  RedemptionLocalizedDto,
  RedemptionStatusDto,
} from '@be-green/dto';
import { AuthenticationService, UtilsService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedemptionApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  approve(code: string): Observable<RedemptionStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<RedemptionStatusDto>(
      `/redemptions/${code}/approve`,
      null,
    );
  }

  export(): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/redemptions/export`, {
      responseType: 'blob',
    });
  }

  getAll(
    queryUiDto: QueryUiDto,
  ): Observable<{ rows: RedemptionDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: RedemptionDto[]; count: number }>(
      `/redemptions`,
      {
        params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
      },
    );
  }

  getOneAsAdmin(code: string): Observable<RedemptionDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<RedemptionDto>(`/redemptions/admin/${code}`);
  }

  getOneOfMine(
    languageCode: LanguageCode,
    code: string,
  ): Observable<RedemptionLocalizedDto> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.get<RedemptionLocalizedDto>(
      `/redemptions/${code}/${languageCode}`,
    );
  }

  redeem(
    programCode: string,
    giftCode: string,
    formValues?: RedemptionFormValue[],
  ): Observable<{ code: string; ownPoints: number }> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.post<{ code: string; ownPoints: number }>(
      `/programs/${programCode}/gift/${giftCode}/redemption`,
      formValues || null,
    );
  }

  reject(code: string): Observable<RedemptionStatusDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<RedemptionStatusDto>(
      `/redemptions/${code}/reject`,
      null,
    );
  }

  scan(
    languageCode: LanguageCode,
    code: string,
  ): Observable<RedemptionLocalizedDto> {
    if (!this.authenticationService.isHandler) {
      return of();
    }

    return this.httpClient.put<RedemptionLocalizedDto>(
      `/redemptions/${code}/scan/${languageCode}`,
      null,
    );
  }
}
