<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4 class="font-bold mb-0">
        <ng-container
          *ngIf="categoriesEditService.editAction === 'create'; else update"
          >Création catégorie</ng-container
        >
        <ng-template #update>Mise à jour catégorie</ng-template>

        <ng-container *ngIf="categoriesEditService.category">
          <div
            class="inline-block mx-2 text-blue-600"
            [ngClass]="{
              'line-through':
                categoriesEditService.category &&
                categoriesEditService.dirtyCategory &&
                categoriesEditService.dirtyCategory.nameFr !==
                  categoriesEditService.category.nameFr
            }"
          >
            {{ categoriesEditService.category.nameFr }}
          </div>
        </ng-container>

        <div
          class="inline-block mx-2 text-green-600"
          [ngClass]="{
            'font-bold':
              categoriesEditService.category &&
              categoriesEditService.dirtyCategory &&
              categoriesEditService.dirtyCategory.nameFr !==
                categoriesEditService.category.nameFr
          }"
          *ngIf="
            categoriesEditService.dirtyCategory &&
            (!categoriesEditService.category ||
              categoriesEditService.dirtyCategory.nameFr !==
                categoriesEditService.category.nameFr)
          "
        >
          {{ categoriesEditService.dirtyCategory.nameFr }}
        </div>
      </h4>
    </div>

    <ng-template #loading>
      <p-card class="text-center">
        <p-progressSpinner></p-progressSpinner>
      </p-card>
    </ng-template>

    <p-card
      *ngIf="
        categoriesEditService.currentStep === 1 &&
          (categoriesEditService.uiReady$ | async);
        else loading
      "
    >
      <ng-template pTemplate="content">
        <form
          class="w-full md:w-10 mx-auto"
          (change)="categoriesEditService.setDirty()"
          [formGroup]="form"
        >
          <div class="flex align-items-start mb-4 gap-8">
            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="nameFr"
              >
                <img class="mr-2" src="assets/flags/fr.svg" height="20" />
                Nom en français
              </label>

              <input
                class="w-full mb-3"
                id="nameFr"
                (keyup.enter)="save()"
                formControlName="nameFr"
                pInputText
                style="padding: 1rem"
                maxlength="140"
              />

              <p>
                {{ 140 - formControls.nameFr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.nameFr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>

            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="nameAr"
              >
                <img class="mr-2" src="assets/flags/ma.svg" height="20" />
                Nom en arabe
              </label>

              <input
                class="w-full mb-3"
                id="nameAr"
                dir="rtl"
                (keyup.enter)="save()"
                formControlName="nameAr"
                pInputText
                style="padding: 1rem"
                maxlength="140"
              />

              <p>
                {{ 140 - formControls.nameAr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.nameAr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>
          </div>

          <div class="flex align-items-start mb-4 gap-8">
            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="descriptionFr"
              >
                <img class="mr-2" src="assets/flags/fr.svg" height="20" />
                Description en français
              </label>

              <textarea
                class="w-full mb-3"
                formControlName="descriptionFr"
                maxlength="255"
                pInputTextarea
                style="min-height: 109px"
                [autoResize]="true"
                [rows]="5"
                (keyup.enter)="save()"
              ></textarea>

              <p>
                {{ 255 - formControls.descriptionFr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.descriptionFr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>

            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="descriptionAr"
              >
                <img class="mr-2" src="assets/flags/ma.svg" height="20" />
                Description en arabe
              </label>

              <textarea
                class="w-full mb-3"
                dir="rtl"
                formControlName="descriptionAr"
                maxlength="255"
                pInputTextarea
                style="min-height: 109px"
                [autoResize]="true"
                [rows]="5"
                (keyup.enter)="save()"
              ></textarea>

              <p>
                {{ 255 - formControls.descriptionAr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.descriptionAr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>
          </div>
        </form>
      </ng-template>

      <ng-template pTemplate="footer">
        <div class="w-full md:w-10 mx-auto">
          <div class="grid grid-nogutter justify-content-end">
            <p-button
              (click)="save()"
              label="Suivant"
              icon="pi pi-angle-right"
              iconPos="right"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>
