<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start">
    <p-selectButton
      [options]="segments"
      [(ngModel)]="segment"
      optionLabel="label"
      optionValue="value"
    ></p-selectButton>
  </div>

  <div class="p-toolbar-group-end">
    <p-splitButton
      [disabled]="isLoading"
      [icon]="actionMenuItems[0].icon!!"
      [label]="actionMenuItems[0].label!!"
      [model]="actionMenuItems"
      (onClick)="actionMenuItems[0].command!!()"
    ></p-splitButton>

    <button
      class="p-button-help ml-2"
      icon="pi pi-upload"
      label="Export"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="export()"
    ></button>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="card" *ngIf="segment === 'list'">
  <p-table
    #handlerIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="apiPageSize"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [totalRecords]="recordCount"
    [value]="handlers"
    (onLazyLoad)="fetchNextPage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>

        <th style="min-width: 20rem" pSortableColumn="code" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            Code

            <p-sortIcon field="code"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="level">
          <div class="flex justify-content-between align-items-center">
            Niveau

            <p-sortIcon field="level"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameFr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="addressFr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Adresse
            </div>

            <p-sortIcon field="addressFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="addressAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Adresse
            </div>

            <p-sortIcon field="addressAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="phone">
          <div class="flex justify-content-between align-items-center">
            N° de téléphone

            <p-sortIcon field="phone"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="latitude">
          <div class="flex justify-content-between align-items-center">
            Latitude

            <p-sortIcon field="latitude"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="longitude">
          <div class="flex justify-content-between align-items-center">
            Longitude

            <p-sortIcon field="longitude"></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="currentPeriodApprovedBasketCount"
        >
          <div class="flex justify-content-between align-items-center">
            QR codes validés

            <p-sortIcon field="currentPeriodApprovedBasketCount"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="currentRank">
          <div class="flex justify-content-between align-items-center">
            Classement

            <p-sortIcon field="currentRank"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="created_at">
          <div class="flex justify-content-between align-items-center">
            Créé

            <p-sortIcon field="created_at"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="updated_at">
          <div class="flex justify-content-between align-items-center">
            Mis à jour

            <p-sortIcon field="updated_at"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="code"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="numeric" field="level"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="addressFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="addressAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="phone"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="latitude"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="longitude"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="currentPeriodApprovedBasketCount"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="numeric" field="currentRank"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="date" field="created_at"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="date" field="updated_at"></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-handler>
      <ng-container *ngLet="asHandlerDto(handler) as handler">
        <tr *ngIf="handler">
          <td style="text-align: center">
            <div class="flex" style="line-height: 0">
              <p-image
                class="shadow-4"
                src="{{ handler.clonedPhotoUrl ?? handler.photoUrl }}"
                [preview]="true"
                width="100"
                *ngIf="handler.clonedPhotoUrl || handler.photoUrl; else noImage"
              ></p-image>
            </div>
          </td>

          <td pFrozenColumn>
            <p-tag [value]="handler.code"></p-tag>
          </td>

          <td>
            {{ handler.level }}
          </td>

          <td>
            {{ handler.nameFr }}
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="handler.nameAr; else notSet">{{
              handler.nameAr
            }}</ng-container>
          </td>

          <td>
            <ng-container *ngIf="handler.addressFr; else notSet">{{
              handler.addressFr
            }}</ng-container>
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="handler.addressAr; else notSet">{{
              handler.addressAr
            }}</ng-container>
          </td>

          <td>
            <ng-container *ngIf="handler.phone; else notSet">
              {{ handler.phone }}
            </ng-container>
          </td>

          <td class="text-right">
            <ng-container *ngIf="handler.latitude; else notSet">
              {{ handler.latitude }}
            </ng-container>
          </td>

          <td class="text-right">
            <ng-container *ngIf="handler.longitude; else notSet">
              {{ handler.longitude }}
            </ng-container>
          </td>

          <td>
            <span
              *ngIf="handler.currentPeriodApprovedBasketCount; else notSet"
              >{{ handler.currentPeriodApprovedBasketCount }}</span
            >
          </td>

          <td>
            <span *ngIf="handler.currentRank; else notSet">{{
              handler.currentRank
            }}</span>
          </td>

          <td>
            {{ handler.createdAt | date : 'long' : '' : 'fr' }}
          </td>

          <td>
            {{ handler.updatedAt | date : 'long' : '' : 'fr' }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/handlers/' + handler.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="11">Aucun détaillant trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="11">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div
  class="card"
  [ngClass]="{ 'tlb--max-screen-height': segment === 'map' }"
  *ngIf="segment === 'map'"
>
  <ng-container *ngIf="googleMapsApiLoaded | async">
    <google-map
      [options]="options"
      (mapInitialized)="onMapInitialized($event)"
      (idle)="fetchHandlers()"
    ></google-map>
  </ng-container>

  <p-confirmPopup></p-confirmPopup>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<p-dialog
  class="p-fluid"
  header="Importer un fichier de détaillants"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isHandlersDialogVisible"
>
  <ng-template pTemplate="content">
    <div class="text-center">
      <p-fileUpload
        name="file"
        mode="basic"
        chooseIcon="pi pi-file-excel"
        chooseLabel="Choisir un fichier Excel (500 ko max.)"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        [auto]="true"
        [maxFileSize]="500 * 1024"
        [url]="handlerFileUploadUrl"
        (onError)="onUploadError($event)"
        (onProgress)="isLoading = true"
        (onUpload)="onUploadHandlersSuccess($event)"
      ></p-fileUpload>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isHandlersDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  header="Importer un fichier d’itinéraires"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isItinerariesDialogVisible"
>
  <ng-template pTemplate="content">
    <div class="text-center">
      <p-fileUpload
        name="file"
        mode="basic"
        chooseIcon="pi pi-file"
        chooseLabel="Choisir un fichier Excel (500 ko max.)"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        [auto]="true"
        [maxFileSize]="500 * 1024"
        [url]="itineraryFileUploadUrl"
        (onError)="onUploadError($event)"
        (onProgress)="isLoading = true"
        (onUpload)="onUploadItinerariesSuccess($event)"
      ></p-fileUpload>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isItinerariesDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>

<ng-template #noImage>
  <img
    class="shadow-4"
    src="assets/layout/images/empty-300x240.jpg"
    width="100"
  />
</ng-template>
