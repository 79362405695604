import { Injectable } from '@angular/core';
import { DeviceApp } from '@be-green/dto';
import { Device } from '@capacitor/device';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private authenticationService: AuthenticationService) {}

  async init(app: DeviceApp): Promise<void> {
    return Promise.all([Device.getId(), Device.getInfo()]).then(
      ([id, info]) => {
        return new Promise((resolve) => {
          this.authenticationService
            .upsertDevice(id, info, app)
            .subscribe(() => resolve());
        });
      },
    );
  }
}
