import {
  IsBoolean,
  IsDateString,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  MaxLength,
} from 'class-validator';
import { PersonGenre } from '../users';

export class RegisterDto {
  @IsBoolean()
  @IsNotEmpty()
  readonly agreeToTerms!: boolean;

  @IsEmail()
  @IsNotEmpty()
  readonly email!: string;

  @IsNotEmpty()
  readonly firstName!: string;

  @IsNotEmpty()
  readonly lastName!: string;

  @IsOptional()
  readonly referralCode?: string;

  @IsOptional()
  readonly genre?: PersonGenre;

  @MaxLength(10)
  @IsDateString()
  @IsOptional()
  readonly birthdate?: string;

  @IsOptional()
  readonly appleUserId?: string;

  @IsOptional()
  readonly facebookUserId?: string;

  @IsOptional()
  readonly googleUserId?: string;
}
