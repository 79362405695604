import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT, formatNumber } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GiftApi,
  HandlerApi,
  IconApi,
  MaterialApi,
  ParameterApi,
  ProductApi,
  ProgramApi,
} from '@be-green/api-services';
import {
  CachedRankingDto,
  CollectionType,
  EditGiftDto,
  GiftDto,
  GiftFormField,
  GiftFormFieldType,
  GiftType,
  HandlerDto,
  IconDto,
  MaterialDto,
  ProductDto,
  ProductType,
  ProgramDto,
  ProgramType,
  TelecomOperator,
  TopUpType,
  UserRole,
  ValueLabelDto,
} from '@be-green/dto';
import {
  ErrorService,
  InputValidationService,
  SeoService,
  UtilsService,
} from '@be-green/ui-services';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { fr } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  base64ToFile,
} from 'ngx-image-cropper';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  MessageService,
} from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import {
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  of,
  share,
  tap,
} from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--programs-view',
  templateUrl: './programs-view.component.html',
  styleUrls: ['./programs-view.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('toggleCardContents', [
      state(
        'true',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ProgramsViewComponent implements OnInit, OnDestroy {
  action?: 'delete' | 'downloadAllFirstLevelHandlerQrCodes';
  actionDefaultStyleClass = 'p-button-warning';
  actionMenuItems: MenuItem[] = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      styleClass: 'p-menuitem-warning',
      command: () => {
        this.edit();
      },
    },

    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      styleClass: 'p-menuitem-danger',
      command: () => {
        this.delete();
      },
    },
  ];
  body = this.document.querySelector('body') as HTMLBodyElement;
  confirmationDialogMessage = '';
  exportDefaultStyleClass = 'p-button-help ml-2';
  exportMenuItems: MenuItem[] = [
    {
      label: 'Export détaillants',
      icon: 'pi pi-upload',
      styleClass: 'p-menuitem-help',
      command: () => {
        this.exportHandlers();
      },
    },
    {
      label: 'Export utilisateurs',
      icon: 'pi pi-upload',
      styleClass: 'p-menuitem-help',
      command: () => {
        this.exportUsers();
      },
    },
    {
      label: 'Export QR codes',
      icon: 'pi pi-upload',
      styleClass: 'p-menuitem-help',
      command: () => {
        this.exportBaskets();
      },
    },
    {
      label: 'Export échanges',
      icon: 'pi pi-upload',
      styleClass: 'p-menuitem-help',
      command: () => {
        this.exportRedemptions();
      },
    },
  ];
  isCo2ImpactCardOpen = true;
  isConfirmDialogVisible = false;
  isDescriptionArCardOpen = true;
  isDescriptionFrCardOpen = true;
  isEnergyImpactCardOpen = true;
  isGeneralCardOpen = true;
  isLoading = false;
  isProAffiliationLinkDialogVisible = false;
  isRankingCardOpen = true;
  materials?: MaterialDto[];
  proAffiliationLink?: string;
  program?: ProgramDto;
  safeVideoUrls: {
    handler: { ar?: SafeUrl; fr?: SafeUrl };
    mobile: { ar?: SafeUrl; fr?: SafeUrl };
  } = { handler: {}, mobile: {} };
  scrollDiv = (this.document.scrollingElement ||
    this.document.documentElement) as HTMLElement;
  scrollPosition = 0;
  segment: 'details' | 'map' = 'details';
  segments: ValueLabelDto[] = [
    { label: 'Détails programme', value: 'details' },
    { label: 'Carte détaillants', value: 'map' },
  ];
  types: ValueLabelDto[] = [
    { value: ProgramType.GoodDeal, label: 'Bon plan' },
    { value: ProgramType.Pro, label: 'Pro' },
    { value: ProgramType.Recycling, label: 'Recyclage' },
  ];
  userRanking?: CachedRankingDto;

  /**
   * Gifts
   */
  @ViewChild('filterGiftIndexDataTable')
  filterGiftIndexDataTable!: ElementRef;
  @ViewChild('giftIndexDataTable') giftIndexDataTable!: Table;

  isDeleteGiftDialogVisible = false;
  isGiftDialogVisible = false;
  isGiftFormDefinitionDialogVisible = false;
  isGiftsCardMaximized = false;
  isGiftsCardOpen = true;
  gift: GiftDto | null = null;
  giftDialogHeader = '';
  giftForm!: FormGroup;
  giftFormDefinition?: {
    firstAndLastName: boolean;
    mobilePhone: boolean;
    telecomOperators: { enabled: boolean; selected?: TelecomOperator[] };
    topUpTypes: { enabled: boolean; selected?: TopUpType[] };
  };
  giftFormDefinitionDialogHeader = '';
  giftIllustrationUploadUrl = `/gifts/upload/illustration`;
  gifts!: GiftDto[];
  giftsCard?: HTMLDivElement;
  giftTypes: ValueLabelDto[] = [
    { value: GiftType.Form, label: 'Formulaire' },
    { value: GiftType.QrCode, label: 'QR Code' },
  ];
  roles: ValueLabelDto[] = [
    { value: UserRole.User, label: 'User' },
    { value: UserRole.Handler, label: 'Handler' },
  ];
  telecomOperators = [
    TelecomOperator.Inwi,
    TelecomOperator.MarocTelecom,
    TelecomOperator.Orange,
  ];
  topUpTypes = [
    TopUpType.Dirhams,
    TopUpType.Star_1,
    TopUpType.Star_2,
    TopUpType.Star_3,
    TopUpType.Star_4,
    TopUpType.Star_5,
    TopUpType.Star_6,
    TopUpType.Star_7,
    TopUpType.Star_8,
    TopUpType.Star_9,
    TopUpType.Star_33,
  ];
  /* end of Gifts */

  /**
   * Handlers
   */
  @ViewChild('filterHandlerIndexDataTable')
  filterHandlerIndexDataTable!: ElementRef;
  @ViewChild('handlerDownloadableQrCode')
  handlerDownloadableQrCode?: ElementRef;
  @ViewChild('handlerIndexDataTable') handlerIndexDataTable!: Table;

  googleMapsApiLoaded?: Observable<boolean>;
  handlerFileUploadUrl = '';
  handlerMenuItems: MenuItem[] = [
    {
      label: 'Importer détaillants',
      icon: 'pi pi-map-marker',
      command: () => {
        this.uploadHandlers();
      },
    },

    {
      label: 'QR Codes compacteurs',
      icon: 'pi pi-qrcode',
      command: () => {
        this.downloadAllFirstLevelHandlerQrCodes();
      },
    },
  ];
  handlerQrCodeSizeMultiplier = 1;
  handlerRecordCount = 0;
  handlers!: HandlerDto[];
  handlersCard?: HTMLDivElement;
  isHandlersCardMaximized = false;
  isHandlersCardOpen = true;
  isHandlersDataLoading = true;
  isHandlersDialogVisible = false;
  isHandlerQrCodeDialogVisible = false;
  isMapLoadedTriggered = false;
  map?: google.maps.Map;
  mapHandlers?: HandlerDto[];
  markerCluster?: MarkerClusterer;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markers: google.maps.Marker[] = [];
  options: google.maps.MapOptions = {
    center: {
      // Casablanca
      lat: 33.5722678,
      lng: -7.6570322,
    },

    styles: [
      {
        featureType: 'administrative.country',
        stylers: [{ visibility: 'off' }],
      },

      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],

    zoom: 11,
  };
  qrCodePageRecordCount = 0;
  qrCodePageRecordIndex = 0;
  selectedHandler?: HandlerDto;
  /* end of Handlers */

  /**
   * Icons
   */
  isDeleteIconDialogVisible = false;
  isIconDialogVisible = false;
  isIconsCardOpen = true;
  icon: IconDto | null = null;
  iconDialogHeader = '';
  iconForm!: FormGroup;
  iconsFileUploadUrl?: string;
  icons!: IconDto[];
  iconsCard?: HTMLDivElement;
  selectedIconFiles: File[] = [];
  /* end of Icons */

  /**
   * ImageCropper
   */
  @ViewChild('fileUploader') fileUploader?: FileUpload;
  @ViewChild('imageCropper') imageCropper?: ImageCropperComponent;
  croppedFiles?: File[];
  croppedImageSrc = '';
  imageFilename?: string;
  imageRotation = 0;
  imageScale = 1;
  imageScaleMax = 2;
  imageScaleMin = 0.5;
  imageTransforms: ImageTransform = {};
  isCropperDialogVisible = false;
  isCropperReady = false;
  selectedImageData = '';
  /* end of ImageCropper */

  /**
   * Products
   */
  @ViewChild('filterProductIndexDataTable')
  filterProductIndexDataTable!: ElementRef;
  @ViewChild('productIndexDataTable') productIndexDataTable!: Table;

  isDeleteProductDialogVisible = false;
  isProductDialogVisible = false;
  isProductsCardMaximized = false;
  isProductsCardOpen = true;
  product: ProductDto | null = null;
  productDialogHeader = '';
  productForm!: FormGroup;
  productIllustrationUploadUrl = `/products/upload/illustration`;
  products!: ProductDto[];
  productsCard?: HTMLDivElement;
  productTypes: ValueLabelDto[] = [
    { value: ProductType.Coupon, label: 'Coupon' },
    { value: ProductType.Recycling, label: 'Recyclage' },
  ];
  /* end of Products */

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    @Inject('DEEP_LINKS_BASE_URL') private readonly deepLinksBaseUrl: string,
    @Inject('GOOGLE_MAPS_API_KEY') private readonly googleMapsApiKey: string,
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly domSanitizer: DomSanitizer,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly giftApi: GiftApi,
    private readonly handlerApi: HandlerApi,
    private readonly httpClient: HttpClient,
    private readonly iconApi: IconApi,
    private readonly materialApi: MaterialApi,
    private readonly productApi: ProductApi,
    private readonly programApi: ProgramApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly parameterApi: ParameterApi,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Programmes' }]);
    this.seoService.setTitle('Programmes - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    combineLatest([
      this.materialApi.getAll(),
      this.parameterApi.getCachedPersonPerProgramRanking(code),
      this.programApi.getOne(code),
    ]).subscribe({
      next: ([materials, userRanking, program]) => {
        this.materials = materials;

        this.program = program;

        this.userRanking = userRanking;

        this.iconsFileUploadUrl = `/programs/upload/${code}/icons`;

        this.generateEmbedUrls();

        this.gifts = (program.gifts || []).map((gift) => ({
          ...gift,
          imageUrl: gift.imageUrl.replace('__PROGRAM_CODE__', code),
        }));

        this.icons = (program.icons || []).map((icon) => ({
          ...icon,
          imageUrl: icon.imageUrl.replace('__PROGRAM_CODE__', code),
        }));

        this.products = (program.products || []).map((product) => ({
          ...product,
          imageUrl: product.imageUrl.replace('__PROGRAM_CODE__', code),
        }));

        this.handlerFileUploadUrl = `/programs/upload/handlers/${this.program.code}`;

        this.giftsCard = this.document.querySelector(
          '#gifts',
        ) as HTMLDivElement;

        this.handlersCard = this.document.querySelector(
          '#handlers',
        ) as HTMLDivElement;

        this.iconsCard = this.document.querySelector(
          '#icons',
        ) as HTMLDivElement;

        this.productsCard = this.document.querySelector(
          '#products',
        ) as HTMLDivElement;

        this.layoutService.registerBreadcrumbs([
          { label: 'Programmes', routerLink: '/programs' },
          { label: `${this.program.nameFr}` },
        ]);

        this.seoService.setTitle(
          `${this.program.nameFr} - Programmes - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });

    this.googleMapsApiLoaded = this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}`,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  ngOnDestroy(): void {
    this.body.classList.remove('overflow-hidden');
  }

  confirm() {
    switch (this.action) {
      case 'delete':
        this._delete();

        break;
      case 'downloadAllFirstLevelHandlerQrCodes':
        this._downloadAllFirstLevelHandlerQrCodes();
    }
  }

  copyProAffiliationLinkToClipboard(): void {
    if (
      !this.program ||
      this.program.type !== ProgramType.Pro ||
      !this.proAffiliationLink
    ) {
      return;
    }

    const el = document.createElement('textarea');
    el.value = this.proAffiliationLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.messageService.add({
      key: 'proAffiliationLink',
      severity: 'success',
      summary: 'Lien copié dans votre presse-papiers',
    });
  }

  private _delete() {
    if (!this.program) {
      return;
    }

    if (this.action === 'delete') {
      this.isLoading = true;

      this.programApi
        .delete(this.program.code)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.router.navigate(['programs']);
          },

          error: async (error: HttpErrorResponse) => {
            await this.errorService.handleError(error);

            this.isLoading = false;
            this.isConfirmDialogVisible = false;
            this.action = undefined;
          },
        });
    }
  }

  private delete() {
    if (!this.program) {
      return;
    }

    this.action = 'delete';

    this.confirmationDialogMessage = `<p>Êtes-vous sûr de vouloir <b>supprimer</b> le programme <b>${this.program.nameFr}</b>?</p>`;

    this.isConfirmDialogVisible = true;
  }

  edit() {
    if (!this.program) {
      return;
    }

    this.router.navigate(['programs', this.program.code, 'update']);
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  exportBaskets() {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.programApi.exportBaskets(this.program.code).subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `program-${(this.program as ProgramDto).code}--baskets--${format(
            new Date(),
            'yyyy-MM-dd_HH-mm-ss',
          )}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'programs',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  exportHandlers() {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.programApi.exportHandlers(this.program.code).subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `program-${(this.program as ProgramDto).code}--handlers--${format(
            new Date(),
            'yyyy-MM-dd_HH-mm-ss',
          )}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'programs',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  exportRedemptions() {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.programApi.exportRedemptions(this.program.code).subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `program-${(this.program as ProgramDto).code}--redemptions--${format(
            new Date(),
            'yyyy-MM-dd_HH-mm-ss',
          )}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'programs',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  exportUsers() {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.programApi.exportUsers(this.program.code).subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `program-${(this.program as ProgramDto).code}--users--${format(
            new Date(),
            'yyyy-MM-dd_HH-mm-ss',
          )}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'programs',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  private generateEmbedUrls() {
    if (!this.program) {
      return;
    }

    if (this.program.handlerVideoAr) {
      this.safeVideoUrls.handler.ar = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.program.handlerVideoAr,
          ) as string,
        )
      );
    }

    if (this.program.handlerVideoFr) {
      this.safeVideoUrls.handler.fr = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.program.handlerVideoFr,
          ) as string,
        )
      );
    }

    if (this.program.mobileVideoAr) {
      this.safeVideoUrls.mobile.ar = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.program.mobileVideoAr,
          ) as string,
        )
      );
    }

    if (this.program.mobileVideoFr) {
      this.safeVideoUrls.mobile.fr = <SafeUrl>(
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          UtilsService.generateEmbedUrlFromVideoUrl(
            this.program.mobileVideoFr,
          ) as string,
        )
      );
    }
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'info' | 'danger' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  showProAffiliationLink() {
    if (!this.program || this.program.type !== ProgramType.Pro) {
      return;
    }

    this.proAffiliationLink = `${this.deepLinksBaseUrl}affiliation/${this.program.affiliationCode}`;
    this.isProAffiliationLinkDialogVisible = true;
  }

  private toggleCardSize(divElement: HTMLDivElement, isMaximized: boolean) {
    if (isMaximized) {
      this.scrollPosition = this.scrollDiv.scrollTop;
      this.scrollDiv.scrollTo(0, 0);
      this.body.classList.add('overflow-hidden');
      divElement.classList.add(
        'tlb--div-maximized-wrapper',
        'p-component-overlay',
      );
      divElement.firstElementChild?.classList.add('tlb--div-maximized');
    } else {
      this.body.classList.remove('overflow-hidden');
      this.scrollDiv.scrollTo(0, this.scrollPosition);
      divElement.classList.remove(
        'tlb--div-maximized-wrapper',
        'p-component-overlay',
      );
      divElement.firstElementChild?.parentElement?.classList.remove(
        'tlb--div-maximized',
      );
    }
  }

  /**
   * Gifts
   */
  get giftFormControls() {
    return this.giftForm?.controls as {
      allowedRedemptions: AbstractControl;
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      imageUrl: AbstractControl;
      mobilePoints: AbstractControl;
      handlerPoints: AbstractControl;
      restrictedToRole: AbstractControl;
      type: AbstractControl;
    };
  }

  addNewGift() {
    if (!this.program || this.program.type === ProgramType.GoodDeal) {
      return;
    }

    this.isLoading = false;
    this.gift = null;

    this.initGiftForm();

    this.giftFormControls.restrictedToRole.patchValue([UserRole.User]);
    this.giftFormControls.restrictedToRole.disable();

    this.giftDialogHeader = 'Nouveau cadeau';
    this.isGiftDialogVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asGiftDto(gift: any): GiftDto {
    return gift as GiftDto;
  }

  clearGiftsTable() {
    this.giftIndexDataTable.clear();
    this.filterGiftIndexDataTable.nativeElement.value = '';
  }

  confirmDeleteGift() {
    if (!this.gift) {
      return;
    }

    this.isLoading = true;

    this.giftApi
      .delete(this.gift.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.isDeleteGiftDialogVisible = false;

          this.gifts = this.gifts.filter(
            (gift) => gift.code !== (this.gift as GiftDto).code,
          );

          this.messageService.add({
            detail: 'Cadeau supprimé',
            key: 'gifts',
            life: 3000,
            severity: 'success',
            summary: 'Succès',
          });

          this.gift = null;

          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            key: 'gifts',
            severity: 'error',
            summary: title,
          });

          this.gift = null;
          this.isDeleteGiftDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  deleteGift(gift: GiftDto) {
    if (!this.program || this.program.type === ProgramType.GoodDeal) {
      return;
    }

    this.isLoading = false;
    this.isDeleteGiftDialogVisible = true;
    this.gift = { ...gift };
  }

  editGift(gift: GiftDto) {
    if (!this.program || this.program.type === ProgramType.GoodDeal) {
      return;
    }

    this.isLoading = false;
    this.gift = { ...gift };

    this.initGiftForm();

    this.giftForm.patchValue(gift);

    if (this.gift.imageUrl.startsWith('shared/img/gifts/tmp')) {
      this.croppedImageSrc =
        this.imageBaseUrl + this.gift.imageUrl.replace('shared/', '');
    } else {
      this.croppedImageSrc = this.imageBaseUrl + this.gift.imageUrl;
    }

    if (this.gift.restrictedToRole) {
      this.giftFormControls.restrictedToRole.patchValue([
        this.gift.restrictedToRole,
      ]);
    } else {
      this.giftFormControls.restrictedToRole.patchValue([
        this.roles.map((role) => role.value),
      ]);
    }

    this.giftFormControls.restrictedToRole.disable();

    this.giftDialogHeader = `Editer le cadeau « ${gift.nameFr} »`;
    this.isGiftDialogVisible = true;
  }

  editGiftFormDefinition(gift: GiftDto) {
    if (
      !this.program ||
      this.program.type === ProgramType.GoodDeal ||
      !gift ||
      gift.type !== GiftType.Form
    ) {
      return;
    }

    this.isLoading = false;
    this.gift = { ...gift };

    if (gift.formDefinition) {
      const telecomOperatorsFormField = gift.formDefinition.find(
        (field) => field.field === GiftFormFieldType.TelecomOperator,
      );

      const topUpTypesFormField = gift.formDefinition.find(
        (field) => field.field === GiftFormFieldType.TopUpType,
      );

      this.giftFormDefinition = {
        firstAndLastName:
          gift.formDefinition.find(
            (field) => field.field === GiftFormFieldType.FirstName,
          ) !== undefined &&
          gift.formDefinition.find(
            (field) => field.field === GiftFormFieldType.LastName,
          ) !== undefined,

        mobilePhone:
          gift.formDefinition.find(
            (field) => field.field === GiftFormFieldType.MobilePhone,
          ) !== undefined,

        telecomOperators: {
          enabled: telecomOperatorsFormField !== undefined,

          selected:
            telecomOperatorsFormField &&
            telecomOperatorsFormField.restrictedToList
              ? this.telecomOperators.filter((telecomOperator) =>
                  (
                    telecomOperatorsFormField.restrictedToList as TelecomOperator[]
                  ).includes(telecomOperator),
                )
              : this.telecomOperators,
        },

        topUpTypes: {
          enabled: topUpTypesFormField !== undefined,

          selected:
            topUpTypesFormField && topUpTypesFormField.restrictedToList
              ? this.topUpTypes.filter((topUpType) =>
                  (
                    topUpTypesFormField.restrictedToList as TopUpType[]
                  ).includes(topUpType),
                )
              : this.topUpTypes,
        },
      };
    } else {
      this.giftFormDefinition = {
        firstAndLastName: false,
        mobilePhone: false,
        telecomOperators: { enabled: false, selected: this.telecomOperators },
        topUpTypes: { enabled: false, selected: this.topUpTypes },
      };
    }

    this.giftFormDefinitionDialogHeader = `Formulaire pour le cadeau « ${gift.nameFr} »`;
    this.isGiftFormDefinitionDialogVisible = true;
  }

  private fetchGift(code: string) {
    return this.giftApi.getOne(code).pipe(first(), share());
  }

  getRoleStyleClass(
    role: UserRole,
  ): 'tlb--status-active' | 'tlb--status-submitted' | '' {
    switch (role) {
      case UserRole.Handler:
        return 'tlb--status-submitted';

      case UserRole.User:
        return 'tlb--status-active';

      default:
        return '';
    }
  }

  private initGiftForm() {
    if (!this.program || this.program.type === ProgramType.GoodDeal) {
      return;
    }

    this.croppedFiles = undefined;
    this.croppedImageSrc = '';
    this.imageFilename = undefined;
    this.isCropperReady = false;
    this.resetImageCropper();

    this.giftForm = this.formBuilder.group({
      allowedRedemptions: [null],
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      handlerPoints: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      imageUrl: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      mobilePoints: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      restrictedToRole: [null, Validators.required],
      type: [null, Validators.required],
    });

    InputValidationService.setOptionalValidator(
      this.giftFormControls.allowedRedemptions,
      Validators.compose([
        InputValidationService.integer,
        Validators.min(1),
      ]) as ValidatorFn,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGiftRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.giftApi
      .reorder(
        this.program.code,
        this.gifts.map((gift) => gift.code),
      )
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }

  onGiftUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ url: string }>;
  }) {
    if (!this.giftForm) {
      return;
    }

    this.giftFormControls.imageUrl.setValue(event.originalEvent.body?.url);

    this.giftForm.markAllAsTouched();

    this.isLoading = false;
  }

  saveGift() {
    if (!this.program || this.program.type === ProgramType.GoodDeal) {
      return;
    }

    this.giftForm.markAllAsTouched();

    if (this.giftForm.invalid) {
      return;
    }

    this.isLoading = true;

    const gift = { ...this.gift, ...this.giftForm.value };
    delete gift.formDefinition;

    if (!this.giftFormControls.allowedRedemptions.value) {
      delete gift.allowedRedemptions;
    }

    if (
      this.giftFormControls.restrictedToRole.value &&
      Array.isArray(this.giftFormControls.restrictedToRole.value) &&
      this.giftFormControls.restrictedToRole.value.length
    ) {
      if (this.giftFormControls.restrictedToRole.value.length > 1) {
        delete gift.restrictedToRole;
      } else {
        gift.restrictedToRole = this.giftFormControls.restrictedToRole.value[0];
      }
    }

    if (this.gift) {
      this.giftApi
        .update(this.program.code, gift as EditGiftDto)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.fetchGift(gift.code).subscribe({
              next: (data) => {
                const index = this.gifts.findIndex(
                  (gift) => gift.code === (this.gift as GiftDto).code,
                );

                this.gifts[index] = {
                  ...data,

                  imageUrl: data.imageUrl.replace(
                    '__PROGRAM_CODE__',
                    (this.program as ProgramDto).code,
                  ),
                };

                this.isGiftDialogVisible = false;
                this.gift = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'gifts',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Cadeau mis à jour`,
              key: 'gifts',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'gifts',
              severity: 'error',
              summary: title,
            });
          },
        });
    } else {
      this.giftApi
        .create(this.program.code, gift as EditGiftDto)
        .pipe(first())
        .subscribe({
          next: async (value: { code: string }) => {
            this.fetchGift(value.code).subscribe({
              next: (data) => {
                this.gifts = [
                  ...this.gifts,

                  {
                    ...data,
                    imageUrl: data.imageUrl.replace(
                      '__PROGRAM_CODE__',
                      (this.program as ProgramDto).code,
                    ),
                  },
                ];

                this.isGiftDialogVisible = false;
                this.gift = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'gifts',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Cadeau créé`,
              key: 'gifts',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'gifts',
              severity: 'error',
              summary: title,
            });
          },
        });
    }
  }

  saveGiftFormDefinition() {
    if (
      !this.program ||
      this.program.type === ProgramType.GoodDeal ||
      !this.gift ||
      this.gift.type !== GiftType.Form
    ) {
      return;
    }

    this.isLoading = true;

    const formDefinition: GiftFormField[] = [];

    if (this.giftFormDefinition?.firstAndLastName) {
      formDefinition.push({ field: GiftFormFieldType.FirstName });
      formDefinition.push({ field: GiftFormFieldType.LastName });
    }

    if (this.giftFormDefinition?.telecomOperators.enabled) {
      formDefinition.push({
        field: GiftFormFieldType.TelecomOperator,

        restrictedToList: this.telecomOperators.filter((telecomOperator) =>
          (
            this.giftFormDefinition?.telecomOperators
              .selected as TelecomOperator[]
          ).includes(telecomOperator),
        ),
      });
    }

    if (this.giftFormDefinition?.mobilePhone) {
      formDefinition.push({ field: GiftFormFieldType.MobilePhone });
    }

    if (this.giftFormDefinition?.topUpTypes.enabled) {
      formDefinition.push({
        field: GiftFormFieldType.TopUpType,

        restrictedToList: this.topUpTypes.filter((topUpType) =>
          (
            this.giftFormDefinition?.topUpTypes.selected as TopUpType[]
          ).includes(topUpType),
        ),
      });
    }

    this.giftApi
      .updateFormDefinition(this.program.code, this.gift.code, formDefinition)
      .pipe(first())
      .subscribe({
        complete: async () => {
          const index = this.gifts.findIndex(
            (gift) => gift.code === (this.gift as GiftDto).code,
          );

          this.gifts[index] = {
            ...(this.gift as GiftDto),
            formDefinition,
          };

          this.isGiftDialogVisible = false;
          this.gift = null;

          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.isLoading = false;

          this.messageService.add({
            detail: message,
            key: 'gifts',
            severity: 'error',
            summary: title,
          });
        },
      });

    this.messageService.add({
      detail: `Cadeau mis à jour`,
      key: 'gifts',
      life: 3000,
      severity: 'success',
      summary: 'Succès',
    });
  }

  toggleGiftsCardSize() {
    this.isGiftsCardMaximized = !this.isGiftsCardMaximized;

    this.toggleCardSize(
      this.giftsCard as HTMLDivElement,
      this.isGiftsCardMaximized,
    );
  }

  /**
   * Handlers
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asHandlerDto(handler: any): HandlerDto {
    return handler as HandlerDto;
  }

  clearHandlersTable() {
    this.handlerIndexDataTable.clear();
    this.filterHandlerIndexDataTable.nativeElement.value = '';
  }

  private _downloadAllFirstLevelHandlerQrCodes() {
    if (
      !this.program ||
      (this.program.type === ProgramType.Recycling &&
        this.program.collectionType !== CollectionType.Deferred) ||
      !this.handlers ||
      !this.handlers.length ||
      !this.qrCodePageRecordCount
    ) {
      return;
    }

    this.handlerQrCodeSizeMultiplier = 2.85;
    this.isHandlerQrCodeDialogVisible = true;
    this.isLoading = true;
    this.isConfirmDialogVisible = false;

    setTimeout(() => {
      // Default export is a4 paper, portrait, using millimeters for units
      const pdfDocument = new jsPDF({ compress: true });

      this.downloadAndPrintNextFirstLevelHandlerQrCodes(pdfDocument, 1);
    }, 700);
  }

  downloadAllFirstLevelHandlerQrCodes() {
    if (
      !this.program ||
      (this.program.type === ProgramType.Recycling &&
        this.program.collectionType !== CollectionType.Deferred)
    ) {
      return;
    }

    if (!this.handlers || !this.handlers.length) {
      this.messageService.add({
        detail: `Le programme ${this.program.nameFr} n’a aucun détaillant associé.`,
        key: 'handlers',
        severity: 'warn',
        summary: 'Impossible de créer le fichier de QR Codes',
      });
    } else {
      this.action = 'downloadAllFirstLevelHandlerQrCodes';

      this.handlerApi
        .getAll({ page: 1, results: 10 }, this.program.code, 1)
        .subscribe({
          next: async (level1Handlers) => {
            this.qrCodePageRecordCount = level1Handlers.count;

            if (this.qrCodePageRecordCount) {
              const duration = formatDuration(
                intervalToDuration({
                  start: 0,
                  end: 3500 * this.qrCodePageRecordCount,
                }),
                {
                  format: ['hours', 'minutes', 'seconds'],
                  locale: fr,
                },
              );

              this.confirmationDialogMessage = `<p>Cette action nécessite un temps de traitement significatif, et vous devrez patienter pendant la génération du fichier global (ou annuler en cours de route).</p>
              
              <p>Le fichier PDF généré contiendra <b class="text-red-500">${
                this.qrCodePageRecordCount
              } page(s)</b> (1 pour chaque compacteur), et nécessitera environ <b class="text-red-500">${duration}</b> (à raison de 3,5 secondes par page).</p>
              
              <p>Pour information, le fichier PDF généré pèsera environ <b class="text-red-500">${formatNumber(
                120 * this.qrCodePageRecordCount,
                'fr',
                '1.0-0',
              )} ko</b> (à raison de 120 ko par page).</p>

              <p><b>Rappel :</b> vous pouvez à tout moment afficher, et télécharger, les QR codes individuels des compacteurs au niveau de la liste des détaillants du programme.<p>
              
              <p>Êtes-vous sûr de vouloir <b>générer le PDF global</b> des <b>${
                this.qrCodePageRecordCount
              } compacteurs</b> du programme <b>${
                (this.program as ProgramDto).nameFr
              }</b>&nbsp;?</p>`;

              this.isConfirmDialogVisible = true;
            } else {
              this.messageService.add({
                detail: `Le programme ${
                  (this.program as ProgramDto).nameFr
                } n’a aucun compacteur associé. En effet, les QR codes des programmes Pro sont générés uniquement pour les détaillants de niveau 1.`,
                key: 'handlers',
                severity: 'warn',
                summary: 'Impossible de créer le fichier de QR Codes',
              });

              this.isHandlerQrCodeDialogVisible = false;
              this.isLoading = false;
            }
          },
        });
    }
  }

  private downloadAndPrintNextFirstLevelHandlerQrCodes(
    pdfDocument: jsPDF,
    page: number,
    results = 10,
  ): void {
    if (
      !this.program ||
      (this.program.type === ProgramType.Recycling &&
        this.program.collectionType !== CollectionType.Deferred) ||
      !this.handlers ||
      !this.handlers.length ||
      !this.qrCodePageRecordCount
    ) {
      return;
    }

    this.handlerApi
      .getAll({ page, results }, this.program.code, 1)
      .pipe(
        tap(async (level1Handlers) => {
          let counter = 0;
          for (const handler of level1Handlers.rows) {
            counter++;

            this.qrCodePageRecordIndex = counter + (page - 1) * results;

            this.selectedHandler = handler;
            this.changeDetectorRef.detectChanges();

            if (!this.handlerDownloadableQrCode) {
              return;
            }

            await new Promise((resolve) => setTimeout(resolve, 700));

            const canvas = await html2canvas(
              (this.handlerDownloadableQrCode as ElementRef).nativeElement,
            );

            const height = (canvas.height * 180) / canvas.width;
            pdfDocument.addImage(canvas, 'PNG', 15, 20, 180, height);

            if (this.qrCodePageRecordIndex < this.qrCodePageRecordCount) {
              pdfDocument.addPage();
            }
          }

          if (page * results < this.qrCodePageRecordCount) {
            this.downloadAndPrintNextFirstLevelHandlerQrCodes(
              pdfDocument,
              page + 1,
            );
          } else {
            pdfDocument.save(
              `QR-compacteurs--Programme-${
                (this.program as ProgramDto).code
              }.pdf`,
            );

            this.handlerQrCodeSizeMultiplier = 1;
            this.qrCodePageRecordIndex = 0;
            this.isHandlerQrCodeDialogVisible = false;
            this.isLoading = false;
          }
        }),
      )
      .subscribe();
  }

  downloadHandlerQrCode() {
    if (
      !this.program ||
      (this.program.type === ProgramType.Recycling &&
        this.program.collectionType !== CollectionType.Deferred)
    ) {
      return;
    }

    if (
      !this.selectedHandler ||
      !this.handlerDownloadableQrCode ||
      this.selectedHandler.level !== 1
    ) {
      return;
    }

    // Since the generated QR Code is on canvas, we cannot
    // simply scale, so we have to hack around sizes and Angular change detection
    this.isLoading = true;

    // Backup the currently selected handler
    const selectedHandlerBackup = this.selectedHandler;

    // Nullify the generated QR code
    this.selectedHandler = undefined;
    this.changeDetectorRef.detectChanges();

    // Scale up then use the backuped handler to generate the scaled-up QR code
    this.handlerQrCodeSizeMultiplier = 2.85;
    this.selectedHandler = selectedHandlerBackup;
    this.changeDetectorRef.detectChanges();

    // Without a timer, it can be too fast to happen
    setTimeout(async () => {
      const canvas = await html2canvas(
        (this.handlerDownloadableQrCode as ElementRef).nativeElement,
      );

      const height = (canvas.height * 180) / canvas.width;

      // Default export is a4 paper, portrait, using millimeters for units
      const pdfDocument = new jsPDF({ compress: true });
      pdfDocument.addImage(canvas, 'PNG', 15, 20, 180, height);
      pdfDocument.save(
        `QR-compacteur--${
          (this.selectedHandler as HandlerDto).code
        }--Programme-${(this.program as ProgramDto).code}.pdf`,
      );

      // Nullify the generated QR code, again :-)
      this.selectedHandler = undefined;
      this.changeDetectorRef.detectChanges();

      // Scale down then use the backuped handler to generate the scaled-down QR code
      this.handlerQrCodeSizeMultiplier = 1;
      this.selectedHandler = selectedHandlerBackup;
      this.changeDetectorRef.detectChanges();

      this.isLoading = false;
    }, 700);
  }

  async fetchHandlers() {
    if (!this.program) {
      return;
    }

    const bounds = this.map?.getBounds();

    if (bounds) {
      this.handlerApi
        .getAllInBounds(
          bounds?.getNorthEast().lat() as number,
          bounds?.getNorthEast().lng() as number,
          bounds?.getSouthWest().lat() as number,
          bounds?.getSouthWest().lng() as number,
          this.program.code,
        )
        .subscribe((handlers) => {
          this.mapHandlers = handlers;

          for (const handler of handlers) {
            if (
              !this.markers?.find(
                (marker) => marker.getTitle() === handler?.code,
              )
            ) {
              const marker = new google.maps.Marker({
                position: {
                  lat: handler.latitude as number,
                  lng: handler.longitude as number,
                },

                icon: `assets/images/ui/marker.png`,
                title: handler.code,
              });

              this.markers.push(marker);

              marker.addListener(
                'click',
                (event: google.maps.MapMouseEvent) => {
                  event.stop();
                  this.onMarkerClick(event, marker);
                },
              );
            }
          }

          this.markerCluster?.clearMarkers();
          this.markerCluster = new MarkerClusterer({
            map: this.map,
            markers: this.markers,
          });
        });
    }
  }

  fetchNextHandlerPage(lazyLoadEvent: LazyLoadEvent) {
    if (!this.program) {
      return;
    }

    this.isHandlersDataLoading = true;

    this.handlerApi
      .getAll(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
        this.program.code,
      )
      .subscribe({
        next: (data) => {
          this.handlers = data.rows;
          this.handlerRecordCount = data.count;

          this.isHandlersDataLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isHandlersDataLoading = false;
        },
      });
  }

  onHandlerQrCodeDialogHidden() {
    this.handlerQrCodeSizeMultiplier = 1;
    this.qrCodePageRecordCount = 0;
    this.qrCodePageRecordIndex = 0;
    this.isHandlerQrCodeDialogVisible = false;
    this.isLoading = false;
  }

  onHandlerUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      detail: event.error.error?.message,
      key: 'handlers',
      severity: 'error',
      summary: event.error.error?.reason,
    });

    this.isLoading = false;
    this.isHandlersDialogVisible = false;
  }

  onHandlerUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{
      url: string;
      warnings?: {
        ignoredHandlers: { line: number; code: string }[];
        ignoredSheets: string[];
        invalidCodes: { line: number; code: string }[];
      };
    }>;
  }) {
    if (
      event.originalEvent.body?.warnings &&
      (event.originalEvent.body.warnings.ignoredHandlers.length ||
        event.originalEvent.body.warnings.ignoredSheets.length ||
        event.originalEvent.body.warnings.invalidCodes.length)
    ) {
      if (
        event.originalEvent.body.warnings.ignoredHandlers &&
        event.originalEvent.body.warnings.ignoredHandlers.length
      ) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces codes détaillants ne sont pas présents dans la base : ** ${event.originalEvent.body.warnings.ignoredHandlers
            .map(
              (ignoredLine) =>
                `${ignoredLine.code} à la ligne ${ignoredLine.line}`,
            )
            .join(' * , * ')} **`,
          key: 'handlers',
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.ignoredSheets.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, une seule feuille peut être importée et les feuilles suivantes ont été ignorées : ** ${event.originalEvent.body.warnings.ignoredSheets.join(
            ' * , * ',
          )} **`,
          key: 'handlers',
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidCodes.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs codes ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidCodes
            .map(
              (invalidCode) =>
                `"${invalidCode.code}" à la ligne ${invalidCode.line}`,
            )
            .join(' * , * ')} **`,
          key: 'handlers',
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }
    } else {
      this.messageService.add({
        detail: `L’importation et le traitement du fichier « ${event.files[0].name} » sont terminés.`,
        key: 'handlers',
        severity: 'success',
        summary: 'Importation réussie',
      });
    }

    this.handlerIndexDataTable.reset();

    this.isLoading = false;
    this.isHandlersDialogVisible = false;
  }

  onMapInitialized(map: google.maps.Map) {
    this.map = map;
  }

  onMarkerClick(
    event: google.maps.MapMouseEvent,
    clickedMarker: google.maps.Marker,
  ) {
    const marker = this.mapHandlers?.find(
      (addedMarker) => addedMarker.code === clickedMarker.getTitle(),
    );

    if (!marker) {
      return;
    }

    this.confirmationService.confirm({
      acceptLabel: 'Détails',
      message: `${marker.nameFr} (${marker.code})`,
      icon: 'pi pi-search',
      rejectVisible: false,
      target: event.domEvent.target as EventTarget,

      accept: () => {
        this.router.navigate(['handlers', marker.code]);
      },
    });
  }

  showHandlerQrCode(handler: HandlerDto) {
    if (
      !this.program ||
      (this.program.type === ProgramType.Recycling &&
        this.program.collectionType !== CollectionType.Deferred)
    ) {
      return;
    }

    if (handler.level !== 1) {
      return;
    }

    this.selectedHandler = handler;
    this.handlerQrCodeSizeMultiplier = 1;
    this.qrCodePageRecordCount = 0;
    this.qrCodePageRecordIndex = 0;
    this.isHandlerQrCodeDialogVisible = true;
  }

  toggleHandlersCardSize() {
    this.isHandlersCardMaximized = !this.isHandlersCardMaximized;

    this.toggleCardSize(
      this.handlersCard as HTMLDivElement,
      this.isHandlersCardMaximized,
    );
  }

  uploadHandlers() {
    this.isHandlersDialogVisible = true;
  }

  /**
   * Icons
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asIconDto(icon: any): IconDto {
    return icon as IconDto;
  }

  confirmDeleteIcon() {
    if (!this.icon) {
      return;
    }

    this.isLoading = true;

    this.iconApi
      .delete(this.icon.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.isDeleteIconDialogVisible = false;

          this.icons = this.icons.filter(
            (icon) => icon.code !== (this.icon as IconDto).code,
          );

          this.messageService.add({
            detail: 'Icône supprimée',
            key: 'icons',
            life: 3000,
            severity: 'success',
            summary: 'Succès',
          });

          this.icon = null;

          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            key: 'icons',
            severity: 'error',
            summary: title,
          });

          this.icon = null;
          this.isDeleteIconDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  deleteIcon(icon: IconDto) {
    this.isLoading = false;
    this.isDeleteIconDialogVisible = true;
    this.icon = { ...icon };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onIconRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    if (!this.program) {
      return;
    }

    this.isLoading = true;

    this.iconApi
      .reorder(
        this.program.code,
        this.icons.map((icon) => icon.code),
      )
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }

  onIconUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ code: string; url: string }[]>;
  }) {
    for (const file of event.files) {
      this.selectedIconFiles.push(file);
    }

    if (Array.isArray(event.originalEvent.body)) {
      for (const icon of event.originalEvent.body) {
        this.icons.push({
          code: icon.code,
          imageUrl: icon.url.replace('shared/', ''),
        });
      }
    }

    this.messageService.add({
      detail:
        event.files.length > 1
          ? `Les ${event.files.length} icônes sont enregistrées sur le serveur.`
          : `L’icône est enregistrée sur le serveur.`,
      key: 'icons',
      severity: 'success',
      summary: 'Téléversement réussi',
    });

    this.isLoading = false;
  }

  /**
   * Products
   */
  get productFormControls() {
    return this.productForm?.controls as {
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      imageUrl: AbstractControl;
      material: AbstractControl;
      mobilePoints: AbstractControl;
      type: AbstractControl;
      weightInG: AbstractControl;
    };
  }

  addNewProduct() {
    if (!this.program) {
      return;
    }

    this.isLoading = false;
    this.product = null;

    this.initProductForm();

    if (this.program.type === ProgramType.GoodDeal) {
      this.productFormControls.mobilePoints.patchValue(1);

      this.productFormControls.type.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value: ProductType) => {
          if (value && value === ProductType.Recycling) {
            this.productFormControls.material.setValue(null);
            this.productFormControls.weightInG.setValue(null);
          } else {
            this.productFormControls.material.setValue('DUMMY_MATERIAL');
            this.productFormControls.weightInG.patchValue(1);
          }

          this.productFormControls.material.updateValueAndValidity();
          this.productFormControls.weightInG.updateValueAndValidity();
        });
    } else {
      this.productFormControls.type.patchValue(ProductType.Recycling);
    }

    this.productDialogHeader = 'Nouveau produit';
    this.isProductDialogVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asProductDto(product: any): ProductDto {
    return product as ProductDto;
  }

  clearProductsTable() {
    this.productIndexDataTable.clear();
    this.filterProductIndexDataTable.nativeElement.value = '';
  }

  confirmDeleteProduct() {
    if (!this.product) {
      return;
    }

    this.isLoading = true;

    this.productApi
      .delete(this.product.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.isDeleteProductDialogVisible = false;

          this.products = this.products.filter(
            (product) => product.code !== (this.product as ProductDto).code,
          );

          this.messageService.add({
            detail: 'Produit supprimé',
            key: 'products',
            life: 3000,
            severity: 'success',
            summary: 'Succès',
          });

          this.product = null;

          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            key: 'products',
            severity: 'error',
            summary: title,
          });

          this.product = null;
          this.isDeleteProductDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  deleteProduct(product: ProductDto) {
    this.isLoading = false;
    this.isDeleteProductDialogVisible = true;
    this.product = { ...product };
  }

  editProduct(product: ProductDto) {
    if (!this.program) {
      return;
    }

    this.isLoading = false;
    this.product = { ...product };

    this.initProductForm();

    this.productForm.patchValue(product);

    if (this.program.type === ProgramType.GoodDeal) {
      this.productFormControls.mobilePoints.patchValue(1);

      if (product.type === ProductType.Recycling) {
        this.productFormControls.material.patchValue(product.material.code);
      } else {
        this.productFormControls.material.patchValue('DUMMY_MATERIAL');
        this.productFormControls.weightInG.patchValue(1);
      }
    } else {
      this.productFormControls.material.patchValue(product.material.code);
      this.productFormControls.type.patchValue(ProductType.Recycling);
    }

    this.productFormControls.type.disable();

    if (this.product.imageUrl.startsWith('shared/img/products/tmp')) {
      this.croppedImageSrc =
        this.imageBaseUrl + this.product.imageUrl.replace('shared/', '');
    } else {
      this.croppedImageSrc = this.imageBaseUrl + this.product.imageUrl;
    }

    this.productDialogHeader = `Editer le produit « ${product.nameFr} »`;
    this.isProductDialogVisible = true;
  }

  private fetchProduct(code: string) {
    return this.productApi.getOne(code).pipe(first(), share());
  }

  getProductTypeTagSeverity(type: ProductType): 'success' | 'warning' | '' {
    return UtilsService.getProductTypeTagSeverity(type);
  }

  private initProductForm() {
    this.croppedFiles = undefined;
    this.croppedImageSrc = '';
    this.imageFilename = undefined;
    this.isCropperReady = false;
    this.resetImageCropper();

    this.productForm = this.formBuilder.group({
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      imageUrl: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      material: [null, Validators.required],
      mobilePoints: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
      type: [null, Validators.required],
      weightInG: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onProductRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    if (!this.program) {
      return;
    }

    this.productApi
      .reorder(
        this.program.code,
        this.products.map((product) => product.code),
      )
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }

  onProductUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ url: string }>;
  }) {
    if (!this.productForm) {
      return;
    }

    this.productFormControls.imageUrl.setValue(event.originalEvent.body?.url);

    this.productForm.markAllAsTouched();

    this.isLoading = false;
  }

  saveProduct() {
    if (!this.program) {
      return;
    }

    this.productForm.markAllAsTouched();

    if (this.productForm.invalid) {
      return;
    }

    this.isLoading = true;

    const product: ProductDto = {
      ...this.product,
      ...this.productForm.value,
      material: { code: this.productFormControls.material.value },
    };

    if (this.product) {
      this.productApi
        .update(this.program.code, product)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.fetchProduct(product.code).subscribe({
              next: (data) => {
                const index = this.products.findIndex(
                  (product) =>
                    product.code === (this.product as ProductDto).code,
                );

                this.products[index] = {
                  ...data,

                  imageUrl: data.imageUrl.replace(
                    '__PROGRAM_CODE__',
                    (this.program as ProgramDto).code,
                  ),
                };

                this.isProductDialogVisible = false;
                this.product = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'products',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Produit mis à jour`,
              key: 'products',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'products',
              severity: 'error',
              summary: title,
            });
          },
        });
    } else {
      this.productApi
        .create(this.program.code, product)
        .pipe(first())
        .subscribe({
          next: async (value: { code: string }) => {
            this.fetchProduct(value.code).subscribe({
              next: (data) => {
                this.products = [
                  ...this.products,

                  {
                    ...data,
                    imageUrl: data.imageUrl.replace(
                      '__PROGRAM_CODE__',
                      (this.program as ProgramDto).code,
                    ),
                  },
                ];

                this.isProductDialogVisible = false;
                this.product = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'products',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Produit créé`,
              key: 'products',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'products',
              severity: 'error',
              summary: title,
            });
          },
        });
    }
  }

  toggleProductsCardSize() {
    this.isProductsCardMaximized = !this.isProductsCardMaximized;

    this.toggleCardSize(
      this.productsCard as HTMLDivElement,
      this.isProductsCardMaximized,
    );
  }

  /**
   * ImageCropper
   */

  cancelImageCropModal() {
    this.croppedFiles = undefined;
    this.isCropperDialogVisible = false;
    this.resetImageCropper();
  }

  changeImageScale(): void {
    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  cropAndUploadImage() {
    this.imageCropper?.crop();
  }

  private flipAfterRotate() {
    const flippedH = this.imageTransforms.flipH;
    const flippedV = this.imageTransforms.flipV;

    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: !this.imageTransforms.flipH,
    };
  }

  flipVertical() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipV: !this.imageTransforms.flipV,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCropperReady(event: Dimensions) {
    this.isCropperReady = true;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.isCropperDialogVisible = false;

    if (this.imageFilename) {
      this.croppedImageSrc = event.base64 as string;

      const imageFilenameWithoutExtension =
        UtilsService.getFilenameWithoutExtension(this.imageFilename);

      this.croppedFiles = [
        new File(
          [base64ToFile(this.croppedImageSrc)],
          `${imageFilenameWithoutExtension}.png`,
          { type: 'image/png' },
        ),
      ];

      this.changeDetectorRef.detectChanges();

      this.fileUploader?.upload();
    }
  }

  onLoadImageFailed() {
    this.messageService.add({
      detail: 'Impossible de charger l’image sélectionnée',
      severity: 'error',
      summary: 'Erreur inattendue',
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(event: { files: File[]; error: any; originalEvent: Event }) {
    if (event.files && event.files.length) {
      const file = event.files[0];
      this.imageFilename = file.name;

      // Create a new FileReader object
      const reader = new FileReader();

      // Set the onload event handler of the FileReader object
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Get the base64 data of the selected image file
        const base64data = (e.target as FileReader).result;

        // Do something with the base64 data, such as display it in an image element
        this.selectedImageData = base64data as string;
        this.isCropperDialogVisible = true;
        this.fileUploader?.clear();
      };

      // Read the selected image file as a data URL
      reader.readAsDataURL(file);
    }
  }

  onUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      detail: event.error.error?.message,
      severity: 'error',
      summary: event.error.error?.reason,
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  resetImageCropper() {
    this.imageRotation = 0;
    this.imageTransforms = {};
    this.imageScale = 1;
  }

  rotateLeft() {
    this.imageRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.imageRotation++;
    this.flipAfterRotate();
  }

  zoomIn() {
    if (this.imageScale >= this.imageScaleMax) {
      return;
    }

    this.imageScale += 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  zoomOut() {
    if (this.imageScale <= this.imageScaleMin) {
      return;
    }

    this.imageScale -= 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }
}
