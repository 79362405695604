import { Injectable } from '@angular/core';
import { OrganizationTypeApi } from '@be-green/api-services';
import { EditOrganizationTypeDto, OrganizationTypeDto } from '@be-green/dto';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class OrganizationTypesEditService {
  private _currentStep = 1;
  private _editAction?: 'create' | 'update';
  private _isDirty = false;
  private _organizationType?: OrganizationTypeDto;
  private _dirtyOrganizationType?: EditOrganizationTypeDto;
  private uiReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly organizationTypeApi: OrganizationTypeApi) {}

  get currentStep() {
    return this._currentStep;
  }

  get dirtyOrganizationType() {
    return this._dirtyOrganizationType;
  }

  get editAction() {
    return this._editAction;
  }

  get isDirty() {
    return this._isDirty;
  }

  get organizationType() {
    return this._organizationType;
  }

  get uiReady$() {
    return this.uiReadySubject.asObservable();
  }

  init() {
    this._isDirty = false;
    this.uiReadySubject.next(false);
  }

  saveDirtyOrganizationType(): Observable<{ code: string } | void> {
    if (!this._dirtyOrganizationType || !this.editAction) {
      return throwError(() => new Error(''));
    }

    const organizationTypeCodeToUpdate = this._organizationType
      ? this._organizationType.code
      : undefined;

    switch (this._editAction) {
      case 'create':
        return this.organizationTypeApi
          .create(this._dirtyOrganizationType)
          .pipe(
            tap(() => {
              this._dirtyOrganizationType = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      case 'update':
        return this.organizationTypeApi
          .update(
            organizationTypeCodeToUpdate as string,
            this._dirtyOrganizationType,
          )
          .pipe(
            tap(() => {
              this._dirtyOrganizationType = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      default:
        return throwError(() => new Error(''));
    }
  }

  setDirty(isDirty = true) {
    this._isDirty = isDirty;
  }

  setDirtyOrganizationType(organizationType: EditOrganizationTypeDto) {
    this._dirtyOrganizationType = {
      ...this._dirtyOrganizationType,
      ...organizationType,
    };
  }

  setOrganizationType(
    editAction: 'create' | 'update',
    organizationType?: OrganizationTypeDto,
    dirtyOrganizationType?: EditOrganizationTypeDto,
  ) {
    this._editAction = editAction;

    if (
      editAction === 'create' ||
      !organizationType ||
      !dirtyOrganizationType
    ) {
      this._dirtyOrganizationType = undefined;
      this._organizationType = undefined;
    } else {
      this._dirtyOrganizationType = dirtyOrganizationType;
      this._organizationType = organizationType;
    }

    this.uiReadySubject.next(true);
  }
}
