<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex justify-content-between">
        <h4 class="font-bold mb-6">
          <ng-container
            *ngIf="programsEditService.editAction === 'create'; else update"
            >Création programme</ng-container
          >
          <ng-template #update>Mise à jour programme</ng-template>

          <ng-container *ngIf="programsEditService.program">
            <div
              class="inline-block mx-2 text-blue-600"
              [ngClass]="{
                'line-through':
                  programsEditService.program &&
                  programsEditService.dirtyProgram &&
                  programsEditService.dirtyProgram.nameFr !==
                    programsEditService.program.nameFr
              }"
            >
              {{ programsEditService.program.nameFr }}
            </div>
          </ng-container>

          <div
            class="inline-block mx-2 text-green-600"
            [ngClass]="{
              'font-bold':
                programsEditService.program &&
                programsEditService.dirtyProgram &&
                programsEditService.dirtyProgram.nameFr !==
                  programsEditService.program.nameFr
            }"
            *ngIf="
              programsEditService.dirtyProgram &&
              (!programsEditService.program ||
                programsEditService.dirtyProgram.nameFr !==
                  programsEditService.program.nameFr)
            "
          >
            {{ programsEditService.dirtyProgram.nameFr }}
          </div>
        </h4>

        <div
          *ngIf="
            programsEditService.dirtyProgram &&
            programsEditService.dirtyProgram?.type
          "
        >
          <p-tag
            [value]="
              programsEditService.dirtyProgram.type === 'goodDeal'
                ? 'Bon plan'
                : programsEditService.dirtyProgram.type === 'pro'
                ? 'Pro'
                : 'Recyclage'
            "
            [severity]="
              getTypeTagSeverity(programsEditService.dirtyProgram.type)
            "
          ></p-tag>

          <p-tag
            class="ml-2"
            [value]="programsEditService.dirtyProgram.organizationType.nameFr"
            severity="info"
            *ngIf="
              programsEditService.dirtyProgram.type === 'pro' &&
              programsEditService.dirtyProgram.organizationType &&
              programsEditService.dirtyProgram.organizationType.nameFr
            "
          ></p-tag>

          <p-tag
            class="ml-2"
            [value]="
              'Collecte ' +
              (programsEditService.dirtyProgram.collectionType === 'realTime'
                ? 'temps réel'
                : 'différée')
            "
            [severity]="
              getCollectionTypeTagSeverity(
                programsEditService.dirtyProgram.collectionType
              )
            "
            *ngIf="
              programsEditService.dirtyProgram.type === 'recycling' &&
              programsEditService.dirtyProgram.collectionType
            "
          ></p-tag>
        </div>
      </div>

      <p-toast></p-toast>

      <p-steps [model]="steps" [readonly]="true"></p-steps>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
