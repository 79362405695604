import { Component, Input } from '@angular/core';
import {
  mdiAlert,
  mdiBellOff,
  mdiBellOffOutline,
  mdiCarHatchback,
  mdiCloud,
  mdiDeleteVariant,
  mdiEmoticonHappy,
  mdiEmoticonNeutral,
  mdiEmoticonSad,
  mdiEmoticonWink,
  mdiEmoticonWinkOutline,
  mdiGift,
  mdiGiftOff,
  mdiGiftOutline,
  mdiHistory,
  mdiLeafOff,
  mdiLightbulbOn,
  mdiListBoxOutline,
  mdiLockClock,
  mdiMapMarkerOffOutline,
  mdiPowerPlug,
  mdiQrcodeScan,
  mdiRecycle,
  mdiStarShooting,
  mdiWeightGram,
  mdiWifiOff,
} from '@mdi/js';

export type beGreenPathsImportedFromMDI =
  | 'mdiAlert'
  | 'mdiBellOff'
  | 'mdiBellOffOutline'
  | 'mdiCarHatchback'
  | 'mdiCloud'
  | 'mdiDeleteVariant'
  | 'mdiEmoticonHappy'
  | 'mdiEmoticonNeutral'
  | 'mdiEmoticonSad'
  | 'mdiEmoticonWink'
  | 'mdiEmoticonWinkOutline'
  | 'mdiGift'
  | 'mdiGiftOff'
  | 'mdiGiftOutline'
  | 'mdiHistory'
  | 'mdiLeafOff'
  | 'mdiLightbulbOn'
  | 'mdiListBoxOutline'
  | 'mdiLockClock'
  | 'mdiMapMarkerOffOutline'
  | 'mdiPowerPlug'
  | 'mdiQrcodeScan'
  | 'mdiRecycle'
  | 'mdiStarShooting'
  | 'mdiWeightGram'
  | 'mdiWifiOff';

@Component({
  selector: 'be-green--ui--mdi',

  styles: [
    `
      .fill-current {
        fill: currentColor;
      }

      .mr-2 {
        margin-right: 0.5rem; /* 8px */
      }

      .h-4 {
        height: 1rem; /* 16px */
      }

      .w-4 {
        width: 1rem; /* 16px */
      }
    `,
  ],

  template: `<svg
      class="fill-current"
      *ngIf="class; else noClassSpecified"
      [ngClass]="class"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        *ngIf="path && mdiIcons[path]; else noPathSpecified"
        [attr.d]="mdiIcons[path]"
      />

      <ng-template #noPathSpecified>
        <path [attr.d]="mdiIcons['mdiAlert']" style="fill: red;" />
      </ng-template>
    </svg>

    <ng-template #noClassSpecified>
      <svg
        class="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          *ngIf="path && mdiIcons[path]; else noPathSpecified"
          [attr.d]="mdiIcons[path]"
        />

        <ng-template #noPathSpecified>
          <path [attr.d]="mdiIcons['mdiAlert']" style="fill: red;" />
        </ng-template>
      </svg>
    </ng-template>`,
})
export class MaterialDesignIconComponent {
  @Input() class?: string;
  @Input() path?: beGreenPathsImportedFromMDI;

  mdiIcons = {
    mdiAlert,
    mdiBellOff,
    mdiBellOffOutline,
    mdiCarHatchback,
    mdiCloud,
    mdiDeleteVariant,
    mdiEmoticonHappy,
    mdiEmoticonNeutral,
    mdiEmoticonSad,
    mdiEmoticonWink,
    mdiEmoticonWinkOutline,
    mdiGift,
    mdiGiftOff,
    mdiGiftOutline,
    mdiHistory,
    mdiLeafOff,
    mdiLightbulbOn,
    mdiListBoxOutline,
    mdiLockClock,
    mdiMapMarkerOffOutline,
    mdiPowerPlug,
    mdiQrcodeScan,
    mdiRecycle,
    mdiStarShooting,
    mdiWeightGram,
    mdiWifiOff,
  };
}
