import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  HandlerApi,
  HistoryApi,
  ParticipationApi,
} from '@be-green/api-services';
import {
  BasketNotificationDto,
  CollectionType,
  HandlerDto,
  HistoryDto,
  NotificationContext,
  ParticipationDto,
  PersonDto,
  ProgramType,
  RedemptionNotificationDto,
} from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--handlers-view',
  templateUrl: './handlers-view.component.html',
  styleUrls: ['./handlers-view.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('toggleCardContents', [
      state(
        'true',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class HandlersViewComponent implements OnInit {
  handler?: HandlerDto;
  history!: HistoryDto[];
  isHistoryCardOpen = true;
  isLoading = false;
  isParticipationCardOpen = true;
  isResetPasswordDialogVisible = false;
  participations!: ParticipationDto[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly handlerApi: HandlerApi,
    private readonly historyApi: HistoryApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly participationApi: ParticipationApi,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Détaillants' }]);
    this.seoService.setTitle('Détaillants - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.handlerApi.getOne(code).subscribe({
      next: (handler) => {
        this.handler = handler;

        if (handler.person) {
          this.historyApi
            .getAllOfUser((handler.person as PersonDto).user.code)
            .subscribe({
              next: (history) => {
                const contextOrder: { [key: string]: number } = {
                  [NotificationContext.Gifts]: 1,
                  [NotificationContext.Collections]: 2,
                  [NotificationContext.Points]: 3,
                  [NotificationContext.Baskets]: 4,
                };

                history.sort((a, b) => {
                  if ((a.createdAt as Date) > (b.createdAt as Date)) {
                    return -1; // reverse chronological order
                  } else if ((a.createdAt as Date) < (b.createdAt as Date)) {
                    return 1;
                  } else {
                    return contextOrder[a.context] - contextOrder[b.context];
                  }
                });

                this.history = history;
              },

              error: (error: HttpErrorResponse) =>
                this.errorService.handleError(error),
            });

          this.participationApi
            .getAllOfUser((handler.person as PersonDto).user.code)
            .subscribe({
              next: (participations) => {
                this.participations = participations;
              },

              error: (error: HttpErrorResponse) =>
                this.errorService.handleError(error),
            });
        } else {
          this.history = [];
          this.participations = [];
        }

        this.layoutService.registerBreadcrumbs([
          { label: 'Détaillants', routerLink: '/handlers' },
          { label: `${this.handler.nameFr} [${this.handler.code}]` },
        ]);

        this.seoService.setTitle(
          `${this.handler.nameFr} [${this.handler.code}] - Détaillants - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  asBasketNotificationDto(
    data: BasketNotificationDto | RedemptionNotificationDto,
  ): BasketNotificationDto {
    return data as BasketNotificationDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asHistoryDto(history: any): HistoryDto {
    return history as HistoryDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asParticipationDto(participation: any): ParticipationDto {
    return participation as ParticipationDto;
  }

  asRedemptionNotificationDto(
    data: BasketNotificationDto | RedemptionNotificationDto,
  ): RedemptionNotificationDto {
    return data as RedemptionNotificationDto;
  }

  asRedemptionUsedPoints(usedPoints: string): number {
    return parseInt(usedPoints, 10);
  }

  confirmResetPassword() {
    if (!this.handler || !this.handler.phone) {
      return;
    }

    this.isLoading = true;

    this.handlerApi
      .resetPassword(this.handler.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.messageService.add({
            detail: `Mot de passe réinitialisé. Il est maintenant égal au numéro de téléphone`,
            severity: 'success',
            summary: 'Succès',
          });

          this.isResetPasswordDialogVisible = false;
          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            severity: 'error',
            summary: title,
          });

          this.isResetPasswordDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'danger' | 'info' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getProgramTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  resetPassword() {
    if (!this.handler || !this.handler.phone) {
      return;
    }

    this.isLoading = false;
    this.isResetPasswordDialogVisible = true;
  }
}
