import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramHandlerLocalizedDto } from '../handlers';
import { QualityDto } from '../qualities';
import { UserDto } from '../users';
import { BasketProductLocalizedDto } from './basket-product-localized.dto';
import { BasketStatusDto } from './basket-status.dto';
import { ParticipationLocalizedDto } from './participation-localized.dto';

export class BasketLocalizedDto {
  @IsNotEmpty()
  readonly code!: string;

  @ValidateNested()
  @Type(() => ParticipationLocalizedDto)
  @IsNotEmpty()
  readonly participation!: ParticipationLocalizedDto;

  @ValidateNested()
  @Type(() => ProgramHandlerLocalizedDto)
  @IsOptional()
  readonly programHandler?: ProgramHandlerLocalizedDto;

  @ValidateNested()
  @Type(() => BasketLocalizedDto)
  @IsOptional()
  readonly collectorBasket?: BasketLocalizedDto;

  @IsOptional()
  readonly afterPhotoUrl?: string;

  @IsOptional()
  readonly beforePhotoUrl?: string;

  @ValidateNested()
  @Type(() => QualityDto)
  @IsOptional()
  readonly quality?: QualityDto;

  @ValidateNested()
  @Type(() => BasketStatusDto)
  @IsOptional()
  readonly status?: BasketStatusDto;

  @IsOptional()
  readonly declaredQuantity?: number;

  @IsOptional()
  readonly declaredWeight?: number;

  @IsOptional()
  readonly expectedPoints?: number;

  @IsOptional()
  readonly validatedPoints?: number;

  @IsOptional()
  readonly validatedQuantity?: number;

  @IsOptional()
  readonly validatedWeight?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;

  basketProducts?: BasketProductLocalizedDto[];
  localNotificationId?: number;
}
