import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterialApi } from '@be-green/api-services';
import { EditMaterialDto } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../layout/layout.service';
import { MaterialsEditService } from './materials-edit.service';

@Component({
  selector: 'be-green--admin--materials-edit',
  templateUrl: './materials-edit.component.html',
  styleUrls: ['./materials-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsEditComponent implements OnInit, ComponentCanDeactivate {
  isLoading = false;
  steps: MenuItem[] = [
    { label: 'Général', routerLink: 'general' },
    { label: 'Illustration', routerLink: 'illustration' },
    { label: 'Impact énergie', routerLink: 'energy-impact' },
    { label: 'Impact CO2', routerLink: 'co2-impact' },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly materialApi: MaterialApi,
    readonly materialsEditService: MaterialsEditService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Matériaux' }]);
    this.seoService.setTitle('Matériaux - Admin - Be Green');
  }

  ngOnInit(): void {
    this.materialsEditService.init();

    const code = this.activatedRoute.snapshot.paramMap.get('code');

    if (code) {
      this.isLoading = true;

      this.materialApi.getOne(code).subscribe({
        next: (material) => {
          this.materialsEditService.setMaterial(
            'update',
            material,
            material as EditMaterialDto,
          );

          if (this.materialsEditService.material) {
            this.layoutService.registerBreadcrumbs([
              { label: 'Matériaux', routerLink: '/materials' },
              {
                label: this.materialsEditService.material.nameFr,
                routerLink: `/materials/${code}`,
              },
            ]);

            this.seoService.setTitle(
              `${this.materialsEditService.material.nameFr}  - Matériaux - Admin - Be Green`,
            );
          }
          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
    } else {
      this.materialsEditService.setMaterial('create');
    }
  }

  isDirty() {
    return this.materialsEditService.isDirty;
  }
}
