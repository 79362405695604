<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12 xl:col-7">
    <div class="card">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="basket; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="mb-2">
            <p-tag
              [value]="
                basket.participation.program.type === 'goodDeal'
                  ? 'Bon plan'
                  : basket.participation.program.type === 'pro'
                  ? 'Pro'
                  : 'Recyclage'
              "
              [severity]="
                getProgramTypeTagSeverity(basket.participation.program.type)
              "
            ></p-tag>

            <p-tag
              class="ml-2"
              [value]="basket.participation.program.organizationType.nameFr"
              severity="info"
              *ngIf="
                basket.participation.program.type === 'pro' &&
                basket.participation.program.organizationType
              "
            ></p-tag>
          </div>

          <div class="flex flex-wrap align-items-center">
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                height="49.5"
                [preview]="true"
                [src]="imageBaseUrl + basket.participation.program.imageUrl"
                *ngIf="basket.participation.program.imageUrl; else noPicture"
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="basket.participation.program.nameFr.charAt(0)"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">
              <a
                [routerLink]="'/programs/' + basket.participation.program.code"
              >
                {{ basket.participation.program.nameFr }}
                <i class="pi pi-external-link"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="basket; else loadingSkeleton">
      <div class="card" *ngIf="basket.quality">
        <div class="flex align-items-center justify-content-between mb-3">
          <h3 class="text-2xl font-bold mb-0">Qualité de la collecte</h3>
        </div>

        <div class="mt-3 lg:mt-0 flex justify-content-center gap-4">
          <div
            class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
          >
            <be-green--ui--mdi-emoticon
              [type]="basket.quality.code"
            ></be-green--ui--mdi-emoticon>

            <div class="font-bold text-3xl text-900">
              {{ basket.quality.points }} points
            </div>
          </div>

          <div
            class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
          >
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                height="62"
                [preview]="true"
                [src]="
                  imageBaseUrl +
                  basket.beforePhotoUrl.replace('__BASKET_CODE__', basket.code)
                "
                *ngIf="basket.beforePhotoUrl; else noPicture"
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="'∅'"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">Avant</div>
          </div>

          <div
            class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
          >
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                height="62"
                [preview]="true"
                [src]="
                  imageBaseUrl +
                  basket.afterPhotoUrl.replace('__BASKET_CODE__', basket.code)
                "
                *ngIf="basket.afterPhotoUrl; else noPicture"
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="'∅'"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">Après</div>
          </div>
        </div>
      </div>

      <div
        class="card"
        *ngIf="
          basket.participation.program.type === 'pro' &&
          basket.collectorBasket &&
          basket.collectorBasket.quality
        "
      >
        <div class="flex align-items-center justify-content-between mb-3">
          <h3 class="text-2xl font-bold mb-0">QR code de regroupement</h3>
        </div>

        <div class="mt-3 lg:mt-0 flex justify-content-center gap-4">
          <div
            class="flex flex-column align-items-center font-medium gap-3 pb-4"
          >
            <be-green--ui--mdi-emoticon
              [type]="basket.collectorBasket.quality.code"
            ></be-green--ui--mdi-emoticon>

            <div class="font-bold text-xl text-900">
              {{ basket.collectorBasket.quality.ratio }}%
            </div>
          </div>

          <div
            class="flex flex-column align-items-center font-medium gap-3 pb-4"
          >
            <i class="pi pi-qrcode p-2" style="font-size: 3.5rem"></i>

            <a
              [routerLink]="'/baskets/' + basket.collectorBasket.code"
              target="_blank"
            >
              <i class="pi pi-external-link"></i>
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="card">
      <div
        class="flex align-items-center justify-content-between mb-3"
        *ngIf="basket; else loadingSkeleton"
      >
        <h3 class="text-2xl font-bold mb-0">
          {{ basket.basketProducts?.length }}
          produits
        </h3>
      </div>

      <p-table
        styleClass="p-datatable-gridlines"
        [currentPageReportTemplate]="
          'Enregistrements {first} à {last} sur un total de {totalRecords}'
        "
        [dataKey]="'code'"
        [loading]="isLoading"
        [paginator]="true"
        [responsiveLayout]="'scroll'"
        [rowHover]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true"
        [value]="basket.basketProducts"
        *ngIf="
          basket && basket.basketProducts !== undefined;
          else loadingSkeleton
        "
      >
        <ng-template pTemplate="header">
          <tr>
            <th></th>

            <th pSortableColumn="nameFr">
              <div class="flex justify-content-between align-items-center">
                Nom

                <p-sortIcon field="nameFr"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="declaredQuantity">
              <div class="flex justify-content-between align-items-center">
                Déclaré

                <p-sortIcon field="declaredQuantity"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="validatedQuantity">
              <div class="flex justify-content-between align-items-center">
                Validé

                <p-sortIcon field="validatedQuantity"></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-basketProduct>
          <ng-container
            *ngLet="asBasketProductDto(basketProduct) as basketProduct"
          >
            <tr *ngIf="basketProduct">
              <td style="text-align: center">
                <div class="flex justify-content-center" style="line-height: 0">
                  <p-image
                    class="shadow-4"
                    [preview]="true"
                    [src]="
                      imageBaseUrl +
                      basketProduct.product.imageUrl.replace(
                        '__PROGRAM_CODE__',
                        basket.participation.program.code
                      )
                    "
                    width="100"
                  ></p-image>
                </div>
              </td>

              <td>
                {{ basketProduct.product.nameFr }}
              </td>

              <td class="text-center">
                <div class="mt-3 lg:mt-0 flex justify-content-center gap-4">
                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-star text-yellow-500"></i>
                    </div>

                    <span
                      [ngClass]="{
                        'line-through':
                          (basketProduct.validatedPoints !== null &&
                            basketProduct.declaredPoints !==
                              basketProduct.validatedPoints) ||
                          (basketProduct.validatedQuantity !== null &&
                            basketProduct.declaredQuantity !==
                              basketProduct.validatedQuantity)
                      }"
                    >
                      {{
                        basketProduct.declaredPoints ??
                          basketProduct.declaredQuantity *
                            basketProduct.product.mobilePoints
                          | number : '1.0-0' : 'fr'
                      }}
                    </span>
                  </div>

                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-green-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-inbox text-green-500"></i>
                    </div>

                    <span
                      [ngClass]="{
                        'line-through':
                          basketProduct.validatedQuantity !== null &&
                          basketProduct.declaredQuantity !==
                            basketProduct.validatedQuantity
                      }"
                    >
                      {{
                        basketProduct.declaredQuantity | number : '1.0-0' : 'fr'
                      }}
                    </span>
                  </div>

                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-orange-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-map-marker text-orange-500"></i>
                    </div>

                    <span
                      [ngClass]="{
                        'line-through':
                          (basketProduct.validatedWeight !== null &&
                            basketProduct.declaredWeight !==
                              basketProduct.validatedWeight) ||
                          (basketProduct.validatedQuantity !== null &&
                            basketProduct.declaredQuantity !==
                              basketProduct.validatedQuantity)
                      }"
                    >
                      {{
                        basketProduct.declaredWeight ??
                          basketProduct.declaredQuantity *
                            basketProduct.product.weightInG
                          | number : '1.0-0' : 'fr'
                      }}
                      g
                    </span>
                  </div>
                </div>
              </td>

              <td class="text-center">
                <div
                  class="mt-3 lg:mt-0 flex justify-content-center gap-4"
                  *ngIf="
                    basketProduct.validatedQuantity !== null &&
                      basketProduct.validatedQuantity !== undefined;
                    else notSet
                  "
                >
                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-yellow-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-star text-yellow-500"></i>
                    </div>

                    <p-tag
                      [value]="
                        (basketProduct.validatedPoints ??
                          (basket.participation.person.handler &&
                          (basket.participation.program.type !== 'pro' ||
                            basket.participation.person.handler.level > 1) &&
                          basketProduct.validatedQuantity <
                            basketProduct.declaredQuantity
                            ? (2 * basketProduct.validatedQuantity -
                                basketProduct.declaredQuantity) *
                              basketProduct.product.mobilePoints
                            : basketProduct.validatedQuantity *
                              basketProduct.product.mobilePoints *
                              (basket.collectorBasket &&
                              basket.collectorBasket.quality
                                ? basket.collectorBasket.quality.ratio / 100
                                : 1)) | number : '1.0-0' : 'fr') || '0'
                      "
                      severity="success"
                    ></p-tag>
                  </div>

                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-green-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-inbox text-green-500"></i>
                    </div>

                    <p-tag
                      [value]="
                        (basketProduct.validatedQuantity
                          | number : '1.0-0' : 'fr') || '0'
                      "
                      severity="success"
                    ></p-tag>
                  </div>

                  <div
                    class="flex flex-column align-items-center font-medium gap-3"
                  >
                    <div
                      class="flex align-items-center justify-content-center bg-orange-100 border-round"
                      style="width: 2rem; height: 2rem"
                    >
                      <i class="pi pi-map-marker text-orange-500"></i>
                    </div>

                    <p-tag
                      [value]="
                        (basketProduct.validatedWeight ??
                          basketProduct.validatedQuantity *
                            basketProduct.product.weightInG
                          | number : '1.0-0' : 'fr') + ' g'
                      "
                      severity="success"
                    ></p-tag>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>

    <div class="card" *ngIf="includedBaskets">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">
          {{ includedBaskets.length }}
          QR codes inclus
        </h3>
      </div>

      <p-table
        styleClass="p-datatable-gridlines"
        [currentPageReportTemplate]="
          'Enregistrements {first} à {last} sur un total de {totalRecords}'
        "
        [dataKey]="'code'"
        [loading]="isLoading"
        [paginator]="true"
        [responsiveLayout]="'scroll'"
        [rowHover]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [scrollable]="true"
        [showCurrentPageReport]="true"
        [value]="includedBaskets"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="participation.person.firstName, participation.person.lastName"
              pFrozenColumn
            >
              <div class="flex justify-content-between align-items-center">
                Participant

                <p-sortIcon field="participation.person.firstName"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="status.code">
              <div class="flex justify-content-between align-items-center">
                État

                <p-sortIcon field="status.code"></p-sortIcon>
              </div>
            </th>

            <th style="min-width: 16rem" pSortableColumn="updated_at">
              <div class="flex justify-content-between align-items-center">
                Date état

                <p-sortIcon field="updated_at"></p-sortIcon>
              </div>
            </th>

            <th style="min-width: 15rem" pSortableColumn="expectedPoints">
              <div class="flex justify-content-between align-items-center">
                ⭐ attendues

                <p-sortIcon field="expectedPoints"></p-sortIcon>
              </div>
            </th>

            <th style="min-width: 15rem" pSortableColumn="validatedPoints">
              <div class="flex justify-content-between align-items-center">
                ⭐ confirmées

                <p-sortIcon field="validatedPoints"></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-includedBasket>
          <ng-container *ngLet="asBasketDto(includedBasket) as includedBasket">
            <tr *ngIf="includedBasket">
              <td pFrozenColumn>
                {{ includedBasket.participation.person.firstName }}
                {{ includedBasket.participation.person.lastName }}
              </td>

              <td>
                <p-tag
                  [value]="includedBasket.status.name"
                  [severity]="getStatusTagSeverity(includedBasket.status.code)"
                  *ngIf="includedBasket.status"
                ></p-tag>
              </td>

              <td>
                <a
                  [routerLink]="'/baskets/' + includedBasket.code"
                  target="_blank"
                >
                  {{
                    includedBasket.updatedAt
                      | date : 'medium' : undefined : 'fr'
                  }}

                  <i class="pi pi-external-link"></i>
                </a>
              </td>

              <td class="text-right">
                {{ includedBasket.expectedPoints | number : '1.0-0' : 'fr' }}
              </td>

              <td class="text-right">
                <ng-container
                  *ngIf="includedBasket.validatedPoints !== null; else notSet"
                >
                  <p-tag
                    [value]="
                      (includedBasket.validatedPoints
                        | number : '1.0-0' : 'fr') || '0'
                    "
                    severity="success"
                  ></p-tag>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="col-12 xl:col-5" *ngIf="basket; else loadingSkeleton">
    <div class="card">
      <div
        class="flex justify-content-between align-items-center text-700 flex-wrap text-xl"
      >
        <div class="mr-5 flex align-items-center my-3">
          <i class="pi pi-user mr-2"></i>

          <a
            [routerLink]="
              '/handlers/' + basket.participation.person.handler.code
            "
            *ngIf="basket.participation.person.handler; else userLink"
          >
            {{ basket.participation.person.firstName }}
            {{ basket.participation.person.lastName }}
            <i class="pi pi-external-link"></i>
          </a>

          <ng-template #userLink>
            <a
              [routerLink]="'/users/' + basket.createdBy.code"
              *ngIf="basket.createdBy"
            >
              {{ basket.participation.person.firstName }}
              {{ basket.participation.person.lastName }}
              <i class="pi pi-external-link"></i>
            </a>
          </ng-template>
        </div>

        <div class="mr-5 flex align-items-center my-3">
          <i class="pi pi-map-marker mr-2"></i>

          <a
            [routerLink]="'/handlers/' + basket.programHandler.handler.code"
            *ngIf="basket.programHandler; else notSet"
          >
            {{ basket.programHandler.handler.nameFr }}
            <i class="pi pi-external-link"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="card">
      <qrcode
        [cssClass]="'text-center tlb--qr-code'"
        [errorCorrectionLevel]="'M'"
        [imageSrc]="'./assets/images/ui/logo-positive-213x213.png'"
        [imageHeight]="75"
        [imageWidth]="75"
        [qrdata]="basket.code"
        [width]="352"
      ></qrcode>

      <div
        class="my-4 lg:mt-0 flex justify-content-center align-items-center gap-2"
      >
        <p-tag
          [value]="basket.status.name"
          [severity]="getStatusTagSeverity(basket.status.code)"
          *ngIf="basket.status"
        ></p-tag>

        {{ basket.updatedAt | date : 'long' : '' : 'fr' }}
      </div>

      <div class="mt-3 lg:mt-0 flex justify-content-center gap-4">
        <div
          class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
        >
          <div
            class="flex align-items-center justify-content-center bg-yellow-100 border-round"
            style="width: 4rem; height: 4rem"
          >
            <i class="pi pi-star text-yellow-500 text-3xl"></i>
          </div>

          <span
            [ngClass]="{
              'line-through':
                basket.validatedPoints !== null &&
                basket.expectedPoints !== basket.validatedPoints
            }"
          >
            {{ basket.expectedPoints || 0 | number : '1.0-0' : 'fr' }}
          </span>

          <p-tag
            severity="success"
            [style]="{ transform: 'scale(1.5)' }"
            [value]="(basket.validatedPoints | number : '1.0-0' : 'fr') || '0'"
            *ngIf="basket.validatedPoints !== null"
          ></p-tag>
        </div>

        <div
          class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
        >
          <div
            class="flex align-items-center justify-content-center bg-green-100 border-round"
            style="width: 4rem; height: 4rem"
          >
            <i class="pi pi-inbox text-green-500 text-3xl"></i>
          </div>

          <span
            [ngClass]="{
              'line-through':
                basket.validatedQuantity !== null &&
                basket.declaredQuantity !== basket.validatedQuantity
            }"
          >
            {{ basket.declaredQuantity || 0 | number : '1.0-0' : 'fr' }}
          </span>

          <p-tag
            severity="success"
            [style]="{ transform: 'scale(1.5)' }"
            [value]="
              (basket.validatedQuantity | number : '1.0-0' : 'fr') || '0'
            "
            *ngIf="basket.validatedQuantity !== null"
          ></p-tag>
        </div>

        <div
          class="flex flex-column align-items-center text-3xl font-medium gap-3 pb-4"
        >
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 4rem; height: 4rem"
          >
            <i class="pi pi-map-marker text-orange-500 text-3xl"></i>
          </div>

          <span
            [ngClass]="{
              'line-through':
                basket.validatedWeight !== null &&
                basket.declaredWeight !== basket.validatedWeight
            }"
          >
            {{ basket.declaredWeight || 0 | number : '1.0-0' : 'fr' }} g
          </span>

          <p-tag
            severity="success"
            [style]="{ transform: 'scale(1.5)' }"
            [value]="(basket.validatedWeight | number : '1.0-0' : 'fr') + ' g'"
            *ngIf="basket.validatedWeight !== null"
          ></p-tag>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
