import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuillModules } from 'ngx-quill';
import { ArticlesEditService } from '../../articles-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--articles-edit-steps-content',
  templateUrl: './articles-edit-steps-content.component.html',
  styleUrls: ['./articles-edit-steps-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesEditStepsContentComponent implements OnInit {
  currentStepForm!: FormGroup;
  contentAr?: string;
  contentFr?: string;
  isLoading = false;
  quillModules: QuillModules = {
    clipboard: { matchVisual: false },
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly articlesEditService: ArticlesEditService,
  ) {}

  ngOnInit(): void {
    if (!this.articlesEditService.dirtyArticle) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.articlesEditService.currentStep === 3) {
      this.articlesEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());

      if (this.articlesEditService.editAction === 'update') {
        this.contentAr = this.articlesEditService.article?.contentAr;
        this.contentFr = this.articlesEditService.article?.contentFr;
      }
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      contentAr: AbstractControl;
      contentFr: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      contentAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(65536)]),
      ],
      contentFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(65536)]),
      ],
    });

    if (this.articlesEditService.dirtyArticle) {
      this.currentStepForm.patchValue(this.articlesEditService.dirtyArticle);
    }
  }

  nextStep() {
    this.currentStepFormControls.contentAr.setValue(this.contentAr);
    this.currentStepFormControls.contentFr.setValue(this.contentFr);

    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.articlesEditService.setDirtyArticle(this.currentStepForm.value);

    this.articlesEditService.nextStep();

    this.router.navigate(['settings'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  previousStep() {
    this.articlesEditService.previousStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
