import { Type } from 'class-transformer';
import {
  IsDecimal,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { ProgramDto } from '../programs';
import { PersonDto, UserDto } from '../users';

export type WeekdayInFrench =
  | 'LUNDI'
  | 'MARDI'
  | 'MERCREDI'
  | 'JEUDI'
  | 'VENDREDI'
  | 'SAMEDI'
  | 'DIMANCHE';

export class HandlerDto {
  @IsString()
  readonly code!: string;

  @IsInt()
  readonly level!: number;

  @IsOptional()
  @IsString()
  readonly phone?: string;

  @IsNotEmpty()
  @IsString()
  readonly nameFr!: string;

  @IsOptional()
  @IsString()
  readonly nameAr?: string;

  @IsOptional()
  @IsDecimal()
  readonly latitude?: number;

  @IsOptional()
  @IsDecimal()
  readonly longitude?: number;

  @IsOptional()
  @IsString()
  readonly photoUrl?: string;

  @IsOptional()
  readonly isProcessingPhotoUrl?: boolean;

  @IsOptional()
  @IsString()
  readonly clonedPhotoUrl?: string;

  @IsOptional()
  @IsString()
  readonly addressFr?: string;

  @IsOptional()
  @IsString()
  readonly addressAr?: string;

  @IsOptional()
  readonly usualVisitDays?: Date[];

  @IsOptional()
  @IsString()
  readonly nextVisitDay?: Date;

  @IsOptional()
  readonly itinerary?: { code: string; hasBeenVisited?: boolean }[];

  currentPeriodApprovedBasketCount?: number;
  currentRank?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;

  @IsOptional()
  readonly initial?: string;

  @IsOptional()
  readonly initials?: string;

  @IsOptional()
  readonly nameToHslColors?: { background: string; contrast: string };

  hasBeenVisited?: boolean;
  person?: PersonDto;
  programs?: ProgramDto[];
}
