import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryApi, TipApi } from '@be-green/api-services';
import { CategoryDto, TipDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  base64ToFile,
} from 'ngx-image-cropper';
import { MenuItem, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import { first, share } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--tips-view',
  templateUrl: './tips-view.component.html',
  styleUrls: ['./tips-view.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('toggleCardContents', [
      state(
        'true',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class TipsViewComponent implements OnInit, OnDestroy {
  action?: 'delete';
  actionDefaultStyleClass = 'p-button-warning';
  actionMenuItems: MenuItem[] = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      styleClass: 'p-menuitem-warning',
      command: () => {
        this.edit();
      },
    },

    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      styleClass: 'p-menuitem-danger',
      command: () => {
        this.delete();
      },
    },
  ];
  body = this.document.querySelector('body') as HTMLBodyElement;
  category?: CategoryDto;
  confirmationDialogMessage = '';
  isConfirmDialogVisible = false;
  isDescriptionArCardOpen = true;
  isDescriptionFrCardOpen = true;
  isGeneralCardOpen = true;
  isLoading = false;
  scrollDiv = (this.document.scrollingElement ||
    this.document.documentElement) as HTMLElement;
  scrollPosition = 0;

  /**
   * ImageCropper
   */
  @ViewChild('fileUploader') fileUploader?: FileUpload;
  @ViewChild('imageCropper') imageCropper?: ImageCropperComponent;
  croppedFiles?: File[];
  croppedImageSrc = '';
  imageFilename?: string;
  imageRotation = 0;
  imageScale = 1;
  imageScaleMax = 2;
  imageScaleMin = 0.5;
  imageTransforms: ImageTransform = {};
  isCropperDialogVisible = false;
  isCropperReady = false;
  selectedImageData = '';
  /* end of ImageCropper */

  /**
   * Tips
   */
  @ViewChild('filterTipIndexDataTable')
  filterTipIndexDataTable!: ElementRef;
  @ViewChild('tipIndexDataTable') tipIndexDataTable!: Table;

  isDeleteTipDialogVisible = false;
  isTipDialogVisible = false;
  isTipsCardMaximized = false;
  isTipsCardOpen = true;
  tipDialogHeader = '';
  tipForm!: FormGroup;
  tipIllustrationUploadUrl = `/tips/upload/illustration`;
  tip: TipDto | null = null;
  tips!: TipDto[];
  tipsCard?: HTMLDivElement;
  /* end of Tips */

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly activatedRoute: ActivatedRoute,
    private readonly categoryApi: CategoryApi,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly seoService: SeoService,
    private readonly tipApi: TipApi,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Astuces écolos' }]);
    this.seoService.setTitle('Astuces écolos - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.categoryApi.getOne(code).subscribe({
      next: (category) => {
        this.category = category;

        if (category.tips) {
          this.tips = (category.tips as TipDto[]).map((tip) => ({
            ...tip,
            imageUrl: tip.imageUrl.replace('__CATEGORY_CODE__', code),
          }));
        } else {
          this.tips = [];
        }

        this.tipsCard = this.document.querySelector('#tips') as HTMLDivElement;

        this.layoutService.registerBreadcrumbs([
          { label: 'Astuces écolos', routerLink: '/tips' },
          { label: `${this.category.nameFr}` },
        ]);

        this.seoService.setTitle(
          `${this.category.nameFr} - Astuces écolos - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.body.classList.remove('overflow-hidden');
  }

  confirm() {
    switch (this.action) {
      case 'delete':
        this._delete();
    }
  }

  private _delete() {
    if (!this.category) {
      return;
    }

    if (this.action === 'delete') {
      this.isLoading = true;

      this.categoryApi
        .delete(this.category.code)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.router.navigate(['tips']);
          },

          error: async (error: HttpErrorResponse) => {
            await this.errorService.handleError(error);

            this.isLoading = false;
            this.isConfirmDialogVisible = false;
            this.action = undefined;
          },
        });
    }
  }

  private delete() {
    if (!this.category) {
      return;
    }

    this.action = 'delete';

    this.confirmationDialogMessage = `<p>Êtes-vous sûr de vouloir <b>supprimer</b> la catégorie <b>${this.category.nameFr}</b>?</p>`;

    this.isConfirmDialogVisible = true;
  }

  edit() {
    if (!this.category) {
      return;
    }

    this.router.navigate(['tips', this.category.code, 'update']);
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  private toggleCardSize(divElement: HTMLDivElement, isMaximized: boolean) {
    if (isMaximized) {
      this.scrollPosition = this.scrollDiv.scrollTop;
      this.scrollDiv.scrollTo(0, 0);
      this.body.classList.add('overflow-hidden');
      divElement.classList.add(
        'tlb--div-maximized-wrapper',
        'p-component-overlay',
      );
      divElement.firstElementChild?.classList.add('tlb--div-maximized');
    } else {
      this.body.classList.remove('overflow-hidden');
      this.scrollDiv.scrollTo(0, this.scrollPosition);
      divElement.classList.remove(
        'tlb--div-maximized-wrapper',
        'p-component-overlay',
      );
      divElement.firstElementChild?.parentElement?.classList.remove(
        'tlb--div-maximized',
      );
    }
  }

  /**
   * Tips
   */
  get tipFormControls() {
    return this.tipForm?.controls as {
      contentFr: AbstractControl;
      contentAr: AbstractControl;
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      imageUrl: AbstractControl;
    };
  }

  addNewTip() {
    this.isLoading = false;
    this.tip = null;

    this.initTipForm();

    this.tipDialogHeader = 'Nouvelle habitude';
    this.isTipDialogVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asTipDto(tip: any): TipDto {
    return tip as TipDto;
  }

  clearTipsTable() {
    this.tipIndexDataTable.clear();
    this.filterTipIndexDataTable.nativeElement.value = '';
  }

  confirmDeleteTip() {
    if (!this.tip) {
      return;
    }

    this.isLoading = true;

    this.tipApi
      .delete(this.tip.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.isDeleteTipDialogVisible = false;

          this.tips = this.tips.filter(
            (tip) => tip.code !== (this.tip as TipDto).code,
          );

          this.messageService.add({
            detail: 'Habitude supprimée',
            key: 'tips',
            life: 3000,
            severity: 'success',
            summary: 'Succès',
          });

          this.tip = null;

          this.isLoading = false;
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            key: 'tips',
            severity: 'error',
            summary: title,
          });

          this.tip = null;
          this.isDeleteTipDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  deleteTip(tip: TipDto) {
    this.isLoading = false;
    this.isDeleteTipDialogVisible = true;
    this.tip = { ...tip };
  }

  editTip(tip: TipDto) {
    this.isLoading = false;
    this.tip = { ...tip };

    this.initTipForm();

    this.tipForm.patchValue(tip);

    if (this.tip.imageUrl.startsWith('shared/img/tips/tmp')) {
      this.croppedImageSrc =
        this.imageBaseUrl + this.tip.imageUrl.replace('shared/', '');
    } else {
      this.croppedImageSrc = this.imageBaseUrl + this.tip.imageUrl;
    }

    this.tipDialogHeader = `Editer l‘habitude « ${tip.nameFr} »`;
    this.isTipDialogVisible = true;
  }

  private fetchTip(code: string) {
    return this.tipApi.getOne(code).pipe(first(), share());
  }

  private initTipForm() {
    this.croppedFiles = undefined;
    this.croppedImageSrc = '';
    this.imageFilename = undefined;
    this.isCropperReady = false;
    this.resetImageCropper();

    this.tipForm = this.formBuilder.group({
      contentAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(2048)]),
      ],
      contentFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(2048)]),
      ],
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(2048)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      imageUrl: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTipRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    if (!this.category) {
      return;
    }

    this.tipApi
      .reorder(
        this.category.code,
        this.tips.map((tip) => tip.code),
      )
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }

  onTipUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ url: string }>;
  }) {
    if (!this.tipForm) {
      return;
    }

    this.tipFormControls.imageUrl.setValue(event.originalEvent.body?.url);

    this.tipForm.markAllAsTouched();

    this.isLoading = false;
  }

  saveTip() {
    if (!this.category) {
      return;
    }

    this.tipForm.markAllAsTouched();

    if (this.tipForm.invalid) {
      return;
    }

    this.isLoading = true;

    const tip: TipDto = { ...this.tip, ...this.tipForm.value };

    if (this.tip) {
      this.tipApi
        .update(this.category.code, tip)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.fetchTip(tip.code).subscribe({
              next: (data) => {
                const index = this.tips.findIndex(
                  (tip) => tip.code === (this.tip as TipDto).code,
                );

                this.tips[index] = {
                  ...data,

                  imageUrl: data.imageUrl.replace(
                    '__CATEGORY_CODE__',
                    (this.category as CategoryDto).code,
                  ),
                };

                this.isTipDialogVisible = false;
                this.tip = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'tips',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Habitude mise à jour`,
              key: 'tips',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'tips',
              severity: 'error',
              summary: title,
            });
          },
        });
    } else {
      this.tipApi
        .create(this.category.code, tip)
        .pipe(first())
        .subscribe({
          next: async (value: { code: string }) => {
            this.fetchTip(value.code).subscribe({
              next: (data) => {
                this.tips = [
                  ...this.tips,

                  {
                    ...data,
                    imageUrl: data.imageUrl.replace(
                      '__CATEGORY_CODE__',
                      (this.category as CategoryDto).code,
                    ),
                  },
                ];

                this.isTipDialogVisible = false;
                this.tip = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'tips',
                  severity: 'error',
                  summary: title,
                });
              },
            });

            this.messageService.add({
              detail: `Habitude créée`,
              key: 'tips',
              life: 3000,
              severity: 'success',
              summary: 'Succès',
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'tips',
              severity: 'error',
              summary: title,
            });
          },
        });
    }
  }

  toggleTipsCardSize() {
    this.isTipsCardMaximized = !this.isTipsCardMaximized;

    this.toggleCardSize(
      this.tipsCard as HTMLDivElement,
      this.isTipsCardMaximized,
    );
  }

  /**
   * ImageCropper
   */

  cancelImageCropModal() {
    this.croppedFiles = undefined;
    this.isCropperDialogVisible = false;
    this.resetImageCropper();
  }

  changeImageScale(): void {
    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  cropAndUploadImage() {
    this.imageCropper?.crop();
  }

  private flipAfterRotate() {
    const flippedH = this.imageTransforms.flipH;
    const flippedV = this.imageTransforms.flipV;

    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipH: !this.imageTransforms.flipH,
    };
  }

  flipVertical() {
    this.imageTransforms = {
      ...this.imageTransforms,
      flipV: !this.imageTransforms.flipV,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCropperReady(event: Dimensions) {
    this.isCropperReady = true;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.isCropperDialogVisible = false;

    if (this.imageFilename) {
      this.croppedImageSrc = event.base64 as string;

      const imageFilenameWithoutExtension =
        UtilsService.getFilenameWithoutExtension(this.imageFilename);

      this.croppedFiles = [
        new File(
          [base64ToFile(this.croppedImageSrc)],
          `${imageFilenameWithoutExtension}.png`,
          { type: 'image/png' },
        ),
      ];

      this.changeDetectorRef.detectChanges();

      this.fileUploader?.upload();
    }
  }

  onLoadImageFailed() {
    this.messageService.add({
      detail: 'Impossible de charger l’image sélectionnée',
      severity: 'error',
      summary: 'Erreur inattendue',
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(event: { files: File[]; error: any; originalEvent: Event }) {
    if (event.files && event.files.length) {
      const file = event.files[0];
      this.imageFilename = file.name;

      // Create a new FileReader object
      const reader = new FileReader();

      // Set the onload event handler of the FileReader object
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Get the base64 data of the selected image file
        const base64data = (e.target as FileReader).result;

        // Do something with the base64 data, such as display it in an image element
        this.selectedImageData = base64data as string;
        this.isCropperDialogVisible = true;
        this.fileUploader?.clear();
      };

      // Read the selected image file as a data URL
      reader.readAsDataURL(file);
    }
  }

  onUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      detail: event.error.error?.message,
      severity: 'error',
      summary: event.error.error?.reason,
    });

    this.isLoading = false;
    this.isCropperDialogVisible = false;
  }

  resetImageCropper() {
    this.imageRotation = 0;
    this.imageTransforms = {};
    this.imageScale = 1;
  }

  rotateLeft() {
    this.imageRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.imageRotation++;
    this.flipAfterRotate();
  }

  zoomIn() {
    if (this.imageScale >= this.imageScaleMax) {
      return;
    }

    this.imageScale += 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }

  zoomOut() {
    if (this.imageScale <= this.imageScaleMin) {
      return;
    }

    this.imageScale -= 0.1;

    this.imageTransforms = {
      ...this.imageTransforms,
      scale: this.imageScale,
    };
  }
}
