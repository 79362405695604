import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryApi } from '@be-green/api-services';
import { EditCategoryDto } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { LayoutService } from '../../layout/layout.service';
import { CategoriesEditService } from './categories-edit.service';

@Component({
  selector: 'be-green--admin--tips-edit',
  templateUrl: './tips-edit.component.html',
  styleUrls: ['./tips-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TipsEditComponent implements OnInit, ComponentCanDeactivate {
  form!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    readonly categoriesEditService: CategoriesEditService,
    private readonly categoryApi: CategoryApi,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Astuces écolos' }]);
    this.seoService.setTitle('Astuces écolos - Admin - Be Green');
  }

  ngOnInit(): void {
    this.categoriesEditService.init();

    const code = this.activatedRoute.snapshot.paramMap.get('code');

    if (code) {
      this.isLoading = true;

      this.categoryApi.getOne(code).subscribe({
        next: (category) => {
          this.categoriesEditService.setCategory(
            'update',
            category,
            category as EditCategoryDto,
          );

          this.initForm();

          if (this.categoriesEditService.category) {
            this.layoutService.registerBreadcrumbs([
              { label: 'Astuces écolos', routerLink: '/tips' },
              {
                label: this.categoriesEditService.category.nameFr,
                routerLink: `/tips/${code}`,
              },
            ]);

            this.seoService.setTitle(
              `${this.categoriesEditService.category.nameFr}  - Astuces écolos - Admin - Be Green`,
            );
          }

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
    } else {
      this.categoriesEditService.setCategory('create');
      this.initForm();
    }
  }

  isDirty() {
    return this.categoriesEditService.isDirty;
  }

  get formControls() {
    return this.form?.controls as {
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      descriptionAr: AbstractControl;
      descriptionFr: AbstractControl;
    };
  }

  private initForm() {
    this.form = this.formBuilder.group({
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      descriptionAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      descriptionFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
    });

    if (this.categoriesEditService.dirtyCategory) {
      this.form.patchValue(this.categoriesEditService.dirtyCategory);
    }
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.categoriesEditService.setDirtyCategory(this.form.value);

    this.categoriesEditService.saveDirtyCategory().subscribe({
      next: (value: { code: string } | void) => {
        if (value) {
          this.router.navigate(['/tips', value.code]);
        } else if (
          this.categoriesEditService.category &&
          this.categoriesEditService.editAction === 'update'
        ) {
          this.router.navigate([
            '/tips',
            this.categoriesEditService.category.code,
          ]);
        } else {
          this.router.navigate(['/tips']);
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.isLoading = false;
      },
    });
  }
}
