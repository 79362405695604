import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'be-green--admin--alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertsComponent implements OnInit {
  ngOnInit(): void {
    console.dir('works');
  }
}
