import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MaterialApi } from '@be-green/api-services';
import { MaterialDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--materials-index',
  templateUrl: './materials-index.component.html',
  styleUrls: ['./materials-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsIndexComponent implements OnInit {
  @ViewChild('filterMaterialIndexDataTable')
  filterMaterialIndexDataTable!: ElementRef;
  @ViewChild('materialIndexDataTable') materialIndexDataTable!: Table;

  isLoading = false;
  materials!: MaterialDto[];

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly materialApi: MaterialApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Matériaux' }]);
    this.seoService.setTitle('Matériaux - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllMaterials();
  }

  private getAllMaterials() {
    this.materialApi.getAll().subscribe({
      next: (materials) => {
        this.materials = materials;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asMaterialDto(material: any): MaterialDto {
    return material as MaterialDto;
  }

  clearMaterialsTable() {
    this.materialIndexDataTable.clear();
    this.filterMaterialIndexDataTable.nativeElement.value = '';
  }
}
