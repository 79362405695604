import { Inject, Injectable } from '@angular/core';
import { Scope } from '@be-green/dto';
import { Capacitor } from '@capacitor/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from '../utils/utils.service';

export const enum StorageType {
  Cookie = 'cookie',
  Local = 'local',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    private readonly cookieService: CookieService,
  ) {}

  get(key: string, type = StorageType.Local): string | undefined {
    // We should reactivate cookie-JWT in prod when building with Angular Universal
    if (type === StorageType.Local || this.appScope === Scope.Mobile) {
      return <string | undefined>localStorage.getItem(key);
    } else {
      return this.cookieService.get(key);
    }
  }

  async getSecure(key: string): Promise<string | object | undefined> {
    if (Capacitor.isNativePlatform()) {
      const entry: { value: string } | undefined =
        await SecureStoragePlugin.get({
          key,
        }).catch(() => {
          // Item with specified key does not exist.

          return undefined;
        });

      return UtilsService.parseJson(entry?.value);
    } else {
      return new Promise((resolve) => {
        const value = this.get(key);

        resolve(UtilsService.parseJson(value));
      });
    }
  }

  remove(key: string, type = StorageType.Local): void {
    // We should reactivate cookie-JWT in prod when building with Angular Universal
    if (type === StorageType.Local || this.appScope === Scope.Mobile) {
      return localStorage.removeItem(key);
    } else {
      return this.cookieService.delete(key);
    }
  }

  async removeSecure(key: string): Promise<{ value: boolean }> {
    if (Capacitor.isNativePlatform()) {
      return SecureStoragePlugin.remove({ key });
    } else {
      return new Promise((resolve) => {
        this.remove(key);

        resolve({ value: true });
      });
    }
  }

  set(key: string, value: unknown, type = StorageType.Local): void {
    const stringValue =
      typeof value === 'string' ? value : JSON.stringify(value || null);

    // We should reactivate cookie-JWT in prod when building with Angular Universal
    if (type === StorageType.Local || this.appScope === Scope.Mobile) {
      return localStorage.setItem(key, stringValue);
    } else {
      return this.cookieService.set(key, stringValue);
    }
  }

  async setSecure(key: string, value: unknown): Promise<{ value: boolean }> {
    const stringValue =
      typeof value === 'string' ? value : JSON.stringify(value || null);

    if (Capacitor.isNativePlatform()) {
      return SecureStoragePlugin.set({ key, value: stringValue });
    } else {
      return new Promise((resolve) => {
        this.set(key, value);

        resolve({ value: true });
      });
    }
  }
}
