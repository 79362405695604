import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedemptionApi } from '@be-green/api-services';
import { GiftDto, RedemptionDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--redemptions-view',
  templateUrl: './redemptions-view.component.html',
  styleUrls: ['./redemptions-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedemptionsViewComponent implements OnInit {
  redemption?: RedemptionDto;
  isLoading = false;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly redemptionApi: RedemptionApi,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Échanges' }]);
    this.seoService.setTitle('Échanges - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.redemptionApi.getOneAsAdmin(code).subscribe({
      next: (redemption) => {
        this.redemption = redemption;

        this.layoutService.registerBreadcrumbs([
          { label: 'Échanges', routerLink: '/redemptions' },
          { label: this.redemption.code },
        ]);

        this.seoService.setTitle(
          `${this.redemption.code} - Échanges - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asGiftDto(gift: any): GiftDto {
    return gift as GiftDto;
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'danger' | 'success' | 'warning' | '' {
    return UtilsService.getRedemptionStatusTagSeverity(statusCode);
  }
}
