<div class="card">
  <p-table
    #accountIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="apiPageSize"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [totalRecords]="recordCount"
    [value]="accounts"
    (onLazyLoad)="fetchNextPage($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between flex-column sm:flex-row">
        <button
          class="p-button-success sm:mb-2 mr-2"
          pButton
          pRipple
          label="Créer un nouvel accès"
          icon="pi pi-plus"
          (click)="addNewUser()"
        ></button>
      </div>

      <p-messages key="users"></p-messages>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th
          style="min-width: 20rem"
          pSortableColumn="person.firstName, person.lastName"
          pFrozenColumn
        >
          <div class="flex justify-content-between align-items-center">
            Nom

            <p-sortIcon field="person.firstName, person.lastName"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="email">
          <div class="flex justify-content-between align-items-center">
            Email

            <p-sortIcon field="email"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="role">
          <div class="flex justify-content-between align-items-center">
            Rôle

            <p-sortIcon field="role"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="status.code">
          <div class="flex justify-content-between align-items-center">
            État

            <p-sortIcon field="status.code"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn>
          <p-columnFilter
            type="text"
            field="person.firstName, person.lastName"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="email"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter field="role" matchMode="equals" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="roles"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <span
                    class="tlb--status-badge"
                    [ngClass]="getRoleStyleClass(option.value)"
                  >
                    {{ option.label }}
                  </span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="status.code"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="statuses"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getStatusTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-account>
      <ng-container *ngLet="asUserDto(account) as account">
        <tr *ngIf="account">
          <td pFrozenColumn>
            <a
              [routerLink]="goToPerson(account)"
              *ngIf="
                account.person &&
                  account.roles &&
                  ((account.roles[0] === 'handler' && account.person.handler) ||
                    account.roles[0] === 'user');
                else notLinkable
              "
            >
              {{ account.person.firstName }}
              {{ account.person.lastName }}

              <i class="pi pi-external-link"></i>
            </a>

            <ng-template #notLinkable>
              {{ account.person?.firstName }}
              {{ account.person?.lastName }}
            </ng-template>
          </td>

          <td>
            {{ account.email }}
          </td>

          <td>
            <span
              class="tlb--status-badge"
              [ngClass]="getRoleStyleClass(account.roles[0])"
              *ngIf="account.roles && account.roles.length; else notSet"
            >
              {{ account.roles[0] }}
            </span>
          </td>

          <td>
            <p-tag
              [value]="account.status.name"
              [severity]="getStatusTagSeverity(account.status.code)"
              *ngIf="account.status; else notSet"
            ></p-tag>
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <div
              class="flex"
              *ngIf="
                account.roles &&
                account.roles.length &&
                ['admin', 'supervisor', 'user'].includes(account.roles[0]) &&
                account.status &&
                !['CLOSED'].includes(account.status.code)
              "
            >
              <button
                class="p-button-rounded p-button-warning mr-2"
                pButton
                pRipple
                icon="pi pi-pencil"
                (click)="editUser(account)"
                *ngIf="account.code === authenticationService.decodedToken?.sub"
              ></button>

              <ng-container
                *ngIf="account.code !== authenticationService.decodedToken?.sub"
              >
                <button
                  class="p-button-rounded p-button-info mr-2"
                  *ngIf="
                    account.status &&
                    ['ACTIVE', 'NEW'].includes(account.status.code)
                  "
                  pButton
                  pRipple
                  icon="pi pi-pause"
                  (click)="deactivateUser(account)"
                ></button>

                <button
                  class="p-button-rounded p-button-info mr-2"
                  *ngIf="account.status?.code === 'DEACTIVATED'"
                  pButton
                  pRipple
                  icon="pi pi-play"
                  (click)="reactivateUser(account)"
                ></button>

                <button
                  class="p-button-rounded p-button-danger"
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  (click)="deleteUser(account)"
                ></button>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">Aucun compte trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="4">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<p-dialog
  class="p-fluid"
  [header]="userDialogHeader"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isUserDialogVisible"
>
  <ng-template pTemplate="content">
    <form class="w-full md:w-10 mx-auto" [formGroup]="userForm">
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="email"
            >Adresse e-mail</label
          >
          <input
            class="mb-3"
            id="email"
            (keyup.enter)="saveUser()"
            formControlName="email"
            pInputText
            inputmode="email"
            style="padding: 1rem"
            maxlength="64"
          />

          <div>
            <be-green--ui--input-error
              [control]="userFormControls.email"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <div class="mb-4">
            <label class="block text-900 text-xl font-medium mb-2" for="role"
              >Rôle</label
            >

            <p-dropdown
              id="role"
              formControlName="role"
              placeholder="Choisir un rôle"
              styleClass="w-full mb-3 tlb--p-dropdown-padding-1rem"
              [options]="userRoles"
              [showClear]="true"
            ></p-dropdown>

            <div>
              <be-green--ui--input-error
                [control]="userFormControls.role"
                platform="web"
              ></be-green--ui--input-error>
            </div>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="firstName"
          >
            Prénom
          </label>

          <input
            class="w-full mb-3"
            id="firstName"
            (keyup.enter)="saveUser()"
            formControlName="firstName"
            pInputText
            style="padding: 1rem"
            maxlength="64"
          />

          <div>
            <be-green--ui--input-error
              [control]="userFormControls.firstName"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="lastName">
            Nom
          </label>

          <input
            class="w-full mb-3"
            id="lastName"
            (keyup.enter)="saveUser()"
            formControlName="lastName"
            pInputText
            style="padding: 1rem"
            maxlength="64"
          />

          <div>
            <be-green--ui--input-error
              [control]="userFormControls.lastName"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isUserDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveUser()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isUserActionDialogVisible"
>
  <div class="flex align-items-center">
    <i
      class="pi pi-exclamation-triangle mr-3 text-blue-500"
      style="font-size: 2rem"
      [ngClass]="{ 'text-red-500': userAction === 'delete' }"
    ></i>

    <div
      class="flex flex-column"
      *ngIf="userAction && user && user.roles && user.roles.length"
    >
      <div>
        Êtes-vous sûr de vouloir
        <b>{{ userActions[userAction].verb }}</b> l’utilisateur
        <b>{{ user.email }}</b> de rôle
        <span
          class="tlb--status-badge"
          [ngClass]="getRoleStyleClass(user.roles[0])"
        >
          {{ user.roles[0] }}
        </span>
        &nbsp;?
      </div>

      <div class="mt-4 text-red-500 font-bold" *ngIf="userAction === 'delete'">
        Attention, cette action est irréversible&nbsp;!
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isUserActionDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmUserAction()"
    ></button>
  </ng-template>
</p-dialog>
