import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LanguageCode,
  StaticContentDto,
  StaticContentLocalizedDto,
  VersionDto,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  checkVersion(): Observable<VersionDto> {
    return this.httpClient.get<VersionDto>(`/common/version`);
  }

  createFrequentlyAskedQuestions(content: {
    ar?: string;
    fr: string;
  }): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/common/frequently-asked-questions`,
      {
        contentAr: content.ar,
        contentFr: content.fr,
      },
    );
  }

  createTermsAndConditions(content: {
    ar?: string;
    fr: string;
  }): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/common/terms-and-conditions`,
      {
        contentAr: content.ar,
        contentFr: content.fr,
      },
    );
  }

  getFrequentlyAskedQuestions(): Observable<StaticContentDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<StaticContentDto>(
      `/common/frequently-asked-questions`,
    );
  }

  getLocalizedFrequentlyAskedQuestions(
    languageCode: LanguageCode,
  ): Observable<StaticContentLocalizedDto> {
    return this.httpClient.get<StaticContentLocalizedDto>(
      `/common/frequently-asked-questions/${languageCode}`,
    );
  }

  getLocalizedTermsAndConditions(
    languageCode: LanguageCode,
  ): Observable<StaticContentLocalizedDto> {
    return this.httpClient.get<StaticContentLocalizedDto>(
      `/common/terms-and-conditions/${languageCode}`,
    );
  }

  getTermsAndConditions(): Observable<StaticContentDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<StaticContentDto>(
      `/common/terms-and-conditions`,
    );
  }

  updateFrequentlyAskedQuestions(content: {
    ar?: string;
    fr: string;
  }): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/common/frequently-asked-questions`, {
      contentAr: content.ar,
      contentFr: content.fr,
    });
  }

  updateTermsAndConditions(content: {
    ar?: string;
    fr: string;
  }): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/common/terms-and-conditions`, {
      contentAr: content.ar,
      contentFr: content.fr,
    });
  }
}
