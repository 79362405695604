import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable()
export class LayoutService {
  private _breadcrumbs!: MenuItem[];
  private _footerName = 'Tellibus';
  private _logoName = 'Tellibus Admin';
  private _menuConfig!: MenuItem[];

  private menuSource = new Subject<string>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  get breadcrumbs() {
    return this._breadcrumbs;
  }

  get breadcrumbsHome(): MenuItem {
    return { icon: 'pi pi-home', routerLink: '/' };
  }

  get footerName() {
    return this._footerName;
  }

  get logoName() {
    return this._logoName;
  }

  get menuConfig() {
    return this._menuConfig;
  }

  registerBreadcrumbs(breadcrumbs: MenuItem[]) {
    setTimeout(() => (this._breadcrumbs = breadcrumbs));
  }

  registerFooterName(footerName: string) {
    this._footerName = footerName;
  }

  registerLogoName(logoName: string) {
    this._logoName = logoName;
  }

  registerMenu(menuConfig: MenuItem[]) {
    this._menuConfig = menuConfig;
  }

  reset() {
    this.resetSource.next(true);
  }
}
