import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Scope } from '@be-green/dto';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from './error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private dialogTranslations!: {
    0: {
      MESSAGE: string;
      TITLE: string;
    };

    400: {
      TITLE: string;
    };

    401: {
      MESSAGE: string;
      TITLE: string;
    };

    403: {
      TITLE: string;
    };

    404: {
      TITLE: string;
    };

    405: {
      TITLE: string;
      MESSAGE: string;
    };

    409: {
      TITLE: string;
    };

    422: {
      TITLE: string;
    };

    429: {
      MESSAGE: string;
      TITLE: string;
    };

    500: {
      MESSAGE: string;
      TITLE: string;
    };

    DEFAULT: {
      TITLE: string;
    };

    BUTTONS: { OK: string };
  };

  constructor(
    @Inject('APP_SCOPE') private readonly appScope: string,
    private readonly alertController: AlertController,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  async handleError(
    error: HttpErrorResponse,
    displayError = true,
  ): Promise<{ title: string; message: string }> {
    let title;
    let message;

    switch (error.status) {
      case 0:
        title = this.dialogTranslations[0].TITLE;
        message = this.dialogTranslations[0].MESSAGE;

        break;
      case 400:
        title = this.dialogTranslations[400].TITLE;
        message = error.error.message || error.error.reason;

        break;
      case 401:
        title = this.dialogTranslations[401].TITLE;
        message = this.dialogTranslations[401].MESSAGE;

        break;
      case 403:
        title = this.dialogTranslations[403].TITLE;
        message = error.error.message || error.error.reason;

        break;
      case 404:
        title = this.dialogTranslations[404].TITLE;
        message = error.error.message || error.error.reason;

        break;
      case 405:
        title = this.dialogTranslations[405].TITLE;
        message = this.dialogTranslations[405].MESSAGE;

        break;
      case 409:
        title = this.dialogTranslations[409].TITLE;
        message = error.error.message || error.error.reason;

        break;
      case 422:
        title = this.dialogTranslations[422].TITLE;
        message = error.error.message || error.error.reason;

        break;
      case 429:
        title = this.dialogTranslations[429].TITLE;
        message = this.dialogTranslations[429].MESSAGE;

        break;
      case 500:
        title = this.dialogTranslations[500].TITLE;
        message =
          error.error.message ||
          error.error.reason ||
          this.dialogTranslations[500].MESSAGE;

        break;
      default:
        title = this.dialogTranslations.DEFAULT.TITLE;
        message = error.status === undefined ? error.toString() : error.status;
    }

    if (displayError) {
      if (this.appScope === Scope.Mobile) {
        const alert = await this.alertController.create({
          header: title,
          message,
          backdropDismiss: false,
          buttons: [this.dialogTranslations.BUTTONS.OK],
        });

        await alert.present();
      } else {
        this.dialogService.open(ErrorDialogComponent, {
          data: { message },
          header: title,
          width: '450px',
          contentStyle: {
            'border-bottom-right-radius': '0.375rem',
            'border-bottom-left-radius': '0.375rem',
          },
        });
      }
    }

    return { title, message };
  }

  async init() {
    this.translateService.get('be_green__serverErrors').subscribe({
      next: async (dialogTranslations) =>
        (this.dialogTranslations = dialogTranslations),
    });
  }
}
