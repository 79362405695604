<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    materialsEditService.currentStep === 1 &&
      (materialsEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="materialsEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="nameFr"
            (keyup.enter)="nextStep()"
            formControlName="nameFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - currentStepFormControls.nameFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.nameFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="nameAr"
            dir="rtl"
            (keyup.enter)="nextStep()"
            formControlName="nameAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - currentStepFormControls.nameAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.nameAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="color">
            Couleur à utiliser sur les graphes d’impact
          </label>

          <div
            class="flex flex-column align-items-start mb-4"
            style="width: 193px"
          >
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"
                ><i class="pi pi-hashtag" style="line-height: 1.25"></i
              ></span>

              <input
                id="color"
                formControlName="color"
                pInputText
                pKeyFilter="hex"
                style="padding: 1rem"
                maxlength="6"
                (keyup)="updateColorPicker()"
              />

              <p-colorPicker
                formControlName="colorPickerValue"
                styleClass="w-3rem h-full"
                (onChange)="updateColorInput($event)"
              ></p-colorPicker>
            </div>
          </div>

          <div>
            <be-green--ui--input-error
              [control]="currentStepFormControls.colorPickerValue"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-end">
        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
