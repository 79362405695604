<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    materialsEditService.currentStep === 4 &&
      (materialsEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <form
      class="w-full md:w-10 mx-auto"
      (change)="materialsEditService.setDirty()"
      [formGroup]="currentStepForm"
    >
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="gramsOfCo2SavedWhenRecyclingOneGram"
          >
            Grammes de CO<sub>2</sub> économisés par gramme recyclé
          </label>

          <input
            class="w-full mb-3"
            id="gramsOfCo2SavedWhenRecyclingOneGram"
            (keyup.enter)="nextStep()"
            formControlName="gramsOfCo2SavedWhenRecyclingOneGram"
            pInputText
            [pKeyFilter]="'num'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="
                currentStepFormControls.gramsOfCo2SavedWhenRecyclingOneGram
              "
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="kilometersAllowedByOneKiloOfCo2"
          >
            Kilomètres parcourus par Kg de CO<sub>2</sub>
          </label>

          <input
            class="w-full mb-3"
            id="kilometersAllowedByOneKiloOfCo2"
            (keyup.enter)="nextStep()"
            formControlName="kilometersAllowedByOneKiloOfCo2"
            pInputText
            [pKeyFilter]="'num'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="
                currentStepFormControls.kilometersAllowedByOneKiloOfCo2
              "
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
