import { Component, Input } from '@angular/core';

@Component({
  selector: 'be-green--ui--mdi-emoticon',

  template: `<ng-container [ngSwitch]="type">
    <be-green--ui--mdi
      class="w-4rem text-green-500"
      [path]="'mdiEmoticonHappy'"
      *ngSwitchCase="'happy'"
    ></be-green--ui--mdi>

    <be-green--ui--mdi
      class="w-4rem text-yellow-500"
      [path]="'mdiEmoticonNeutral'"
      *ngSwitchCase="'neutral'"
    ></be-green--ui--mdi>

    <be-green--ui--mdi
      class="w-4rem text-red-500"
      [path]="'mdiEmoticonSad'"
      *ngSwitchCase="'sad'"
    ></be-green--ui--mdi>
  </ng-container> `,
})
export class MaterialDesignEmoticonComponent {
  @Input() type!: string;
}
