import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputValidationService, UtilsService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MaterialsEditService } from '../../materials-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--materials-edit-steps-general',
  templateUrl: './materials-edit-steps-general.component.html',
  styleUrls: ['./materials-edit-steps-general.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsEditStepsGeneralComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly materialsEditService: MaterialsEditService,
  ) {}

  ngOnInit(): void {
    if (this.materialsEditService.currentStep === 1) {
      this.materialsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      color: AbstractControl;
      colorPickerValue: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      color: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      colorPickerValue: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.hexColor,
          Validators.minLength(7),
          Validators.maxLength(7),
        ]),
      ],
    });

    if (this.materialsEditService.dirtyMaterial) {
      this.currentStepForm.patchValue(this.materialsEditService.dirtyMaterial);
      this.currentStepFormControls.colorPickerValue.patchValue(
        '#' + this.materialsEditService.dirtyMaterial.color,
      );
    } else {
      const color =
        UtilsService.generateRandomHexColorForBackgroundWithWhiteText();

      this.currentStepFormControls.color.patchValue(color.replace('#', ''));
      this.currentStepFormControls.colorPickerValue.patchValue(color);
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.materialsEditService.setDirtyMaterial(this.currentStepForm.value);

    this.materialsEditService.nextStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  updateColorInput(event: { originalEvent: Event; value: string }) {
    if (event.value) {
      this.currentStepFormControls.color.patchValue(
        event.value.replace('#', ''),
      );
    }
  }

  updateColorPicker() {
    if (this.currentStepFormControls.color.errors === null) {
      this.currentStepFormControls.colorPickerValue.patchValue(
        '#' + this.currentStepFormControls.color.value,
      );
    } else {
      this.currentStepFormControls.colorPickerValue.patchValue(null);
    }
  }
}
