import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ArticleApi } from '@be-green/api-services';
import { ArticleDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--articles-index',
  templateUrl: './articles-index.component.html',
  styleUrls: ['./articles-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesIndexComponent implements OnInit {
  @ViewChild('filterArticleIndexDataTable')
  filterArticleIndexDataTable!: ElementRef;
  @ViewChild('articleIndexDataTable') articleIndexDataTable!: Table;

  isLoading = false;
  articles!: ArticleDto[];

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly errorService: ErrorService,
    private readonly articleApi: ArticleApi,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Articles' }]);
    this.seoService.setTitle('Articles - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllArticles();
  }

  private getAllArticles() {
    this.articleApi.getAll().subscribe({
      next: (articles) => {
        this.articles = articles.map((article) => ({
          ...article,
          date: new Date(article.date),
        }));

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asArticleDto(article: any): ArticleDto {
    return article as ArticleDto;
  }

  clearArticlesTable() {
    this.articleIndexDataTable.clear();
    this.filterArticleIndexDataTable.nativeElement.value = '';
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }
}
