import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CommonApi } from '@be-green/api-services';
import { FaqDto, LanguageCode } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { OrderList } from 'primeng/orderlist';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'be-green--admin--faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FaqComponent implements OnInit, ComponentCanDeactivate {
  @ViewChildren(OrderList) orderLists!: OrderList[];

  private contentCache?: {
    ar?: FaqDto[];
    fr?: FaqDto[];
  };

  editedEntry?: FaqDto;
  editHeader?: 'Nouvelle entrée' | 'Modification entrée';
  editHeaderLanguage?: ' en arabe' | ' en français';
  editMode?: 'create' | 'delete' | 'update';
  entries?: {
    ar?: FaqDto[];
    fr?: FaqDto[];
  };
  entryAnswer = '';
  entryLanguage?: LanguageCode;
  entryQuestion = '';
  isDeleteDialogVisible = false;
  isEditDialogVisible = false;
  isEditMode = false;
  isLoading = false;
  isNewRecord = false;
  isSelectionEditPossible = { ar: false, fr: false };
  messageError = false;

  constructor(
    private readonly commonApi: CommonApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'F.A.Q.' }]);
    this.seoService.setTitle('F.A.Q. - Admin - Be Green');
  }

  isDirty() {
    return (
      this.contentCache !== undefined &&
      (JSON.stringify(this.entries?.ar) !==
        JSON.stringify(this.contentCache.ar) ||
        JSON.stringify(this.entries?.fr) !==
          JSON.stringify(this.contentCache.fr))
    );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.commonApi.getFrequentlyAskedQuestions().subscribe({
      next: (faq) => {
        this.isNewRecord = faq === null;

        if (this.isNewRecord) {
          this.entries = {
            ar: [],
            fr: [],
          };
        } else {
          this.entries = {
            ar: faq.contentAr ? JSON.parse(faq.contentAr) : [],
            fr: faq.contentFr ? JSON.parse(faq.contentFr) : [],
          };
        }
        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  private addEntry() {
    switch (this.entryLanguage) {
      case LanguageCode.Arabic:
        this.editHeaderLanguage = ' en arabe';

        break;
      case LanguageCode.French:
        this.editHeaderLanguage = ' en français';
    }

    this.editMode = 'create';
    this.entryAnswer = '';
    this.entryQuestion = '';
    this.messageError = false;
    this.editHeader = 'Nouvelle entrée';
    this.isEditDialogVisible = true;
  }

  addEntryInArabic() {
    this.entryLanguage = LanguageCode.Arabic;

    this.addEntry();
  }

  addEntryInFrench() {
    this.entryLanguage = LanguageCode.French;

    this.addEntry();
  }

  asEntry(entry: unknown): FaqDto {
    return entry as FaqDto;
  }

  cancelEditMode() {
    this.entries = {
      ar: [...(this.contentCache?.ar as FaqDto[])],
      fr: [...(this.contentCache?.fr as FaqDto[])],
    };

    this.isEditMode = false;

    for (const orderlist of this.orderLists) {
      orderlist.selection = [];
    }
  }

  confirmDeleteEntry() {
    this.deleteEntry();
  }

  private deleteEntry() {
    if (!this.editedEntry || !this.entryLanguage) {
      return;
    }

    this.editMode = 'delete';

    this.saveEntry();
  }

  deleteEntryInArabic() {
    this.entryLanguage = LanguageCode.Arabic;
    this.isDeleteDialogVisible = true;
  }

  deleteEntryInFrench() {
    this.entryLanguage = LanguageCode.French;
    this.isDeleteDialogVisible = true;
  }

  private editEntry() {
    if (!this.editedEntry || !this.entryLanguage) {
      return;
    }

    this.editMode = 'update';

    switch (this.entryLanguage) {
      case LanguageCode.Arabic:
        this.editHeaderLanguage = ' en arabe';

        break;
      case LanguageCode.French:
        this.editHeaderLanguage = ' en français';
    }

    this.entryAnswer = this.editedEntry.answer;
    this.entryQuestion = this.editedEntry.question;
    this.messageError = false;
    this.editHeader = 'Modification entrée';
    this.isEditDialogVisible = true;
  }

  editEntryInArabic() {
    this.entryLanguage = LanguageCode.Arabic;

    this.editEntry();
  }

  editEntryInFrench() {
    this.entryLanguage = LanguageCode.French;

    this.editEntry();
  }

  enterEditMode() {
    this.contentCache = {
      ar: [...(this.entries?.ar as FaqDto[])],
      fr: [...(this.entries?.fr as FaqDto[])],
    };

    this.isSelectionEditPossible = { ar: false, fr: false };
    this.isEditMode = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSelectionChange(event: any, language: LanguageCode) {
    if (event.value.length === 1) {
      this.entryLanguage = language;
      this.editedEntry = event.value[0];
      this.isSelectionEditPossible[language] = true;
    } else {
      this.entryLanguage = undefined;
      this.editedEntry = undefined;
      this.isSelectionEditPossible[language] = false;
    }
  }

  onSelectionChangeInArabic(event: unknown) {
    this.onSelectionChange(event, LanguageCode.Arabic);
  }

  onSelectionChangeInFrench(event: unknown) {
    this.onSelectionChange(event, LanguageCode.French);
  }

  save() {
    if (this.entries?.fr?.length) {
      this.isLoading = true;

      if (this.isNewRecord) {
        this.commonApi
          .createFrequentlyAskedQuestions({
            ar: JSON.stringify(this.entries.ar || []),
            fr: JSON.stringify(this.entries.fr),
          })
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.isEditMode = false;
              this.isNewRecord = false;

              this.contentCache = {
                ar: [...(this.entries?.ar as FaqDto[])],
                fr: [...(this.entries?.fr as FaqDto[])],
              };
            },

            error: (error: HttpErrorResponse) => {
              this.errorService.handleError(error);

              this.isLoading = false;
            },
          });
      } else {
        this.commonApi
          .updateFrequentlyAskedQuestions({
            ar: JSON.stringify(this.entries.ar || []),
            fr: JSON.stringify(this.entries.fr),
          })
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.isEditMode = false;

              this.contentCache = {
                ar: [...(this.entries?.ar as FaqDto[])],
                fr: [...(this.entries?.fr as FaqDto[])],
              };
            },

            error: (error: HttpErrorResponse) => {
              this.errorService.handleError(error);

              this.isLoading = false;
            },
          });
      }
    }
  }

  saveEntry() {
    if (this.messageError || !this.entries || !this.entryLanguage) {
      return;
    }

    switch (this.editMode) {
      case 'create':
        this.entries[this.entryLanguage] = [
          ...(this.entries[this.entryLanguage] || []),

          {
            position: 1 + (this.entries[this.entryLanguage]?.length || 0),
            answer: this.entryAnswer,
            question: this.entryQuestion,
          },
        ];

        break;
      case 'delete':
        if (!this.editedEntry) {
          return;
        }

        this.entries[this.entryLanguage] = [
          ...(this.entries[this.entryLanguage] || []).slice(
            0,
            // position is 1-based while array is 0-based
            this.editedEntry.position - 1,
          ),

          ...(this.entries[this.entryLanguage] || []).slice(
            // position is 1-based while array is 0-based
            this.editedEntry.position,
          ),
        ];

        break;
      case 'update':
        if (!this.editedEntry) {
          return;
        }

        this.entries[this.entryLanguage] = [
          ...(this.entries[this.entryLanguage] || []).slice(
            0,
            // position is 1-based while array is 0-based
            this.editedEntry.position - 1,
          ),

          {
            ...this.editedEntry,
            answer: this.entryAnswer,
            question: this.entryQuestion,
          },

          ...(this.entries[this.entryLanguage] || []).slice(
            // position is 1-based while array is 0-based
            this.editedEntry.position,
          ),
        ];
    }

    this.editedEntry = undefined;
    this.isSelectionEditPossible[this.entryLanguage] = false;
    this.entryLanguage = undefined;
    this.isDeleteDialogVisible = false;
    this.isEditDialogVisible = false;
    this.messageError = false;
  }
}
