import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CategoryApi,
  ContributionApi,
  HistoryApi,
  ParticipationApi,
  UserApi,
} from '@be-green/api-services';
import {
  BasketNotificationDto,
  CategoryDto,
  CollectionType,
  HistoryDto,
  ImpactDto,
  NotificationContext,
  ParticipationDto,
  ProgramType,
  RedemptionNotificationDto,
  TipDto,
  UserDto,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { Chart } from 'chart.js';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('toggleCardContents', [
      state(
        'true',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class UsersViewComponent implements OnInit {
  badgeLabel = '';
  badges: ValueLabelDto[] = [
    { value: '0', label: 'Guerrier du gaspillage' },
    { value: '1', label: 'Gardien de la nature' },
    { value: '2', label: 'Lion du recyclage' },
  ];
  categories!: CategoryDto[];
  expandedCategories = {};
  history!: HistoryDto[];
  impact?: ImpactDto;
  isCategoryCardOpen = true;
  isHistoryCardOpen = true;
  isLoading = false;
  isParticipationActionDialogVisible = false;
  isParticipationCardOpen = true;
  participation?: ParticipationDto;
  participationAction?: '_detach';
  participationActions = {
    _detach: { verb: 'détacher', adjective: 'détaché' },
  };
  participations!: ParticipationDto[];
  user?: UserDto;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly categoryApi: CategoryApi,
    private readonly contributionApi: ContributionApi,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly errorService: ErrorService,
    private readonly historyApi: HistoryApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly participationApi: ParticipationApi,
    private readonly seoService: SeoService,
    private readonly userApi: UserApi,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Utilisateurs' }]);
    this.seoService.setTitle('Utilisateurs - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.userApi.getOne(code).subscribe({
      next: (user) => {
        this.user = user;

        const badge = this.badges.find(
          (badge) => badge.value === user.person?.currentBadge?.toString(),
        );

        if (badge) {
          this.badgeLabel = badge.label;
        }

        this.categoryApi.getAllOfUser(code).subscribe({
          next: (categories) => {
            this.categories = categories;
          },

          error: (error: HttpErrorResponse) =>
            this.errorService.handleError(error),
        });

        this.contributionApi.getUserImpact(code).subscribe({
          next: (impact) => {
            this.impact = impact;

            if (impact.contributions.length) {
              this.changeDetectorRef.detectChanges();

              const totalWeight = impact.contributions.reduce(
                (a, b) => a + (b.weight as number),
                0,
              );

              // Draw material icons only when slice represents more than 8%
              const images = impact.contributions.map((contribution) => {
                if (
                  contribution.weight &&
                  contribution.weight > totalWeight * 0.08
                ) {
                  const image = new Image();
                  image.src =
                    this.imageBaseUrl + contribution.material.imageUrl;
                  return image;
                } else {
                  return null;
                }
              });

              new Chart('impactChart', {
                type: 'pie',
                plugins: [
                  {
                    id: 'tlb__icons_on_chartjs',

                    afterDatasetsDraw: (chart) => {
                      const ctx = chart.ctx;
                      const chartArea = chart.chartArea;
                      // Adjust the size of the icons based on the chart size
                      const iconsSize =
                        Math.min(chartArea.width, chartArea.height) / 8;

                      ctx.save();

                      const xCenter = chartArea.left + chartArea.width / 2;
                      const yCenter = chartArea.top + chartArea.height / 2;
                      const data = chart.config.data.datasets[0].data;
                      const vTotal = data.reduce(
                        (a: number, b) => a + (b as number),
                        0,
                      );

                      data.forEach((v, i) => {
                        const vAngle =
                          data
                            .slice(0, i)
                            .reduce((a: number, b) => a + (b as number), 0) +
                          (v as number) / 2;

                        const angle = (360 / vTotal) * vAngle - 90;
                        const radians = angle * (Math.PI / 180);

                        const r = yCenter;
                        const x = xCenter + (Math.cos(radians) * r) / 2;
                        const y = yCenter + (Math.sin(radians) * r) / 2;

                        ctx.translate(x, y);

                        const image = images[i];

                        if (
                          image &&
                          iconsSize <= image.width &&
                          iconsSize <= image.height
                        ) {
                          ctx.drawImage(
                            image,
                            -iconsSize / 2,
                            -iconsSize / 2,
                            iconsSize,
                            iconsSize,
                          );
                        }

                        ctx.translate(-x, -y);
                      });

                      ctx.restore();
                    },
                  },
                ],

                data: {
                  labels: impact.contributions.map(
                    (contribution) => contribution.material.nameFr,
                  ),

                  datasets: [
                    {
                      data: impact.contributions.map(
                        (contribution) => contribution.weight as number,
                      ),

                      backgroundColor: impact.contributions.map(
                        (contribution) => '#' + contribution.material.color,
                      ),
                    },
                  ],
                },

                options: {
                  responsive: false,

                  plugins: {
                    legend: {
                      onClick: (event: ChartEvent) =>
                        event.native?.stopPropagation(),
                    },

                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return tooltipItem.parsed + ' g';
                        },
                      },
                    },
                  },
                },
              });
            }
          },

          error: (error: HttpErrorResponse) =>
            this.errorService.handleError(error),
        });

        this.historyApi.getAllOfUser(code).subscribe({
          next: (history) => {
            const contextOrder: { [key: string]: number } = {
              [NotificationContext.Gifts]: 1,
              [NotificationContext.Points]: 2,
              [NotificationContext.Baskets]: 3,
            };

            history.sort((a, b) => {
              if ((a.createdAt as Date) > (b.createdAt as Date)) {
                return -1; // reverse chronological order
              } else if ((a.createdAt as Date) < (b.createdAt as Date)) {
                return 1;
              } else {
                return contextOrder[a.context] - contextOrder[b.context];
              }
            });

            this.history = history;
          },

          error: (error: HttpErrorResponse) =>
            this.errorService.handleError(error),
        });

        this.participationApi.getAllOfUser(code).subscribe({
          next: (participations) => {
            this.participations = participations;
          },

          error: (error: HttpErrorResponse) =>
            this.errorService.handleError(error),
        });

        this.layoutService.registerBreadcrumbs([
          { label: 'Utilisateurs', routerLink: '/users' },
          {
            label: `${this.user.person?.firstName} ${this.user.person?.lastName}`,
          },
        ]);

        this.seoService.setTitle(
          `${this.user.person?.firstName} ${this.user.person?.lastName} - Utilisateurs - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  asBasketNotificationDto(
    data: BasketNotificationDto | RedemptionNotificationDto,
  ): BasketNotificationDto {
    return data as BasketNotificationDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asCategoryDto(category: any): CategoryDto {
    return category as CategoryDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asHistoryDto(history: any): HistoryDto {
    return history as HistoryDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asParticipationDto(participation: any): ParticipationDto {
    return participation as ParticipationDto;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asTipDto(tip: any): TipDto {
    return tip as TipDto;
  }

  asRedemptionNotificationDto(
    data: BasketNotificationDto | RedemptionNotificationDto,
  ): RedemptionNotificationDto {
    return data as RedemptionNotificationDto;
  }

  asRedemptionUsedPoints(usedPoints: string): number {
    return parseInt(usedPoints, 10);
  }

  confirmParticipationAction() {
    if (!this.user || !this.participation || !this.participationAction) {
      return;
    }

    this.isLoading = true;

    this[this.participationAction]().subscribe({
      complete: async () => {
        this.isParticipationActionDialogVisible = false;

        this.participationApi
          .getAllOfUser((this.user as UserDto).code)
          .subscribe({
            next: (participations) => {
              this.participations = participations;

              this.messageService.add({
                detail: `Utilisateur ${(this.user as UserDto).email} ${
                  this.participationActions[
                    this.participationAction as '_detach'
                  ].adjective
                }`,
                key: 'users',
                life: 3000,
                severity: 'success',
                summary: 'Succès',
              });

              this.isParticipationActionDialogVisible = false;
              this.participation = undefined;

              this.isLoading = false;
            },

            error: (error: HttpErrorResponse) =>
              this.errorService.handleError(error),
          });
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.messageService.add({
          detail: message,
          key: 'users',
          severity: 'error',
          summary: title,
        });

        this.participation = undefined;
        this.isParticipationActionDialogVisible = false;
        this.isLoading = false;
      },
    });
  }

  private _detach(): Observable<void> {
    if (
      !this.user ||
      !this.participation ||
      !this.participation.referralCode ||
      this.participation.deletedAt ||
      this.participation.program.type !== ProgramType.Pro
    ) {
      return of();
    }

    return this.participationApi.removeAffiliatedOne(
      this.participation.referralCode,
    );
  }

  detachFromParticipation(participation: ParticipationDto) {
    if (
      !this.user ||
      participation.program.type !== ProgramType.Pro ||
      participation.deletedAt
    ) {
      return;
    }

    this.isLoading = false;
    this.participationAction = '_detach';
    this.isParticipationActionDialogVisible = true;
    this.participation = { ...participation };
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'danger' | 'info' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getProgramTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'success' | 'info' | 'warning' | 'danger' {
    return UtilsService.getUserStatusTagSeverity(statusCode);
  }
}
