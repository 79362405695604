import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditTipDto,
  LanguageCode,
  TipDto,
  TipLocalizedDto,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TipApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(code: string, payload: EditTipDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/categories/${code}/tip`,
      payload,
    );
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/tips/admin/${code}`);
  }

  getAll(): Observable<TipDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<TipDto[]>(`/tips/admin`);
  }

  getAllLocalized(languageCode: LanguageCode): Observable<TipLocalizedDto[]> {
    return this.httpClient.get<TipLocalizedDto[]>(`/tips/${languageCode}`);
  }

  getOne(code: string): Observable<TipDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<TipDto>(`/tips/admin/${code}`);
  }

  getOneLocalized(
    languageCode: LanguageCode,
    code: string,
  ): Observable<TipLocalizedDto> {
    return this.httpClient.get<TipLocalizedDto>(
      `/tips/${languageCode}/${code}`,
    );
  }

  reorder(code: string, codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/categories/${code}/reorder/tips`, codes);
  }

  update(code: string, payload: EditTipDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/categories/${code}/tip`, payload);
  }

  validate(code: string): Observable<void> {
    if (!this.authenticationService.isUser) {
      return of();
    }

    return this.httpClient.post<void>(`/tips/${code}`, null);
  }
}
