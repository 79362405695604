import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

/**
 * TELLIBUS
 */
import { UiPipesModule } from '@be-green/ui-pipes';
import { UiServicesModule } from '@be-green/ui-services';

/**
 * angularx-qrcode
 */
import { QRCodeModule } from 'angularx-qrcode';

/**
 * ngrx-utils
 */
import { NgLetModule } from '@ngrx-utils/store';

/**
 * ngx-image-cropper
 */
import { ImageCropperModule } from 'ngx-image-cropper';

/**
 * ngx-translate
 */
import { TranslateModule } from '@ngx-translate/core';

/**
 * PrimeNG Components
 */
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

/**
 * Components
 */
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AccountsIndexComponent } from './accounts/accounts-index/accounts-index.component';
import { ActivateAccessComponent } from './activate-access/activate-access.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ArticlesEditComponent } from './articles/articles-edit/articles-edit.component';
import { ArticlesEditService } from './articles/articles-edit/articles-edit.service';
import { ArticlesEditStepsContentComponent } from './articles/articles-edit/steps/articles-edit-steps-content/articles-edit-steps-content.component';
import { ArticlesEditStepsGeneralComponent } from './articles/articles-edit/steps/articles-edit-steps-general/articles-edit-steps-general.component';
import { ArticlesEditStepsIllustrationComponent } from './articles/articles-edit/steps/articles-edit-steps-illustration/articles-edit-steps-illustration.component';
import { ArticlesEditStepsSettingsComponent } from './articles/articles-edit/steps/articles-edit-steps-settings/articles-edit-steps-settings.component';
import { ArticlesIndexComponent } from './articles/articles-index/articles-index.component';
import { ArticlesViewComponent } from './articles/articles-view/articles-view.component';
import { BasketsIndexComponent } from './baskets/baskets-index/baskets-index.component';
import { BasketsViewComponent } from './baskets/baskets-view/baskets-view.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { ErrorComponent } from './error/error.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HandlersIndexComponent } from './handlers/handlers-index/handlers-index.component';
import { HandlersViewComponent } from './handlers/handlers-view/handlers-view.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutService } from './layout/layout.service';
import { LoginComponent } from './login/login.component';
import { MaterialsEditComponent } from './materials/materials-edit/materials-edit.component';
import { MaterialsEditService } from './materials/materials-edit/materials-edit.service';
import { MaterialsEditStepsCo2ImpactComponent } from './materials/materials-edit/steps/materials-edit-steps-co2-impact/materials-edit-steps-co2-impact.component';
import { MaterialsEditStepsEnergyImpactComponent } from './materials/materials-edit/steps/materials-edit-steps-energy-impact/materials-edit-steps-energy-impact.component';
import { MaterialsEditStepsGeneralComponent } from './materials/materials-edit/steps/materials-edit-steps-general/materials-edit-steps-general.component';
import { MaterialsEditStepsIllustrationComponent } from './materials/materials-edit/steps/materials-edit-steps-illustration/materials-edit-steps-illustration.component';
import { MaterialsIndexComponent } from './materials/materials-index/materials-index.component';
import { MaterialsViewComponent } from './materials/materials-view/materials-view.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrganizationTypesEditComponent } from './organization-types/organization-types-edit/organization-types-edit.component';
import { OrganizationTypesEditService } from './organization-types/organization-types-edit/organization-types-edit.service';
import { OrganizationTypesIndexComponent } from './organization-types/organization-types-index/organization-types-index.component';
import { OrganizationTypesViewComponent } from './organization-types/organization-types-view/organization-types-view.component';
import { ProgramsEditComponent } from './programs/programs-edit/programs-edit.component';
import { ProgramsEditService } from './programs/programs-edit/programs-edit.service';
import { ProgramsEditStepsDescriptionComponent } from './programs/programs-edit/steps/programs-edit-steps-description/programs-edit-steps-description.component';
import { ProgramsEditStepsGeneralComponent } from './programs/programs-edit/steps/programs-edit-steps-general/programs-edit-steps-general.component';
import { ProgramsEditStepsIllustrationComponent } from './programs/programs-edit/steps/programs-edit-steps-illustration/programs-edit-steps-illustration.component';
import { ProgramsEditStepsSettingsComponent } from './programs/programs-edit/steps/programs-edit-steps-settings/programs-edit-steps-settings.component';
import { ProgramsIndexComponent } from './programs/programs-index/programs-index.component';
import { ProgramsViewComponent } from './programs/programs-view/programs-view.component';
import { QualitiesIndexComponent } from './qualities/qualities-index/qualities-index.component';
import { RedemptionsIndexComponent } from './redemptions/redemptions-index/redemptions-index.component';
import { RedemptionsViewComponent } from './redemptions/redemptions-view/redemptions-view.component';
import { ReferralsIndexComponent } from './referrals/referrals-index/referrals-index.component';
import { ResetAccessComponent } from './reset-access/reset-access.component';
import { TermsComponent } from './terms/terms.component';
import { CategoriesEditService } from './tips/tips-edit/categories-edit.service';
import { TipsEditComponent } from './tips/tips-edit/tips-edit.component';
import { TipsIndexComponent } from './tips/tips-index/tips-index.component';
import { TipsViewComponent } from './tips/tips-view/tips-view.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { UsersIndexComponent } from './users/users-index/users-index.component';
import { UsersViewComponent } from './users/users-view/users-view.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /**
     * angularx-qrcode
     */
    QRCodeModule,

    /**
     * Google Maps
     */
    GoogleMapsModule,

    /**
     * ngx-image-cropper
     */
    ImageCropperModule,

    /**
     * ngLet directive to provide static typing in ng-template let-* bindings
     */
    NgLetModule,

    /**
     * ngx-translate (in order to use pipe and directive)
     */
    TranslateModule,

    /**
     * PrimeNG
     */
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    ChipModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    ImageModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KeyFilterModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TagModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    VirtualScrollerModule,
    StyleClassModule,

    // TELLIBUS
    UiPipesModule,
    UiServicesModule,
  ],

  declarations: [
    AccessDeniedComponent,
    AccountsIndexComponent,
    ActivateAccessComponent,
    AlertsComponent,
    ArticlesEditComponent,
    ArticlesEditStepsContentComponent,
    ArticlesEditStepsGeneralComponent,
    ArticlesEditStepsIllustrationComponent,
    ArticlesEditStepsSettingsComponent,
    ArticlesIndexComponent,
    ArticlesViewComponent,
    BasketsIndexComponent,
    BasketsViewComponent,
    DashboardComponent,
    DashboardCardComponent,
    DemoVideosComponent,
    ErrorComponent,
    FaqComponent,
    FooterComponent,
    ForgotPasswordComponent,
    HandlersIndexComponent,
    HandlersViewComponent,
    LayoutComponent,
    LoginComponent,
    MaterialsEditComponent,
    MaterialsEditStepsCo2ImpactComponent,
    MaterialsEditStepsEnergyImpactComponent,
    MaterialsEditStepsGeneralComponent,
    MaterialsEditStepsIllustrationComponent,
    MaterialsIndexComponent,
    MaterialsViewComponent,
    MenuComponent,
    MenuItemComponent,
    NotFoundComponent,
    NotificationsComponent,
    OrganizationTypesEditComponent,
    OrganizationTypesIndexComponent,
    OrganizationTypesViewComponent,
    ProgramsEditComponent,
    ProgramsEditStepsDescriptionComponent,
    ProgramsEditStepsGeneralComponent,
    ProgramsEditStepsIllustrationComponent,
    ProgramsEditStepsSettingsComponent,
    ProgramsIndexComponent,
    ProgramsViewComponent,
    QualitiesIndexComponent,
    RedemptionsIndexComponent,
    RedemptionsViewComponent,
    ReferralsIndexComponent,
    ResetAccessComponent,
    TermsComponent,
    TipsEditComponent,
    TipsIndexComponent,
    TipsViewComponent,
    TopBarComponent,
    UsersIndexComponent,
    UsersViewComponent,
  ],

  exports: [
    AccessDeniedComponent,
    AccountsIndexComponent,
    ActivateAccessComponent,
    AlertsComponent,
    ArticlesEditComponent,
    ArticlesEditStepsContentComponent,
    ArticlesEditStepsGeneralComponent,
    ArticlesEditStepsIllustrationComponent,
    ArticlesEditStepsSettingsComponent,
    ArticlesIndexComponent,
    ArticlesViewComponent,
    BasketsIndexComponent,
    BasketsViewComponent,
    DashboardComponent,
    DashboardCardComponent,
    DemoVideosComponent,
    ErrorComponent,
    FaqComponent,
    FooterComponent,
    ForgotPasswordComponent,
    HandlersIndexComponent,
    HandlersViewComponent,
    LayoutComponent,
    LoginComponent,
    MaterialsEditComponent,
    MaterialsEditStepsCo2ImpactComponent,
    MaterialsEditStepsEnergyImpactComponent,
    MaterialsEditStepsGeneralComponent,
    MaterialsEditStepsIllustrationComponent,
    MaterialsIndexComponent,
    MaterialsViewComponent,
    MenuComponent,
    MenuItemComponent,
    NotFoundComponent,
    NotificationsComponent,
    OrganizationTypesEditComponent,
    OrganizationTypesIndexComponent,
    OrganizationTypesViewComponent,
    ProgramsEditComponent,
    ProgramsEditStepsDescriptionComponent,
    ProgramsEditStepsGeneralComponent,
    ProgramsEditStepsIllustrationComponent,
    ProgramsEditStepsSettingsComponent,
    ProgramsIndexComponent,
    ProgramsViewComponent,
    RedemptionsIndexComponent,
    RedemptionsViewComponent,
    ReferralsIndexComponent,
    ResetAccessComponent,
    TermsComponent,
    TipsEditComponent,
    TipsIndexComponent,
    TipsViewComponent,
    TopBarComponent,
    UsersIndexComponent,
    UsersViewComponent,
  ],

  providers: [
    ArticlesEditService,
    CategoriesEditService,
    ConfirmationService,
    LayoutService,
    MessageService,
    MaterialsEditService,
    OrganizationTypesEditService,
    ProgramsEditService,
  ],

  schemas: [NO_ERRORS_SCHEMA],
})
export class AppLayoutModule {}
