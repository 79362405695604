import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { MaterialsEditService } from '../../materials-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--materials-edit-steps-illustration',
  templateUrl: './materials-edit-steps-illustration.component.html',
  styleUrls: ['./materials-edit-steps-illustration.component.scss'],
})
export class MaterialsEditStepsIllustrationComponent implements OnInit {
  currentStepForm!: FormGroup;
  illustrationFileUploadUrl = `/materials/upload/illustration`;
  isLoading = false;

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    readonly materialsEditService: MaterialsEditService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.materialsEditService.dirtyMaterial) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.materialsEditService.currentStep === 2) {
      this.materialsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      imageUrl: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      imageUrl: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
    });

    if (this.materialsEditService.dirtyMaterial) {
      this.currentStepForm.patchValue(this.materialsEditService.dirtyMaterial);
    }
  }

  onUploadError(event: { files: File[]; error: HttpErrorResponse }) {
    this.messageService.add({
      severity: 'error',
      summary: event.error.error?.reason,
      detail: event.error.error?.message,
    });

    this.isLoading = false;
  }

  onUploadSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{ url: string }>;
  }) {
    this.messageService.add({
      severity: 'success',
      summary: 'Téléversement réussi',
      detail: `L’image « ${event.files[0].name} » recadrée est enregistrée sur le serveur.`,
    });

    this.currentStepFormControls.imageUrl.setValue(
      event.originalEvent.body?.url,
    );

    this.currentStepForm.markAllAsTouched();

    this.isLoading = false;
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.materialsEditService.setDirtyMaterial(this.currentStepForm.value);

    this.materialsEditService.nextStep();

    this.router.navigate(['energy-impact'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  previousStep() {
    this.materialsEditService.previousStep();

    this.router.navigate(['general'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
