import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EditUserDto,
  LanguageCode,
  PersonDto,
  QueryUiDto,
  UserDto,
  VerifyMobilePhoneDto,
} from '@be-green/dto';
import { AuthenticationService, UtilsService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  create(payload: EditUserDto): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(`/users`, payload);
  }

  deactivate(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/users/${code}/deactivate`, null);
  }

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/users/${code}`);
  }

  export(): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/users/export`, {
      responseType: 'blob',
    });
  }

  getAll(
    queryUiDto: QueryUiDto,
  ): Observable<{ rows: UserDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: UserDto[]; count: number }>(`/users`, {
      params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
    });
  }

  getAllPersons(
    queryUiDto: QueryUiDto,
  ): Observable<{ rows: UserDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: UserDto[]; count: number }>(
      `/users/persons`,
      {
        params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
      },
    );
  }

  getOne(code: string): Observable<UserDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<UserDto>(`/users/${code}`);
  }

  getOnePerson(code: string): Observable<PersonDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<PersonDto>(`/users/persons/${code}`);
  }

  reactivate(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/users/${code}/reactivate`, null);
  }

  update(code: string, payload: EditUserDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/users/${code}`, payload);
  }

  verifyMobilePhone(
    languageCode: LanguageCode,
    payload: VerifyMobilePhoneDto,
  ): Observable<{ mccMnc: string }> {
    return this.httpClient.post<{ mccMnc: string }>(
      `/auth/verify-mobile-phone/${languageCode}`,
      payload,
    );
  }
}
