<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    materialsEditService.currentStep === 2 &&
      (materialsEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <div class="mb-4">
      <p-messages></p-messages>
    </div>

    <div class="text-center">
      <div class="inline-block mb-4 shadow-4" style="line-height: 0">
        <div
          class="w-6rem h-6rem flex align-items-center justify-content-center"
          [style.background-color]="
            '#' + materialsEditService.dirtyMaterial.color
          "
          *ngIf="
            materialsEditService.dirtyMaterial &&
            this.currentStepFormControls.imageUrl.value
          "
        >
          <p-avatar
            [image]="
              imageBaseUrl +
              this.currentStepFormControls.imageUrl.value.replace('shared/', '')
            "
            size="xlarge"
          ></p-avatar>
        </div>
      </div>

      <p-fileUpload
        name="file"
        accept="image/svg+xml"
        chooseIcon="pi pi-image"
        chooseLabel="Choisir l’icône (48px x 48px)"
        mode="basic"
        url="{{ illustrationFileUploadUrl }}"
        [auto]="true"
        [maxFileSize]="500 * 1024"
        (onError)="onUploadError($event)"
        (onProgress)="isLoading = true"
        (onUpload)="onUploadSuccess($event)"
      ></p-fileUpload>

      <div>
        <be-green--ui--input-error
          [class]="'justify-content-center font-bold p-error mt-2'"
          [control]="currentStepFormControls.imageUrl"
          platform="web"
        ></be-green--ui--input-error>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>
