import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationTypeApi } from '@be-green/api-services';
import { OrganizationTypeDto, ProgramDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { MenuItem, MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--organization-types-view',
  templateUrl: './organization-types-view.component.html',
  styleUrls: ['./organization-types-view.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('toggleCardContents', [
      state(
        'true',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class OrganizationTypesViewComponent implements OnInit, OnDestroy {
  action?: 'delete';
  actionDefaultStyleClass = 'p-button-warning';
  actionMenuItems: MenuItem[] = [
    {
      label: 'Modifier',
      icon: 'pi pi-pencil',
      styleClass: 'p-menuitem-warning',
      command: () => {
        this.edit();
      },
    },

    {
      label: 'Supprimer',
      icon: 'pi pi-trash',
      styleClass: 'p-menuitem-danger',
      command: () => {
        this.delete();
      },
    },
  ];
  body = this.document.querySelector('body') as HTMLBodyElement;
  organizationType?: OrganizationTypeDto;
  confirmationDialogMessage = '';
  isConfirmDialogVisible = false;
  isLoading = false;

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly organizationTypeApi: OrganizationTypeApi,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Types d’organisme' }]);
    this.seoService.setTitle('Types d’organisme - Admin - Be Green');
  }

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.paramMap.get('code') as string;

    this.isLoading = true;

    this.organizationTypeApi.getOne(code).subscribe({
      next: (organizationType) => {
        this.organizationType = organizationType;

        this.layoutService.registerBreadcrumbs([
          { label: 'Types d’organisme', routerLink: '/organization-types' },
          { label: `${this.organizationType.nameFr}` },
        ]);

        this.seoService.setTitle(
          `${this.organizationType.nameFr} - Types d’organisme - Admin - Be Green`,
        );

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.body.classList.remove('overflow-hidden');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asProgramDto(program: any): ProgramDto {
    return program as ProgramDto;
  }

  confirm() {
    switch (this.action) {
      case 'delete':
        this._delete();
    }
  }

  private _delete() {
    if (!this.organizationType) {
      return;
    }

    if (this.action === 'delete' && this.isDeletePossible()) {
      this.isLoading = true;

      this.organizationTypeApi
        .delete(this.organizationType.code)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.router.navigate(['organization-types']);
          },

          error: async (error: HttpErrorResponse) => {
            await this.errorService.handleError(error);

            this.isLoading = false;
            this.isConfirmDialogVisible = false;
            this.action = undefined;
          },
        });
    }
  }

  private delete() {
    if (!this.organizationType) {
      return;
    }

    if (this.isDeletePossible()) {
      this.action = 'delete';

      this.confirmationDialogMessage = `<p>Êtes-vous sûr de vouloir <b>supprimer</b> le type d’organisme <b>${this.organizationType.nameFr}</b>?</p>`;

      this.isConfirmDialogVisible = true;
    }
  }

  private isDeletePossible(): boolean {
    if (
      this.organizationType &&
      this.organizationType.programs &&
      this.organizationType.programs.length
    ) {
      this.messageService.add({
        detail: `Ce type d’organisme a déjà ${this.organizationType.programs.length} programmes associés. Si vous souhaitez quand même le supprimer, vous pouvez changer le type d’organisme des programmes associés, puis revenir ici pour le supprimer.`,
        severity: 'warn',
        summary: 'Suppression impossible',
      });

      this.isConfirmDialogVisible = false;
      this.action = undefined;

      return false;
    }

    return true;
  }

  edit() {
    if (!this.organizationType) {
      return;
    }

    this.router.navigate([
      'organization-types',
      this.organizationType.code,
      'update',
    ]);
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }
}
