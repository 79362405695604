<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4 class="font-bold mb-0">
        <ng-container
          *ngIf="
            organizationTypesEditService.editAction === 'create';
            else update
          "
          >Création type d’organisme</ng-container
        >
        <ng-template #update>Mise à jour type d’organisme</ng-template>

        <ng-container *ngIf="organizationTypesEditService.organizationType">
          <div
            class="inline-block mx-2 text-blue-600"
            [ngClass]="{
              'line-through':
                organizationTypesEditService.organizationType &&
                organizationTypesEditService.dirtyOrganizationType &&
                organizationTypesEditService.dirtyOrganizationType.nameFr !==
                  organizationTypesEditService.organizationType.nameFr
            }"
          >
            {{ organizationTypesEditService.organizationType.nameFr }}
          </div>
        </ng-container>

        <div
          class="inline-block mx-2 text-green-600"
          [ngClass]="{
            'font-bold':
              organizationTypesEditService.organizationType &&
              organizationTypesEditService.dirtyOrganizationType &&
              organizationTypesEditService.dirtyOrganizationType.nameFr !==
                organizationTypesEditService.organizationType.nameFr
          }"
          *ngIf="
            organizationTypesEditService.dirtyOrganizationType &&
            (!organizationTypesEditService.organizationType ||
              organizationTypesEditService.dirtyOrganizationType.nameFr !==
                organizationTypesEditService.organizationType.nameFr)
          "
        >
          {{ organizationTypesEditService.dirtyOrganizationType.nameFr }}
        </div>
      </h4>
    </div>

    <ng-template #loading>
      <p-card class="text-center">
        <p-progressSpinner></p-progressSpinner>
      </p-card>
    </ng-template>

    <p-card
      *ngIf="
        organizationTypesEditService.currentStep === 1 &&
          (organizationTypesEditService.uiReady$ | async);
        else loading
      "
    >
      <ng-template pTemplate="content">
        <form
          class="w-full md:w-10 mx-auto"
          (change)="organizationTypesEditService.setDirty()"
          [formGroup]="form"
        >
          <div class="flex align-items-start mb-4 gap-8">
            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="nameFr"
              >
                <img class="mr-2" src="assets/flags/fr.svg" height="20" />
                Nom en français
              </label>

              <input
                class="w-full mb-3"
                id="nameFr"
                (keyup.enter)="save()"
                formControlName="nameFr"
                pInputText
                style="padding: 1rem"
                maxlength="140"
              />

              <p>
                {{ 140 - formControls.nameFr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.nameFr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>

            <div class="w-6">
              <label
                class="block text-900 text-xl font-medium mb-2 flex align-items-center"
                for="nameAr"
              >
                <img class="mr-2" src="assets/flags/ma.svg" height="20" />
                Nom en arabe
              </label>

              <input
                class="w-full mb-3"
                id="nameAr"
                dir="rtl"
                (keyup.enter)="save()"
                formControlName="nameAr"
                pInputText
                style="padding: 1rem"
                maxlength="140"
              />

              <p>
                {{ 140 - formControls.nameAr.value?.length }}
                caractères restants.
              </p>

              <div>
                <be-green--ui--input-error
                  [control]="formControls.nameAr"
                  platform="web"
                ></be-green--ui--input-error>
              </div>
            </div>
          </div>
        </form>
      </ng-template>

      <ng-template pTemplate="footer">
        <div class="w-full md:w-10 mx-auto">
          <div class="grid grid-nogutter justify-content-end">
            <p-button
              (click)="save()"
              label="Suivant"
              icon="pi pi-angle-right"
              iconPos="right"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>
