import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UserApi } from '@be-green/api-services';
import { UserDto, UserStatusCode, ValueLabelDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { format } from 'date-fns';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--users-index',
  templateUrl: './users-index.component.html',
  styleUrls: ['./users-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersIndexComponent implements OnInit {
  @ViewChild('filterPersonIndexDataTable')
  filterPersonIndexDataTable!: ElementRef;
  @ViewChild('personIndexDataTable') personIndexDataTable!: Table;

  badges: ValueLabelDto[] = [
    { value: '0', label: 'Guerrier du gaspillage' },
    { value: '1', label: 'Gardien de la nature' },
    { value: '2', label: 'Lion du recyclage' },
  ];
  isLoading = false;
  recordCount = 0;
  statuses: ValueLabelDto[] = [
    { value: UserStatusCode.Active, label: 'Actif' },
    { value: UserStatusCode.New, label: 'Nouveau' },
    { value: UserStatusCode.Deactivated, label: 'Désactivé' },
    { value: UserStatusCode.Closed, label: 'Clôturé' },
  ];
  users!: UserDto[];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    private readonly errorService: ErrorService,
    private readonly userApi: UserApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Utilisateurs' }]);
    this.seoService.setTitle('Utilisateurs - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asUserDto(user: any): UserDto {
    return user as UserDto;
  }

  clearPersonsTable() {
    this.personIndexDataTable.clear();
    this.filterPersonIndexDataTable.nativeElement.value = '';
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  export() {
    this.isLoading = true;

    this.userApi.export().subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `users--${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'users',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.userApi
      .getAllPersons(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.users = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'success' | 'info' | 'warning' | 'danger' {
    return UtilsService.getUserStatusTagSeverity(statusCode);
  }
}
