import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoryDto } from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  getAllOfMine(programCode?: string): Observable<HistoryDto[]> {
    if (
      !this.authenticationService.isHandler &&
      !this.authenticationService.isUser
    ) {
      return of();
    }

    return this.httpClient.get<HistoryDto[]>(
      `/history${programCode ? '/program/' + programCode : ''}`,
    );
  }

  getAllOfUser(code: string): Observable<HistoryDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<HistoryDto[]>(`/history/admin/${code}`);
  }
}
