import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { OrganizationTypeDto } from '../organization-types';
import { GiftDto } from './gift.dto';
import { IconDto } from './icon.dto';
import { ProductDto } from './product.dto';

export const enum ProgramType {
  GoodDeal = 'goodDeal',
  Pro = 'pro',
  Recycling = 'recycling',
}

export const enum CollectionType {
  Deferred = 'deferred',
  RealTime = 'realTime',
}

export class ProgramDto {
  @IsNotEmpty()
  readonly code!: string;

  /**
   * DEPRECATED
   * ----------
   * Use "type!: ProgramType"
   *
   * Kept for backwards compatibility with:
   * - Handler mobile apps ≤ 1.2.1
   * - User mobile apps ≤ 1.2.0
   */
  // 'true' or 'false'
  @IsNotEmpty()
  readonly isGoodDeal!: boolean;

  @IsNotEmpty()
  readonly type!: ProgramType;

  @IsNotEmpty()
  readonly collectionType!: CollectionType;

  @IsOptional()
  readonly organizationType?: OrganizationTypeDto;

  @IsOptional()
  readonly affiliationCode?: string;

  @IsNotEmpty()
  readonly nameFr!: string;

  @IsOptional()
  readonly nameAr?: string;

  @IsNotEmpty()
  readonly startDate!: Date;

  @IsNotEmpty()
  readonly endDate!: Date;

  readonly handlerEndDate?: Date;

  @IsNotEmpty()
  readonly minimumProductQuantity!: number;

  readonly maximumProductQuantity?: number;

  readonly maximumUserBasketsPerDay?: number;

  @IsNotEmpty()
  readonly summaryFr!: string;

  @IsOptional()
  readonly summaryAr?: string;

  @IsNotEmpty()
  readonly descriptionFr!: string;

  @IsOptional()
  readonly descriptionAr?: string;

  @IsNotEmpty()
  readonly imageUrl!: string;

  @IsNotEmpty()
  readonly referrerPoints!: number;

  @IsNotEmpty()
  readonly referredPoints!: number;

  @IsOptional()
  readonly mobileVideoFr?: string;

  @IsOptional()
  readonly mobileVideoAr?: string;

  @IsOptional()
  readonly handlerVideoFr?: string;

  @IsOptional()
  readonly handlerVideoAr?: string;

  @IsNotEmpty()
  readonly userCount!: number;

  @IsNotEmpty()
  readonly collectedQuantity!: number;

  @IsNotEmpty()
  readonly collectedWeight!: number;

  @IsNotEmpty()
  readonly gramsOfCo2Saved!: number;

  @IsNotEmpty()
  readonly metersAllowed!: number;

  @IsNotEmpty()
  readonly kiloJoulesSaved!: number;

  @IsNotEmpty()
  readonly hoursOfLightingAllowed!: number;

  currentPeriodApprovedBasketCount?: number;
  currentRank?: number;

  @ValidateNested()
  @Type(() => GiftDto)
  @IsOptional()
  readonly gifts?: GiftDto[];

  @ValidateNested()
  @Type(() => IconDto)
  @IsOptional()
  readonly icons?: IconDto[];

  @ValidateNested()
  @Type(() => ProductDto)
  @IsOptional()
  readonly products?: ProductDto[];

  /**
   * Specific to auth'd user
   * -----------------------
   * UI-only
   */
  createdAt?: Date;
  gift?: boolean;
  pendingBasketCode?: string;
  progress?: number;
  ownPoints?: number;
  ownQuantity?: number;
  ownWeight?: number;
  referralCode?: string;
  updatedAt?: Date;
}
