import { Injectable } from '@angular/core';
import { ArticleApi } from '@be-green/api-services';
import { ArticleDto, EditArticleDto } from '@be-green/dto';
import { format } from 'date-fns';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class ArticlesEditService {
  private _currentStep = 1;
  private _editAction?: 'create' | 'update';
  private _isDirty = false;
  private _article?: ArticleDto;
  private _dirtyArticle?: EditArticleDto;
  private uiReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly articleApi: ArticleApi) {}

  get currentStep() {
    return this._currentStep;
  }

  get dirtyArticle() {
    return this._dirtyArticle;
  }

  get editAction() {
    return this._editAction;
  }

  get isDirty() {
    return this._isDirty;
  }

  get article() {
    return this._article;
  }

  get uiReady$() {
    return this.uiReadySubject.asObservable();
  }

  init() {
    this._isDirty = false;
    this.uiReadySubject.next(false);
  }

  nextStep() {
    if (this._currentStep < 4) {
      this._currentStep++;
    }
  }

  previousStep() {
    if (this._currentStep > 1) {
      this._currentStep--;
    }
  }

  saveDirtyArticle(): Observable<{ code: string } | void> {
    if (!this._dirtyArticle || !this.editAction) {
      return throwError(() => new Error(''));
    }

    const articleCodeToUpdate = this._article ? this._article.code : undefined;

    switch (this._editAction) {
      case 'create':
        return this.articleApi.create(this._dirtyArticle).pipe(
          tap(() => {
            this._dirtyArticle = undefined;
            this._isDirty = false;
            this._currentStep = 1;
            this.uiReadySubject.next(false);
          }),
        );

      case 'update':
        return this.articleApi
          .update(articleCodeToUpdate as string, this._dirtyArticle)
          .pipe(
            tap(() => {
              this._dirtyArticle = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      default:
        return throwError(() => new Error(''));
    }
  }

  setDirty(isDirty = true) {
    this._isDirty = isDirty;
  }

  setDirtyArticle(article: EditArticleDto) {
    this._dirtyArticle = { ...this._dirtyArticle, ...article };
  }

  setArticle(editAction: 'create' | 'update', article?: ArticleDto) {
    this._editAction = editAction;

    if (editAction === 'create' || !article) {
      this._dirtyArticle = undefined;
      this._article = undefined;
    } else {
      this._dirtyArticle = {
        ...article,
        date: format(new Date(article.date), 'yyyy-MM-dd'),
      };

      this._article = article;
    }

    this.uiReadySubject.next(true);
  }
}
