import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { BadgeComponent } from './badge/badge.component';
import { ConfirmOnLeaveDialogComponent } from './confirm-on-leave/confirm-on-leave-dialog.component';
import { ErrorDialogComponent } from './error/error-dialog.component';
import { InputErrorComponent } from './form-validation/input-error.component';
import { LoadingComponent } from './loading/loading.component';
import { MaterialDesignEmoticonComponent } from './material-design-emoticon/material-design-emoticon.component';
import { MaterialDesignIconComponent } from './material-design-icon/material-design-icon.component';
import { StateComponent } from './state/state.component';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,

    // Ionic
    IonicModule,

    // 3rd-party libraries
    ButtonModule,
    DynamicDialogModule,
    TranslateModule,
  ],

  declarations: [
    BadgeComponent,
    ConfirmOnLeaveDialogComponent,
    ErrorDialogComponent,
    InputErrorComponent,
    LoadingComponent,
    MaterialDesignEmoticonComponent,
    MaterialDesignIconComponent,
    StateComponent,
  ],

  exports: [
    BadgeComponent,
    InputErrorComponent,
    LoadingComponent,
    MaterialDesignEmoticonComponent,
    MaterialDesignIconComponent,
    StateComponent,
  ],

  providers: [DialogService],
})
export class UiServicesModule {}
