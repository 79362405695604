import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationTypeApi } from '@be-green/api-services';
import { EditOrganizationTypeDto } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
} from '@be-green/ui-services';
import { LayoutService } from '../../layout/layout.service';
import { OrganizationTypesEditService } from './organization-types-edit.service';

@Component({
  selector: 'be-green--admin--organization-types-edit',
  templateUrl: './organization-types-edit.component.html',
  styleUrls: ['./organization-types-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationTypesEditComponent
  implements OnInit, ComponentCanDeactivate
{
  form!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationTypeApi: OrganizationTypeApi,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    readonly organizationTypesEditService: OrganizationTypesEditService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Types d’organisme' }]);
    this.seoService.setTitle('Types d’organisme - Admin - Be Green');
  }

  ngOnInit(): void {
    this.organizationTypesEditService.init();

    const code = this.activatedRoute.snapshot.paramMap.get('code');

    if (code) {
      this.isLoading = true;

      this.organizationTypeApi.getOne(code).subscribe({
        next: (organizationType) => {
          this.organizationTypesEditService.setOrganizationType(
            'update',
            organizationType,
            organizationType as EditOrganizationTypeDto,
          );

          this.initForm();

          if (this.organizationTypesEditService.organizationType) {
            this.layoutService.registerBreadcrumbs([
              { label: 'Types d’organisme', routerLink: '/organization-types' },
              {
                label:
                  this.organizationTypesEditService.organizationType.nameFr,
                routerLink: `/organization-types/${code}`,
              },
            ]);

            this.seoService.setTitle(
              `${this.organizationTypesEditService.organizationType.nameFr}  - Types d’organisme - Admin - Be Green`,
            );
          }

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
    } else {
      this.organizationTypesEditService.setOrganizationType('create');
      this.initForm();
    }
  }

  isDirty() {
    return this.organizationTypesEditService.isDirty;
  }

  get formControls() {
    return this.form?.controls as {
      nameAr: AbstractControl;
      nameFr: AbstractControl;
    };
  }

  private initForm() {
    this.form = this.formBuilder.group({
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
    });

    if (this.organizationTypesEditService.dirtyOrganizationType) {
      this.form.patchValue(
        this.organizationTypesEditService.dirtyOrganizationType,
      );
    }
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.organizationTypesEditService.setDirtyOrganizationType(this.form.value);

    this.organizationTypesEditService.saveDirtyOrganizationType().subscribe({
      next: (value: { code: string } | void) => {
        if (value) {
          this.router.navigate(['/organization-types', value.code]);
        } else if (
          this.organizationTypesEditService.organizationType &&
          this.organizationTypesEditService.editAction === 'update'
        ) {
          this.router.navigate([
            '/organization-types',
            this.organizationTypesEditService.organizationType.code,
          ]);
        } else {
          this.router.navigate(['/organization-types']);
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.isLoading = false;
      },
    });
  }
}
