import { Type } from 'class-transformer';
import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
} from 'class-validator';

export class EditQualityDto {
  @IsOptional()
  readonly code?: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsNotEmpty()
  readonly points!: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @Min(0)
  @Max(100)
  @IsNotEmpty()
  readonly ratio!: number;
}
