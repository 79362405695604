<div class="card">
  <p-table
    #articleIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de {totalRecords}'
    "
    [dataKey]="'code'"
    [globalFilterFields]="[
      'contentAr',
      'contentFr',
      'sourceAr',
      'sourceFr',
      'date',
      'titleAr',
      'titleFr'
    ]"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [value]="articles"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex justify-content-between align-items-start flex-column sm:flex-row"
      >
        <div class="flex-column sm:flex-row mb-2">
          <button
            class="p-button-outlined sm:mb-2 mr-2"
            (click)="clearArticlesTable()"
            pButton
            label="Effacer tous les filtres"
            icon="pi pi-filter-slash"
          ></button>

          <button
            class="mb-2 p-button-success"
            routerLink="/articles/create"
            pButton
            label="Créer un article"
            icon="pi pi-plus"
          ></button>
        </div>

        <span class="p-input-icon-left mb-2">
          <i class="pi pi-search"></i>
          <input
            class="w-full"
            type="text"
            #filterArticleIndexDataTable
            (input)="
              articleIndexDataTable.filterGlobal(
                eventTargetValue($event),
                'contains'
              )
            "
            pInputText
            placeholder="Filtrer aussi par contenu"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th></th>

        <th style="min-width: 20rem" pSortableColumn="titleFr" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Titre
            </div>

            <p-sortIcon field="titleFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="titleAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Titre
            </div>

            <p-sortIcon field="titleAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="date">
          <div class="flex justify-content-between align-items-center">
            Date à afficher

            <p-sortIcon field="date"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="sourceFr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Source
            </div>

            <p-sortIcon field="sourceFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="sourceAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Source
            </div>

            <p-sortIcon field="sourceAr"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="titleFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="titleAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="date" field="date"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="sourceFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="sourceAr"></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-article>
      <ng-container *ngLet="asArticleDto(article) as article">
        <tr *ngIf="article">
          <td style="text-align: center">
            <div class="flex" style="line-height: 0">
              <p-image
                class="shadow-4"
                [preview]="true"
                [src]="imageBaseUrl + article.imageUrl"
                width="100"
              ></p-image>
            </div>
          </td>

          <td pFrozenColumn>
            {{ article.titleFr }}
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="article.titleAr">
              <span class="text-sm">{{ article.titleAr }}</span>
            </ng-container>
          </td>

          <td>
            {{ article.date | date : 'shortDate' : undefined : 'fr' }}
          </td>

          <td>
            <ng-container *ngIf="article.sourceFr; else notSet">
              <span class="text-sm">{{ article.sourceFr }}</span>
            </ng-container>
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="article.sourceAr; else notSet">
              <span class="text-sm">{{ article.sourceAr }}</span>
            </ng-container>
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/articles/' + article.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Aucun article trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="7">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
