<ng-template #loading>
  <p-card class="text-center">
    <p-progressSpinner></p-progressSpinner>
  </p-card>
</ng-template>

<p-card
  *ngIf="
    articlesEditService.currentStep === 3 &&
      (articlesEditService.uiReady$ | async);
    else loading
  "
>
  <ng-template pTemplate="content">
    <div class="grid">
      <div class="col-12 md:col-6">
        <ng-content *ngTemplateOutlet="headerFr"></ng-content>

        <p-editor
          [(ngModel)]="contentFr"
          [sanitize]="true"
          [modules]="quillModules"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <select class="ql-header" title="Style">
                <option value="1">Titre</option>
                <option value="2">Sous-titre</option>
                <option selected></option>
              </select>
            </span>

            <span class="ql-formats">
              <button class="ql-bold" type="button" title="Gras"></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-list"
                type="button"
                value="ordered"
                title="Liste numérotée"
              ></button>

              <button
                class="ql-list"
                type="button"
                value="bullet"
                title="Liste à puces"
              ></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-indent"
                type="button"
                value="-1"
                title="Désindenter"
              ></button>
              <button
                class="ql-indent"
                type="button"
                value="+1"
                title="Indenter"
              ></button>
            </span>

            <span class="ql-formats">
              <select class="ql-align" title="Alignement">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </span>

            <span class="ql-formats">
              <button
                class="ql-clean"
                type="button"
                title="Supprimer le formatage"
              ></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-link"
                type="button"
                title="Insérer un lien"
              ></button>
            </span>
          </ng-template>
        </p-editor>

        <div class="mt-2">
          <be-green--ui--input-error
            [control]="currentStepFormControls.contentFr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <ng-content *ngTemplateOutlet="headerAr"></ng-content>

        <p-editor
          [(ngModel)]="contentAr"
          [sanitize]="true"
          [modules]="quillModules"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <select class="ql-header" title="Style">
                <option value="1">Titre</option>
                <option value="2">Sous-titre</option>
                <option selected></option>
              </select>
            </span>

            <span class="ql-formats">
              <button class="ql-bold" type="button" title="Gras"></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-list"
                type="button"
                value="ordered"
                title="Liste numérotée"
              ></button>

              <button
                class="ql-list"
                type="button"
                value="bullet"
                title="Liste à puces"
              ></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-indent"
                type="button"
                value="-1"
                title="Désindenter"
              ></button>
              <button
                class="ql-indent"
                type="button"
                value="+1"
                title="Indenter"
              ></button>
            </span>

            <span class="ql-formats">
              <select class="ql-align" title="Alignement">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </span>

            <span class="ql-formats">
              <button
                class="ql-clean"
                type="button"
                title="Supprimer le formatage"
              ></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-link"
                type="button"
                title="Insérer un lien"
              ></button>
            </span>

            <span class="ql-formats">
              <button
                class="ql-direction"
                type="button"
                value="rtl"
                title="Changer la direction"
              ></button>
            </span>
          </ng-template>
        </p-editor>

        <div class="mt-2">
          <be-green--ui--input-error
            [control]="currentStepFormControls.contentAr"
            platform="web"
          ></be-green--ui--input-error>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full md:w-10 mx-auto">
      <div class="grid grid-nogutter justify-content-between">
        <button
          class="p-button-outlined"
          pButton
          (click)="previousStep()"
          label="Précédent"
          icon="pi pi-angle-left"
          iconPos="left"
        ></button>

        <p-button
          (click)="nextStep()"
          label="Suivant"
          icon="pi pi-angle-right"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-card>

<ng-template #headerAr>
  <div class="flex align-items-center tlb--editor-header">
    <img class="mr-2" src="assets/flags/ma.svg" height="20" />

    <div style="font-weight: 700">Contenu en arabe</div>
  </div>
</ng-template>

<ng-template #headerFr>
  <div class="flex align-items-center tlb--editor-header">
    <img class="mr-2" src="assets/flags/fr.svg" height="20" />

    <div style="font-weight: 700">Contenu en français</div>
  </div>
</ng-template>
