<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start"></div>

  <div class="p-toolbar-group-end">
    <button
      icon="pi pi-thumbs-up"
      label="Import résultats"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="uploadResults()"
    ></button>

    <button
      class="p-button-help ml-2"
      icon="pi pi-upload"
      label="Export"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="export()"
    ></button>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="card">
  <p-table
    #redemptionIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de  ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="apiPageSize"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [totalRecords]="recordCount"
    [value]="redemptions"
    (onLazyLoad)="fetchNextPage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          style="min-width: 20rem"
          pSortableColumn="participation.program.nameFr"
          pFrozenColumn
        >
          <div class="flex justify-content-between align-items-center">
            Programme

            <p-sortIcon field="participation.program.nameFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="gift.nameFr">
          <div class="flex justify-content-between align-items-center">
            Cadeau

            <p-sortIcon field="gift.nameFr"></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 20rem"
          pSortableColumn="participation.person.firstName, participation.person.lastName"
        >
          <div class="flex justify-content-between align-items-center">
            Participant

            <p-sortIcon
              field="participation.person.firstName, participation.person.lastName"
            ></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="created_at">
          <div class="flex justify-content-between align-items-center">
            Date demande

            <p-sortIcon field="created_at"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="status.code">
          <div class="flex justify-content-between align-items-center">
            État

            <p-sortIcon field="status.code"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="updated_at">
          <div class="flex justify-content-between align-items-center">
            Date état

            <p-sortIcon field="updated_at"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="previousPoints">
          <div class="flex justify-content-between align-items-center">
            Solde avant

            <p-sortIcon field="previousPoints"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="points">
          <div class="flex justify-content-between align-items-center">
            Solde utilisé

            <p-sortIcon field="points"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn>
          <p-columnFilter type="text" field="participation.program.nameFr">
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="gift.nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="participation.person.firstName, participation.person.lastName"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="created_at"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="status.code"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="statuses"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getStatusTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="updated_at"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="previousPoints"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="numeric" field="points"></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-redemption>
      <ng-container *ngLet="asRedemptionDto(redemption) as redemption">
        <tr *ngIf="redemption">
          <td pFrozenColumn>
            <a
              [routerLink]="
                '/programs/' + redemption.participation?.program?.code
              "
            >
              {{ redemption.participation?.program?.nameFr }}

              <i class="pi pi-external-link"></i>
            </a>
          </td>

          <td>
            {{ redemption.gift?.nameFr }}
          </td>

          <td>
            {{ redemption.participation?.person?.firstName }}
            {{ redemption.participation?.person?.lastName }}
          </td>

          <td>
            {{ redemption.createdAt | date : 'medium' : undefined : 'fr' }}
          </td>

          <td>
            <p-tag
              [value]="redemption.status.name"
              [severity]="getStatusTagSeverity(redemption.status.code)"
              *ngIf="redemption.status"
            ></p-tag>
          </td>

          <td>
            {{ redemption.updatedAt | date : 'medium' : undefined : 'fr' }}
          </td>

          <td class="text-right">
            {{ redemption.previousPoints | number : '1.0-0' : 'fr' }}
          </td>

          <td class="text-right">
            {{ redemption.points | number : '1.0-0' : 'fr' }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/redemptions/' + redemption.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9">Aucun échange trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="9">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<p-dialog
  class="p-fluid"
  header="Importer un fichier de résultats"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isUploadResultsDialogVisible"
>
  <ng-template pTemplate="content">
    <div class="text-center">
      <p-fileUpload
        name="file"
        mode="basic"
        chooseIcon="pi pi-file-excel"
        chooseLabel="Choisir un fichier Excel (500 ko max.)"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        [auto]="true"
        [maxFileSize]="500 * 1024"
        [url]="uploadResultsFileUploadUrl"
        (onError)="onUploadError($event)"
        (onProgress)="isLoading = true"
        (onUpload)="onUploadResultsSuccess($event)"
      ></p-fileUpload>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isUploadResultsDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>
