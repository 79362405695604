<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="material; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center">
            <div class="flex mr-4">
              <div
                class="w-6rem h-6rem flex align-items-center justify-content-center"
                [style.background-color]="'#' + material.color"
                *ngIf="material"
              >
                <p-avatar
                  size="xlarge"
                  [image]="imageBaseUrl + material.imageUrl"
                  *ngIf="material.imageUrl"
                ></p-avatar>
              </div>
            </div>

            <div class="font-bold text-3xl text-900">
              {{ material.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ material.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="material.updatedAt; else notSet">
                  {{ material.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <p-splitButton
            [disabled]="isLoading"
            [icon]="actionMenuItems[0].icon!!"
            [label]="actionMenuItems[0].label!!"
            [model]="actionMenuItems"
            [styleClass]="actionDefaultStyleClass"
            (onClick)="actionMenuItems[0].command!!()"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </div>

  <p-messages></p-messages>

  <div class="col-12 md:col-4">
    <div class="card">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Général</h3>
      </div>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="material; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-2 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nom
            </div>

            <div class="text-900 w-full xl:w-10">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="material.nameFr; else notSet">{{
                  material.nameFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="material.nameAr; else notSet">{{
                  material.nameAr
                }}</ng-container>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <div class="card">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Impact énergie</h3>
      </div>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="material; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilo-joules économisés par gramme recyclé
            </div>

            <div class="text-900 w-full xl:w-2">
              {{
                material.kiloJoulesSavedWhenRecyclingOneGram
                  | number : '1.2-2' : 'fr'
              }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Heures d'éclairage par kilo-joule
            </div>

            <div class="text-900 w-full xl:w-2">
              {{
                material.hoursOfLightingAllowedByOneKiloJoule
                  | number : '1.2-2' : 'fr'
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <div class="card">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Impact CO<sub>2</sub></h3>
      </div>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="material; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Grammes de CO<sub>2</sub> économisés par gramme recyclé
            </div>

            <div class="text-900 w-full xl:w-2">
              {{
                material.gramsOfCo2SavedWhenRecyclingOneGram
                  | number : '1.2-2' : 'fr'
              }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilomètres parcourus par kilogramme de CO<sub>2</sub>
            </div>

            <div class="text-900 w-full xl:w-2">
              {{
                material.kilometersAllowedByOneKiloOfCo2
                  | number : '1.2-2' : 'fr'
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<ng-template #noImage>
  <img
    class="shadow-4"
    src="assets/layout/images/empty-300x240.jpg"
    width="100"
  />
</ng-template>

<p-dialog
  header="Confirmation"
  [breakpoints]="{ '960px': '50vw', '640px': '85vw' }"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isConfirmDialogVisible"
>
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

    <div>
      <span [innerHTML]="confirmationDialogMessage"></span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isConfirmDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirm()"
    ></button>
  </ng-template>
</p-dialog>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>
