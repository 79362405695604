import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditArticleDto } from '@be-green/dto';
import { ErrorService, InputValidationService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { format } from 'date-fns';
import { ArticlesEditService } from '../../articles-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--articles-edit-steps-settings',
  templateUrl: './articles-edit-steps-settings.component.html',
  styleUrls: ['./articles-edit-steps-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesEditStepsSettingsComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;
  today = new Date();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly articlesEditService: ArticlesEditService,
  ) {}

  ngOnInit(): void {
    if (!this.articlesEditService.dirtyArticle) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.articlesEditService.currentStep === 4) {
      this.articlesEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      date: AbstractControl;
      readingTimeInMinutes: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      date: [null, Validators.compose([Validators.required])],
      readingTimeInMinutes: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
          Validators.min(1),
        ]),
      ],
    });

    if (this.articlesEditService.dirtyArticle) {
      this.currentStepForm.patchValue(this.articlesEditService.dirtyArticle);

      if (this.articlesEditService.dirtyArticle.date) {
        this.currentStepFormControls.date.patchValue(
          new Date(this.articlesEditService.dirtyArticle.date),
        );
      }
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.articlesEditService.setDirtyArticle(<EditArticleDto>{
      date: format(this.currentStepFormControls.date.value, 'yyyy-MM-dd'),
      readingTimeInMinutes:
        this.currentStepFormControls.readingTimeInMinutes.value,
    });

    this.articlesEditService.saveDirtyArticle().subscribe({
      next: (value: { code: string } | void) => {
        if (value) {
          this.router.navigate(['/articles', value.code]);
        } else if (
          this.articlesEditService.article &&
          this.articlesEditService.editAction === 'update'
        ) {
          this.router.navigate([
            '/articles',
            this.articlesEditService.article.code,
          ]);
        } else {
          this.router.navigate(['/articles']);
        }

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.isLoading = false;
      },
    });
  }

  previousStep() {
    this.articlesEditService.previousStep();

    this.router.navigate(['content'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
