import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramApi } from '@be-green/api-services';
import { CollectionType, ProgramType } from '@be-green/dto';
import {
  ComponentCanDeactivate,
  ErrorService,
  SeoService,
  UtilsService,
} from '@be-green/ui-services';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../layout/layout.service';
import { ProgramsEditService } from './programs-edit.service';

@Component({
  selector: 'be-green--admin--programs-edit',
  templateUrl: './programs-edit.component.html',
  styleUrls: ['./programs-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramsEditComponent implements OnInit, ComponentCanDeactivate {
  isLoading = false;
  steps: MenuItem[] = [
    { label: 'Général', routerLink: 'general' },
    { label: 'Illustration', routerLink: 'illustration' },
    { label: 'Description', routerLink: 'description' },
    { label: 'Paramètres', routerLink: 'settings' },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly programApi: ProgramApi,
    readonly programsEditService: ProgramsEditService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Programmes' }]);
    this.seoService.setTitle('Programmes - Admin - Be Green');
  }

  ngOnInit(): void {
    this.programsEditService.init();

    const code = this.activatedRoute.snapshot.paramMap.get('code');

    if (code) {
      this.isLoading = true;

      this.programApi.getOne(code).subscribe({
        next: (program) => {
          this.programsEditService.setProgram('update', program);

          if (this.programsEditService.program) {
            this.layoutService.registerBreadcrumbs([
              { label: 'Programmes', routerLink: '/programs' },
              {
                label: this.programsEditService.program.nameFr,
                routerLink: `/programs/${code}`,
              },
            ]);

            this.seoService.setTitle(
              `${this.programsEditService.program.nameFr}  - Programmes - Admin - Be Green`,
            );
          }
          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
    } else {
      this.programsEditService.setProgram('create');
    }
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'info' | 'danger' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  isDirty() {
    return this.programsEditService.isDirty;
  }
}
