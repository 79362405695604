import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { ProgramHandlerDto } from '../handlers';
import { QualityDto } from '../qualities';
import { UserDto } from '../users';
import { BasketProductDto } from './basket-product.dto';
import { BasketStatusDto } from './basket-status.dto';
import { ParticipationDto } from './participation.dto';

export class BasketDto {
  @IsNotEmpty()
  readonly code!: string;

  @ValidateNested()
  @Type(() => ParticipationDto)
  @IsNotEmpty()
  readonly participation!: ParticipationDto;

  @ValidateNested()
  @Type(() => ProgramHandlerDto)
  @IsOptional()
  readonly programHandler?: ProgramHandlerDto;

  @ValidateNested()
  @Type(() => BasketDto)
  @IsOptional()
  readonly collectorBasket?: BasketDto;

  @IsOptional()
  readonly afterPhotoUrl?: string;

  @IsOptional()
  readonly beforePhotoUrl?: string;

  @ValidateNested()
  @Type(() => QualityDto)
  @IsOptional()
  readonly quality?: QualityDto;

  @ValidateNested()
  @Type(() => BasketStatusDto)
  @IsOptional()
  readonly status?: BasketStatusDto;

  @IsOptional()
  readonly declaredQuantity?: number;

  @IsOptional()
  readonly declaredWeight?: number;

  @IsOptional()
  readonly expectedPoints?: number;

  @IsOptional()
  readonly validatedPoints?: number;

  @IsOptional()
  readonly validatedQuantity?: number;

  @IsOptional()
  readonly validatedWeight?: number;

  @Type(() => Date)
  @IsOptional()
  readonly createdAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly createdBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly updatedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly updatedBy?: UserDto;

  @Type(() => Date)
  @IsOptional()
  readonly deletedAt?: Date;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly deletedBy?: UserDto;

  basketProducts?: BasketProductDto[];
  localNotificationId?: number;
  isRead?: boolean;
}
