<div class="card">
  <p-table
    #qualityIndexDataTable
    styleClass="p-datatable-gridlines"
    [dataKey]="'code'"
    [loading]="isLoading"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [scrollable]="true"
    [value]="qualities"
  >
    <ng-template pTemplate="caption">
      <p-messages key="qualities"></p-messages>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="min-width: 6rem; width: 6rem" pFrozenColumn></th>

        <th style="min-width: 20rem">Ratio</th>

        <th style="min-width: 20rem">Points</th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-quality>
      <ng-container *ngLet="asQualityDto(quality) as quality">
        <tr *ngIf="quality">
          <td style="text-align: center" pFrozenColumn>
            <be-green--ui--mdi-emoticon
              [type]="quality.code"
            ></be-green--ui--mdi-emoticon>
          </td>

          <td class="text-right">
            {{ quality.ratio }}
          </td>

          <td class="text-right">
            {{ quality.points }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded p-button-warning"
              pButton
              pRipple
              icon="pi pi-pencil"
              (click)="editQuality(quality)"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">Aucune qualité trouvée.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="4">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  class="p-fluid"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isQualityDialogVisible"
>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2" *ngIf="quality">
      <be-green--ui--mdi-emoticon
        [type]="quality.code"
      ></be-green--ui--mdi-emoticon>

      <span class="text-xl font-bold"> Editer la qualité </span>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <form class="w-full md:w-10 mx-auto" [formGroup]="qualityForm">
      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="ratio">
            Ratio
          </label>

          <input
            class="w-full mb-3"
            id="ratio"
            (keyup.enter)="saveQuality()"
            formControlName="ratio"
            pInputText
            style="padding: 1rem"
            maxlength="64"
          />

          <div>
            <be-green--ui--input-error
              [control]="qualityFormControls.ratio"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="points">
            Points
          </label>

          <input
            class="w-full mb-3"
            id="points"
            (keyup.enter)="saveQuality()"
            formControlName="points"
            pInputText
            style="padding: 1rem"
            maxlength="64"
          />

          <div>
            <be-green--ui--input-error
              [control]="qualityFormControls.points"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isQualityDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveQuality()"
    ></button>
  </ng-template>
</p-dialog>
