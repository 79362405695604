import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MaterialsEditService } from '../../materials-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--materials-edit-steps-energy-impact',
  templateUrl: './materials-edit-steps-energy-impact.component.html',
  styleUrls: ['./materials-edit-steps-energy-impact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialsEditStepsEnergyImpactComponent implements OnInit {
  currentStepForm!: FormGroup;
  isLoading = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly materialsEditService: MaterialsEditService,
  ) {}

  ngOnInit(): void {
    if (!this.materialsEditService.dirtyMaterial) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.materialsEditService.currentStep === 3) {
      this.materialsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      kiloJoulesSavedWhenRecyclingOneGram: AbstractControl;
      hoursOfLightingAllowedByOneKiloJoule: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      kiloJoulesSavedWhenRecyclingOneGram: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(9.99),
        ]),
      ],
      hoursOfLightingAllowedByOneKiloJoule: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0.01),
          Validators.max(9.99),
        ]),
      ],
    });

    if (this.materialsEditService.dirtyMaterial) {
      this.currentStepForm.patchValue(this.materialsEditService.dirtyMaterial);
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.materialsEditService.setDirtyMaterial(this.currentStepForm.value);

    this.materialsEditService.nextStep();

    this.router.navigate(['co2-impact'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  previousStep() {
    this.materialsEditService.previousStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
