import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { QualityApi } from '@be-green/api-services';
import { EditQualityDto, QualityDto } from '@be-green/dto';
import {
  ErrorService,
  InputValidationService,
  SeoService,
} from '@be-green/ui-services';
import { MessageService } from 'primeng/api';
import { first, share } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--qualities-index',
  templateUrl: './qualities-index.component.html',
  styleUrls: ['./qualities-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QualitiesIndexComponent implements OnInit {
  isLoading = false;
  isQualityActionDialogVisible = false;
  isQualityDialogVisible = false;
  qualities!: QualityDto[];
  quality: QualityDto | null = null;
  qualityForm!: FormGroup;

  constructor(
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly qualityApi: QualityApi,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Qualité de collecte' }]);
    this.seoService.setTitle('Qualité de collecte - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllQualities();
  }

  get qualityFormControls() {
    return this.qualityForm?.controls as {
      points: AbstractControl;
      ratio: AbstractControl;
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asQualityDto(quality: any): QualityDto {
    return quality as QualityDto;
  }

  editQuality(quality: QualityDto) {
    this.isLoading = false;
    this.quality = { ...quality };

    this.initQualityForm();

    this.qualityForm.patchValue(this.quality);

    this.isQualityDialogVisible = true;
  }

  private fetchQuality(code: string) {
    return this.qualityApi.getOne(code).pipe(first(), share());
  }

  private getAllQualities() {
    this.qualityApi.getAll().subscribe({
      next: (qualities) => {
        this.qualities = qualities;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  private initQualityForm() {
    this.qualityForm = this.formBuilder.group({
      points: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.integer,
        ]),
      ],
      ratio: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(100),
        ]),
      ],
    });
  }

  saveQuality() {
    this.qualityForm.markAllAsTouched();

    if (!this.quality || this.qualityForm.invalid) {
      return;
    }

    this.isLoading = true;

    const editQualityDto: EditQualityDto = {
      ...this.quality,
      ...this.qualityForm.value,
    };

    this.qualityApi
      .update(this.quality.code, editQualityDto)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.fetchQuality((this.quality as QualityDto).code).subscribe({
            next: (data) => {
              const index = this.qualities.findIndex(
                (quality) => quality.code === (this.quality as QualityDto).code,
              );

              this.qualities[index] = data;

              this.messageService.add({
                detail: `Qualité mise à jour`,
                key: 'qualities',
                life: 3000,
                severity: 'success',
                summary: 'Succès',
              });

              this.isQualityDialogVisible = false;
              this.quality = null;

              this.isLoading = false;
            },

            error: async (error: HttpErrorResponse) => {
              const { title, message } = await this.errorService.handleError(
                error,
              );

              this.isLoading = false;

              this.messageService.add({
                detail: message,
                key: 'qualities',
                severity: 'error',
                summary: title,
              });
            },
          });
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.isLoading = false;

          this.messageService.add({
            detail: message,
            key: 'qualities',
            severity: 'error',
            summary: title,
          });
        },
      });
  }
}
