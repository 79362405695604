<div
  class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
>
  <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
    <div class="col-12 mt-5 xl:mt-0 text-center">
      <img
        class="mb-5"
        src="assets/layout/images/logo-dark.svg"
        alt="Be Green"
        style="width: 81px; height: 96px"
      />
    </div>

    <div
      class="col-12 xl:col-6"
      style="
        border-radius: 56px;
        padding: 0.3rem;
        border: solid 3px var(--primary-color);
      "
    >
      <div
        class="h-full w-full m-0 py-7 px-4"
        style="
          border-radius: 53px;
          background: linear-gradient(
            180deg,
            var(--surface-50) 38.9%,
            var(--surface-0)
          );
        "
      >
        <div class="text-center mb-5">
          <span class="text-900 text-3xl font-medium mb-3">{{
            'ADMIN_UI.RESET_ACCESS.TITLE' | translate
          }}</span>
        </div>

        <div class="text-center mb-5">
          <p-message
            *ngIf="errorMessage"
            text="{{ errorMessage.detail }}"
            severity="{{ errorMessage.severity }}"
          >
          </p-message>
        </div>

        <form
          class="w-full md:w-10 mx-auto"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="submitResetPasswordForm()"
        >
          <div class="mb-4">
            <label
              class="block text-900 text-xl font-medium mb-2"
              for="email"
              >{{ 'ADMIN_UI.RESET_ACCESS.LABELS.EMAIL' | translate }}</label
            >
            <input
              class="w-full mb-3"
              id="email"
              type="email"
              autocomplete="email"
              formControlName="email"
              pInputText
              style="padding: 1rem"
            />
            <div>
              <be-green--ui--input-error
                [control]="resetPasswordFormControls.email"
                platform="web"
              ></be-green--ui--input-error>
            </div>
          </div>

          <div class="mb-4">
            <label
              class="block text-900 font-medium text-xl mb-2"
              for="password"
              >{{ 'ADMIN_UI.RESET_ACCESS.LABELS.PASSWORD' | translate }}</label
            >
            <p-password
              id="password"
              [feedback]="false"
              [toggleMask]="true"
              autocapitalize="off"
              autocomplete="current-password"
              formControlName="password"
              styleClass="w-full mb-3"
            ></p-password>
            <div>
              <be-green--ui--input-error
                [control]="resetPasswordFormControls.password"
                platform="web"
              ></be-green--ui--input-error>
            </div>
          </div>

          <button
            class="w-full p-3 text-xl"
            [loading]="submitting"
            [label]="'ADMIN_UI.RESET_ACCESS.BUTTONS.SUBMIT' | translate"
            pButton
            pRipple
          ></button>
        </form>
      </div>
    </div>

    <div class="col-12 mt-5 xl:mt-0 text-center">
      {{ appVersion }}
    </div>
  </div>
</div>
