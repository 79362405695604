import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ProgramApi } from '@be-green/api-services';
import {
  CollectionType,
  ProgramDto,
  ProgramType,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--programs-index',
  templateUrl: './programs-index.component.html',
  styleUrls: ['./programs-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramsIndexComponent implements OnInit {
  @ViewChild('filterProgramIndexDataTable')
  filterProgramIndexDataTable!: ElementRef;
  @ViewChild('programIndexDataTable') programIndexDataTable!: Table;

  collectionTypes: ValueLabelDto[] = [
    { value: CollectionType.RealTime, label: 'Temps réel' },
    { value: CollectionType.Deferred, label: 'Différée' },
  ];
  isLoading = false;
  programs!: ProgramDto[];
  types: ValueLabelDto[] = [
    { value: ProgramType.GoodDeal, label: 'Bon plan' },
    { value: ProgramType.Pro, label: 'Pro' },
    { value: ProgramType.Recycling, label: 'Recyclage' },
  ];

  constructor(
    @Inject('IMAGE_BASE_URL') readonly imageBaseUrl: string,
    private readonly errorService: ErrorService,
    private readonly programApi: ProgramApi,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Programmes' }]);
    this.seoService.setTitle('Programmes - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllPrograms();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asProgramDto(program: any): ProgramDto {
    return program as ProgramDto;
  }

  clearProgramsTable() {
    this.programIndexDataTable.clear();
    this.filterProgramIndexDataTable.nativeElement.value = '';
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  private getAllPrograms() {
    this.programApi.getAll().subscribe({
      next: (programs) => {
        this.programs = programs.map((program) => ({
          ...program,
          createdAt: program.createdAt
            ? new Date(program.createdAt)
            : undefined,
          endDate: new Date(program.endDate),
          startDate: new Date(program.startDate),
          updatedAt: program.updatedAt
            ? new Date(program.updatedAt)
            : undefined,
        }));

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'info' | 'danger' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    this.isLoading = true;

    this.programApi
      .reorder(this.programs.map((program) => program.code))
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }
}
