<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="article; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center">
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                width="100"
                [preview]="true"
                [src]="imageBaseUrl + article.imageUrl"
                *ngIf="article.imageUrl; else noPicture"
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="article.titleFr.charAt(0)"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">
              {{ article.titleFr }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ article.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="article.updatedAt; else notSet">
                  {{ article.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <p-splitButton
            [disabled]="isLoading"
            [icon]="actionMenuItems[0].icon!!"
            [label]="actionMenuItems[0].label!!"
            [model]="actionMenuItems"
            [styleClass]="actionDefaultStyleClass"
            (onClick)="actionMenuItems[0].command!!()"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </div>

  <p-messages></p-messages>

  <div class="col-12 md:col-6">
    <div class="card">
      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="article; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Titre
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="article.titleFr; else notSet">{{
                  article.titleFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="article.titleAr; else notSet">{{
                  article.titleAr
                }}</ng-container>
              </div>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Date à afficher
            </div>

            <div class="text-900 w-full xl:w-8">
              {{ article.date | date : 'mediumDate' : '' : 'fr' }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-6">
    <div class="card">
      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="article; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Source
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="article.sourceFr; else notSet">{{
                  article.sourceFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="article.sourceAr; else notSet">{{
                  article.sourceAr
                }}</ng-container>
              </div>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Temps de lecture en minutes
            </div>

            <div class="text-900 w-full xl:w-8">
              {{ article.readingTimeInMinutes }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="col-12">
  <div class="grid" *ngIf="article; else loadingSkeleton">
    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/fr.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Contenu en français</h3>
          </div>
        </div>

        <div>
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="article.contentFr"
              *ngIf="article.contentFr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/ma.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Contenu en arabe</h3>
          </div>
        </div>

        <div>
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="article.contentAr"
              dir="rtl"
              *ngIf="article.contentAr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<ng-template #noImage>
  <img
    class="shadow-4"
    src="assets/layout/images/empty-300x240.jpg"
    width="100"
  />
</ng-template>

<p-dialog
  header="Confirmation"
  [breakpoints]="{ '960px': '50vw', '640px': '85vw' }"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isConfirmDialogVisible"
>
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

    <div>
      <span [innerHTML]="confirmationDialogMessage"></span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isConfirmDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirm()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  header="Recadrer l’image (vers un ratio de 3/2)"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isCropperDialogVisible"
>
  <ng-template pTemplate="content">
    <image-cropper
      #imageCropper
      [aspectRatio]="3 / 2"
      [autoCrop]="false"
      [canvasRotation]="imageRotation"
      [imageBase64]="selectedImageData"
      [maintainAspectRatio]="true"
      [onlyScaleDown]="true"
      [resizeToWidth]="700"
      [transform]="imageTransforms"
      (cropperReady)="onCropperReady($event)"
      (imageCropped)="onImageCropped($event)"
      (loadImageFailed)="onLoadImageFailed()"
    ></image-cropper>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="grid" *ngIf="isCropperReady">
      <div class="w-3 text-left">
        <button
          class="p-button-text p-button-danger"
          icon="pi pi-times"
          label="Annuler"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cancelImageCropModal()"
        ></button>
      </div>

      <div class="w-6 text-center">
        <p-button
          icon="pi pi-replay"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateLeft()"
        ></p-button>

        <p-button
          icon="pi pi-refresh"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateRight()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-h"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipHorizontal()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-v"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipVertical()"
        ></p-button>

        <p-button
          icon="pi pi-search-minus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale <= imageScaleMin"
          [loading]="isLoading"
          (click)="zoomOut()"
        ></p-button>

        <p-button
          icon="pi pi-search-plus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale >= imageScaleMax"
          [loading]="isLoading"
          (click)="zoomIn()"
        ></p-button>
      </div>

      <div class="w-3 text-right">
        <button
          class="p-button-text p-button-success"
          icon="pi pi-check"
          label="Enregistrer"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cropAndUploadImage()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>
