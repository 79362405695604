import { Injectable } from '@angular/core';
import { MaterialApi } from '@be-green/api-services';
import { EditMaterialDto, MaterialDto } from '@be-green/dto';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class MaterialsEditService {
  private _currentStep = 1;
  private _editAction?: 'create' | 'update';
  private _isDirty = false;
  private _material?: MaterialDto;
  private _dirtyMaterial?: EditMaterialDto;
  private uiReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly materialApi: MaterialApi) {}

  get currentStep() {
    return this._currentStep;
  }

  get dirtyMaterial() {
    return this._dirtyMaterial;
  }

  get editAction() {
    return this._editAction;
  }

  get isDirty() {
    return this._isDirty;
  }

  get material() {
    return this._material;
  }

  get uiReady$() {
    return this.uiReadySubject.asObservable();
  }

  init() {
    this._isDirty = false;
    this.uiReadySubject.next(false);
  }

  nextStep() {
    if (this._currentStep < 4) {
      this._currentStep++;
    }
  }

  previousStep() {
    if (this._currentStep > 1) {
      this._currentStep--;
    }
  }

  saveDirtyMaterial(): Observable<{ code: string } | void> {
    if (!this._dirtyMaterial || !this.editAction) {
      return throwError(() => new Error(''));
    }

    const materialCodeToUpdate = this._material
      ? this._material.code
      : undefined;

    switch (this._editAction) {
      case 'create':
        return this.materialApi.create(this._dirtyMaterial).pipe(
          tap(() => {
            this._dirtyMaterial = undefined;
            this._isDirty = false;
            this._currentStep = 1;
            this.uiReadySubject.next(false);
          }),
        );

      case 'update':
        return this.materialApi
          .update(materialCodeToUpdate as string, this._dirtyMaterial)
          .pipe(
            tap(() => {
              this._dirtyMaterial = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      default:
        return throwError(() => new Error(''));
    }
  }

  setDirty(isDirty = true) {
    this._isDirty = isDirty;
  }

  setDirtyMaterial(material: EditMaterialDto) {
    this._dirtyMaterial = { ...this._dirtyMaterial, ...material };
  }

  setMaterial(
    editAction: 'create' | 'update',
    material?: MaterialDto,
    dirtyMaterial?: EditMaterialDto,
  ) {
    this._editAction = editAction;

    if (editAction === 'create' || !material || !dirtyMaterial) {
      this._dirtyMaterial = undefined;
      this._material = undefined;
    } else {
      this._dirtyMaterial = dirtyMaterial;
      this._material = material;
    }

    this.uiReadySubject.next(true);
  }
}
