import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IconApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  delete(code: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(`/icons/admin/${code}`);
  }

  reorder(code: string, codes: string[]): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(`/programs/${code}/reorder/icons`, codes);
  }
}
