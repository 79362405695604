import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParticipationDto, QueryUiDto } from '@be-green/dto';
import { AuthenticationService, UtilsService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParticipationApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  exportReferrals(): Observable<Blob> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get(`/participations/referrals/export`, {
      responseType: 'blob',
    });
  }

  getAllOfUser(code: string): Observable<ParticipationDto[]> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<ParticipationDto[]>(`/participations/${code}`);
  }

  getAllReferrals(
    queryUiDto: QueryUiDto,
  ): Observable<{ rows: ParticipationDto[]; count: number }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<{ rows: ParticipationDto[]; count: number }>(
      `/participations/referrals`,
      {
        params: UtilsService.fromQueryUiDtoToHttpParams(queryUiDto),
      },
    );
  }

  removeAffiliatedOne(referralCode: string): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.delete<void>(
      `/participations/affiliated/${referralCode}`,
    );
  }
}
