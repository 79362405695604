import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutService } from './layout.service';

@Component({
  selector: 'be-green--admin--layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0px',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
})
export class LayoutComponent implements AfterViewInit, OnDestroy {
  @Input() menuMode: 'static' | 'overlay' = 'static';

  menuClick = false;
  menuInactiveDesktop = false;
  menuActiveMobile = false;
  overlayMenuActive = false;
  profileActive = false;
  staticMenuInactive = false;
  subscription!: Subscription;
  topMenuActive = false;
  topMenuButtonClick = false;
  topMenuLeaving = false;

  constructor(
    readonly layoutService: LayoutService,
    private readonly renderer: Renderer2,
  ) {}

  documentClickListener: (() => void) | undefined;

  ngAfterViewInit() {
    // hides the overlay menu and top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', () => {
      if (!this.isDesktop()) {
        if (!this.menuClick) {
          this.menuActiveMobile = false;
        }

        if (!this.topMenuButtonClick) {
          this.hideTopMenu();
        }
      } else {
        if (!this.menuClick && this.isOverlay()) {
          this.menuInactiveDesktop = true;
        }

        if (!this.menuClick) {
          this.overlayMenuActive = false;
        }
      }

      this.menuClick = false;
      this.topMenuButtonClick = false;
    });
  }

  toggleMenuActiveMobile(value: boolean) {
    this.menuActiveMobile = value;
  }

  toggleMenuInactiveDesktop(value: boolean) {
    this.menuInactiveDesktop = value;
  }

  toggleMenu(event: Event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuInactive = !this.staticMenuInactive;
    } else {
      this.menuActiveMobile = !this.menuActiveMobile;
      this.topMenuActive = false;
    }

    event.preventDefault();
  }

  toggleProfile(event: Event) {
    this.profileActive = !this.profileActive;
    event.preventDefault();
  }

  toggleTopMenu(event: Event) {
    this.topMenuButtonClick = true;
    this.menuActiveMobile = false;

    if (this.topMenuActive) {
      this.hideTopMenu();
    } else {
      this.topMenuActive = true;
    }

    event.preventDefault();
  }

  hideTopMenu() {
    this.topMenuLeaving = true;
    setTimeout(() => {
      this.topMenuActive = false;
      this.topMenuLeaving = false;
    }, 1);
  }

  onMenuClick() {
    this.menuClick = true;
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 992;
  }

  isMobile() {
    return window.innerWidth < 1024;
  }

  onSearchClick() {
    this.topMenuButtonClick = true;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
