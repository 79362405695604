import { Injectable } from '@angular/core';
import { BasketDto, LanguageCode, VersionMigrationDto } from '@be-green/dto';
import { combineLatest, distinctUntilChanged, tap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { BiometryService } from '../biometry/biometry.service';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private completedMigrationsKey = 'begreen__completed_migrations';
  private isOnboardingDoneKey = 'begreen__is_onboarding_done';
  private languageCodeKey = 'begreen__language_code';
  private latestVersionKey = 'begreen__latest_version';
  private keys = {
    email: 'begreen__email',
    baskets: 'begreen__baskets',
  };
  private _hasUnreadBadge?: boolean;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly biometryService: BiometryService,
    private readonly storageService: StorageService,
  ) {
    this.setOnboardingDone();
    this.setUnreadBadge();
  }

  get completedMigrations(): VersionMigrationDto[] {
    const value = this.storageService.get(this.completedMigrationsKey);

    if (value) {
      return UtilsService.parseJson(value);
    } else {
      return [];
    }
  }

  get hasUnreadBadge(): boolean {
    return this._hasUnreadBadge === true;
  }

  get isOnboardingDone(): boolean {
    return this.storageService.get(this.isOnboardingDoneKey) === 'true';
  }

  get latestVersion(): string | undefined {
    return this.storageService.get(this.latestVersionKey);
  }

  get storedBaskets(): BasketDto[] {
    const value = this.storageService.get(this.keys.baskets);

    if (value) {
      return UtilsService.parseJson(value);
    } else {
      return [];
    }
  }

  get storedEmail(): string {
    if (
      this.authenticationService.decodedToken &&
      this.authenticationService.decodedToken.sub
    ) {
      return this.authenticationService.decodedToken.sub;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.storageService.get(this.keys.email)!;
    }
  }

  get storedLanguage(): LanguageCode {
    return (
      (this.storageService.get(this.languageCodeKey) as LanguageCode) || ''
    );
  }

  clearLocalStorage() {
    for (const value of Object.values(this.keys)) {
      this.storageService.remove(value);
    }
  }

  init() {
    combineLatest([
      this.authenticationService.isAuthenticated$,
      this.biometryService.isBiometricAuthDesired$,
    ])
      .pipe(
        distinctUntilChanged(),
        tap(([isAuthenticated, isBiometricAuthDesired]) => {
          if (!isAuthenticated && !isBiometricAuthDesired) {
            this.clearLocalStorage();
          }
        }),
      )
      .subscribe();
  }

  markBasketAsRead(basketCode: string): void {
    const baskets = this.storedBaskets;

    const storedBasketIndex = baskets.findIndex(
      (storedBasket) => storedBasket.code === basketCode,
    );

    if (storedBasketIndex > -1) {
      baskets[storedBasketIndex].isRead = true;

      this.storageService.set(this.keys.baskets, baskets);

      this.setUnreadBadge();
    }
  }

  removeBasket(basketCode: string): void {
    const baskets = this.storedBaskets;

    const storedBasketIndex = baskets.findIndex(
      (storedBasket) => storedBasket.code === basketCode,
    );

    baskets.splice(storedBasketIndex, 1);

    this.storageService.set(this.keys.baskets, baskets);

    this.setUnreadBadge();
  }

  setOnboardingDone() {
    this.storageService.set(this.isOnboardingDoneKey, true);
  }

  setUnreadBadge() {
    this._hasUnreadBadge = this.storedBaskets.some(
      (basket) => basket.isRead === false,
    );
  }

  storeBaskets(baskets: BasketDto[]): void {
    this.storageService.set(this.keys.baskets, baskets);

    this.setUnreadBadge();
  }

  storeLanguage(code: LanguageCode): void {
    this.storageService.set(this.languageCodeKey, code);
  }

  storeLatestVersion(version: string): void {
    this.storageService.set(this.latestVersionKey, version);
  }

  upsertBasket(basket: BasketDto): number | null | undefined {
    const baskets = this.storedBaskets;

    let previousLocalNotificationId = null;

    const storedBasketIndex = baskets.findIndex(
      (storedBasket) => storedBasket.code === basket.code,
    );

    let basketPreviousStatus: string | undefined = undefined;

    if (storedBasketIndex > -1) {
      previousLocalNotificationId =
        baskets[storedBasketIndex].localNotificationId;

      basketPreviousStatus = baskets.splice(storedBasketIndex, 1)[0].status
        ?.code;
    }

    this.storageService.set(this.keys.baskets, [
      {
        ...basket,
        isRead:
          storedBasketIndex > -1 &&
          basketPreviousStatus === basket.status?.code,
      },

      ...baskets,
    ]);

    this.setUnreadBadge();

    return previousLocalNotificationId;
  }

  upsertCompletedMigration(migration: VersionMigrationDto): void {
    const completedMigrations = this.completedMigrations;

    const storedMigrationIndex = completedMigrations.findIndex(
      (storedMigration) => storedMigration.name === migration.name,
    );

    if (storedMigrationIndex > -1) {
      completedMigrations.splice(storedMigrationIndex, 1);
    }

    this.storageService.set(this.completedMigrationsKey, [
      ...completedMigrations,
      migration,
    ]);
  }
}
