import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DashboardProgressionDto } from '@be-green/dto';

@Component({
  selector: 'be-green--admin--dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardCardComponent {
  @Input() icon!: string;
  @Input() iconColor: 'blue' | 'cyan' | 'green' | 'orange' | 'purple' =
    'purple';
  @Input() lastPeriodLabel?: string;
  @Input() progression?: DashboardProgressionDto;
  @Input() thisPeriodLabel?: string;
  @Input() title!: string;
  @Input() total?: number;
  @Input() unit?: string;
}
