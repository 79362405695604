<div class="card">
  <p-table
    #programIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de {totalRecords}'
    "
    [dataKey]="'code'"
    [globalFilterFields]="[
      'collectionType',
      'createdAt',
      'descriptionAr',
      'descriptionFr',
      'endDate',
      'nameAr',
      'nameFr',
      'startDate',
      'summaryAr',
      'summaryFr',
      'type',
      'updatedAt'
    ]"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [value]="programs"
    (onRowReorder)="onRowReorder($event)"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex justify-content-between align-items-start flex-column sm:flex-row"
      >
        <div class="flex-column sm:flex-row mb-2">
          <button
            class="p-button-outlined sm:mb-2 mr-2"
            (click)="clearProgramsTable()"
            pButton
            label="Effacer tous les filtres"
            icon="pi pi-filter-slash"
          ></button>

          <button
            class="mb-2 p-button-success"
            routerLink="/programs/create"
            pButton
            label="Créer un programme"
            icon="pi pi-plus"
          ></button>
        </div>

        <span class="p-input-icon-left mb-2">
          <i class="pi pi-search"></i>
          <input
            class="w-full"
            type="text"
            #filterProgramIndexDataTable
            (input)="
              programIndexDataTable.filterGlobal(
                eventTargetValue($event),
                'contains'
              )
            "
            pInputText
            placeholder="Filtrer aussi par description"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th></th>

        <th></th>

        <th style="min-width: 20rem" pSortableColumn="nameFr" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameFr"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="type">
          <div class="flex justify-content-between align-items-center">
            Type

            <p-sortIcon field="type"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="collectionType">
          <div class="flex justify-content-between align-items-center">
            Collecte

            <p-sortIcon field="collectionType"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="startDate">
          <div class="flex justify-content-between align-items-center">
            Début

            <p-sortIcon field="startDate"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="endDate">
          <div class="flex justify-content-between align-items-center">
            Fin

            <p-sortIcon field="endDate"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="summaryFr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Résumé
            </div>

            <p-sortIcon field="summaryFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="summaryAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Résumé
            </div>

            <p-sortIcon field="summaryAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="createdAt">
          <div class="flex justify-content-between align-items-center">
            Créé

            <p-sortIcon field="createdAt"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="updatedAt">
          <div class="flex justify-content-between align-items-center">
            Mis à jour

            <p-sortIcon field="updatedAt"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th></th>

        <th></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter field="type" matchMode="equals" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="types"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getTypeTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown> </ng-template
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="collectionType"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Toutes"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="collectionTypes"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getCollectionTypeTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown> </ng-template
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="startDate"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="endDate"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="summaryFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="summaryAr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="createdAt"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="updatedAt"
          ></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-program let-index="rowIndex">
      <ng-container *ngLet="asProgramDto(program) as program">
        <tr [pReorderableRow]="index" *ngIf="program">
          <td>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>

          <td style="text-align: center">
            <div class="flex" style="line-height: 0">
              <p-image
                class="shadow-4"
                [preview]="true"
                [src]="imageBaseUrl + program.imageUrl"
                width="100"
              ></p-image>
            </div>
          </td>

          <td pFrozenColumn>
            {{ program.nameFr }}
          </td>

          <td>
            <p-tag
              [value]="
                program.type === 'goodDeal'
                  ? 'Bon plan'
                  : program.type === 'pro'
                  ? 'Pro'
                  : 'Recyclage'
              "
              [severity]="getTypeTagSeverity(program.type)"
            ></p-tag>

            <p *ngIf="program.type === 'pro' && program.organizationType">
              <p-tag
                [value]="program.organizationType.nameFr"
                severity="info"
              ></p-tag>
            </p>
          </td>

          <td>
            <p-tag
              [value]="
                program.collectionType === 'realTime'
                  ? 'Temps réel'
                  : 'Différée'
              "
              [severity]="getCollectionTypeTagSeverity(program.collectionType)"
              *ngIf="program.type === 'recycling'; else notSet"
            ></p-tag>
          </td>

          <td>
            {{ program.startDate | date : 'shortDate' : '' : 'fr' }}
          </td>

          <td>
            {{ program.endDate | date : 'shortDate' : '' : 'fr' }}
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="program.nameAr">
              <span class="text-sm">{{ program.nameAr }}</span>
            </ng-container>
          </td>

          <td>
            {{ program.summaryFr }}
          </td>

          <td class="text-right" dir="rtl">
            <ng-container *ngIf="program.summaryAr">
              <span class="text-sm">{{ program.summaryAr }}</span>
            </ng-container>
          </td>

          <td>
            {{ program.createdAt | date : 'long' : '' : 'fr' }}
          </td>

          <td>
            {{ program.updatedAt | date : 'long' : '' : 'fr' }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/programs/' + program.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10">Aucun programme trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="10">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
