import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { LanguageCode } from './language.dto';
import { UserDto } from './user.dto';

export const enum DeviceApp {
  Handler = 'handler',
  Mobile = 'mobile',
}

export const enum DevicePlatform {
  iOS = 'ios',
  Android = 'android',
}

export const enum DeviceOperatingSystem {
  iOS = 'ios',
  Android = 'android',
  Unknown = 'unknown',
}

export const enum AuthProvider {
  Apple = 'Apple',
  Facebook = 'Facebook',
  Google = 'Google',
}

export class DeviceDto {
  @IsNotEmpty()
  readonly uuid!: string;

  @IsNotEmpty()
  readonly app!: DeviceApp;

  @IsNotEmpty()
  readonly model!: string;

  @IsNotEmpty()
  readonly platform!: DevicePlatform;

  @IsNotEmpty()
  readonly operatingSystem!: DeviceOperatingSystem;

  @IsNotEmpty()
  readonly osVersion!: string;

  @IsOptional()
  readonly manufacturer?: string;

  @IsOptional()
  readonly memoryUsedInMb?: number;

  @IsOptional()
  readonly realDiskFreeInMb?: number;

  @IsOptional()
  readonly realDiskTotalInMb?: number;

  @IsOptional()
  readonly webViewVersion?: string;

  @ValidateNested()
  @Type(() => UserDto)
  @IsOptional()
  readonly user?: UserDto;

  @IsOptional()
  readonly authProvider?: AuthProvider;

  @IsOptional()
  readonly languageCode?: LanguageCode;

  @IsOptional()
  readonly notificationsAllowed?: boolean;

  @IsOptional()
  readonly notificationsAllowedAt?: Date;

  @IsOptional()
  readonly notificationsToken?: string;
}
