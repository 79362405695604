import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CachedHandlerRankingDto,
  CachedProgramRankingDto,
  CachedRankingDto,
  DemoVideoConfigDto,
  LanguageCode,
} from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParameterApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  createDemoVideos(
    demoVideoConfig: DemoVideoConfigDto,
  ): Observable<{ code: string }> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.post<{ code: string }>(
      `/parameters/demo-videos`,
      demoVideoConfig,
    );
  }

  getCachedHandlerRanking(): Observable<CachedHandlerRankingDto> {
    return this.httpClient.get<CachedHandlerRankingDto>(
      `/parameters/ranking/handlers`,
    );
  }

  getCachedPersonPerProgramRanking(
    programCode: string,
  ): Observable<CachedRankingDto> {
    return this.httpClient.get<CachedRankingDto>(
      `/parameters/ranking/programs/${programCode}`,
    );
  }

  getCachedProProgramRanking(): Observable<CachedProgramRankingDto> {
    return this.httpClient.get<CachedProgramRankingDto>(
      `/parameters/ranking/programs/pro`,
    );
  }

  getCachedUserRanking(): Observable<CachedRankingDto> {
    return this.httpClient.get<CachedRankingDto>(`/parameters/ranking`);
  }

  getDemoVideos(): Observable<DemoVideoConfigDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<DemoVideoConfigDto>(`/parameters/demo-videos`);
  }

  getLocalizedHandlerDemoVideo(
    languageCode: LanguageCode,
  ): Observable<{ url: string }> {
    return this.httpClient.get<{ url: string }>(
      `/parameters/demo-videos/handler/${languageCode}`,
    );
  }

  getLocalizedMobileDemoVideo(
    languageCode: LanguageCode,
  ): Observable<{ url: string }> {
    return this.httpClient.get<{ url: string }>(
      `/parameters/demo-videos/mobile/${languageCode}`,
    );
  }

  updateDemoVideos(demoVideoConfig: DemoVideoConfigDto): Observable<void> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.put<void>(
      `/parameters/demo-videos`,
      demoVideoConfig,
    );
  }
}
