import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardDto } from '@be-green/dto';
import { AuthenticationService } from '@be-green/ui-services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardApi {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
  ) {}

  getDefault(
    period: 'month' | 'week' | 'year' = 'week',
  ): Observable<DashboardDto> {
    if (!this.authenticationService.isAdmin) {
      return of();
    }

    return this.httpClient.get<DashboardDto>(`/dashboard/${period}`);
  }
}
