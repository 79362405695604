import { Injectable } from '@angular/core';
import { CategoryApi } from '@be-green/api-services';
import { CategoryDto, EditCategoryDto } from '@be-green/dto';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class CategoriesEditService {
  private _currentStep = 1;
  private _editAction?: 'create' | 'update';
  private _isDirty = false;
  private _category?: CategoryDto;
  private _dirtyCategory?: EditCategoryDto;
  private uiReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly categoryApi: CategoryApi) {}

  get currentStep() {
    return this._currentStep;
  }

  get dirtyCategory() {
    return this._dirtyCategory;
  }

  get editAction() {
    return this._editAction;
  }

  get isDirty() {
    return this._isDirty;
  }

  get category() {
    return this._category;
  }

  get uiReady$() {
    return this.uiReadySubject.asObservable();
  }

  init() {
    this._isDirty = false;
    this.uiReadySubject.next(false);
  }

  saveDirtyCategory(): Observable<{ code: string } | void> {
    if (!this._dirtyCategory || !this.editAction) {
      return throwError(() => new Error(''));
    }

    const categoryCodeToUpdate = this._category
      ? this._category.code
      : undefined;

    switch (this._editAction) {
      case 'create':
        return this.categoryApi.create(this._dirtyCategory).pipe(
          tap(() => {
            this._dirtyCategory = undefined;
            this._isDirty = false;
            this._currentStep = 1;
            this.uiReadySubject.next(false);
          }),
        );

      case 'update':
        return this.categoryApi
          .update(categoryCodeToUpdate as string, this._dirtyCategory)
          .pipe(
            tap(() => {
              this._dirtyCategory = undefined;
              this._isDirty = false;
              this._currentStep = 1;
              this.uiReadySubject.next(false);
            }),
          );

      default:
        return throwError(() => new Error(''));
    }
  }

  setDirty(isDirty = true) {
    this._isDirty = isDirty;
  }

  setDirtyCategory(category: EditCategoryDto) {
    this._dirtyCategory = { ...this._dirtyCategory, ...category };
  }

  setCategory(
    editAction: 'create' | 'update',
    category?: CategoryDto,
    dirtyCategory?: EditCategoryDto,
  ) {
    this._editAction = editAction;

    if (editAction === 'create' || !category || !dirtyCategory) {
      this._dirtyCategory = undefined;
      this._category = undefined;
    } else {
      this._dirtyCategory = dirtyCategory;
      this._category = category;
    }

    this.uiReadySubject.next(true);
  }
}
