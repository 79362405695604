import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationTypeApi } from '@be-green/api-services';
import {
  CollectionType,
  OrganizationTypeDto,
  ProgramType,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService } from '@be-green/ui-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { ProgramsEditService } from '../../programs-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--programs-edit-steps-general',
  templateUrl: './programs-edit-steps-general.component.html',
  styleUrls: ['./programs-edit-steps-general.component.scss'],
})
export class ProgramsEditStepsGeneralComponent implements OnInit {
  collectionTypes: ValueLabelDto[] = [
    { value: CollectionType.RealTime, label: 'Temps réel' },
    { value: CollectionType.Deferred, label: 'Différée' },
  ];
  currentStepForm!: FormGroup;
  isLoading = false;
  organizationTypes?: OrganizationTypeDto[];
  selectedOrganizationTypeNameFr?: string;
  types: ValueLabelDto[] = [
    { value: ProgramType.GoodDeal, label: 'Bon plan' },
    { value: ProgramType.Pro, label: 'Pro' },
    { value: ProgramType.Recycling, label: 'Recyclage' },
  ];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly organizationTypeApi: OrganizationTypeApi,
    readonly programsEditService: ProgramsEditService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.programsEditService.currentStep === 1) {
      this.programsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.organizationTypeApi.getAll().subscribe({
            next: (organizationTypes) =>
              (this.organizationTypes = organizationTypes),

            error: (error: HttpErrorResponse) =>
              this.errorService.handleError(error),
          });

          this.initForm();
        });
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      collectionType: AbstractControl;
      nameAr: AbstractControl;
      nameFr: AbstractControl;
      organizationType: AbstractControl;
      summaryAr: AbstractControl;
      summaryFr: AbstractControl;
      type: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      collectionType: [null],
      nameAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      nameFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(140)]),
      ],
      organizationType: [null],
      summaryAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      summaryFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      type: [null, Validators.required],
    });

    if (this.programsEditService.dirtyProgram) {
      this.currentStepForm.patchValue(this.programsEditService.dirtyProgram);
      this.currentStepFormControls.type.disable();

      if (
        this.programsEditService.dirtyProgram &&
        this.programsEditService.dirtyProgram.type === ProgramType.Pro &&
        this.programsEditService.dirtyProgram.organizationType
      ) {
        this.currentStepFormControls.organizationType.patchValue(
          this.programsEditService.dirtyProgram.organizationType.code,
        );
      }
    } else {
      this.currentStepFormControls.type.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value: ProgramType) => {
          /**
           * Clear optional validators that depend on `type`
           */

          this.currentStepFormControls.collectionType.clearValidators();
          this.currentStepFormControls.organizationType.clearValidators();

          if (value) {
            switch (value) {
              case ProgramType.Pro:
                this.currentStepFormControls.organizationType.setValidators(
                  Validators.required,
                );

                /**
                 * And cancel `collectionType` value
                 */
                this.currentStepFormControls.collectionType.setValue(null);

                break;
              case ProgramType.Recycling:
                this.currentStepFormControls.collectionType.setValidators(
                  Validators.required,
                );

                /**
                 * And cancel `organizationType` value
                 */
                this.currentStepFormControls.organizationType.setValue(null);

                break;
              default:
                this.currentStepFormControls.collectionType.setValue(null);
                this.currentStepFormControls.organizationType.setValue(null);
            }
          }

          this.currentStepFormControls.collectionType.updateValueAndValidity();
          this.currentStepFormControls.organizationType.updateValueAndValidity();
        });
    }
  }

  nextStep() {
    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    if (this.currentStepFormControls.type.value === ProgramType.Pro) {
      this.programsEditService.setDirtyProgram({
        ...this.currentStepForm.value,

        organizationType: {
          code: this.currentStepFormControls.organizationType.value,
          nameFr: this.selectedOrganizationTypeNameFr,
        },
      });
    } else {
      this.programsEditService.setDirtyProgram(this.currentStepForm.value);
    }

    this.programsEditService.nextStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  persistOrganizationTypeName(selectedOrganizationTypeCode?: string) {
    if (
      !this.organizationTypes ||
      this.currentStepFormControls.type.value !== ProgramType.Pro
    ) {
      return;
    }

    if (selectedOrganizationTypeCode) {
      this.selectedOrganizationTypeNameFr = this.organizationTypes.find(
        (organizationType) =>
          organizationType.code === selectedOrganizationTypeCode,
      )?.nameFr;
    } else {
      this.selectedOrganizationTypeNameFr = undefined;
    }
  }
}
