<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start"></div>

  <div class="p-toolbar-group-end">
    <button
      class="p-button-help ml-2"
      icon="pi pi-upload"
      label="Export"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="export()"
    ></button>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="card">
  <p-table
    #personIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="apiPageSize"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [totalRecords]="recordCount"
    [value]="users"
    (onLazyLoad)="fetchNextPage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          style="min-width: 20rem"
          pSortableColumn="person.lastName"
          pFrozenColumn
        >
          <div class="flex justify-content-between align-items-center">
            Nom

            <p-sortIcon field="lastName"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="person.firstName">
          <div class="flex justify-content-between align-items-center">
            Prénom

            <p-sortIcon field="firstName"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="status.code">
          <div class="flex justify-content-between align-items-center">
            État

            <p-sortIcon field="status.code"></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 15rem"
          pSortableColumn="person.currentPeriodApprovedBasketCount"
        >
          <div class="flex justify-content-between align-items-center">
            QR codes validés

            <p-sortIcon
              field="person.currentPeriodApprovedBasketCount"
            ></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="person.currentRank">
          <div class="flex justify-content-between align-items-center">
            Classement

            <p-sortIcon field="person.currentRank"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="person.currentBadge">
          <div class="flex justify-content-between align-items-center">
            Badge

            <p-sortIcon field="person.currentBadge"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="person.created_at">
          <div class="flex justify-content-between align-items-center">
            Créé

            <p-sortIcon field="person.created_at"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="person.updated_at">
          <div class="flex justify-content-between align-items-center">
            Mis à jour

            <p-sortIcon field="person.updated_at"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn>
          <p-columnFilter type="text" field="person.lastName"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="person.firstName"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="status.code"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="statuses"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getStatusTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="person.currentPeriodApprovedBasketCount"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="person.currentRank"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="person.currentBadge"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="badges"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle">
                    <img
                      class="vertical-align-middle"
                      [src]="
                        'assets/images/badges/levels/' + option.value + '.svg'
                      "
                      [alt]="option.label"
                      width="24"
                    />

                    <span class="ml-1 mt-1">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            field="person.created_at"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            field="person.updated_at"
          ></p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user>
      <ng-container *ngLet="asUserDto(user) as user">
        <tr *ngIf="user && user.person">
          <td pFrozenColumn>
            {{ user.person.lastName }}
          </td>

          <td>
            {{ user.person.firstName }}
          </td>

          <td>
            <p-tag
              [value]="user.status.name"
              [severity]="getStatusTagSeverity(user.status.code)"
              *ngIf="user.status"
            ></p-tag>
          </td>

          <td>
            <span
              *ngIf="user.person.currentPeriodApprovedBasketCount; else notSet"
              >{{ user.person.currentPeriodApprovedBasketCount }}</span
            >
          </td>

          <td>
            <span *ngIf="user.person.currentRank; else notSet">{{
              user.person.currentRank
            }}</span>
          </td>

          <td style="text-align: center">
            <p-avatar
              size="xlarge"
              [image]="
                'assets/images/badges/levels/' +
                user.person.currentBadge +
                '.svg'
              "
            ></p-avatar>

            <p>
              QR codes M-1 :
              <span
                *ngIf="user.person.lastPeriodApprovedBasketCount; else notSet"
              >
                {{ user.person.lastPeriodApprovedBasketCount }}</span
              >
            </p>
          </td>

          <td>
            {{ user.person.createdAt | date : 'long' : '' : 'fr' }}
          </td>

          <td>
            {{ user.person.updatedAt | date : 'long' : '' : 'fr' }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/users/' + user.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Aucun utilisateur trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="7">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
