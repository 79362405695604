import { Type } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsOptional,
  IsPositive,
  Max,
  Min,
} from 'class-validator';

export const enum QuerySortOrder {
  Ascend = 'ASC',
  Descend = 'DESC',
}

export class QueryFilterDto {
  matchMode?:
    | 'contains'
    | 'dateAfter'
    | 'dateBefore'
    | 'dateIs'
    | 'dateIsNot'
    | 'endsWith'
    | 'equals'
    | 'gt'
    | 'gte'
    | 'lt'
    | 'lte'
    | 'notContains'
    | 'notEquals'
    | 'startsWith';
  operator?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export class QueryDto {
  @IsOptional()
  filters?: string;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsOptional()
  page?: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @Min(10)
  @Max(50)
  @IsOptional()
  results?: number;

  @IsOptional()
  sortField?: string;

  @IsOptional()
  sortOrder?: QuerySortOrder;
}

export class QueryUiDto {
  @IsOptional()
  filters?: {
    [s: string]: QueryFilterDto;
  };

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @IsPositive()
  @IsOptional()
  page?: number;

  @Type(() => Number)
  @IsNumber()
  @IsInt()
  @Min(10)
  @Max(50)
  @IsOptional()
  results?: number;

  @IsOptional()
  sortField?: string;

  @IsOptional()
  sortOrder?: QuerySortOrder;
}
