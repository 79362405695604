<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/layout/images/logo-dark.svg" alt="logo" />
    <span [innerHTML]="logoName"></span>
  </a>

  <a
    class="p-link layout-menu-button layout-topbar-button"
    href="#"
    (click)="toggleMenu($event)"
  >
    <i class="pi pi-bars"></i>
  </a>

  <a
    class="p-link layout-topbar-menu-button layout-topbar-button"
    href="#"
    (click)="toggleTopMenu($event)"
  >
    <i class="pi pi-ellipsis-v"></i>
  </a>

  <div
    class="layout-topbar-menu"
    [ngClass]="{ 'layout-topbar-menu-mobile-active': topMenuActive }"
  >
    <!-- <a
      class="p-link layout-topbar-button"
      (click)="notificationsOverlayPanel.toggle($event)"
    >
      <i class="pi pi-bell"></i>
      <span>Notifications</span>
    </a> -->

    <a
      class="p-link layout-topbar-button"
      (click)="profileOverlayPanel.toggle($event)"
    >
      <p-avatar
        label="{{ authenticationService.decodedToken?.profile?.initials }}"
        shape="circle"
        size="large"
        styleClass="mr-2"
        [style]="{
          'background-color':
            authenticationService.decodedToken?.profile?.nameToHslColors
              ?.background,
          color:
            authenticationService.decodedToken?.profile?.nameToHslColors
              ?.contrast
        }"
      ></p-avatar>
    </a>
  </div>
</div>

<!-- <p-overlayPanel #notificationsOverlayPanel>
  <ng-template pTemplate>
    <be-green--admin--notifications></be-green--admin--notifications>
  </ng-template>
</p-overlayPanel> -->

<p-overlayPanel #profileOverlayPanel>
  <ng-template pTemplate>
    <div class="layout-menu-container">
      <div class="p-2 white-space-nowrap font-bold">
        {{ authenticationService.decodedToken?.profile?.firstName }}
        {{ authenticationService.decodedToken?.profile?.lastName }}
      </div>

      <ul class="layout-menu" role="menu">
        <li class="layout-menuitem-category" role="none" [root]="true">
          <div class="layout-menuitem-root-text">
            <a role="menuitem" (click)="logout()" pRipple>
              <i class="layout-menuitem-icon pi pi-sign-out"></i>

              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</p-overlayPanel>
