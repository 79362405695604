<div class="surface-card shadow-2 p-3 border-round">
  <div class="flex justify-content-between mb-3">
    <div>
      <span class="block text-500 font-medium mb-3 text-xl">{{ title }}</span>

      <div class="text-900 font-medium text-3xl">
        {{ total | number : '1.0-0' : 'fr' }}
        {{ unit }}
      </div>
    </div>

    <div
      class="flex align-items-center justify-content-center bg-{{
        iconColor
      }}-100 border-round"
      style="width: 2.5rem; height: 2.5rem"
    >
      <i class="pi text-{{ iconColor }}-500 text-xl" [ngClass]="icon"></i>
    </div>
  </div>

  <div class="flex justify-content-between">
    <div>
      <div>
        <span
          class="font-medium"
          [ngClass]="{
            'text-green-500 ':
              !progression ||
              progression.periodProgression === null ||
              progression.periodProgression > 0,
            'text-red-500 ': progression && progression.periodProgression <= 0
          }"
        >
          {{ progression?.periodDifference | number : '1.0-0' : 'fr' }}
          {{ unit }}
        </span>

        <span class="text-500">
          {{ thisPeriodLabel }}
        </span>
      </div>

      <div class="text-500">
        ({{ progression?.lastPeriod | number : '1.0-0' : 'fr' }}
        {{ unit }}
        {{ lastPeriodLabel }})
      </div>
    </div>

    <div
      class="text-xl"
      [ngClass]="{
        'text-green-500 ':
          !progression ||
          progression.periodProgression === null ||
          progression.periodProgression > 0,
        'text-red-500 ': progression && progression.periodProgression <= 0
      }"
    >
      <i
        *ngIf="progression && progression.periodProgression !== 0"
        [class]="
          progression.periodProgression >= 0
            ? 'pi pi-arrow-up'
            : 'pi pi-arrow-down'
        "
      ></i>

      {{
        progression?.periodProgression === null
          ? '∞'
          : (progression?.periodProgression | percent : '1.0-0' : 'fr')
      }}
    </div>
  </div>
</div>
