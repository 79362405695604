import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuillModules } from 'ngx-quill';
import { ProgramsEditService } from '../../programs-edit.service';

@UntilDestroy()
@Component({
  selector: 'be-green--admin--programs-edit-steps-description',
  templateUrl: './programs-edit-steps-description.component.html',
  styleUrls: ['./programs-edit-steps-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramsEditStepsDescriptionComponent implements OnInit {
  currentStepForm!: FormGroup;
  descriptionAr?: string;
  descriptionFr?: string;
  isLoading = false;
  quillModules: QuillModules = {
    clipboard: { matchVisual: false },
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    readonly programsEditService: ProgramsEditService,
  ) {}

  ngOnInit(): void {
    if (!this.programsEditService.dirtyProgram) {
      this.router.navigate(['general'], {
        relativeTo: this.activatedRoute.parent,
      });
    }

    if (this.programsEditService.currentStep === 3) {
      this.programsEditService.uiReady$
        .pipe(untilDestroyed(this))
        .subscribe(() => this.initForm());

      if (this.programsEditService.editAction === 'update') {
        this.descriptionAr = this.programsEditService.program?.descriptionAr;
        this.descriptionFr = this.programsEditService.program?.descriptionFr;
      }
    }
  }

  get currentStepFormControls() {
    return this.currentStepForm?.controls as {
      descriptionAr: AbstractControl;
      descriptionFr: AbstractControl;
    };
  }

  private initForm() {
    this.currentStepForm = this.formBuilder.group({
      descriptionAr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(65536)]),
      ],
      descriptionFr: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(65536)]),
      ],
    });

    if (this.programsEditService.dirtyProgram) {
      this.currentStepForm.patchValue(this.programsEditService.dirtyProgram);
    }
  }

  nextStep() {
    this.currentStepFormControls.descriptionAr.setValue(this.descriptionAr);
    this.currentStepFormControls.descriptionFr.setValue(this.descriptionFr);

    this.currentStepForm.markAllAsTouched();

    if (this.currentStepForm.invalid) {
      return;
    }

    this.programsEditService.setDirtyProgram(this.currentStepForm.value);

    this.programsEditService.nextStep();

    this.router.navigate(['settings'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  previousStep() {
    this.programsEditService.previousStep();

    this.router.navigate(['illustration'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
